import { Fragment } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import { PaginaCardapio } from "../pages/menu";
import { DetalhesPedido } from "../pages/menu/detalhesPedido";
import { MeusPedidos } from "../pages/menu/meusPedidos";

// import { CardapioProgramado } from "../pages/menu/cardapio";
import { LoginWaypag } from "../pages/waypag/login";
import { MeusPagamentosWaypag } from "../pages/waypag/meusPagamentos";
import { CpfWaypag } from "../pages/waypag/cpf";
import { Home } from "../pages/home";
import { CadastroWaypag } from "../pages/waypag/cadastro";
import { FormasPagamentoWaypag } from "../pages/waypag/formas_pagamento";
import { AdicionarCartaoWaypag } from "../pages/waypag/adicionar_cartao";
import { ProcessandoPagamentoWaypag } from "../pages/waypag/processando_pagamento";
import { Cupons } from "../pages/menu/cupons";
import { useApi } from "../contexts/api";
import { CircularProgress } from "@mui/material";
import { useTheme } from "styled-components";
import { useUserAuth } from "../contexts/userAuth";
import { CarrinhoProvider } from "../contexts/carrinho";

const RoutesApp = () => {
  const { loading, logado } = useUserAuth();
  const theme = useTheme();

  return loading ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{ color: theme.coresCardapio.brand500 }}
        style={{ alignSelf: "center" }}
      />
    </div>
  ) : logado ? (
    <BrowserRouter>
      <CarrinhoProvider>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={"/menu/restaurante-waymenu/delivery/"} />}
          />

          <Route path="/cupons" element={<Cupons />} />
          <Route path="/login" element={<Login />} />
          <Route path="/menu/:urlAmigavel/" element={<PaginaCardapio />} />
          <Route
            path="/menu/:urlAmigavel/:tipoCardapio/"
            element={<PaginaCardapio />}
          />
          <Route
            path="/menu/:urlAmigavel/:tipoCardapio/:slugMesa/"
            element={<PaginaCardapio />}
          />
          <Route
            path="/usuarios/pedidos/detalhes"
            element={<DetalhesPedido />}
          />
          <Route path="*" element={<Home />} />
          <Route path="/waypag/login" element={<LoginWaypag />} />
        </Routes>
      </CarrinhoProvider>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <CarrinhoProvider>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={"/menu/restaurante-waymenu/delivery/"} />}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/usuario/pedidos" element={<MeusPedidos />} />
          <Route path="/cupons" element={<Cupons />} />
          <Route
            path="/usuarios/pedidos/detalhes"
            element={<DetalhesPedido />}
          />

          <Route path="/menu/:urlAmigavel/" element={<PaginaCardapio />} />
          <Route
            path="/menu/:urlAmigavel/:tipoCardapio/"
            element={<PaginaCardapio />}
          />
          <Route
            path="/menu/:urlAmigavel/:tipoCardapio/:slugMesa/"
            element={<PaginaCardapio />}
          />
          <Route path="*" element={<Home />} />

          <Route path="/waypag/login" element={<LoginWaypag />} />
          <Route
            path="/waypag/meusPagamentos"
            element={<MeusPagamentosWaypag />}
          />
          <Route path="/waypag/cpf" element={<CpfWaypag />} />

          <Route path="/waypag/cadastro" element={<CadastroWaypag />} />
          <Route
            path="/waypag/formasPagamento"
            element={<FormasPagamentoWaypag />}
          />
          <Route
            path="/waypag/adicionarCartao"
            element={<AdicionarCartaoWaypag />}
          />
          <Route
            path="/waypag/processandoPagamento"
            element={<ProcessandoPagamentoWaypag />}
          />
          <Route path="/404" element={<h1>Página não encontrada.</h1>} />
        </Routes>
      </CarrinhoProvider>
    </BrowserRouter>
  );
};

export default RoutesApp;
