import { Calendar, Clock } from "phosphor-react";
import {
  BotaoCancelarPedido,
  CabecalhoContainer,
  Container,
  ContainerDataHora,
  ContainerDetalhesPedido,
  ContainerStatus,
  FooterCard,
  IconeData,
  IconeHora,
  LinhaDataHora,
  Subtitulo,
  TextoDataHora,
  TextoDetalhesPedido,
  TextoRepetirPedido,
  TextoTotalPedido,
  TextoVerMaisDetalhes,
  Titulo,
} from "./styles";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import GerarModalResponsivo from "../gerarModalResponsivo";
import { ModalExcluir } from "../../pages/menu/meusPedidos/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { ContainerBotoesModal } from "../../pages/menu/detalhesPedido/styles";
import { BotaoAzulPreenchido, BotaoCardapioPreenchidoVerde, BotaoVermelhoPreenchido } from "../botao";
import { useApi } from "../../contexts/api";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCarrinho } from "../../contexts/carrinho";

export function CardPedido({
  valor,
  buscarTodasVendas
}) {
  const { atualizarCarrinho, limparCarrinho } = useCarrinho()
  const navigate = useNavigate()
  const { put, post } = useApi()
  const [status, setStatus] = useState(null)
  async function cancelarPedido() {
    try {
      const res = await put(`vendas/nota/cancelar/${valor.idNota}`, {
        motivoCancelamento: "",
        gerarImpressao: false
      })
      toast.success("Pedido cancelado com sucesso!")

      if(valor.idTransacao !== null){
        cancelarTransacao()
      }
      buscarTodasVendas()
    } catch (error) {
      console.error(error)
    }
  }

  async function cancelarTransacao(){
    try {
      const res = post(`pagamento/pagamento-online-waymenu/cancelar-transacao/${valor.idTransacao}`)
    } catch (error) {
      console.error(error)
    }
  }


  async function repetirPedido(idNota) {
    try {
      const res = await post(`vendas/nota-item/repetir-pedido/${idNota}`)
      console.log(res.data.id);
      atualizarCarrinho(res.data.id)
    } catch (error) {
      console.error(error)
    }
  }
  useState(() => {
    function verificarStatus() {
      let status = valor.status;

      if (valor.status === "ABERTA") {
        status = "Aberta";
      }

      if (valor.status === "CANCELADA") {
        status = "Cancelada";
      }

      if (valor.status === "RECEBIDA") {
        status = "Pedido entregue";
      }

      if (valor.status === "FECHADA") {

        if (valor.tipoEntrega === "COMUM") {
          switch (valor.statusExpedicao) {
            case "PENDENTE":
              status = "Pedido aceito"
              break
            case "EXPEDIDA":
              status = "Saiu para entrega"
              break
          }

        }
        if (valor.tipoEntrega === "RETIRADA_LOCAL") {
          switch (valor.statusExpedicao) {
            case 'PENDENTE':
              status = "Pedido aceito"
              break
            case "RETIRADA_LIBERADA":
              status = "Liberada retirada"

          }
        }

      }

      setStatus(status);
    }


    verificarStatus()
  }, [])

  const getStatusColor = (status) => {
    switch (status) {
      case 'Aberta':
        return '#FFD700';
      case 'Cancelada':
        return '#e74a3b';
      case 'Liberada retirada':
        return '#3498db';
      case 'Liberada entrega':
        return '#8e44ad';
      case 'Saiu para entrega':
        return '#f39c12';
      case 'Pedido entregue':
        return 'rgba(28, 200, 138, 1)';
      case 'Pedido aceito':
        return '#00E676';
      default:
        return '#a6a6a6';
    }
  };

  return (
    <>
      <Container>
        <CabecalhoContainer>
          <Titulo>{valor.nomeEmpresa}</Titulo>
          <ContainerStatus status={getStatusColor(status)} ><span>{status}</span></ContainerStatus>
        </CabecalhoContainer>
        <Subtitulo>Código do pedido: #{valor.numero}</Subtitulo>
        <ContainerDataHora>
          <LinhaDataHora>
            <IconeData>
              <Calendar weight="bold" size={24} />
            </IconeData>
            <TextoDataHora>{dayjs(valor.dataVenda).format("DD/MM/YYYY")}</TextoDataHora>
            <IconeHora>
              <Clock weight="bold" size={24} />
            </IconeHora>
            <TextoDataHora>
              {dayjs(valor.horaVenda, "HH:mm:ss.SSS").format("HH:mm")}
            </TextoDataHora>
          </LinhaDataHora>
        </ContainerDataHora>
        <ContainerDetalhesPedido>
          <TextoDetalhesPedido>
            Subtotal:
            <span>R$ {valor.valorSubtotal.toFixed(2)}</span>
          </TextoDetalhesPedido>
          <TextoDetalhesPedido>
            Taxa de Entrega:
            <span>R$ {valor.valorFrete.toFixed(2)}</span>
          </TextoDetalhesPedido>
          <TextoDetalhesPedido>
            Desconto:
            <span>R$ {valor.valorDesconto.toFixed(2)}</span>
          </TextoDetalhesPedido>
          <TextoTotalPedido>
            Total
            <span>R$ {valor.valorTotal.toFixed(2)}</span>
          </TextoTotalPedido>
        </ContainerDetalhesPedido>
        <FooterCard>
          <TextoVerMaisDetalhes onClick={() => navigate(`/menu/restaurante-waymenu/delivery/#/detalhes-do-pedido`, {
            state: valor.idNota
          })}>
            Ver mais detalhes
          </TextoVerMaisDetalhes>
          {valor.status === "ABERTA" &&
            <GerarModalResponsivo trigger={<BotaoCancelarPedido status={status}>
              CANCELAR PEDIDO
            </BotaoCancelarPedido>} children={(close) => (
              <ModalExcluir>

                <h1>Cancelar pedido</h1>
                <FontAwesomeIcon icon={faX} onClick={() => close()} />

                <p>Você tem certeza que deseja cancelar o pedido?</p>

                <ContainerBotoesModal>
                  <BotaoVermelhoPreenchido tamanho={"MD"} texto="Não" onClick={() => close()} />
                  <BotaoCardapioPreenchidoVerde tamanho={"MD"} texto="Sim" onClick={() => {
                    cancelarPedido()
                    close()
                  }} />
                </ContainerBotoesModal>
              </ModalExcluir>
            )} />}

          {valor.status === "RECEBIDA" && (
            <BotaoAzulPreenchido
              texto="Repetir pedido"
              onClick={async () => {
                await limparCarrinho();
                repetirPedido(valor.idNota);
              }}
            />
          )}

        </FooterCard>
      </Container>


    </>

  );
}
