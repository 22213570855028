import {
  ThemeProvider as ThemeMaterial,
  createTheme,
} from "@mui/material/styles";
import { createContext, useEffect, useState } from "react";
import { ThemeContext as ThemeStyledComponentes } from "styled-components";
import temaCardapio from "../styles/temaCardapio";
import temaWaypag from "../styles/temaWaypag";
import dark from "../styles/temaDark";
import light from "../styles/temaLight";

export const TemaCustomizadoContext = createContext({
  mudarTema: () => {},
  getTemaDarkAtivo: () => {},
});

export const TemaProvider = ({ children }) => {
  const [temaAtual, setTemaAtual] = useState("light");

  useEffect(() => {
    let tema = localStorage.getItem("_tema");
    setTemaAtual(tema ? tema : "light");
  }, []);

  const mudarTema = () => {
    setTemaAtual(temaAtual === "light" ? "dark" : "light");
    localStorage.setItem("_tema", temaAtual === "light" ? "dark" : "light");
  };

  const getTemaDarkAtivo = () => {
    return temaAtual === "light" ? false : true;
  };

  const temaMaterial = createTheme({
    palette: {
      mode: temaAtual,
      primary: {
        main: "rgba(244, 179, 76, 1)",
        light: "rgba(250, 222, 177, 1)",
        dark: "rgba(240, 148, 0, 1)",
        contrastText: "#fff",
      },
    },
  });

  return (
    <ThemeStyledComponentes.Provider
      value={
        temaAtual === "light"
          ? { ...light, ...temaCardapio, ...temaWaypag }
          : { ...dark, ...temaCardapio, ...temaWaypag }
      }
    >
      <TemaCustomizadoContext.Provider value={{ mudarTema, getTemaDarkAtivo }}>
        <ThemeMaterial theme={temaMaterial}>{children}</ThemeMaterial>
      </TemaCustomizadoContext.Provider>
    </ThemeStyledComponentes.Provider>
  );
};
