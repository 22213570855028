import { styled } from "styled-components";

export const Container = styled.div`
    background: black;
    height: 100vh;
    padding-left: 10rem;
    padding-right: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
`

export const ButtonNavigation = styled.button`
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 56px;
    border: none;
    margin: 0;
    background: #188FFC;
    cursor: pointer;
    border-radius: 16px;

    &:hover {
        opacity: 0.8;
    }
`