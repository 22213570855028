import React, { useState } from "react";
import {
  ContainerCupom,
  RecorteDireita,
  RecorteEsquerda,
  LinhaMeio,
  ImagemProdutoContainer,
  ImagemProduto,
  ContainerTexto,
  IconeFavoritoEstilizado,
  IconeFavoritoEstilizadoPreenchido,
  GerarModalResponsivoCustomizado,
  ColunaPopup,
  LinhaPopup,
  LinhaBotoes,
  ConteudoCupons,
} from "./styles";

import { useTheme } from "styled-components";
import { BotaoCardapioPreenchidoVerde, BotaoCardapioVazado } from "../../botao";
import { ColunaCopiarCupom, LinhaCopiarCupom } from "../../botao/styles";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import { useApi } from "../../../contexts/api";
import { toast } from "react-toastify";
import { useUserAuth } from "../../../contexts/userAuth";
import ErroImagem from "../../../assets/images/img-erro.png";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const Cupom = ({
  item,
  backgroundColor,
  loginAberto = () => {},
  setLoginAberto = () => {},
  logado,
  iconFav = true,
  style,
  cupomResgatado = false
}) => {
  const [state, setState] = useState({
    erro: false,
    favorito: false,
    loading: false,
    ativo: false,
    mostrarValidade: false,
  });

  const theme = useTheme();
  const { largura } = useTamanhoTela();
  const { post } = useApi();
  const { urlBucket } = useUserAuth();
  const [cupomAtivado, setCupomAtivado] = useState(false)

  const favoritar = (event) => {
    event.stopPropagation();
    if (!logado && !loginAberto) {
      setLoginAberto(true);
    } else {
      setState((prevState) => ({ ...prevState, favorito: !prevState.favorito }));
    }
  };

  const ativarCupom = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      await post(
        `/fidelidade/cupom-vantagem/pegar-vantagem/${item.id}?size=9999`
      );
      toast.success("Vantagem ativada com sucesso!");
      setCupomAtivado(true)
      setState((prevState) => ({
        ...prevState,
        loading: false,
        ativo: true,
        erro: false,
      }));
    } catch (error) {
      toast.error("Erro ao ativar vantagem");
      setState((prevState) => ({ ...prevState, loading: false, erro: true }));
    }
  };

  const RenderCorpo = ({extraStyles = {}, semRecorte= false}) => (
    <ContainerCupom style={{  ...extraStyles, backgroundColor: (cupomAtivado || cupomResgatado) && "#92ecb3" }}>
      {!semRecorte && <RecorteEsquerda $background={backgroundColor} />}
      <ImagemProdutoContainer>
        <ImagemProduto
          src={
            item.imagem
              ? `${urlBucket}/imagens/CUPOM_VANTAGEM_IMAGEM/${item.imagem}`
              : ErroImagem
          }
          alt="imagem da vantagem"
        />
      </ImagemProdutoContainer>
      <LinhaMeio />
      <ContainerTexto>
        <div>
          <p>{item.descricao}</p>
          <span>{item.percentualDesconto}%</span>
          {cupomResgatado ||( state.mostrarValidade  && state.ativo) ? (
            <p style={{ opacity: 0.7, fontSize: 12 }}>Válido até {new Date(item.fimVigencia).toLocaleDateString("pt-BR")}</p>
          ) : (
            <p>de desconto</p>
          )}
        </div>
      </ContainerTexto>
      {!semRecorte &&  <RecorteDireita $background={backgroundColor} />}
      {iconFav && (
        state.favorito ? (
          <IconeFavoritoEstilizadoPreenchido onClick={favoritar} />
        ) : (
          <IconeFavoritoEstilizado onClick={favoritar} />
        )
      )}
    </ContainerCupom>
  );

  const renderModalContent = (close) => (
    <ColunaPopup>
      <h2>{cupomResgatado ? "Vantagem resgatada" : "Resgatar vantagem"}</h2>
      {<RenderCorpo extraStyles={({ alignSelf: "center", backgroundColor: theme.coresCardapio.brand050 })} semRecorte={true}/>}
      <ConteudoCupons>
        {largura >= 768 && !state.ativo && (
          <>
            <h4>Regras de utilização:</h4>
            <LinhaPopup>
              <h5>
                • Limite de {item.quantidadeLimite} {item.quantidadeLimite > 1 ? 'ativações' : 'ativação'} por usuário
              </h5>
              <h5>• Data de expiração: {dayjs(item.fimVigencia).format("DD/MM/YYYY")}</h5>
            </LinhaPopup>
          </>
        )}
        {state.erro && <p style={{ color: "red", margin: "8px 0" }}>Desculpe, houve um erro ao ativar a vantagem!</p>}
        {state.ativo || cupomResgatado ? (
          <ColunaCopiarCupom>
            <LinhaCopiarCupom>
              <p>{state.mostrarValidade ? 'Vantagem ativada com sucesso!' : 'Vantagem ativa!'}</p>
            </LinhaCopiarCupom>
          </ColunaCopiarCupom>
        ) : (
          <LinhaBotoes>
            {largura > 768 && (
              <BotaoCardapioVazado
                tamanho={"SM"}
                onClick={close}
                texto={"Cancelar"}
              />
            )}
            <BotaoCardapioPreenchidoVerde
              tamanho={"SM"}
              onClick={() => (logado ? ativarCupom() : setLoginAberto(true))}
              texto={"Pegar Vantagem"}
              loading={state.loading}
            />
          </LinhaBotoes>
        )}
      </ConteudoCupons>
    </ColunaPopup>
  );

  return (
    <GerarModalResponsivoCustomizado
      width={"430px"}
      backgroundColor={theme.coresCardapio.brand050}
      onClose={() => state.ativo && setState((prevState) => ({ ...prevState, mostrarValidade: true }))}
      trigger={<div style={{ cursor: "pointer" }}>{<RenderCorpo/>}</div>}
    >
      {renderModalContent}
    </GerarModalResponsivoCustomizado>
  );
};

export default Cupom;
