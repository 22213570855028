import React, { useState, useEffect } from "react";
import {
  PopupCustomizado,
  BottomSheetCustomizado,
  OverlayManual,
} from "./style";
import useTamanhoTela from "../../utils/useTamanhoTela";

export const GerarModalResponsivo = ({
  children,
  trigger,
  width,
  height,
  onClose = () => {},
  backgroundColor,
  controle,
  blocking = true,
  overlayManual = false,
}) => {
  const LARGURA_QUEBRA = 768;

  const { largura } = useTamanhoTela();
  let modo = largura < LARGURA_QUEBRA ? "bottom-sheet" : "modal";

  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    if (modo !== "bottom-sheet") setAberto(false);
  }, [modo]);

  return modo === "bottom-sheet" ? (
    <>
      {overlayManual && controle && (
        <OverlayManual
          onClick={() => {
            setAberto(false);
            onClose();
          }}
        />
      )}

      <div
        onClick={() => {
          setAberto(true);
        }}
      >
        {trigger}
      </div>
      <BottomSheetCustomizado
        $backgroundColor={backgroundColor}
        open={aberto || controle}
        onDismiss={() => {
          setAberto(false);
          onClose();
        }}
        blocking={blocking}
      >
        {children(() => setAberto(false))}
      </BottomSheetCustomizado>
    </>
  ) : (
    <PopupCustomizado
      $width={width}
      $height={height}
      $backgroundColor={backgroundColor}
      trigger={trigger}
      open={aberto || controle}
      $open={aberto || controle}
      onClose={onClose}
      modal
      nested
    >
      {children}
    </PopupCustomizado>
  );
};

export default GerarModalResponsivo;
