import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { BotaoCredito, BotaoDebito, BotaoIcone, BotaoIconeCpfDesmarcado, BotaoIconeCpfMarcado, Container, ContainerBotoes, ContainerIcone, ContainerSelecioneFormaPagamento, ContainerTitulo, TotalContainer  ,CpfNotaContainer, InputCpf, LinhaTitulo, TextoCpfNota, Titulo, TituloBotoes, TituloSelecioneFormaPagamento, TituloValorTotal, BotaoFazerPagamento, TituloBotaoFazerPagamento } from './styles'




import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Check, CreditCard, CurrencyCircleDollar, Square } from 'phosphor-react';

export function CpfWaypag() {
    const [cpfNota, setCpfNota] = useState(false)

    function mudarEstadoCpf() {
        if (cpfNota === true) {
            setCpfNota(false)
        }

        if (cpfNota === false) {
            setCpfNota(true)
        }
    }
    

    return (
        <Container>
            <ContainerIcone>
                <BotaoIcone>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </BotaoIcone>
            </ContainerIcone>
            <ContainerTitulo>
                <Titulo>
                    Pagamento
                </Titulo>
                <LinhaTitulo />
            </ContainerTitulo>
            {!cpfNota ?  (
                <CpfNotaContainer>
                    <BotaoIconeCpfDesmarcado onClick={mudarEstadoCpf}>
                        <Square size={20} color='#188FFC' style={{ background: 'transparent'}} />
                    </BotaoIconeCpfDesmarcado>
                    <TextoCpfNota>
                        CPF na nota
                    </TextoCpfNota>            
                </CpfNotaContainer>
            ): (
                <>
                <CpfNotaContainer>
                    <BotaoIconeCpfMarcado onClick={mudarEstadoCpf}>
                            <Check size={16} weight='bold' color='#188FFC' style={{ background: 'transparent', borderRadius: 2}} />
                    </BotaoIconeCpfMarcado>
                    <TextoCpfNota>
                        CPF na nota 
                    </TextoCpfNota>
                </CpfNotaContainer>
                <InputCpf type='number'placeholder='CPF' required />            
                </>
            )}
            <ContainerSelecioneFormaPagamento>
                <TituloSelecioneFormaPagamento>
                    Selecione a forma de pagamento
                </TituloSelecioneFormaPagamento>
            </ContainerSelecioneFormaPagamento>
            <ContainerBotoes>
                <BotaoDebito>
                    <CurrencyCircleDollar size={20} />
                    <TituloBotoes>
                        Débito
                    </TituloBotoes>
                </BotaoDebito>
                <BotaoCredito>
                    <CreditCard size={20} />
                    <TituloBotoes>
                        Crédito
                    </TituloBotoes>
                </BotaoCredito>
            </ContainerBotoes>
            <TotalContainer>
                <TituloValorTotal>
                    Total
                </TituloValorTotal>
                <TituloValorTotal>
                    R$&nbsp;10,00
                </TituloValorTotal>
            </TotalContainer>
            <BotaoFazerPagamento>
                <TituloBotaoFazerPagamento>
                    Fazer o pagamento
                </TituloBotaoFazerPagamento>
            </BotaoFazerPagamento>
        </Container>
    )
}