import React, { useEffect, useState } from "react";
import {
  EscolhaExpansivel,
  EscolhaExpansivelTitulo,
  EscolhaExpansivelConteudo,
  FormControlLabelCustomizado,
  CardCustomizadoOpcao,
  ConteudoOpcao,
  ContainerContador,
  ImagemProduto,
  ConteudoOpcaoPizza,
  IconeBotao,
} from "./styles";
import {
  faX,
  faMinus,
  faPlus,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErroImagem from "../../../assets/images/img-erro.png";
import scrollParaElemento from "../../../utils/scrollParaElemento";
import { useUserAuth } from "../../../contexts/userAuth";
import { useParams } from "react-router-dom";
import { checarModoCardapio } from "../../../utils/checarModoCardapio";
import useTamanhoTela from "../../../utils/useTamanhoTela";

export const Escolha = ({
  idValor,
  idProximo,
  complemento,
  aberto,
  pizza,
  updateValorComplemento = () => { },
  style,
  setEscolhasFeitas = () => { },
  setDadosEscolhas = () => { },
  idProdutoPai,
  iconeExpandir = true,
  modoEstatico = false,
  modoCardapio = false,
  produto
}) => {
  const [escolha, setEscolha] = useState({});
console.log(produto)

function verificarEstoque() {
  if (produto.estocado) {
    if (produto.estoque > 0) {
      return "CONTEM_ESTOQUE"
    }
    return "SEM_ESTOQUE"
  }

  return "NAO_CONTROLA"
}

console.log(verificarEstoque())
console.log(complemento)
  useEffect(() => {
    if (escolha?.descricaoComponente) {
      updateValorComplemento(escolha.valorComponente, complemento);
      setEscolhasFeitas((state) => {
        return { ...state, [complemento.produtoMenu.id]: true };
      });
      setDadosEscolhas((dadosEscolhas) => {
        let novosDadosEscolhas = { ...dadosEscolhas };
        if (idProdutoPai) {
          if (!novosDadosEscolhas[idProdutoPai]) {
            novosDadosEscolhas[idProdutoPai] = {};
          }
          novosDadosEscolhas[idProdutoPai][complemento.produtoMenu.id] =
            escolha;
        } else {
          novosDadosEscolhas[complemento.produtoMenu.id] = escolha;
        }
        return novosDadosEscolhas;
      });
    } else {
      updateValorComplemento(null, complemento);
      setDadosEscolhas((dadosEscolhas) => {
        let novosDadosEscolhas = { ...dadosEscolhas };
        if (idProdutoPai) {
          if (
            novosDadosEscolhas[idProdutoPai] &&
            novosDadosEscolhas[idProdutoPai][complemento.produtoMenu.id]
          ) {
            delete novosDadosEscolhas[idProdutoPai][complemento.produtoMenu.id];
            if (Object.keys(novosDadosEscolhas[idProdutoPai]).length === 0) {
              delete novosDadosEscolhas[idProdutoPai];
            }
          }
        } else {
          if (
            novosDadosEscolhas[idProdutoPai] &&
            novosDadosEscolhas[idProdutoPai][complemento.produtoMenu.id]
          ) {
            delete novosDadosEscolhas[complemento.produtoMenu.id];
          }
        }
        return novosDadosEscolhas;
      });
      if (complemento.produtoMenu.quantidadeMinima > 0) {
        setEscolhasFeitas((state) => {
          return { ...state, [complemento.produtoMenu.id]: false };
        });
      } else {
        setEscolhasFeitas((state) => {
          return { ...state, [complemento.produtoMenu.id]: true };
        });
      }
    }
  }, [escolha]);

  useEffect(() => {
    if (complemento.produtoMenu.quantidadeMinima > 0) {
      setEscolhasFeitas((state) => {
        return { ...state, [complemento.produtoMenu.id]: false };
      });
    } else {
      setEscolhasFeitas((state) => {
        return { ...state, [complemento.produtoMenu.id]: true };
      });
    }
  }, []);

  function handleClick(event) {
    if (
      JSON.parse(event.target.value)?.descricaoComponente ===
      escolha?.descricaoComponente
    ) {
      setEscolha({});
    } else {
      setEscolha(JSON.parse(event.target.value));
      scrollParaElemento("complemento" + idProximo);
    }
  }

  function gerarStringQuantidadeMinima() {
    if (
      complemento.produtoMenu.quantidadeMinima > 0 &&
      complemento.produtoMenu.quantidadeMaxima > 0
    ) {
      return `Obrigatório - Escolha até ${complemento.produtoMenu.quantidadeMaxima}.`;
    }

    if (
      complemento.produtoMenu.quantidadeMinima == 0 &&
      complemento.produtoMenu.quantidadeMaxima > 0
    ) {
      return `Escolha até ${complemento.produtoMenu.quantidadeMaxima}.`;
    }

    return "";
  }
console.log(produto)
  console.log( verificarEstoque());
  return verificarEstoque() !== "SEM_ESTOQUE" &&  (
    <EscolhaExpansivel
      defaultExpanded={aberto}
      expanded={modoEstatico ? true : undefined}
      $modoEstatico={modoEstatico}
      style={{ ...style }}
    >
      <EscolhaExpansivelTitulo
        className={modoEstatico ? "modoEstatico" : ""}
        expandIcon={iconeExpandir ? <ExpandMoreIcon /> : <></>}
      >
        {complemento.produtoMenu.descricao}
        <p>{gerarStringQuantidadeMinima()}</p>
      </EscolhaExpansivelTitulo>
      <EscolhaExpansivelConteudo>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup name="escolha" value={JSON.stringify(escolha)}>
            {complemento.listaMenuItem.map((opcao, index) => {
              function verificarEstoque() {
                if (opcao.estocado) {
                  if (opcao.estoque > 0) {
                    return "CONTEM_ESTOQUE"
                  }
                  return "SEM_ESTOQUE"
                }
              
                return "NAO_CONTROLA"
              }
             
              return verificarEstoque() !== "SEM_ESTOQUE" && (
                <FormControlLabelCustomizado
                  key={"opcao" + opcao.descricao + index}
                  value={JSON.stringify(opcao)}
                  control={
                    !modoCardapio ? <Radio onClick={handleClick} /> : <></>
                  }
                  label={<CardOpcaoLabel opcao={opcao} pizza={pizza} />}
                  labelPlacement="start"
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </EscolhaExpansivelConteudo>
    </EscolhaExpansivel>
  );
};

export const CardOpcaoLabel = ({ opcao, pizza = false }) => {
  const { urlBucket } = useUserAuth();
  const { largura } = useTamanhoTela();

  const [imagemSrc, setImagemSrc] = useState(
    opcao.imagem ? opcao.imagem : ErroImagem
  );

  useEffect(() => {
    let imgUrl = `${urlBucket}/imagens/PRODUTO_GRADE_IMAGEM/${opcao.imagem}`;
    const img = new Image();
    img.onload = () => setImagemSrc(imgUrl); // Imagem carregada com sucesso
    img.onerror = () => setImagemSrc(ErroImagem); // Falha ao carregar, usar imagem de erro
    img.src = imgUrl;
  }, [opcao]);

  function formatarReal(valor) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(valor);
  }

  return (
    <CardCustomizadoOpcao>
      <ImagemProduto
        style={{
          width: 100,
          minWidth: 100,
          height: 100,
          minHeight: 100,
          borderRadius: "0.5rem",
          marginTop: 5,
          marginBottom: 5,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        url={imagemSrc}
      />

      <ConteudoOpcao>
        <h3>{opcao.descricaoComponente}</h3>
        <p>{opcao.detalhes ? opcao.detalhes : ""}</p>
        <span>
          {opcao.valorComponente && parseFloat(opcao.valorComponente) > 0
            ? `+  ${formatarReal(opcao.valorComponente)}`
            : ""}
        </span>
      </ConteudoOpcao>
    </CardCustomizadoOpcao>
  );
};

export const Adicionar = ({
  idProximo,
  aberto,
  complemento,
  pizza,
  alterarOpcao = () => { },
  updateValorComplemento = () => { },
  style,
  setEscolhasFeitas = () => { },
  index,
  setDadosEscolhas = () => { },
  idProdutoPai,
  iconeExpandir = true,
  modoEstatico = false,
  modoCardapio = false,
  produto
}) => {
  const [escolhas, setEscolhas] = useState({});
  const [escolhasQuantidade, setEscolhasQuantidade] = useState(0);
  function alterarOpcao(opcao, quantidade) {
    let escolhasVar = { ...escolhas };
    let idInterno = opcao.idProdutoMenuComponente;
    if (quantidade > 0) {
      escolhasVar[idInterno] = {
        ...opcao,
        quantidade: quantidade,
      };
      console.log(produto)



      console.log(produto)

      setDadosEscolhas((dadosEscolhas) => {
        let novosDadosEscolhas = { ...dadosEscolhas };
        if (idProdutoPai) {
          if (!novosDadosEscolhas[idProdutoPai]) {
            novosDadosEscolhas[idProdutoPai] = {};
          }
          novosDadosEscolhas[idProdutoPai][complemento.produtoMenu.id] =
            escolhasVar;
        } else {
          novosDadosEscolhas[complemento.produtoMenu.id] = escolhasVar;
        }

        return novosDadosEscolhas;
      });
    } else {
      if (escolhasVar[idInterno]) {
        delete escolhasVar[idInterno];
      }

      setDadosEscolhas((dadosEscolhas) => {
        let novosDadosEscolhas = { ...dadosEscolhas };
        if (idProdutoPai) {
          if (
            novosDadosEscolhas[idProdutoPai] &&
            novosDadosEscolhas[idProdutoPai][complemento.produtoMenu.id]
          ) {
            delete novosDadosEscolhas[idProdutoPai][complemento.produtoMenu.id];
            if (Object.keys(novosDadosEscolhas[idProdutoPai]).length === 0) {
              delete novosDadosEscolhas[idProdutoPai];
            }
          }
        } else {
          if (
            novosDadosEscolhas[idProdutoPai] &&
            novosDadosEscolhas[idProdutoPai][complemento.produtoMenu.id]
          ) {
            delete novosDadosEscolhas[complemento.produtoMenu.id];
          }
        }

        novosDadosEscolhas[complemento.produtoMenu.id] = escolhasVar;
        return novosDadosEscolhas;
      });
    }
    setEscolhas(escolhasVar);
  }
console.log(complemento);
  console.log(produto);
  function verificarEstoque() {
    if (produto?.estocado) {
      if (produto.estoque > 0) {
        return "CONTEM_ESTOQUE"
      }
      return "SEM_ESTOQUE"
    }

    return "NAO_CONTROLA"
  }
  console.log(verificarEstoque())
  const [limiteAtingido, setLimiteAtingido] = useState(false);

  useEffect(() => {
    let quantidadeTotal = Object.values(escolhas).reduce(
      (total, opcao) => total + opcao.quantidade,
      0
    );
    let valorTotal = Object.values(escolhas).reduce(
      (total, opcao) =>
        total + parseFloat(opcao.valorComponente) * opcao.quantidade,
      0
    );

    updateValorComplemento(valorTotal, complemento);
    setEscolhasQuantidade(quantidadeTotal);

    if (quantidadeTotal >= complemento.produtoMenu.quantidadeMaxima) {
      setLimiteAtingido(true);
    } else {
      setLimiteAtingido(false);
    }

    if (quantidadeTotal >= complemento.produtoMenu.quantidadeMinima) {
      setEscolhasFeitas((state) => {
        return { ...state, [complemento.produtoMenu.id]: true };
      });
    } else {
      if (complemento.produtoMenu.quantidadeMinima > 0) {
        setEscolhasFeitas((state) => {
          return { ...state, [complemento.produtoMenu.id]: false };
        });
      } else {
        setEscolhasFeitas((state) => {
          return { ...state, [complemento.produtoMenu.id]: true };
        });
      }
    }
  }, [escolhas]);

  useEffect(() => {
    if (complemento.produtoMenu.quantidadeMinima > 0) {
      setEscolhasFeitas((state) => {
        return { ...state, [complemento.produtoMenu.id]: false };
      });
    } else {
      setEscolhasFeitas((state) => {
        return { ...state, [complemento.produtoMenu.id]: true };
      });
    }
  }, []);

  useEffect(() => {
    if (limiteAtingido) {
      scrollParaElemento("complemento" + idProximo);
    }
  }, [limiteAtingido]);

  function gerarStringQuantidadeMinima() {
    if (
      complemento.produtoMenu.quantidadeMinima > 0 &&
      complemento.produtoMenu.quantidadeMaxima > 0
    ) {
      return `Obrigatório - Escolha de ${complemento.produtoMenu.quantidadeMinima} até ${complemento.produtoMenu.quantidadeMaxima}.`;
    }
    if (
      complemento.produtoMenu.quantidadeMinima > 0 &&
      complemento.produtoMenu.quantidadeMaxima == 0
    ) {
      return `Obrigatório - Escolha no minimo ${complemento.produtoMenu.quantidadeMinima}`;
    }

    if (
      complemento.produtoMenu.quantidadeMinima == 0 &&
      complemento.produtoMenu.quantidadeMaxima > 0
    ) {
      return `Escolha até ${complemento.produtoMenu.quantidadeMaxima}`;
    }

    return "";
  }
  console.log()
  return verificarEstoque() !== "SEM_ESTOQUE" && (
    <EscolhaExpansivel
      defaultExpanded={aberto}
      expanded={modoEstatico ? true : undefined}
      $modoEstatico={modoEstatico}
      style={{ ...style }}
    >
      <EscolhaExpansivelTitulo
        className={modoEstatico ? "modoEstatico" : ""}
        expandIcon={iconeExpandir ? <ExpandMoreIcon /> : <></>}
      >
        {complemento.produtoMenu.descricao}
        <p>{gerarStringQuantidadeMinima()}</p>
      </EscolhaExpansivelTitulo>
      <EscolhaExpansivelConteudo>
        <FormControl sx={{ width: "100%" }}>
          {complemento.listaMenuItem.map((opcao, index) => {
            function verificarEstoque() {
              if (opcao.estocado) {
                if (opcao.estoque > 0) {
                  return "CONTEM_ESTOQUE"
                }
                return "SEM_ESTOQUE"
              }

              return "NAO_CONTROLA"
            }
            
            if (verificarEstoque() === "SEM_ESTOQUE") {
              return
            }
            return (
              <FormControlLabelCustomizado
                style={{ pointerEvents: "none" }}
                key={"opcao" + opcao.descricao + index}
                value={opcao.descricao}
                control={
                  !modoCardapio ? (
                    <Contador
                      opcao={opcao}
                      alterarOpcao={alterarOpcao}
                      limiteAtingido={limiteAtingido}
                      estoque={verificarEstoque()}
                      produto={produto}
                    />
                  ) : (
                    <></>
                  )
                }
                label={<CardOpcaoLabel opcao={opcao} pizza={pizza} />}
                labelPlacement="start"
              />
            );
          })}
        </FormControl>
      </EscolhaExpansivelConteudo>
    </EscolhaExpansivel>
  );
};

export const Contador = ({
  opcao,
  alterarOpcao,
  limiteAtingido,
  quantidadeMaxima,
  estoque,
  produto
}) => {
  const [quantidade, setQuantidade] = useState(
    opcao.quantidade ? opcao.quantidade : 0
  );

  function incrementar(e) {
    e.stopPropagation();
    if (quantidade < opcao.quantidadeMaxima && !limiteAtingido) {
      let novaQuantidade = quantidade + 1;
      setQuantidade(novaQuantidade);
      alterarOpcao(opcao, novaQuantidade, quantidadeMaxima);
    }
  }

  function decrementar(e) {
    e.stopPropagation();
    if (quantidade > 0) {
      let novaQuantidade = quantidade - 1;
      setQuantidade(novaQuantidade);
      alterarOpcao(opcao, novaQuantidade, quantidadeMaxima);
    }
  }


  console.log(estoque)
  console.log(produto)

  return (
    <ContainerContador style={{ pointerEvents: "all", minWidth: 70 }}>
      <IconeBotao
        onClick={decrementar}
      >
        <FontAwesomeIcon
          icon={faMinus}
          style={{ cursor: "pointer", fontSize: "22px" }}
        />
      </IconeBotao>
      <p>{quantidade}</p>
      <IconeBotao
        onClick={incrementar}
        disabled={(estoque === "SEM_ESTOQUE" || estoque === "CONTEM_ESTOQUE") && quantidade >= produto?.estoque}
      >

        <FontAwesomeIcon

          icon={faPlus}
          style={{
            cursor: "pointer",
            fontSize: "22px",
            opacity:
              limiteAtingido || quantidade >= opcao.quantidadeMaxima ? 0.5 : 1,
          }}
        />
      </IconeBotao>

    </ContainerContador>
  );
};

export default Escolha;
