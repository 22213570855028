const temaCardapio = {
  titulo: "light",
  temaDarkAtivo: false,
  coresCardapio: {
    brand050: "rgba(255, 244, 220, 1)",
    brand100: "rgba(250, 222, 177, 1)",
    brand300: "rgba(244, 179, 76, 1)",
    brand500: "rgba(240, 148, 0, 1)",
    brand600: "rgba(230, 121, 0, 1)",
    brand900: "rgba(215, 81, 0, 1)",

    gray100: "rgb(166, 166, 166)",
    gray200: "rgba(133, 133, 133, 1)",
    gray400: "rgba(94, 94, 94,1)",

    semanticSucess: "rgba(28, 200, 138, 1)",
    verdeBotao: "rgba(30, 185, 27, 1)",
    vermelhoDelete: "rgba(231, 74, 59, 1)",

    background1: "rgba(246,246,246, 1)",
    background2: "rgba(255, 255, 255, 1)",

    textoClaro: "rgba(255, 255, 255, 1)",
    textoEscuro: "rgba(0, 0, 0, 1)",
  },
};

export default temaCardapio;
