import { tratarCasasDecimais } from "./tratarCasasDecimais";

export function ajustarQuantidadeItemCombinado(combinado, operacao) {
  let dadosAtualizar = { ...combinado };

  if (operacao == "adicionar") {
    dadosAtualizar.quantidade = combinado.quantidade + 1;
  } else if (operacao == "remover") {
    dadosAtualizar.quantidade = combinado.quantidade - 1;
  }

  dadosAtualizar.precoUnitario = combinado.precoUnitario;
  dadosAtualizar.precoTotal =
    dadosAtualizar.quantidade * combinado.precoUnitario;

  dadosAtualizar.subitens = [];
  combinado.itens.forEach((subItem) => {
    if (subItem.tipo == "ITEM_COMBINADO") {
      let quantidadeBase = tratarCasasDecimais(
        subItem.quantidade / combinado.quantidade
      );

      if (operacao == "adicionar") {
        subItem.quantidade += tratarCasasDecimais(quantidadeBase);
        subItem.precoTotal = tratarCasasDecimais(
          subItem.quantidade * subItem.precoUnitario
        );
        dadosAtualizar.subitens.push(subItem);
      } else if (operacao == "remover") {
        subItem.quantidade -= tratarCasasDecimais(quantidadeBase);
        subItem.precoTotal = tratarCasasDecimais(
          subItem.quantidade * subItem.precoUnitario
        );
        dadosAtualizar.subitens.push(subItem);
      }
    }
  });

  return dadosAtualizar;
}
