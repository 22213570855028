import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  StyledContainer,
  StyledContainerCupons,
  ScrollHorizontal,
  CuponsPopulares,
  ContainerCategoria,
  BlocoCategoria,
  ContainerMostrarMais,
} from "./styles.js";
import SwitchCupons from "../../../components/switchCupons/index.js";
import useTamanhoTela from "../../../utils/useTamanhoTela.js";
import Cupom from "../../../components/cardapio/cupom/index.js";
import Carousel from "react-material-ui-carousel";
import { useApi } from "../../../contexts/api.js";
import ModalLogin from "../../../components/modalLogin/index.js";
import { useUserAuth } from "../../../contexts/userAuth.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function Cupons() {
  const scrollHorizontalRef = useRef(null);
  const { largura } = useTamanhoTela();
  const { dadosEmpresa, logado } = useUserAuth();
  const [cuponsAtivos, setCuponsAtivos] = useState([])

console.log(dadosEmpresa)
  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (scrollHorizontalRef.current) {
        if (
          scrollHorizontalRef.current.scrollLeft >=
          scrollHorizontalRef.current.scrollWidth -
          scrollHorizontalRef.current.clientWidth - 30
        ) {
          scrollHorizontalRef.current.scrollLeft = 0;
        } else {
          scrollHorizontalRef.current.scrollLeft += largura > 768 ? 330 : largura;
        }
      }
    }, 3000);

    return () => clearInterval(scrollInterval);
  }, [largura]);

  const { get } = useApi();
  const [dadosCupons, setDadosCupons] = useState([]);
  const [dadosRequest, setDadosRequest] = useState([]);
  const [loadingCupons, setLoadingCupons] = useState(true);
  const [loginAberto, setLoginAberto] = useState(false);
  // const [logado, setLogado] = useState(false);
  const [mostrarMais, setMostrarMais] = useState(false);
  const navigate = useNavigate()
  const getDados = async () => {
    const response = await get(
      `/fidelidade/cupom-vantagem/buscar-vantagens-cardapio/${dadosEmpresa.idEmpresa}?size=9999`
    );

    const dados = response.data;
    setLoadingCupons(false);
    buscarVantagensAtivas(dados)
  };


  async function buscarVantagensAtivas(dados) {
    try {
      const response = await get(`fidelidade/cupom-vantagem/ativados?size=9999`)

      const newArr = dados.filter((item) => {

        if (response.data && response.data?.some((cupom) => cupom.id === item.id)) {
          return null
        }
        return item
      })

      const dadosProcessados = newArr.reduce((acc, cupom) => {
        if (acc[cupom.idCategoria]) {
          acc[cupom.idCategoria].push(cupom);
        } else {
          acc[cupom.idCategoria] = [cupom];
        }
        return acc;
      }, {});
      setDadosCupons(dadosProcessados);

      setDadosRequest(newArr);

      setCuponsAtivos(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  async function buscarContratos(){
    try {
      const res = await get(`usuario-sessao/client/usuario/waymenu/contrato-itens/${dadosEmpresa.idEmpresa}`)
      console.log(res.data.listaItens);

if(res.data.listaItens.includes("CUPOM_VANTAGEM")){
  getDados();
} else {
  navigate("/")
}


    } catch (error) {
      
    }
  }
  const Categoria = ({ categoria, limitada, titulo }) => {

    const renderizarCupons = useCallback(() => {
      return categoria?.slice(0, limitada ? 2 : categoria.length).map((cupom, index) => (
        <>
          <Cupom
            item={cupom}
            key={`cupom-${index}`}
            loginAberto={loginAberto}
            setLoginAberto={setLoginAberto}
            logado={logado}
            iconFav={false}
            cupomResgatado={cupom.cupomAtivado}
            style={{ backgroundColor: cupom.cupomAtivado && "#92ecb3" }}
          />
        </>

      ));
    }, [categoria, limitada, loginAberto, logado]);

    return (
      <BlocoCategoria>
        <h3>{categoria?.[0]?.descricaoCategoria}</h3>
        <ContainerCategoria>
          {renderizarCupons()}
        </ContainerCategoria>
      </BlocoCategoria>
    );
  };

  useEffect(() => {
    if(logado){
      buscarContratos()
    } else {
      navigate("/")
      toast.error("Você precisa estar logado para acessar essa tela!")
    }
  }, []);

  return (
    <>
      <StyledContainer>
        <StyledContainerCupons>
          <h2>Vantagens ativas</h2>
          {largura > 768 ? (
            <ScrollHorizontal ref={scrollHorizontalRef}>
              <CuponsPopulares>
                {cuponsAtivos.length > 0 && cuponsAtivos.map((cupom, index) => (
                  <Cupom
                    item={cupom}
                    key={`cupom-${index}`}
                    loginAberto={loginAberto}
                    setLoginAberto={setLoginAberto}
                    logado={logado}
                    iconFav={false}
                    style={{ backgroundColor: "#92ecb3" }}
                    cupomResgatado={true}
                  />
                ))}

              </CuponsPopulares>
            </ScrollHorizontal>
          ) : (
            <Carousel>
              {cuponsAtivos.map((cupom, index) => (
                <div key={`cupomDiv-${index}`} style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                  <Cupom
                    item={cupom}
                    key={`cupom-${index}`}
                    loginAberto={loginAberto}
                    setLoginAberto={setLoginAberto}
                    logado={logado}
                    iconFav={false}
                    cupomResgatado={cupom.ativo}
                  />
                </div>
              ))}
            </Carousel>
          )}
          <h2>Vantagens para ativar</h2>

          {(largura > 768 || mostrarMais) ? (
            Object.keys(dadosCupons).map((categoria, index) => (
              <Categoria
                categoria={dadosCupons[categoria]}
                titulo={categoria}
                key={`categoria-${index}`}
              />
            ))
          ) : (
            <>

              <Categoria
                titulo={Object.keys(dadosCupons)[0]}
                categoria={dadosCupons[Object.keys(dadosCupons)[0]]}
                key="categoria-0"
                limitada={true}
              />
              <ContainerMostrarMais onClick={() => setMostrarMais(true)}>
                <p>Ver mais</p>
              </ContainerMostrarMais>
            </>
          )}
        </StyledContainerCupons>
      </StyledContainer>
      <SwitchCupons cupons />
      <ModalLogin
        aberto={loginAberto}
        setAberto={setLoginAberto}
      // setLogado={setLogado}
      />
    </>
  );
}
