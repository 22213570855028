import styled from "styled-components";

export const GlobalContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  padding-bottom: 30px;
  width: 100vw;
  margin: 0 auto;

  @media (max-width: 570px) {
    max-width: 540px;
    margin: 0;
  }

  @media (max-width: 768px) {
    padding: 40px 0px;
  }
`;

// export const ContainerHeader = styled.div`
//   position: relative;
// `;

export const StyledContainer = styled.div`
  margin-top: 32px;

  max-width: 1200px;
  width: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  background-color: ${(props) => props.theme.cores.surface2};
  padding: 24px 20px;

 > *, strong {
    font-family: "Poppins", sans-serif;
  }

>  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 34px;
    color: #000;
  }

 > p,
  strong {
    font-size: 19px;
  }



  @media (max-width: 768px) {
    margin-top: 0px;
    padding: 20px 10px;
  }
`;

export const TextHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    font-size: 12px;
    font-weight: 700;
    padding: 10px 8px;
    background-color: ${({ theme }) => theme.coresCardapio.semanticSucess};
    border-radius: 6px;
    color: #fff;
  }
  >  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 34px;
    color: #000;
  }

  
  @media (max-width: 768px) {
    > h3 {
      font-size: 1.25rem;
    }
  }
`;

export const TextSubTitulo = styled.div`
 >  p {
    color: #858585;
    font-size: 19px;

  }


  
  @media (max-width: 768px) {
    > p{
      font-size: 16px;
    } 
  }
`;

export const TextoDiaHora = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  > p {
    margin-right: 32px;
    font-size: 19px;

  }

  @media (max-width: 768px) {
    > p{
      font-size: 16px;
    } 
  }
`;

export const TextoIcone = styled.div`
  width: 25px;
  margin-right: 5px;
  display: flex;
  align-items: center;

 > svg {
    color: ${({ theme }) => theme.coresCardapio.brand500};
  }
`;

export const ContainerGap = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

export const ContainerLinhaStatus = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const LinhaStatus = styled.div`
  height: 4px;
  background-color: #858585;
  width: 90%;
  position: absolute;
  /* top: 45%; */
  left: 5%;


  @media (max-width: 768px) {
    /* top: 30%; */
  }
`;

export const StatusPedido = styled.div`
  margin: 32px 16px 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  > span {
    font-size: 16px;
    padding: 5px;
    border-radius: 8px;
    color: #fff;
    z-index: 1;
  }

`;

export const StatusPedidoMobile = styled.div`
  margin: 32px 0px 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* font-size: 30px; */
  > span {
   
    font-size: 14px;
    padding: 5px;
    border-radius: 8px;
    color: #fff;
    z-index: 1;

     > p {
      text-align: center;
      color: white;
     }
    
    
}

> p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;

}

> p {
  position: absolute;
  top: 40px;
  font-size: 1rem;
  line-height: 24px;
  text-align: center;
  font-size: 19px;
  text-align: center;

}

@media (max-width: 768px) {
  margin-bottom: 2rem;
 

    font-size: 14px;
  
}
`

export const TextoStatusPedido = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 30px;


`

export const TempoPedidoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  &::before {
    content: " ";
    background-color: ${({ theme }) => theme.coresCardapio.brand500};
    position: absolute;
    top: 45%;
    left: 0;
    transform: translateY(-50%);
    height: 2px;
    width: 100%;
  }



`;

export const TempoPedidoItens = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 1;
 > p {
  font-size: 18px;
 }

  ${props => props.lastItem ? "padding-left: 10px" : "padding-right: 10px"}

  @media (max-width: 768px) {
    > p{
      font-size: 16px;
    } 
  }
`;

export const TextoTempoPedido = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  > span {
    color: #a6a6a6;
    margin-bottom: 30px;
    font-size: 0.875rem;
  font-size: 17px;

  }


  @media (max-width: 768px) {
    > span {
      font-size: 14px;
    } 
  }
`;

export const ContainerPedidos = styled.div`
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  border-radius: 5px;
  padding: 10px;
  > p {
    text-transform: uppercase;
    color: #fff;
    font-size: 19px;

  }
  > div:nth-child(2){
    padding: 10;
  }

  @media (max-width: 768px) {
    > p{
      font-size: 16px;
    } 
  }
`;

export const QuantidadeItens = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 5px;

 >  p:first-child {
   padding-left: 6px;
   padding-right: 6px;
    text-align: center;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;

  }

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
font-size: 20px;


  }

  @media (max-width: 768px) {
    > p {
      font-size: 16px;
    } 
  }
`;

export const PedidoValor = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ItemValor = styled.div`
  display: flex;
 > p {
  white-space: nowrap;
 }

 @media (max-width: 768px) {


}
`;

export const Pedido = styled.div`
padding: 8px;
`

export const ContainerAdicionais = styled.div`
  background-color: #fff;
  /* margin: 0px 16px 8px; */
  border-radius: 5px;
  /* padding: 10px; */
  font-family: "Open Sans", sans-serif;

  > strong {
    color: ${({ theme }) => theme.coresCardapio.brand500};
    font-size: 16px;
  }
`;

export const AdicionalItem = styled.div`
  display: flex;
  justify-content: space-between;
  > P {
    color:#212529;
  }

`;

export const Adicional = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

 > strong {
    color: #212529;
  }
`;

export const ValorAdicional = styled.div``;

export const FormaDePgto = styled.div`
  margin: 32px 0;
  color: #4b4b4b;

 > p {
    margin: 8px 0 32px 0;
    font-size: 19px;

  }
   

`;

export const InfoEndereco = styled.div`
 > p {
    margin: 8px 0 16px 0;
  }

 > strong {
    color: #4b4b4b
  }
`;

export const ContainerValores = styled.div`
  display: flex;
  justify-content: space-between;
  

 > strong {
    font-size: 19px;
    line-height: 28px;
    color: #4b4b4b;
    font-family: "Poppins", sans-serif;
  }
 
   
  @media (max-width: 768px) {
    > strong{
      font-size: 16px;
    } 
  }
`;

export const ContainerValoresTotal = styled.div`
  display: flex;
  justify-content: space-between;

 > strong {
    font-size: 24px;
    color: ${({ theme }) => theme.coresCardapio.semanticSucess};
  }
  
  @media (max-width: 768px) {
    > strong{
      font-size: 20px;
    }
    }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  > a {
    color: ${({ theme }) => theme.coresCardapio.brand500};
  }

 >  button {
    background-color: transparent;
    border-radius: 5px;
    padding: 16px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: #e74a3b;
    border: 1px solid #e74a3b;
    transition: 0.2s;

    &:hover {
      color: white;
      background-color: #e74a3b;
      box-shadow: -2px -2px 6px rgba(231, 74, 59, 0.7),
        2px 2px 6px rgba(231, 74, 59, 0.7);
    }
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;

    button {
      margin-bottom: 1rem;
    }
  }
`;

export const ModalExcluir = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
position: relative;

> svg {
  position: absolute;
  cursor: pointer;
  color: red;
  top: 0;
  right: 0;
}
`


export const ContainerBotoesModal = styled.div`
display: flex;
margin-top: 20px;
gap: 20px;
align-items: center;
justify-content: center;
`