import styled from "styled-components";
import Popup from "reactjs-popup";

export const CardCustomizado = styled.div`
  width: 31%;
  height: 160px;
  margin: 0.5% 1%;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  cursor: pointer;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;

  @media (max-width: 1200px) {
    width: 48%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0.5% 0px;
  }
`;

export const FaixaSemEstoque = styled.div`
position: absolute;
width: 100%;
padding: 8px;
background-color: rgba(230, 121, 0, 1);
bottom: 0;
> p {
  text-align: center;
  color: white;
}
`

export const ImagemProduto = styled.div`
  width: 40%;
  height: 100%;
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
`;

export const ConteudoProduto = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-weight: bolder;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 24px;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  }

  span {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: ${({ theme }) => theme.coresCardapio.semanticSucess};
  }

  .valorRiscado {
    text-decoration: line-through;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
    opacity: 0.5;
    font-size: 14px;
  }
`;

export const CardPromocaoDoDia = styled(CardCustomizado)`
  width: 545px;
  margin: 0px;
  margin-bottom: 7px;
  margin-top: 15px;

  opacity:  ${({possuiEstoque}) => possuiEstoque ? 1 :  0.5};
  cursor:  ${({possuiEstoque}) => possuiEstoque ? 'pointer' :  "not-allowed"};
  user-select: none;
 
  @media (max-width: 1200px) {
    width: 545px;
  }

  @media (max-width: 768px) {
    width: 100vw;
    margin: 0.5% 0px;
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-overlay {
    overflow-y: scroll;
    padding: 20px 0px;

    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  &-content {
    border: none;
    padding: 0;
  }
`;
