import React, { useEffect, useState } from "react";
import { ContainerBuscarProduto } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import { useLocation } from "react-router-dom";

export const BarraPesquisa = ({ style, onChange, value }) => {
  const theme = useTheme();
  const location = useLocation();

  const [visivel, setVisivel] = useState(false);

  useEffect(() => {
    if (window.location.href.includes("/cardapio")) {
      setVisivel(true);
    } else {
      setVisivel(false);
    }
  }, [location]);

  return visivel ? (
    <ContainerBuscarProduto style={{ ...style }}>
      <input
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder="Buscar Produto"
      />
      <FontAwesomeIcon
        icon={faSearch}
        color={theme.coresCardapio.brand500}
        fontSize={18}
      />
    </ContainerBuscarProduto>
  ) : (
    <ContainerBuscarProduto
      style={{ ...style, opacity: 0 }}
    ></ContainerBuscarProduto>
  );
};

export default BarraPesquisa;
