import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContainerCarrinhoPopUp = styled(Link)`
  position: fixed;
  bottom: 0;
  height: 64px;
  width: 100%;
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 88px;
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.coresCardapio.textoClaro};
    font-size: 20px;
  }

  svg {
    color: ${({ theme }) => theme.coresCardapio.textoClaro};
    font-size: 28px;
  }

  .MuiBadge-badge {
    right: -6px;

    color: ${({ theme }) => theme.coresCardapio.textoClaro};
    background-color: ${({ theme }) => theme.coresCardapio.vermelhoDelete};
    padding: "0 4px";
  }
`;
