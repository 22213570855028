import { createContext, useEffect, useState, useContext } from "react";
import { useApi } from "./api";
import { toast } from "react-toastify";
import PopUpCarrinho from "../components/popUpCarrinho";
import dayjs from "dayjs";
import {
  assegurarValor,
  gerarDadosItemNota,
  gerarDadosItemNotaCombinado,
  gerarDadosNota,
  gerarDadosNotaMesa,
  gerarEntradaItem,
  gerarEntradaMenu,
} from "../utils/gerarObjetosEscolhasCarrinho";
import { useUserAuth } from "./userAuth";
import { ajustarQuantidadeItemCombinado } from "../utils/ajustarQuantidadeItemCombinado";
import { tratarCasasDecimais } from "../utils/tratarCasasDecimais";
import { calcularAdicionais } from "../utils/calcularAdicionaisItemCarrinho";
import { useLocation, useParams } from "react-router-dom";

export const CarrinhoContext = createContext({});

export const CarrinhoProvider = ({ children }) => {
  const { get, post, put, del } = useApi();
  const { dadosAuth, modoMesa, dadosEmpresa } = useUserAuth();
  const location = useLocation();

  const [nota, setNota] = useState({});
  const [produtos, setProdutos] = useState([]);
  const [quantidadeItens, setQuantidadeItens] = useState(0);
  const [atualizandoQuantidade, setAtualizandoQuantidade] = useState(false);

  useEffect(() => {
    let produtosValidos = produtos.filter((produto) => !produto.cancelado);
    setQuantidadeItens(produtosValidos.length);
  }, [produtos]);

  useEffect(() => {
    if (!nota?.id) {
      let idNota = localStorage.getItem("CID");
      if (idNota) {
        atualizarCarrinho(idNota);
      }
    }
  }, []);

  useEffect(() => {
    if (atualizandoQuantidade) {
      setTimeout(() => {
        setAtualizandoQuantidade(false);
      }, 1000);
    }
  }, [atualizandoQuantidade]);

  function resetarCarrinho() {
    setNota({});
    setProdutos([]);
    localStorage.removeItem("CID");
  }

  async function atualizarDadosNota(idNota) {
    await get(`/vendas/nota/${idNota}`)
      .then((response) => {
        setNota(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function atualizarCarrinho(id) {
    // Obtém o id da nota, usando o id passado como argumento ou o id da nota existente
    let idNota = nota?.id || id;

    // Se idNota estiver definido, faz as requisições
    if (idNota) {
      // Primeira requisição: obtém os dados da nota
      await atualizarDadosNota(idNota);

      // Segunda requisição: obtém todos os itens da nota
      await get(`/vendas/nota-item/buscar-todos/${idNota}`)
        .then((response) => {
          // Inicializa objetos para categorizar os itens por tipo
          let tipoItem = {};
          let tipoMenu = {};
          let tipoAdicional = {};
          let tipoCombinado = {};
          let tipoItemCombinado = {};

          // Itera sobre os itens recebidos e os categoriza, deixando os itens por default em tipoAdicional
          response.data.forEach((item) => {
            switch (item.tipo) {
              case "ITEM":
                tipoItem[item.id] = item;
                break;
              case "MENU":
                tipoMenu[item.id] = item;
                break;
              case "COMBINADO":
                tipoCombinado[item.id] = item;
                break;
              case "ITEM_COMBINADO":
                tipoItemCombinado[item.id] = item;
                break;
              default:
                tipoAdicional[item.id] = item;
                break;
            }
          });

          Object.values(tipoItem).forEach((item) => {
            // Verifica se o item possui um item pai
            if (item.idItemPai) {
              // Se o item pai for do tipo ITEM, adiciona o item à lista de itens do item pai
              if (tipoItem[item.idItemPai]) {
                if (!tipoItem[item.idItemPai].itens) {
                  tipoItem[item.idItemPai].itens = [];
                }
                tipoItem[item.idItemPai].itens.push(item);
                // Remove o item de tipoItem se ele tiver idItemPai
                delete tipoItem[item.id];
              }
              // Se o item pai for do tipo MENU, adiciona o item à lista de adicionais do menu pai
              else if (tipoMenu[item.idItemPai]) {
                if (!tipoMenu[item.idItemPai].adicionais) {
                  tipoMenu[item.idItemPai].adicionais = [];
                }
                tipoMenu[item.idItemPai].adicionais.push(item);
                // Remove o item de tipoItem se ele tiver idItemPai
                delete tipoItem[item.id];
              }
              // Se o item pai for do tipo ITEM_COMBINADO, adiciona o item à lista de itens do item combinado pai
              else if (tipoItemCombinado[item.idItemPai]) {
                if (!tipoItemCombinado[item.idItemPai].itens) {
                  tipoItemCombinado[item.idItemPai].itens = [];
                }
                tipoItemCombinado[item.idItemPai].itens.push(item);
                // Remove o item de tipoItem se ele tiver idItemPai
                delete tipoItem[item.id];
              }
              // Se o item pai for do tipo COMBINADO, adiciona o item à lista de itens do combinado pai
              else if (tipoCombinado[item.idItemPai]) {
                if (!tipoCombinado[item.idItemPai].itens) {
                  tipoCombinado[item.idItemPai].itens = [];
                }
                tipoCombinado[item.idItemPai].itens.push(item);
                // Remove o item de tipoItem se ele tiver idItemPai
                delete tipoItem[item.id];
              }
            }
          });

          // Associa itens adicionais aos menus ou itens combinados correspondentes
          Object.values(tipoAdicional).forEach((adicional) => {
            // Verifica se o adicional pertence a um menu
            if (tipoMenu[adicional.idItemPai]) {
              // Se o menu ainda não tem uma lista de adicionais, cria uma
              if (!tipoMenu[adicional.idItemPai].adicionais) {
                tipoMenu[adicional.idItemPai].adicionais = [];
              }
              // Adiciona o adicional à lista de adicionais do menu
              tipoMenu[adicional.idItemPai].adicionais.push(adicional);
              // Verifica se o adicional pertence a um item combinado
            } else if (tipoItemCombinado[adicional.idItemPai]) {
              // Se o item combinado ainda não tem uma lista de adicionais, cria uma
              if (!tipoItemCombinado[adicional.idItemPai].adicionais) {
                tipoItemCombinado[adicional.idItemPai].adicionais = [];
              }
              // Adiciona o adicional à lista de adicionais do item combinado
              tipoItemCombinado[adicional.idItemPai].adicionais.push(adicional);
            }
          });

          // Associa menus aos itens ou itens combinados correspondentes
          Object.values(tipoMenu).forEach((menu) => {
            // Verifica se o menu pertence a um item
            if (tipoItem[menu.idItemPai]) {
              // Se o item ainda não tem uma lista de menus, cria uma
              if (!tipoItem[menu.idItemPai].menus) {
                tipoItem[menu.idItemPai].menus = [];
              }
              // Adiciona o menu à lista de menus do item
              tipoItem[menu.idItemPai].menus.push(menu);
              // Verifica se o menu pertence a um item combinado
            } else if (tipoItemCombinado[menu.idItemPai]) {
              // Se o item combinado ainda não tem uma lista de menus, cria uma
              if (!tipoItemCombinado[menu.idItemPai].menus) {
                tipoItemCombinado[menu.idItemPai].menus = [];
              }
              // Adiciona o menu à lista de menus do item combinado
              tipoItemCombinado[menu.idItemPai].menus.push(menu);
              // Verifica se o menu pertence a um combinado
            } else if (tipoCombinado[menu.idItemPai]) {
              // Se o combinado ainda não tem uma lista de menus, cria uma
              if (!tipoCombinado[menu.idItemPai].menus) {
                tipoCombinado[menu.idItemPai].menus = [];
              }
              // Adiciona o menu à lista de menus do combinado
              tipoCombinado[menu.idItemPai].menus.push(menu);
            }
          });

          // Associa itens combinados aos combinados correspondentes
          Object.values(tipoItemCombinado).forEach((itemCombinado) => {
            // Verifica se o item combinado pertence a um combinado
            if (tipoCombinado[itemCombinado.idItemPai]) {
              // Se o combinado ainda não tem uma lista de itens, cria uma
              if (!tipoCombinado[itemCombinado.idItemPai].itens) {
                tipoCombinado[itemCombinado.idItemPai].itens = [];
              }
              // Adiciona o item combinado à lista de itens do combinado
              tipoCombinado[itemCombinado.idItemPai].itens.push(itemCombinado);
            }
          });

          // Atualiza o estado dos produtos com os itens e combinados categorizados unidos em um unico array
          setProdutos([
            ...Object.values(tipoItem),
            ...Object.values(tipoCombinado),
          ]);
        })
        .catch((error) => {
          // Loga o erro no console se a requisição falhar
          console.error(error);
        });
    }
  }

  async function atualizarValorNota(valor, id) {
    let idNota = id || nota.id;

    let dadosNota = {
      "notaDTO": {
        ...nota,
        id: idNota,
        valorSubTotal: valor,
        valorTotal: valor,
      },
    };

    await put(`/vendas/nota/atualizar/${idNota}`, dadosNota)
      .then((response) => {
        setNota({ ...nota, valorTotal: valor });
        atualizarCarrinho(idNota);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function atualizarQuantidadeProduto(produto, novaQuantidade, operacao) {
    setAtualizandoQuantidade(true);

    let dadosAtualizar = { ...produto };
    if (produto.tipo == "COMBINADO") {
      dadosAtualizar = ajustarQuantidadeItemCombinado(produto, operacao);

      dadosAtualizar.subitens.forEach((subItem) => {
        put("/vendas/nota-item/" + subItem.id, {
          ...subItem,
          idNota: nota.id,
          dataLancamento: dayjs().toISOString(),
        })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    } else {
      dadosAtualizar.quantidade = novaQuantidade;
      dadosAtualizar.precoUnitario = produto.precoUnitario;
      dadosAtualizar.precoTotal = produto.precoUnitario * novaQuantidade;
    }
    put("/vendas/nota-item/" + produto.id, {
      ...dadosAtualizar,
      idNota: nota.id,
      dataLancamento: dayjs().toISOString(),
    })
      .then(async (response) => {
        if (operacao == "adicionar") {
          await atualizarValorNota(
            nota.valorTotal +
              dadosAtualizar.precoUnitario +
              calcularAdicionais(dadosAtualizar)
          );
        } else if (operacao == "remover") {
          await atualizarValorNota(
            nota.valorTotal -
              dadosAtualizar.precoUnitario -
              calcularAdicionais(dadosAtualizar)
          );
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          error?.data?.message
            ? error?.data?.message
            : "Erro ao atualizar quantidade do produto."
        );
      });
  }

  async function adicionarItem(produto) {
    let { notaAtual, isPrimeiroItem, dadosNota } = await iniciarCarrinho();

    let dadosItem = gerarDadosItemNota(
      produto,
      produto.pacote !== "NENHUM" ? produto.pacote : "ITEM"
    );

    let subitens = {};

    Object.keys(produto.dadosPedido.dadosEscolhas).forEach((keyEscolha) => {
      let escolhas = produto.dadosPedido.dadosEscolhas[keyEscolha];
      console.log(escolhas);

      if (escolhas.idProdutoMenu) {
        if (!subitens[escolhas.idProdutoMenu]) {
          subitens[keyEscolha] = gerarEntradaMenu({
            tipo: escolhas.tipo == "OBSERVACAO" ? "OBSERVACAO" : "MENU",
            descricao: escolhas.descricaoMenu,
            idGrade: escolhas.idGrade,
          });
          subitens[keyEscolha].subitens.push(gerarEntradaItem(escolhas));
        } else {
          subitens[keyEscolha].subitens.push(gerarEntradaItem(escolhas));
        }
      } else {
        Object.values(escolhas).forEach((escolha) => {
          if (!subitens[escolha.idProdutoMenu]) {
            subitens[keyEscolha] = gerarEntradaMenu({
              tipo: escolhas.tipo == "OBSERVACAO" ? "OBSERVACAO" : "MENU",
              descricao: escolha.descricaoMenu,
            });
            subitens[keyEscolha].subitens.push(gerarEntradaItem(escolha));
          } else {
            subitens[keyEscolha].subitens.push(gerarEntradaItem(escolha));
          }
        });
      }
    });

    dadosItem[0].subitens = Object.values(subitens);

    if (modoMesa) {
      const segmentosURL = location.pathname.split("/");
      const slugMesa = segmentosURL[4];

      await post(
        `/vendas/nota-item-waymenu/inserir-consumo/${dadosEmpresa.idEmpresa}/${slugMesa}`,
        {
          ...dadosNota,
          idEmpresa: dadosEmpresa.idEmpresa,
          notaItemDTOList: dadosItem,
        }
      )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erro ao adicionar produto ao carrinho!");
        });
    } else {
      await post(`/vendas/nota-item/inserir-item/` + notaAtual.id, dadosItem)
        .then((response) => {
          console.log(dadosItem);
          if (isPrimeiroItem) {
            let valorNovo = assegurarValor(dadosItem[0].precoTotal, 0);
            atualizarValorNota(valorNovo, notaAtual.id);
          } else {
            let valorNovo =
              assegurarValor(nota.valorTotal) +
              assegurarValor(dadosItem[0].precoTotal, 0);
            atualizarValorNota(valorNovo, notaAtual.id);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            error?.data?.message
              ? error?.data?.message
              : "Erro ao adicionar produto ao carrinho!"
          );
        });
      atualizarCarrinho(notaAtual.id);
    }
  }

  async function adicionarItemCombinado(combinado, tipoMedia, valorMaior) {
    let { notaAtual, isPrimeiroItem, dadosNota } = await iniciarCarrinho();

    let dadosItem = gerarDadosItemNotaCombinado(combinado, "COMBINADO");
    let subitens = {};
    console.log(dadosItem);

    let quantidadeItemCombinadoBase = tratarCasasDecimais(
      1 / assegurarValor(combinado.dadosPedido.quantidadeSabores, 1)
    );

    let valorPrimeiroItem;
    let valorTotalPrimeiroItem;

    console.log(combinado);
    if (tipoMedia == "MAIOR_PRECO") {
      valorPrimeiroItem = valorMaior;
      valorTotalPrimeiroItem = valorMaior * quantidadeItemCombinadoBase;
    } else if (tipoMedia == "MEDIA_PRECO") {
      valorPrimeiroItem = combinado.dadosPedido.tamanho.valor.precoVenda;
      valorTotalPrimeiroItem = tratarCasasDecimais(
        combinado.dadosPedido.tamanho.valor.precoVenda *
          quantidadeItemCombinadoBase,
        false,
        2
      );
    }

    console.log(valorTotalPrimeiroItem);

    subitens[combinado.idProdutoEmpresa] = gerarEntradaMenu({
      tipo: "ITEM_COMBINADO",
      descricao: combinado.descricao,
      valor: valorPrimeiroItem,
      valorTotal: valorTotalPrimeiroItem,
      quantidade: quantidadeItemCombinadoBase,
      idGrade: combinado.dadosPedido.tamanho.idGrade,
      idProdutoEmpresa: combinado.idProdutoEmpresa,
    });

    combinado.dadosPedido.sabores?.forEach((itemSabor, index) => {
      let sabor = itemSabor.sabor;
      let idGrade = itemSabor.idGrade;
      let quantidadeItemCombinadoPorQuantidade = tratarCasasDecimais(
        quantidadeItemCombinadoBase * itemSabor.quantidade
      );
      if (!subitens[sabor.idProdutoEmpresa]) {
        if (tipoMedia == "MAIOR_PRECO") {
          subitens[sabor.idProdutoEmpresa] = gerarEntradaMenu({
            tipo: "ITEM_COMBINADO",
            descricao: sabor.descricaoSabor,
            valor: valorMaior,
            valorTotal: tratarCasasDecimais(
              valorMaior * quantidadeItemCombinadoPorQuantidade,
              false,
              2
            ),
            quantidade: quantidadeItemCombinadoPorQuantidade,
            idGrade: idGrade,
            idProdutoEmpresa: sabor.idProdutoEmpresa,
          });
        } else {
          subitens[sabor.idProdutoEmpresa] = gerarEntradaMenu({
            tipo: "ITEM_COMBINADO",
            descricao: sabor.descricaoSabor,
            valor: itemSabor.tabelaDePreco.precoVenda,
            valorTotal: tratarCasasDecimais(
              itemSabor.tabelaDePreco.precoVenda *
                quantidadeItemCombinadoPorQuantidade,
              false,
              2
            ),
            quantidade: quantidadeItemCombinadoPorQuantidade,
            idProdutoEmpresa: sabor.idProdutoEmpresa,
          });
        }
      } else {
        // subitens[sabor.idProdutoEmpresa].quantidade += quantidadeItemCombinado;

        subitens[sabor.idProdutoEmpresa].quantidade +=
          quantidadeItemCombinadoPorQuantidade;
        if (tipoMedia == "MAIOR_PRECO") {
          subitens[sabor.idProdutoEmpresa].precoTotal = tratarCasasDecimais(
            valorMaior * subitens[sabor.idProdutoEmpresa].quantidade,
            false,
            2
          );
        } else {
          subitens[sabor.idProdutoEmpresa].precoTotal = tratarCasasDecimais(
            itemSabor.tabelaDePreco.precoVenda *
              subitens[sabor.idProdutoEmpresa].quantidade,
            false,
            2
          );
        }
      }
    });

    combinado?.dadosPedido?.complementos &&
      Object.keys(combinado?.dadosPedido?.complementos).forEach(
        (idProdutoEmpresaSabor) => {
          const complementosSabores =
            combinado?.dadosPedido?.complementos[idProdutoEmpresaSabor];

          let subitensSegundoNivel = {};

          Object.keys(complementosSabores).forEach((keyComplemento) => {
            let complementos =
              combinado?.dadosPedido?.complementos[idProdutoEmpresaSabor][
                keyComplemento
              ];

            console.log(complementos);

            if (complementos.idProdutoMenu) {
              if (!subitensSegundoNivel[complementos.idProdutoMenu]) {
                subitensSegundoNivel[complementos.idProdutoMenu] =
                  gerarEntradaMenu({
                    tipo:
                      complementos.tipo == "OBSERVACAO" ? "OBSERVACAO" : "MENU",
                    descricao: complementos.descricaoMenu,
                    idGrade: complementos.idGrade,
                  });
                subitensSegundoNivel[complementos.idProdutoMenu].subitens.push(
                  gerarEntradaItem(complementos)
                );
              } else {
                subitensSegundoNivel[complementos.idProdutoMenu].subitens.push(
                  gerarEntradaItem(complementos)
                );
              }
            } else {
              Object.values(complementos).forEach((complemento) => {
                if (!subitensSegundoNivel[complemento.idProdutoMenu]) {
                  subitensSegundoNivel[complemento.idProdutoMenu] =
                    gerarEntradaMenu({
                      tipo:
                        complemento.tipo == "OBSERVACAO"
                          ? "OBSERVACAO"
                          : "MENU",
                      descricao: complemento.descricaoMenu,
                      idGrade: complemento.idGrade,
                    });
                  subitensSegundoNivel[complemento.idProdutoMenu].subitens.push(
                    gerarEntradaItem(complemento)
                  );
                } else {
                  subitensSegundoNivel[complemento.idProdutoMenu].subitens.push(
                    gerarEntradaItem(complemento)
                  );
                }
              });
            }
          });
          if (subitens[idProdutoEmpresaSabor]) {
            subitens[idProdutoEmpresaSabor].subitens =
              Object.values(subitensSegundoNivel);
          }
        }
      );

    combinado.dadosPedido.borda &&
      Object.keys(combinado.dadosPedido.borda).forEach((keyEscolha) => {
        let bordas = combinado.dadosPedido.borda[keyEscolha];
        console.log(bordas);

        if (bordas.idProdutoMenu) {
          if (!subitens[bordas.idProdutoMenu]) {
            subitens[keyEscolha] = gerarEntradaMenu({
              tipo: bordas.tipo == "OBSERVACAO" ? "OBSERVACAO" : "MENU",
              descricao: bordas.descricaoMenu,
              idGrade: bordas.idGrade,
            });
            subitens[keyEscolha].subitens.push(gerarEntradaItem(bordas));
          } else {
            subitens[keyEscolha].subitens.push(gerarEntradaItem(bordas));
          }
        } else {
          Object.values(bordas).forEach((borda) => {
            if (!subitens[borda.idProdutoMenu]) {
              subitens[keyEscolha] = gerarEntradaMenu({
                tipo: borda.tipo == "OBSERVACAO" ? "OBSERVACAO" : "MENU",
                descricao: borda.descricaoMenu,
                idGrade: borda.idGrade,
              });
              subitens[keyEscolha].subitens.push(gerarEntradaItem(borda));
            } else {
              subitens[keyEscolha].subitens.push(gerarEntradaItem(borda));
            }
          });
        }
      });
    dadosItem[0].subitens = Object.values(subitens);
    corrigirValoresItemCombinado(
      dadosItem,
      combinado.dadosPedido.quantidadeSabores
    );
    await post(`/vendas/nota-item/inserir-item/` + notaAtual.id, dadosItem)
      .then(() => {
        console.log(dadosItem[0].precoTotal);
        // if (isPrimeiroItem) {
        //   let valorNovo = assegurarValor(dadosItem[0].precoTotal, 0);
        //   atualizarValorNota(valorNovo, notaAtual.id);
        // } else {
        //   let valorNovo =
        //     assegurarValor(nota.valorTotal) +
        //     assegurarValor(dadosItem[0].precoTotal, 0);
        //   atualizarValorNota(valorNovo, notaAtual.id);
        // }
        atualizarCarrinho(notaAtual.id);
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          error?.data?.message
            ? error?.data?.message
            : "Erro ao adicionar produto ao carrinho!"
        );
      });
  }

  function corrigirValoresItemCombinado(dadosItem, quantidadeSabores) {
    console.log(dadosItem, quantidadeSabores);

    let novosDadosItem = { ...dadosItem[0] };

    let primeiroItem = novosDadosItem.subitens[0];
    if (quantidadeSabores === 3) {
      primeiroItem.quantidade += 0.001;
      console.log(primeiroItem.precoUnitario * primeiroItem.quantidade);

      primeiroItem.precoTotal = tratarCasasDecimais(
        primeiroItem.precoUnitario * primeiroItem.quantidade,
        false,
        2
      );
      novosDadosItem.subitens[0] = primeiroItem;
    }

    let valorTotal = 0;
    novosDadosItem.subitens.forEach((subItem) => {
      if (subItem.tipo == "ITEM_COMBINADO") {
        valorTotal += subItem.precoTotal;
      }
    });

    if (valorTotal != novosDadosItem.precoTotal) {
      console.log(novosDadosItem.precoTotal, valorTotal);

      let diferenca = novosDadosItem.precoTotal - valorTotal;

      novosDadosItem.subitens[0].precoTotal = tratarCasasDecimais(
        novosDadosItem.subitens[0].precoTotal + diferenca,
        false,
        2
      );
      console.log(novosDadosItem, "novos", diferenca);

      dadosItem[0] = novosDadosItem;
    }
  }

  async function limparCarrinho() {
    if (!nota?.id) {
      return;
    }
    let idNota = nota.id;

    let dadosNota = {
      "motivoCancelamento": "Cancelado pelo consumidor",
      "gerarImpressao": false,
    };

    await put(`/vendas/nota/cancelar/${idNota}`, dadosNota)
      .then((response) => {
        console.log(response.data);
        localStorage.removeItem("CID");
        setNota({});
        setProdutos([]);
      })
      .catch((error) => {
        toast.error(
          error?.data?.message
            ? error?.data?.message
            : "Erro ao limpar carrinho!"
        );
        console.error(error);
      });
  }

  async function removerProduto(produto) {
    console.log(produto);

    if (!nota?.id) {
      return;
    }
    let idNota = nota.id;

    let novoProduto = {
      ...produto,
      status: "CANCELADO",
      cancelado: true,
      idNota,
    };

    put("/vendas/nota-item/" + produto.id, novoProduto)
      .then((response) => {
        console.log(response.data);

        atualizarValorNota(
          nota.valorTotal - produto.precoTotal - calcularAdicionais(produto),
          idNota
        );

        atualizarCarrinho(idNota);
      })
      .catch((error) => {
        toast.error(
          error?.data?.message
            ? error?.data?.message
            : "Erro ao remover o produto."
        );
        console.error(error);
      });
  }

  async function iniciarCarrinho() {
    let notaAtual;
    let isPrimeiroItem;
    try {
      if (modoMesa) {
        const segmentosURL = location.pathname.split("/");
        const slugMesa = segmentosURL[4];
        let dadosNota = gerarDadosNotaMesa(slugMesa);
        notaAtual = nota?.id;

        return { notaAtual, isPrimeiroItem, dadosNota };
      } else {
        if (!nota?.id) {
          isPrimeiroItem = true;
          let dadosNota = gerarDadosNota(dadosAuth.idCliente);

          await post("/vendas/nota/inserir-nota", dadosNota)
            .then((response) => {
              notaAtual = response.data;
              localStorage.setItem("CID", JSON.stringify(notaAtual.id));
            })
            .catch((error) => {
              console.error(error);
              toast.error(
                error?.data?.message
                  ? error?.data?.message
                  : "Erro ao adicionar produto ao carrinho!"
              );
            });
          setNota(notaAtual);
        } else {
          notaAtual = nota;
        }
        return { notaAtual, isPrimeiroItem };
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error?.data?.message
          ? error?.data?.message
          : "Erro ao adicionar produto ao carrinho!"
      );
    }
  }

  return (
    <CarrinhoContext.Provider
      value={{
        valorTotal: nota?.valorTotal,
        produtos,
        nota,
        atualizarDadosNota,
        quantidadeItens,
        adicionarItem,
        adicionarItemCombinado,
        atualizarCarrinho,
        limparCarrinho,
        resetarCarrinho,
        atualizarQuantidadeProduto,
        atualizandoQuantidade,
        removerProduto,
      }}
    >
      {children}
      <PopUpCarrinho />
    </CarrinhoContext.Provider>
  );
};

export const useCarrinho = () => useContext(CarrinhoContext);
