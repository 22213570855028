import { BottomSheet } from "react-spring-bottom-sheet";
import Popup from "reactjs-popup";
import styled, { css } from "styled-components";

export const BottomSheetCustomizado = styled(BottomSheet)`
  [data-rsbs-overlay] {
    background-color: ${({ $backgroundColor }) => $backgroundColor || "#fff"};

    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    z-index: 999 !important;
  }

  --rsbs-bg: ${({ theme }) => theme.coresCardapio.brand050};
  --rsbs-handle-bg: ${({ theme }) => theme.coresCardapio.brand050};
  --rsbs-overlay-rounded: 32px !important;
  /* --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right); */

  [data-rsbs-content] {
    background-color: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
    padding: 4px 24px 24px 24px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    /* max-height: 80vh; */
    z-index: 999 !important;
  }

  [data-rsbs-handle] {
    background-color: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
  }
`;

export const PopupCustomizado = styled(Popup)`
  &-content {
    width: ${({ $width }) => $width || "auto"};
    height: ${({ $height }) => $height || "auto"};
    border-radius: 16px;
    padding: 24px;
    margin-top: 16px;
    background-color: ${({ $backgroundColor }) => $backgroundColor || "#fff"};
    border: none;
    max-width: 500px;

    ${({ $open }) =>
      $open
        ? css`
            animation: popup 0.3s ease-in;
          `
        : ""}

    @keyframes popup {
      0% {
        opacity: 0;
        transform: translateY(-60px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }

  &-overlay {
    padding: 16px 0px;
    overflow: scroll;
  }
`;

export const OverlayManual = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 150vh;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--rsbs-backdrop-bg, rgba(0, 0, 0, 0.6));
  will-change: opacity;
  z-index: 2;
  cursor: pointer;
  animation: fadeIn 400ms ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
