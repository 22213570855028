import { styled } from "styled-components";

export const Container = styled.div`
  padding: 7px;
  background-color: ${({ theme }) => theme.coresWaypag.background1};
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow-y: scroll;
`;

export const ContainerTitulo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  margin-top: 30%;
`;

export const Titulo = styled.p`
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 36px;
  color: ${({ theme }) => theme.coresWaypag.textoBranco};

  animation: 1.5s animacaoSubir ease-out forwards;
  @keyframes animacaoSubir {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`;

export const ContainerAnimacao = styled.div``;
