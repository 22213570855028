import styled from "styled-components";
import Popup from "reactjs-popup";
import { TextField, Typography } from "@mui/material";

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.coresCardapio.background1};
`;
