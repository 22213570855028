import {
  ContainerHeader,
  StyledContainer,
  TextHeaderContainer,
  TextSubTitulo,
  TextoDiaHora,
  StatusPedido,
  StatusPedidoMobile,
  TextoStatusPedido,
  LinhaStatus,
  ContainerLinhaStatus,
  TempoPedidoContainer,
  TempoPedidoItens,
  TextoTempoPedido,
  ContainerPedidos,
  QuantidadeItens,
  PedidoValor,
  ItemValor,
  ContainerAdicionais,
  AdicionalItem,
  Adicional,
  ValorAdicional,
  FormaDePgto,
  TextoIcone,
  InfoEndereco,
  ContainerValores,
  ContainerValoresTotal,
  Footer,
  Pedido,
  GlobalContainer,
  ModalExcluir,
  ContainerBotoesModal,
} from "./styles";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { Calendar, Clock, MapPin } from "phosphor-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../../contexts/api";
import { BotaoBaseVermelhoVazado } from "../../../components/botao/styles";
import dayjs from "dayjs";
import GerarModalResponsivo, { } from "../../../components/gerarModalResponsivo/index"
import { BotaoCardapioPreenchidoVerde, BotaoVermelhoPreenchido } from "../../../components/botao";

export const DetalhesPedido = () => {
  const tema = useTheme();
  const { largura } = useTamanhoTela();
  const navigate = useNavigate()
  const { state: idNota } = useLocation()
  const { get, put } = useApi()
  const [dadosPedido, setDadosPedido] = useState({ })
  const [status, setStatus] = useState(null)



  async function buscarDadosDoPedido() {
    try {
      const res = await get(`/vendas/nota/venda/${idNota}`)
      setDadosPedido({
        ...res.data
      })


      function verificarStatus() {
        let valor = res.data;
        let status = valor.status
  
        if (valor.status === "ABERTA") {
          status = "Aberta";
        }
  
        if (valor.status === "CANCELADA") {
          status = "Cancelada";
        }
  
        if (valor.status === "RECEBIDA") {
          status = "Pedido entregue";
        }
      
        if (valor.status === " ") {
  
          if(valor.tipoEntrega === "COMUM"){
            switch(valor.statusExpedicao){
              case "PENDENTE":
                status = "Pedido aceito"
                break
            case "EXPEDIDA":
              status = "Saiu para entrega"
              break
            }
  
          }
          if(valor.tipoEntrega === "RETIRADA_LOCAL" ){
            switch(valor.statusExpedicao){
              case 'PENDENTE':
                status = "Pedido aceito"
                break
              case "RETIRADA_LIBERADA": 
              status = "Retirada liberada"
  
            }
          }
  
        }
        
      
        setStatus(status);
      }
  
   
      verificarStatus()
    } catch (error) {
      console.error(error)
    }
  }
     const getStatusColor = (status) => {
        switch (status) {
          case 'Aberta':
            return '#FFD700'; // Dourado para "Aberta"
          case 'Cancelada':
            return '#e74a3b'; // Vermelho para "Cancelada"
          case 'Retirada liberada':
            return '#3498db'; // Azul para "Retirada liberada"
          case 'Liberada entrega':
            return '#8e44ad'; // Roxo para "Liberada entrega"
          case 'Saiu para entrega':
            return '#f39c12'; // Laranja para "Saiu para entrega"
          case 'Pedido entregue':
            return 'rgba(28, 200, 138, 1)'; // Verde escuro para "Pedido entregue"
          case 'Pedido aceito':
            return '#00E676'; // Verde para "Fechada"
          default:
            return '#a6a6a6'; // Cinza para outros status ou "Aguardando"
        }
      };

  async function cancelarPedido() {
    try {
      const res = await put(`vendas/nota/cancelar/${idNota}`, {
        motivoCancelamento: "",
        gerarImpressao: false
      })
    } catch (error) {
      console.error(error)
    }
  }

  const ItemPedido = ({ item, nivel = 0 }) => (
    <div style={{ marginLeft: nivel * 10, marginTop: nivel === 0 && 10 }}>
      <PedidoValor>
        <QuantidadeItens>
          <p>{item.quantidade}x</p>
          <p style={{ color: nivel == 0 && "white" }}>{item.descricao}</p>
        </QuantidadeItens>
        <ItemValor>
          <p style={{ color: nivel == 0 && "white", fontSize: 18 }}>R$ {item.valorLiquido.toFixed(2)}</p>
        </ItemValor>
      </PedidoValor>

      {item.subItens.length > 0 && (
        <ContainerAdicionais style={{ padding: nivel == 0 && 10 }}>
          <strong>{item.descricao}</strong>

          {item.subItens.map((subItem, subIndex) => (
            <ItemPedido key={subIndex} item={subItem} nivel={nivel + 1} />
          ))}
        </ContainerAdicionais>
      )}
    </div>
  );

  function verificarTamanhoLinha(){

    if(status === "Pedido entregue"){
      return "95%"
    }

    if(status === "Retirada liberada" || status === "Saiu para entrega"){
      return  "50%"
    }

    return "0%"
  }



  
  useEffect(() => {
    if (idNota === null) {
      navigate("/menu/restaurante-waymenu/delivery/#/pedidos")
    } else {
      buscarDadosDoPedido()
    }
  }, [])
  return (
    <>
      <GlobalContainer>
        <StyledContainer>
          <TextHeaderContainer>
            <h3>{dadosPedido.nomeEmpresa}</h3>
            <span style={{backgroundColor: getStatusColor(status)}}>{status}</span>
          </TextHeaderContainer>
          <TextSubTitulo>
            <p>Código do Pedido: #{dadosPedido.numero}</p>
          </TextSubTitulo>
          <div>
            <TextoDiaHora>
              <TextoIcone>
                <Calendar size={"SM"} />
              </TextoIcone>
              <p>{dayjs(dadosPedido.dataVenda).format("DD/MM/YYYY")}</p>
              <TextoIcone>
                <Clock size={"SM"} />
              </TextoIcone>
              <p>{dayjs(dadosPedido.horaVenda, "HH:mm:ss.SSS").format("HH:mm")}</p>
            </TextoDiaHora>
            <ContainerLinhaStatus>
              {largura > 768 ? (
                <StatusPedido>
                  {status ===  "Aberta" && (<>
                  <span
                    style={{ backgroundColor: "#858585" }}
                  >
                    Pedido aceito
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                    Pedido pronto
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                    Pedido entregue
                  </span>
                  </>
                  )
                  }
                  {status ===  "Pedido aceito" && (<>
                  <span
                    style={{ backgroundColor: "#00E676" }}
                  >
                    Pedido aceito
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                    Pedido pronto
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                    Pedido entregue
                  </span>
                  </>
                  )
                  }
                  {status ===  "Cancelada" && (<>
                  <span
                    style={{ backgroundColor: "#e74a3b" }}
                  >
                    Pedido cancelado
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                    Pedido pronto
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                    Pedido entregue
                  </span>
                  </>
                  )
                  }
                  {status ===  "Retirada liberada" && (<>
                  <span
                    style={{ backgroundColor: "#00E676" }}
                  >
                    Pedido aceito
                  </span>
                  <span style={{ backgroundColor: "#00E676" }}>
                    Retirada liberada
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                    Pedido retirado
                  </span>
                  </>
                  )
                  }
                  {status ===  "Saiu para entrega" && (<>
                  <span
                    style={{ backgroundColor: "#00E676" }}
                  >
                    Pedido aceito
                  </span>
                  <span style={{ backgroundColor: "#00E676" }}>
                  Saiu para entrega
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                    Pedido Entregue
                  </span>
                  </>
                  )
                  }
                  {status ===  "Pedido entregue" && (<>
                  <span
                    style={{ backgroundColor: "rgba(28, 200, 138, 1)" }}
                  >
                    Pedido aceito
                  </span>
                  <span style={{ backgroundColor: "rgba(28, 200, 138, 1)" }}>
                    {dadosPedido.tipoEntrega === "RETIRADA_LOCAL" ? "Retirada Liberada" : "Saiu para entrega"}
                    
                  </span>
                  <span style={{ backgroundColor: "rgba(28, 200, 138, 1)" }}>
                    Pedido Entregue
                  </span>
                  </>
                  )
                  }
                </StatusPedido>
              ) : (
                <>
                     <StatusPedidoMobile>
                  {status ===  "Aberta" && (<>
                  <span
                    style={{ backgroundColor: "#858585" }}
                  >
                     Aceito
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                   Pronto
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                   Entregue
                  </span>
                  </>
                  )
                  }
                  {status ===  "Pedido aceito" && (<>
                  <span
                    style={{ backgroundColor: "#00E676" }}
                  >
                     Aceito
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                   Pronto
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                   Entregue
                  </span>
                  </>
                  )
                  }
                  {status ===  "Cancelada" && (<>
                  <span
                    style={{ backgroundColor: "#e74a3b" }}
                  >
                     Cancelado
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                   Pronto
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                   Entregue
                  </span>
                  </>
                  )
                  }
                  {status ===  "Retirada liberada" && (<>
                  <span
                    style={{ backgroundColor: "#00E676" }}
                  >
                     Aceito
                  </span>
                  <span style={{ backgroundColor: "#00E676" }}>
                    Retirada liberada
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                   Retirado
                  </span>
                  </>
                  )
                  }
                  {status ===  "Saiu para entrega" && (<>
                  <span
                    style={{ backgroundColor: "#00E676" }}
                  >
                     Aceito
                  </span>
                  <span style={{ backgroundColor: "#00E676" }}>
                  Saiu para entrega
                  </span>
                  <span style={{ backgroundColor: "#858585" }}>
                   Entregue
                  </span>
                  </>
                  )
                  }
                  {status ===  "Pedido entregue" && (<>
                  <span
                    style={{ backgroundColor: "rgba(28, 200, 138, 1)" }}
                  >
                     Aceito
                  </span>
                  <span style={{ backgroundColor: "rgba(28, 200, 138, 1)" }}>
                    Saiu para entrega
                  </span>
                  <span style={{ backgroundColor: "rgba(28, 200, 138, 1)" }}>
                   Entregue
                  </span>
                  </>
                  )
                  }
                </StatusPedidoMobile>
                  <TextoStatusPedido></TextoStatusPedido>
                </>
              )}
              <LinhaStatus>
                <LinhaStatus style={{backgroundColor: "rgba(28, 200, 138, 1)", width: verificarTamanhoLinha()}}/>
              </LinhaStatus>
            </ContainerLinhaStatus>
          </div>
          <TempoPedidoContainer>
            <TempoPedidoItens>
              <TextoIcone>
                <Clock size={"SM"} />
              </TextoIcone>
              <p>{dayjs(dadosPedido.horaVenda, "HH:mm:ss.SSS").format("HH:mm")}</p>
            </TempoPedidoItens>
            <TempoPedidoItens lastItem>
              <TextoIcone>
                <Clock size={"SM"} />
              </TextoIcone>
              {dadosPedido.previsaoEntregaMin && dadosPedido.previsaoEntregaMax ?
              <p>{dayjs(dadosPedido.previsaoEntregaMin, "HH:mm:ss.SSS").format("HH:mm")} às {dayjs(dadosPedido.previsaoEntregaMax, "HH:mm:ss.SSS").format("HH:mm")}</p> : <p>Não informada</p>}
            </TempoPedidoItens>
          </TempoPedidoContainer>

          <TextoTempoPedido>
            <span>Pedido realizado</span>
            <span>Previsão de entrega</span>
          </TextoTempoPedido>
          <ContainerPedidos>
            {dadosPedido.itens && dadosPedido.itens.map((item, index) => (
              <ItemPedido key={index} item={item} />
            ))}
          </ContainerPedidos>
          {dadosPedido.finalizadoras && dadosPedido.finalizadoras.map((finalizadora) =>
          (
            <FormaDePgto>
              <strong>Forma de Pagamento</strong>
              <p>{finalizadora.descricao}</p>
            </FormaDePgto>
          ))}

          {dadosPedido.endereco && dadosPedido.endereco && (<InfoEndereco>
            <strong>Endereço</strong>
            <p>
              <TextoIcone
                style={{
                  display: "inline",
                  verticalAlign: "middle",
                  marginRight: 0,
                }}
              >
                <MapPin weight="fill" />
              </TextoIcone>
              {dadosPedido.endereco.logradouro}, {dadosPedido.endereco.bairro}, {dadosPedido.endereco.numero} - {dadosPedido.endereco.cidade}/{dadosPedido.endereco.uf}
            </p>
          </InfoEndereco>)}



          <ContainerValores>
            <strong>Subtotal:</strong>
            <strong>R$ {dadosPedido.valorSubtotal?.toFixed(2)}</strong>
          </ContainerValores>

          <ContainerValores>
            <strong>Taxa de entrega:</strong>
            <strong>R$ {dadosPedido.valorFrete?.toFixed(2)}</strong>
          </ContainerValores>

          <ContainerValores>
            <strong>Desconto:</strong>
            <strong>R$ {dadosPedido.valorDesconto?.toFixed(2)}</strong>
          </ContainerValores>
          <ContainerValoresTotal>
            <strong>Total:</strong>
            <strong>R$ {dadosPedido.valorTotal?.toFixed(2)}</strong>
          </ContainerValoresTotal>

          <Footer>
            <a href="/menu/restaurante-waymenu/delivery/#/pedidos">Ver menos detalhes</a>
            {dadosPedido.status === "ABERTA" && <GerarModalResponsivo trigger={<button>Cancelar pedido</button>} children={(close) => (
              <ModalExcluir>

                <h1>Cancelar pedido</h1>
                <FontAwesomeIcon icon={faX} onClick={() => close()} />

                <p>Você tem certeza que deseja cancelar o pedido?</p>

                <ContainerBotoesModal>
                  <BotaoVermelhoPreenchido tamanho={"MD"} texto="Não" onClick={() => close()} />
                  <BotaoCardapioPreenchidoVerde tamanho={"MD"} texto="Sim" onClick={() => {
                    cancelarPedido()
                    close()
                  }} />
                </ContainerBotoesModal>
              </ModalExcluir>
            )} />}


          </Footer>
        </StyledContainer>
      </GlobalContainer>

    </>

  );
};
