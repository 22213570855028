import React, { useEffect, useState } from "react";
import {
  EscolhaExpansivel,
  EscolhaExpansivelTitulo,
  EscolhaExpansivelConteudo,
  FormControlLabelCustomizado,
  CardCustomizadoOpcao,
  ConteudoOpcao,
  ContainerContador,
  ImagemProduto,
  ConteudoOpcaoPizza,
  IconeBotao,
} from "./styles";
import {
  faX,
  faMinus,
  faPlus,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErroImagem from "../../../assets/images/img-erro.png";
import scrollParaElemento from "../../../utils/scrollParaElemento";
import { CardOpcaoLabel, Contador } from ".";
import { useUserAuth } from "../../../contexts/userAuth";

export const AdicionarPizza = ({
  aberto,
  sabores,
  setDados,
  dados,
  quantidadeASerEscolhida,
  style,
  escolhas,
  setEscolhas,
  setEscolhaFeita = () => {},
  modoCardapio = false,
  produto
}) => {
  const [escolhasQuantidade, setEscolhasQuantidade] = useState(0);


  function alterarOpcao(opcao, quantidade) {
    let escolhasVar = { ...escolhas };
    let idInterno = opcao.sabor.idProdutoEmpresa;

    if (quantidade > 0) {
      escolhasVar[idInterno] = {
        ...opcao,
        quantidade: quantidade,
      };
    } else {
      if (escolhasVar[idInterno]) {
        delete escolhasVar[idInterno];
      }
    }
    setEscolhas(escolhasVar);
  }


  const [limiteAtingido, setLimiteAtingido] = useState(false);

  useEffect(() => {
    let quantidadeTotal = Object.values(escolhas).reduce((total, opcao) => {
      return total + parseInt(opcao.quantidade);
    }, 0);

    setEscolhasQuantidade(quantidadeTotal);

    if (quantidadeTotal >= quantidadeASerEscolhida) {
      setLimiteAtingido(true);
      setEscolhaFeita(true);
    } else {
      setLimiteAtingido(false);
      setEscolhaFeita(false);
    }
  }, [escolhas]);

  function gerarStringQuantidade() {
    if (escolhasQuantidade < quantidadeASerEscolhida) {
      return `Escolha mais ${quantidadeASerEscolhida - escolhasQuantidade} ${
        quantidadeASerEscolhida - escolhasQuantidade > 1 ? "sabores" : "sabor"
      }`;
    }

    if (escolhasQuantidade === quantidadeASerEscolhida) {
      return "Sabores escolhidos";
    }

    return "";
  }

  function verificarEstoque(opcao) {
    if (opcao?.estocado) {
      if (opcao.quantidadeEstoque > 0) {
        return "CONTEM_ESTOQUE"
      }
      return "SEM_ESTOQUE"
    }

    return "NAO_CONTROLA"
  }

  return (
    <EscolhaExpansivel defaultExpanded={aberto} style={{ ...style }}>
      <EscolhaExpansivelTitulo expandIcon={<ExpandMoreIcon />}>
        Sabores
        <p>{gerarStringQuantidade()}</p>
      </EscolhaExpansivelTitulo>
      <EscolhaExpansivelConteudo>
        <FormControl sx={{ width: "100%" }}>
          {sabores.map((opcao, index) => {
            return verificarEstoque(opcao) !== "SEM_ESTOQUE" && (
              <FormControlLabelCustomizado
                style={{ pointerEvents: "none" }}
                key={"opcao" + opcao.sabor.descricaoSabor + index}
                value={opcao.sabor.descricaoSabor}
                control={
                  !modoCardapio ? (
                    <ContadorPizza
                      opcao={opcao}
                      escolhas={escolhas}
                      alterarOpcao={alterarOpcao}
                      limiteAtingido={limiteAtingido}
                      produto={produto}
                    />
                  ) : (
                    <></>
                  )
                }
                label={<CardOpcaoLabelPizza opcao={opcao} pizza={true} />}
                labelPlacement="start"
              />
            );
          })}
        </FormControl>
      </EscolhaExpansivelConteudo>
    </EscolhaExpansivel>
  );
};

export const EscolhaPizza = ({
  aberto,
  pizza,
  style,
  sabores,
  escolhas,
  setEscolhas = () => {},
  setEscolhaFeita = () => {},
  modoCardapio = false,
  produto
}) => {
  const [escolha, setEscolha] = useState({});
  useEffect(() => {
    let escolhasVar = {};

    if (Object.keys(escolha).length === 0) {
      escolhasVar = {};
      setEscolhaFeita(false);
    } else {
      let idInterno = escolha.sabor.idProdutoEmpresa;
      escolhasVar[idInterno] = {
        ...escolha,
        quantidade: 1,
      };
      setEscolhaFeita(true);
    }

    setEscolhas(escolhasVar);
  }, [escolha]);

  function handleClick(event) {
    if (
      JSON.parse(event.target.value)?.sabor.idProdutoEmpresa ===
      escolha?.sabor?.idProdutoEmpresa
    ) {
      setEscolha({});
    } else {
      setEscolha(JSON.parse(event.target.value));
    }
  }

  
  function verificarEstoque(sabor) {
    if (sabor?.estocado) {
      if (sabor.quantidadeEstoque > 0) {
        return "CONTEM_ESTOQUE"
      }
      return "SEM_ESTOQUE"
    }

    return "NAO_CONTROLA"
  }



  return (
    <EscolhaExpansivel defaultExpanded={aberto} style={{ ...style }}>
      <EscolhaExpansivelTitulo expandIcon={<ExpandMoreIcon />}>
        Sabores
        <p>
          {escolha && Object.keys(escolha).length > 0
            ? `Sabor escolhido`
            : `Escolha mais 1 sabor`}
        </p>
      </EscolhaExpansivelTitulo>
      <EscolhaExpansivelConteudo>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup name="escolha" value={JSON.stringify(escolha)}>
            {sabores.map((opcao, index) => {
              
              return verificarEstoque(opcao) !== "SEM_ESTOQUE" && (
                <FormControlLabelCustomizado
                  key={"opcao" + opcao.sabor.descricao + index}
                  value={JSON.stringify(opcao)}
                  control={
                    !modoCardapio ? <Radio onClick={handleClick} /> : <></>
                  }
                  label={<CardOpcaoLabelPizza opcao={opcao} produto={produto} />}
                  labelPlacement="start"
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </EscolhaExpansivelConteudo>
    </EscolhaExpansivel>
  );
};

export const CardOpcaoLabelPizza = ({ opcao, produto }) => {
  const { urlBucket } = useUserAuth();

  const [imagemSrc, setImagemSrc] = useState(
    opcao.sabor.imagemUrl ? opcao.sabor.imagemUrl : ErroImagem
  );

  useEffect(() => {
    let imgUrl = `${urlBucket}/imagens/PRODUTO_GRADE_IMAGEM/${opcao.sabor.imagemUrl}`;
    const img = new Image();
    img.onload = () => setImagemSrc(imgUrl); // Imagem carregada com sucesso
    img.onerror = () => setImagemSrc(ErroImagem); // Falha ao carregar, usar imagem de erro
    img.src = imgUrl;
  }, [opcao]);

  function formatarReal(valor) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(valor);
  }

 

  return (
    <CardCustomizadoOpcao>
      <ImagemProduto
        style={{
          width: 100,
          minWidth: 100,
          height: 100,
          minHeight: 100,
          borderRadius: "0.5rem",
          marginTop: 5,
          marginBottom: 5,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        url={imagemSrc}
      />
      <ConteudoOpcaoPizza>
        <h3>{opcao.sabor.descricaoSabor}</h3>
        <p>{opcao.sabor.detalhesSabor ? opcao.sabor.detalhesSabor : ""}</p>
        <div style={{ display: "flex", flexDirection: "row", gap: "2px" }}>
          <span>
            {opcao.tabelaDePreco.precoVenda &&
            parseFloat(opcao.tabelaDePreco.precoVenda) > 0
              ? formatarReal(opcao.tabelaDePreco.precoVenda)
              : ""}
          </span>
          {/* <span className="valorRiscado">
              {opcao.valorAntigo ? formatarReal(opcao.valorAntigo) : ""}
            </span> */}
        </div>
      </ConteudoOpcaoPizza>
    </CardCustomizadoOpcao>
  );
};

export const ContadorPizza = ({
  opcao,
  escolhas,
  alterarOpcao,
  limiteAtingido,
  quantidadeMaxima,
  produto
}) => {
  const [quantidade, setQuantidade] = useState(
    escolhas[opcao.idProdutoEmpresa]
      ? escolhas[opcao.sabor.idProdutoEmpresa].quantidade
      : 0
  );


  useEffect(() => {
    if (escolhas[opcao.sabor.idProdutoEmpresa]) {
      setQuantidade(escolhas[opcao.sabor.idProdutoEmpresa].quantidade);
    } else {
      setQuantidade(0);
    }
  }, []);

  useEffect(() => {
    alterarOpcao(opcao, quantidade);
  }, [quantidade]);

  function incrementar(e) {
    e.stopPropagation();
    if (!limiteAtingido) {
      setQuantidade(quantidade + 1);
    }
  }

  function decrementar(e) {
    e.stopPropagation();
    if (quantidade > 0) {
      setQuantidade(quantidade - 1);
    }
  }

  function verificarEstoque() {
    if (produto.estocado) {
      if (produto.estoque > 0) {
        return "CONTEM_ESTOQUE"
      }
      return "SEM_ESTOQUE"
    }

    return "NAO_CONTROLA"
  }
  function verificarEstoqueOpcoes() {
    if (opcao.estocado) {
      if (opcao.quantidadeEstoque > 0) {
        return "CONTEM_ESTOQUE"
      }
      return "SEM_ESTOQUE"
    }

    return "NAO_CONTROLA"
  }

  return (
    <ContainerContador style={{ pointerEvents: "all", minWidth: 70 }}>
      <IconeBotao 
        onClick={decrementar}
        disabled={verificarEstoqueOpcoes() === "SEM_ESTOQUE" || verificarEstoqueOpcoes() === "CONTEM_ESTOQUE" && opcao.quantidadeEstoque <= quantidade}
      >
      <FontAwesomeIcon
        icon={faMinus}
        style={{ cursor: "pointer", fontSize: "22px" }}
        />
        </IconeBotao>
      <p>{quantidade}</p>
      <IconeBotao
      onClick={incrementar}
      disabled={verificarEstoqueOpcoes() === "SEM_ESTOQUE" || verificarEstoqueOpcoes() === "CONTEM_ESTOQUE" && opcao.quantidadeEstoque <= quantidade}
      >
      <FontAwesomeIcon
        
        icon={faPlus}
        style={{
          cursor: "pointer",
          fontSize: "22px",
          opacity:
          limiteAtingido || quantidade >= opcao.quantidadeMaxima ? 0.5 : 1,
        }}
        />
        </IconeBotao>
    </ContainerContador>
  );
};
