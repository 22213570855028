import React, { useEffect, useState } from "react";
import {
  BotaoProximo,
  ItemSabor,
  Linha,
  Separador,
  Tamanhos,
  PaginaContainer,
  TextoSobre,
  CardNenhumResultado,
  TextoTituloNenhumResultado,
  TextoSubtituloNenhumResultado,
} from "../styles";
import Escolha, { Adicionar } from "../../escolhaExpansivel";
import img_pizza from "../imagens-placeholder/img_pizza.jpg";
import { ReactComponent as ImagemSemResultado } from "../../../../assets/images/modal-pizza-sem-resultados.svg";
import BarraPesquisa from "../../../barraPesquisa";
import { CircularProgress } from "@mui/material";
import { useTheme } from "styled-components";
import {
  AdicionarPizza,
  EscolhaPizza,
} from "../../escolhaExpansivel/combinado";
import scrollParaElemento from "../../../../utils/scrollParaElemento";

export const PaginaSabores = ({
  produto,
  combinados,
  mudarPagina,
  dados,
  setDados,
  modoCardapio = false,
}) => {
  const tema = useTheme();

  const [textoPesquisa, setTextoPesquisa] = useState("");
  const [saboresFiltrados, setSaboresFiltrados] = useState([]);
  const [saboresCompletos, setSaboresCompletos] = useState([]);
  const [escolhaFeita, setEscolhaFeita] = useState(false);
  const [escolhas, setEscolhas] = useState({});
  const [quantidadeEscolhida, setQuantidadeEscolhida] = useState(0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    formatarDados();
  }, []);

  useEffect(() => {
    if (saboresFiltrados && saboresFiltrados.length > 0) {
      setLoading(false);
    }
  }, [saboresFiltrados]);

  useEffect(() => {
    const filtrar = () => {
      const texto = textoPesquisa.toLowerCase();
      if (texto) {
        const opcoesFiltradas = saboresCompletos.filter((opcao) => {
          const titulo = opcao.sabor.descricaoSabor.toLowerCase();
          return titulo.includes(texto);
        });
        let novasOpcoes = [...opcoesFiltradas];
        setSaboresFiltrados(novasOpcoes);
      } else {
        formatarDados();
      }
    };
    filtrar();
  }, [textoPesquisa]);

  useEffect(() => {
    if (escolhaFeita) {
      scrollParaElemento("proximo");
    }
  }, [escolhaFeita]);

  function formatarDados() {
    let saboresNovos = combinados.infoMenuCombinado.reduce((acc, item) => {
      if (item.tamanho.idTamanho == dados.tamanho.idTamanho) {
        acc.push(item);
      }
      return acc;
    }, []);

    setSaboresFiltrados(saboresNovos);
    setSaboresCompletos(saboresNovos);
    setQuantidadeEscolhida(dados.quantidadeSabores - 1);
    if (!saboresNovos) {
      mudarPagina();
    }
  }

  function salvarDados() {
    let novosDados = { ...dados };
    novosDados.sabores = Object.values(escolhas);
    setDados(novosDados);
  }

  const NenhumResultado = () => {
    return (
      <CardNenhumResultado>
        <TextoTituloNenhumResultado>
          Não encontramos nenhum resultado
        </TextoTituloNenhumResultado>
        <TextoSubtituloNenhumResultado>
          Tente buscar em outro termo
        </TextoSubtituloNenhumResultado>
        <ImagemSemResultado />
      </CardNenhumResultado>
    );
  };

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{ color: tema.coresCardapio.brand500 }}
        style={{ alignSelf: "center" }}
      />
    </div>
  ) : (
    <>
      <PaginaContainer>
        <BarraPesquisa
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: "100%",
            borderRadius: 16,
            borderWidth: 2,
          }}
          value={textoPesquisa}
          onChange={(e) => setTextoPesquisa(e.target.value)}
        />
        {saboresFiltrados.length > 0 ? (
          dados.quantidadeSabores == 2 ? (
            <EscolhaPizza
              sabores={saboresFiltrados}
              aberto={true}
              escolhas={escolhas}
              setEscolhas={setEscolhas}
              setEscolhaFeita={setEscolhaFeita}
              modoCardapio={modoCardapio}
              produto={produto}
            />
          ) : (
            <AdicionarPizza
              sabores={saboresFiltrados}
              aberto={true}
              escolhas={escolhas}
              setEscolhas={setEscolhas}
              quantidadeASerEscolhida={quantidadeEscolhida}
              setEscolhaFeita={setEscolhaFeita}
              modoCardapio={modoCardapio}
              produto={produto}
            />
          )
        ) : (
          <NenhumResultado />
        )}
        <BotaoProximo
          id="proximo"
          $ativo={escolhaFeita || modoCardapio}
          onClick={() => {
            if (escolhaFeita || modoCardapio) {
              salvarDados();
              mudarPagina();
            }
          }}
        >
          PRÓXIMO
        </BotaoProximo>
      </PaginaContainer>
    </>
  );
};
