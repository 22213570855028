import React, { Fragment, useEffect, useRef, useState } from "react";
import GerarModalResponsivo from "../gerarModalResponsivo";
import { X } from "phosphor-react";
import { TextField, CircularProgress } from "@mui/material";
import {
  CampoDeSenha,
  CampoDeSenhaOutlined,
  CampoDeTexto,
  CampoDeTextoOutlined,
} from "../campoInput";
import {
  BotaoCardapioPreenchido,
  BotaoCardapioVazado,
  BotaoVermelhoPreenchido,
  BotaoVermelhoVazado,
} from "../botao";
import {
  CardPagamento,
  ContainerInputs,
  ContianerBotoes,
  Divider,
  Linha,
  LinhaCardPagamento,
} from "./styles";
import { Close, House, Margin } from "@mui/icons-material";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faLocationDot,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Endereco from "../modalLogin/endereço";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { baseURL, useApi } from "../../contexts/api";
import { toast } from "react-toastify";
import axios from "axios";
import { useTheme } from "styled-components";

import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { useUserAuth } from "../../contexts/userAuth";
import FormasPagamento from "../modalLogin/formasPagamento";

const ModalFormasPagamento = ({
  aberto,
  setAberto,
  loginGoogle,
  onOpen,
  novaEntrada = false,
}) => {
  const { largura } = useTamanhoTela();
  const { loginUsuario, dadosAuth } = useUserAuth();
  const { post, get } = useApi();

  const [etapa, setEtapa] = useState(
    novaEntrada ? "novaFormaPagamento" : "mostrarFormasPagamento"
  );
  const [dadosCadastro, setDadosCadastro] = useState({});
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [formaSelecionada, setFormaSelecionada] = useState({});

  function fechar() {
    document.body.style.overflow = "unset";
    setAberto(false);
  }
  function mudarTela(tela) {
    setEtapa(tela);
  }

  useEffect(() => {
    if (largura > 768 && aberto) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [aberto]);

  useEffect(() => {
    buscarFormasPagamento();
  }, []);

  useEffect(() => {
    if (etapa == "mostrarFormasPagamento") {
      buscarFormasPagamento();
    }
  }, [etapa, aberto]);

  function buscarFormasPagamento() {
    setFormaSelecionada({});
    get(
      "/usuario-sessao/client/usuario/carteira/buscar-todos?idUsuario=" +
        dadosAuth.idUsuario
    )
      .then((res) => {
        setFormasPagamento(res.data.content);
        setEtapa("mostrarFormasPagamento");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getTipoCartao(chipCredito, chipDebito) {
    if (chipCredito) {
      return "CREDITO";
    } else if (chipDebito) {
      return "DEBITO";
    }
  }

  function getErrosPagamento(mensagemErro) {
    let errosRetorno = {};

    try {
      if (mensagemErro.toLowerCase().includes("nome")) {
        errosRetorno.erroNomeTitular = "Nome inválido";
      }
      if (
        mensagemErro.toLowerCase().includes("numeroCartao") ||
        mensagemErro.toLowerCase().includes("Bandeira")
      ) {
        errosRetorno.erroNumeroCartao = "Número inválido";
      }
      if (mensagemErro.toLowerCase().includes("validade")) {
        errosRetorno.erroValidade = "Validade inválida";
      }
      if (mensagemErro.toLowerCase().includes("cvv")) {
        errosRetorno.erroCvv = "CVV inválido";
      }
      if (mensagemErro.toLowerCase().includes("cpf")) {
        errosRetorno.errocpfCartao = "CPF inválido";
      }
      if (mensagemErro.toLowerCase().includes("tipo_cartao")) {
        errosRetorno.erroChips = "Selecione um tipo de cartão";
      }
    } catch (error) {
      return {};
    }

    return errosRetorno;
  }

  async function novaFormaPagamento(dadosCadastro) {
    let sucesso = false;
    setEtapa("loading");
    try {
      await post("/usuario-sessao/client/usuario/carteira", {
        tipoPagamento: getTipoCartao(
          dadosCadastro.pagamento.chipCredito,
          dadosCadastro.pagamento.chipDebito
        ),
        nome: dadosCadastro.pagamento.nomeTitular,
        numeroCartao: dadosCadastro.pagamento.numeroCartao.replace(/-/g, ""),
        validade: dadosCadastro.pagamento.validade.replace("/", "20"),
        cvv: dadosCadastro.pagamento.cvv,
      });
      sucesso = true;
      toast.success("Forma de pagamento cadastrada com sucesso");
    } catch (error) {
      console.log(error);
      toast.error(
        error.data?.message
          ? error.data?.message
          : "Erro ao cadastrar pagamento"
      );
      let erros = getErrosPagamento(error.data?.message);
      setDadosCadastro({
        ...dadosCadastro,
        errosPagamento: {
          ...erros,
        },
      });
      if (novaEntrada) {
        fechar();
      } else {
        setEtapa("novaFormaPagamento");
      }
      sucesso = false;
    }

    if (sucesso) {
      if (novaEntrada) {
        fechar();
      } else {
        setEtapa("mostrarFormasPagamento");
      }
    }
  }

  function MostrarFormasPagamentos() {
    const theme = useTheme();

    const [abertoAvisoRemover, setAbertoAvisoRemover] = useState(false);
    const [loadingFormas, setLoadingFormas] = useState(false);
    const { get, del } = useApi();

    const scrollRef = useRef(null);
    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo(0, 0);
      }
    }, []);

    function removerForma() {
      setLoadingFormas(true);
      del("/usuario-sessao/client/usuario/carteira/" + formaSelecionada.id)
        .then((res) => {
          setFormaSelecionada({});
          buscarFormasPagamento();
          toast.success("Forma de pagamento removida com sucesso");
          setLoadingFormas(false);
        })
        .catch((err) => {
          toast.error("Erro ao remover forma de pagamento");
          setFormaSelecionada({});
          setLoadingFormas(false);
          console.log(err);
        });
    }

    function ModalRemoverForma() {
      return (
        <GerarModalResponsivo
          width={"400px"}
          controle={abertoAvisoRemover}
          onClose={() => setFormaSelecionada({})}
          blocking={false}
          overlayManual={true}
          children={() => {
            return (
              <Fragment>
                <Linha>
                  <h1>Excluir item</h1>
                </Linha>
                <Divider style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    padding: "16px 12px",
                    alignItems: "flex-start",
                    marginBottom: 16,
                  }}
                >
                  <p style={{ textAlign: "flex-start", marginBottom: 16 }}>
                    Deseja realmente remover a entrada
                    <br />({formaSelecionada.tipoPagamento}) **** **** ****{" "}
                    {formaSelecionada.finalCartao}?
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                      gap: 8,
                    }}
                  >
                    <BotaoVermelhoPreenchido
                      tamanho={"MD"}
                      texto={"Remover"}
                      onClick={() => {
                        removerForma();
                      }}
                    />
                    <BotaoVermelhoVazado
                      tamanho={"MD"}
                      texto={"Cancelar"}
                      onClick={() => {
                        setFormaSelecionada({});
                        setAbertoAvisoRemover(false);
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            );
          }}
        />
      );
    }

    return (
      <div
        style={{ overflow: "scroll", maxHeight: largura < 990 ? "70vh" : null }}
        ref={scrollRef}
      >
        <Linha>
          <div />
          <h1>Formas de pagamento</h1>
          <FontAwesomeIcon
            onClick={() => fechar()}
            icon={faXmark}
            style={{ fontSize: 28, color: "red", cursor: "pointer" }}
          />
        </Linha>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            padding: "16px 12px",
            marginBottom: 16,
          }}
        >
          {loadingFormas ? (
            <div
              style={{
                width: "100%",
                height: 500,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress style={{ alignSelf: "center" }} />
            </div>
          ) : (
            formasPagamento?.map((forma, index) => {
              return (
                <CardPagamento key={index + "cardEndereco"} elevation={1}>
                  <p>
                    ({forma.tipoPagamento}) **** **** **** {forma.finalCartao}
                  </p>
                  <FontAwesomeIcon
                    onClick={() => {
                      setFormaSelecionada(forma);
                      setAbertoAvisoRemover(true);
                    }}
                    icon={faTrash}
                    style={{
                      fontSize: 16,
                      marginLeft: "auto",
                      color: "red",
                      cursor: "pointer",
                      position: "absolute",
                      bottom: 16,
                      right: 16,
                    }}
                  />
                </CardPagamento>
              );
            })
          )}
          {!loadingFormas &&
            (!formasPagamento || formasPagamento?.length == 0) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 24,
                }}
              >
                <p>Nenhuma forma de pagamento cadastrada.</p>
              </div>
            )}
          <BotaoCardapioPreenchido
            texto={"ADICIONAR"}
            onClick={() => {
              setDadosCadastro({});
              mudarTela("novaFormaPagamento");
            }}
          />
        </div>
        <ModalRemoverForma />
      </div>
    );
  }

  return (
    <GerarModalResponsivo
      width={"500px"}
      controle={aberto}
      onClose={fechar}
      onOpen={onOpen}
      blocking={false}
      overlayManual={true}
      children={() => {
        switch (novaEntrada ? "novaFormaPagamento" : etapa) {
          case "loading":
            return (
              <div
                style={{
                  width: "100%",
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{ alignSelf: "center" }} />
              </div>
            );

          case "mostrarFormasPagamento":
            return <MostrarFormasPagamentos />;
          case "novaFormaPagamento":
            return (
              <FormasPagamento
                modoEdicao={true}
                fechar={fechar}
                mudarTela={mudarTela}
                dadosCadastro={dadosCadastro}
                setDadosCadastro={setDadosCadastro}
                finalizar={novaFormaPagamento}
                apenasNovaEntrada={novaEntrada}
              />
            );
          default:
            break;
        }
      }}
    />
  );
};

export default ModalFormasPagamento;
