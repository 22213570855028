export default function formatarReal(valor) {
  class RealFormatter {
    static format(value) {
      const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    }
  }

  if (!valor) {
    return "R$ 0,00";
  }

  return RealFormatter.format(valor);
}
