import styled from "styled-components";

export const ContainerBuscarProduto = styled.div`
  width: 250px;
  border: 1px solid ${({ theme }) => theme.coresCardapio.brand500};
  border-radius: 8px;
  padding: 0 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    border: none;
    width: 100%;
    font-size: 16px;
    color: ${({ theme }) => theme.coresCardapio.brand500};
    background-color: transparent;
    font-weight: 500;
    outline: none;
  }
`;
