import React, { Fragment, useEffect, useRef, useState } from "react";
import GerarModalResponsivo from "../gerarModalResponsivo";
import { X } from "phosphor-react";
import { TextField, CircularProgress } from "@mui/material";
import {
  CampoDeSenha,
  CampoDeSenhaOutlined,
  CampoDeTexto,
  CampoDeTextoOutlined,
} from "../campoInput";
import { BotaoCardapioPreenchido, BotaoCardapioVazado } from "../botao";
import {
  CardEndereco,
  ContainerHorario,
  ContainerInputs,
  ContianerBotoes,
  Divider,
  Linha,
  LinhaCardEndereco,
  TituloHorario,
  LinhaInfoHorario,
  TituloHorarioVerde,
  ContainerItemHorario,
} from "./styles";
import { Close, House, Margin } from "@mui/icons-material";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faLocationDot,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Endereco from "../modalLogin/endereço";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { baseURL, useApi } from "../../contexts/api";
import { toast } from "react-toastify";
import axios from "axios";
import { useTheme } from "styled-components";

import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { useUserAuth } from "../../contexts/userAuth";
import dayjs from "dayjs";

const horariosMockados = [
  { titulo: "Segunda-feira", horarios: [] },
  {
    titulo: "Terça-feira",
    horarios: [],
  },
  { titulo: "Quarta-feira", horarios: [] },
  {
    titulo: "Quinta-feira",
    horarios: [],
  },
  { titulo: "Sexta-feira", horarios: [] },
  {
    titulo: "Sábado",
    horarios: [],
  },
  { titulo: "Domingo", horarios: [] },
];

const diasSemana = [
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
  "Domingo",
];

const ModalHorarioFuncionamento = ({ aberto, setAberto, onOpen }) => {
  const { largura } = useTamanhoTela();
  const { post, put } = useApi();
  const { dadosEmpresa } = useUserAuth();

  const [horarios, setHorarios] = useState([]);
  const [diaSemanaAtual, setDiaSemanaAtual] = useState();

  function getHorarios() {
    post(
      "configuracoes-lojas/empresa-horario-funcionamento/buscar-todos?page=0&size=100",
      {
        idEmpresa: dadosEmpresa.idEmpresa,
      }
    ).then((response) => {
      let horariosBrutos = response.data.content;
      let horariosProcessados = [];

      horariosBrutos.forEach((horario) => {
        if (!horariosProcessados[horario.dia]) {
          horariosProcessados[horario.dia] = {
            titulo: diasSemana[horario.dia],
            horarios: [[horario.horaAbertura, horario.horaFechamento]],
          };
        } else {
          horariosProcessados[horario.dia].horarios.push([
            horario.horaAbertura,
            horario.horaFechamento,
          ]);
        }
      });

      Object.keys(horariosProcessados).forEach((dia) => {
        horariosProcessados[dia].horarios.sort((a, b) => {
          return a[0].localeCompare(b[0]);
        });
      });

      setHorarios(horariosProcessados);

      let diaSemanaAtual = dayjs().locale("pt-br").format("dddd");
      setDiaSemanaAtual(diaSemanaAtual);
    });
  }

  useEffect(() => {
    if (dadosEmpresa) {
      getHorarios();
    }
  }, [dadosEmpresa]);

  function fechar() {
    document.body.style.overflow = "unset";
    setAberto(false);
  }

  useEffect(() => {
    if (largura > 768 && aberto) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [aberto]);

  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, []);

  function LinhaHorario({ dia, horario }) {
    return (
      <Fragment>
        <Divider />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ContainerHorario>
            {diaSemanaAtual == dia.toLowerCase() ? (
              <TituloHorarioVerde>{dia}</TituloHorarioVerde>
            ) : (
              <TituloHorario>{dia}</TituloHorario>
            )}
          </ContainerHorario>
          {horario ? (
            <ContainerHorario>
              {/* <LinhaInfoHorario>
                <ContainerHorario>Abre às</ContainerHorario>
                <ContainerHorario>Fecha às</ContainerHorario>
              </LinhaInfoHorario> */}
              {horario.horarios.map((horario, index) => (
                <LinhaInfoHorario key={"infoHorario" + dia + index}>
                  <ContainerItemHorario>
                    {dayjs(horario[0], "HH:mm:ss").format("HH:mm")}
                  </ContainerItemHorario>
                  <ContainerItemHorario style={{ width: "20%" }}>
                    às
                  </ContainerItemHorario>
                  <ContainerItemHorario>
                    {dayjs(horario[1], "HH:mm:ss").format("HH:mm")}
                  </ContainerItemHorario>
                </LinhaInfoHorario>
              ))}
            </ContainerHorario>
          ) : (
            <LinhaInfoHorario
              style={{ width: "100%", justifyContent: "center" }}
            >
              <ContainerHorario
                style={{
                  width: "100%",
                  justifyContent: "center",
                  paddingLeft: 40,
                  textAlign: "center",
                }}
              >
                Fechado
              </ContainerHorario>
            </LinhaInfoHorario>
          )}
        </div>
      </Fragment>
    );
  }
  return (
    <GerarModalResponsivo
      width={"500px"}
      controle={aberto}
      onClose={fechar}
      onOpen={onOpen}
      children={() => (
        <div
          style={{
            overflow: "scroll",
            maxHeight: largura < 990 ? "70vh" : null,
          }}
          ref={scrollRef}
        >
          <Linha>
            <h1 style={{ fontSize: 24 }}>Horários</h1>
            <FontAwesomeIcon
              onClick={() => fechar()}
              icon={faXmark}
              style={{ fontSize: 28, color: "red", cursor: "pointer" }}
            />
          </Linha>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              padding: "16px 12px",
              marginBottom: 16,
            }}
          >
            {diasSemana.map((dia, index) => (
              <LinhaHorario
                dia={dia}
                horario={horarios[index]}
                key={"horario" + index}
              />
            ))}
          </div>
        </div>
      )}
    />
  );
};

export default ModalHorarioFuncionamento;
