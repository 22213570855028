import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Typography } from "@mui/material";
import Popup from "reactjs-popup";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
`;

export const Linha = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const AvatarLoja = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  border: 5px solid white;

  @media (max-width: 1100px) {
    width: 70px;
    height: 70px;
    border: 2px solid white;
  }
`;

export const BordaColorida = styled.div`
  border-radius: 50%;
  border: 5px solid ${({ cor }) => cor};
  @media (max-width: 1100px) {
    border: 2px solid ${({ cor }) => cor};
  }
`;

export const ContainerDados = styled.div`
  width: 100%;
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ theme }) => theme.coresCardapio.background2};

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 3vw;
  }
`;

export const Indicador = styled.div`
  height: 20px;
  padding: 0px 8px;
  max-width: 100px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  color: #fff;
  font-family: "Open Sans";
`;

export const Texto = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;

  @media (max-width: 400px) {
    margin-top: 15px;
    margin-right: 0px;
    font-size: 16px;
  }
`;

export const TextoIcone = styled(FontAwesomeIcon)`
  width: 25px;
  margin-right: 5px;
`;

export const TextoVerde = styled(Texto)`
  color: ${({ theme }) => theme.coresCardapio.semanticSucess};
`;

export const TextoLaranja = styled(Texto)`
  color: ${({ theme }) => theme.coresCardapio.brand500};
`;

export const LinhaTitulo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
`;

export const ContainerRedesSociais = styled.div`
  font-size: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: ${({ theme }) => theme.coresCardapio.brand500};
  fill: ${({ theme }) => theme.coresCardapio.brand500};
  width: 120px;

  @media (max-width: 1100px) {
    position: relative;
    margin-bottom: -40px;
    top: -40px;
    margin-left: 80vw;
    gap: 10px;
    width: 0px;
  }
`;

export const LinhaInfos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: -15px;
  margin-bottom: 10px;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextoTitulo = styled.h1`
  font-size: 58px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;

  @media (max-width: 1100px) {
    font-size: 24px;
    margin-left: 5px;
    margin-bottom: 30px;
  }
`;

export const ContainerPopup = styled(Popup)`
  &-overlay {
    overflow-y: scroll;
    pointer-events: none;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  &-content {
    width: 36%;
    border-radius: 8px;
  }
`;

export const ContainerModal = styled.div``;

export const PopupHeader = styled.div`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const TituloHeader = styled.h1`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 34px;
  margin-bottom: 0;
  box-sizing: border-box;
`;

export const BotaoFecharPopup = styled.button`
  background-color: transparent;
  border: 0;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  display: block;

  svg {
    vertical-align: middle;
    box-sizing: border-box;
  }
`;

export const ContainerCorpoModal = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex: 1 1 auto;
  padding: 1rem;
  box-sizing: border-box;
  display: block;
  margin-bottom: 1rem;
`;

export const CorpoModal = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: block;
`;
export const ContainerTelefone = styled.div`
  box-sizing: border-box;
  display: block;
  margin-top: 1rem;
`;

export const TituloTelefone = styled.span`
  box-sizing: border-box;
  color: rgb(230, 121, 0);
  font-family: "Poppins", sans-serif;
  line-height: 24px;
  margin-bottom: 1rem;
`;

export const ConteudoTelefone = styled.p`
  line-height: 21.79px;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
`;

export const ContainerSobre = styled.div`
  box-sizing: border-box;
  display: block;
  margin-top: 1rem;
`;

export const TituloSobre = styled.span`
  box-sizing: border-box;
  color: rgb(230, 121, 0);
  font-family: "Poppins", sans-serif;
  line-height: 24px;
`;

export const ConteudoSobre = styled.p`
  font-family: "Open Sans", sans-serif;
  line-height: 21.79px;
  box-sizing: border-box;
`;
export const ContainerEndereco = styled.div`
  box-sizing: border-box;
  display: block;
  margin-top: 1rem;
`;

export const TituloEndereco = styled.span`
  box-sizing: border-box;
  color: rgb(230, 121, 0);
  font-family: "Poppins", sans-serif;
  line-height: 24px;
`;

export const ContainerConteudoEndereco = styled.div`
  display: flex;
  gap: 0.3rem;
  margin-top: 0.2rem;

  svg {
    color: rgb(230, 121, 0);
    width: 24px;
    height: 24px;
  }
`;

export const ConteudoEndereco = styled.p`
  font-family: "Open Sans", sans-serif;
  line-height: 21.79px;
  box-sizing: border-box;
`;

export const ContainerPagamento = styled.div`
  box-sizing: border-box;
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const TituloPagamento = styled.span`
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  line-height: 24px;
`;

export const TypographyTituloFormasDePagamento = styled(Typography)`
  color: ${({ theme }) => theme.coresCardapio.brand500};
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
`;

export const TypographyDescricaoFormasDePagamento = styled(Typography)``;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  margin: 0;

  &:before {
    display: none;
  }

  svg {
    color: ${({ theme }) => theme.coresCardapio.brand500};
  }
`;

export const ImagemBandeira = styled.img`
  height: 26px;
  position: relative;
  top: 8px;
  margin-right: 2px;
  border: 3px solid #ccc7;
`;

export const ContainerBandeira = styled.div`
  span {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding-bottom: 0.25rem;
    margin-right: 0.5rem;
    box-sizing: border-box;
    color: rgb(94, 94, 94);
    border-bottom: 1px solid rgb(94, 94, 94);
  }
`;

export const ContainerBandeiras = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;
