import { useNavigate } from "react-router-dom";
import { CardPedido } from "../../../components/cardPedido";
import Rodape from "../../../components/rodape";
import {
  BotaoVoltarAoCardapio,
  Container,
  ContainerBotao,
  ContainerCabecalhoCorpo,
  ContainerCorpo,
  ContainerPaginacao,
  ContainerPedidos,
  ContainerTituloCabecalho,
  TituloCabecalho,
} from "./styles";
import { ArrowLeft } from "phosphor-react";
import { useApi } from "../../../contexts/api";
import { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import useTamanhoTela from "../../../utils/useTamanhoTela";


export function MeusPedidos() {
  const navigate = useNavigate();
  const { get } = useApi()
  const { largura } = useTamanhoTela()
  const [pedidos, setPedidos] = useState([])
  const [pagina, setPagina] = useState(
    1
  )
  const [totalPages, setTotalPages] = useState(0)
  async function buscarTodasVendas(pagina = 0) {
    try {
      const res = await get(`vendas/nota?page=${pagina - 1}&aplicacaoOrigem=WAYMENU`)
      const dadosPaginacao = res.data.pageable
      setPedidos(res.data.content)
      console.log(res.data.totalPages);
      setTotalPages(res.data.totalPages)
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    } catch (error) {
      console.error(error)
    }
  }
  console.log(pagina);
console.log(totalPages);
  const handlePageChange = (event, value) => {
    setPagina(value);
    buscarTodasVendas(value)

  };

  useEffect(() => {
    buscarTodasVendas()
  }, [])
  return (
    <>
      <Container>
        <ContainerCabecalhoCorpo>
          <ContainerTituloCabecalho>
            <TituloCabecalho>Meus Pedidos</TituloCabecalho>
          </ContainerTituloCabecalho>
        </ContainerCabecalhoCorpo>
        <ContainerCorpo>
          <ContainerBotao>
            <BotaoVoltarAoCardapio
              onClick={() => {
                navigate("./#/cardapio");
              }}
            >
              <ArrowLeft size={18} weight="bold" />
              VOLTAR PARA O CARDÁPIO
            </BotaoVoltarAoCardapio>
          </ContainerBotao>
          <ContainerPedidos>

            {pedidos.map((pedido) => (
              <CardPedido
                valor={pedido}
                key={pedido.idNota}
                buscarTodasVendas={() => buscarTodasVendas(pagina)}
              />
            ))}

            {pedidos.length === 0 && <h1>Você ainda não realizou nenhum pedido!</h1>}

          </ContainerPedidos>
          <ContainerPaginacao>

            <Pagination count={totalPages} showFirstButton showLastButton page={pagina} size={largura > 768 ? "large" : "small"} onChange={handlePageChange} />
          </ContainerPaginacao>
          <Rodape />
        </ContainerCorpo>
      </Container>

    </>
  );
}
