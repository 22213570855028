export function tratarCasasDecimais(
  numero,
  arredondar = false,
  quantidadeCasas = 3
) {
  const fator = Math.pow(10, quantidadeCasas);

  if (arredondar) {
    return parseFloat(numero.toFixed(quantidadeCasas));
  } else {
    return Math.floor(numero * fator) / fator;
  }
}
