import React, { Fragment, useEffect, useRef, useState } from "react";
import GerarModalResponsivo from "../gerarModalResponsivo";
import { X } from "phosphor-react";
import { TextField, CircularProgress } from "@mui/material";
import {
  CampoDeSenha,
  CampoDeSenhaOutlined,
  CampoDeTexto,
  CampoDeTextoOutlined,
} from "../campoInput";
import {
  BotaoCardapioPreenchido,
  BotaoCardapioVazado,
  BotaoVermelhoPreenchido,
  BotaoVermelhoVazado,
} from "../botao";
import {
  CardEndereco,
  ContainerInputs,
  ContianerBotoes,
  Divider,
  Linha,
  LinhaCardEndereco,
} from "./styles";
import { Close, House, Margin } from "@mui/icons-material";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faLocationDot,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Endereco from "../modalLogin/endereço";
import useTamanhoTela from "../../utils/useTamanhoTela";
import { baseURL, useApi } from "../../contexts/api";
import { toast } from "react-toastify";
import axios from "axios";
import { useTheme } from "styled-components";

import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { useUserAuth } from "../../contexts/userAuth";

const ModalMeusEnderecos = ({
  aberto,
  setAberto,
  loginGoogle,
  onOpen,
  novaEntrada = false,
}) => {
  const { largura } = useTamanhoTela();

  const [etapa, setEtapa] = useState(
    novaEntrada ? "novoEndereco" : "mostrarEnderecos"
  );
  const [dadosCadastro, setDadosCadastro] = useState({});
  const [enderecoSelecionado, setEnderecoSelecionado] = useState({});
  const [abertoAvisoRemover, setAbertoAvisoRemover] = useState(false);
  const { post, put, del } = useApi();
  const { dadosAuth } = useUserAuth();

  const [loadingEnderecos, setLoadingEnderecos] = useState(true);
  const [enderecos, setEnderecos] = useState([]);
  useEffect(() => {
    if (etapa === "mostrarEnderecos") {
      getEnderecos();
    }
  }, [etapa, aberto]);

  function getEnderecos() {
    setEnderecoSelecionado({});
    post("/usuario-sessao/client/usuario/endereco/buscar-todos/endereco", {
      usuarioEnderecoDTO: {
        idUsuario: dadosAuth?.idUsuario,
      },
    })
      .then((res) => {
        setEnderecos(res.data.content);
        setLoadingEnderecos(false);
      })
      .catch((err) => {
        setEnderecos([]);
        setLoadingEnderecos(false);
        toast.error("Erro ao buscar endereços");
        console.log(err);
      });
  }

  function fechar() {
    document.body.style.overflow = "unset";
    setAberto(false);
  }
  function mudarTela(tela) {
    setEtapa(tela);
  }

  useEffect(() => {
    if (largura > 768 && aberto) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [aberto]);

  function getTipoEndereco(chipCasa, chipTrabalho, chipOutro) {
    if (chipCasa) {
      return "CASA";
    } else if (chipTrabalho) {
      return "TRABALHO";
    } else if (chipOutro) {
      return "OUTRO";
    }
  }

  function getErrosEndereco(mensagemErro) {
    let errosRetorno = {};

    if (mensagemErro.includes("cep")) {
      errosRetorno.erroCep = "CEP inválido";
    }
    if (mensagemErro.includes("logradouro")) {
      errosRetorno.erroEndereco = "Endereço inválido";
    }
    if (mensagemErro.includes("numero")) {
      errosRetorno.erroNumero = "Número inválido";
    }
    if (mensagemErro.includes("complemento")) {
      errosRetorno.erroComplemento = "Complemento inválido";
    }
    if (mensagemErro.includes("bairro")) {
      errosRetorno.erroBairro = "Bairro inválido";
    }
    if (mensagemErro.includes("cidade")) {
      errosRetorno.erroCidade = "Cidade inválida";
    }
    if (mensagemErro.includes("uf")) {
      errosRetorno.erroEstado = "Estado inválido";
    }
    if (mensagemErro.includes("pontoReferencia")) {
      errosRetorno.erroReferencia = "Ponto de referência inválido";
    }
    if (mensagemErro.includes("tipo")) {
      errosRetorno.erroChips = "Selecione um tipo de endereço";
    }

    return errosRetorno;
  }

  async function novoEndereco(dadosCadastro) {
    finalizar(dadosCadastro, true);
  }

  async function finalizar(dadosCadastro, novo = false) {
    let sucesso = false;
    try {
      let dadosEndereco = {
        tipo: getTipoEndereco(
          dadosCadastro.endereco.chipCasa,
          dadosCadastro.endereco.chipTrabalho,
          dadosCadastro.endereco.chipOutro
        ),
        bairro: dadosCadastro.endereco.bairro,
        cep: dadosCadastro.endereco.cep,
        // idUf: dadosCadastro.endereco.estadoID.codigo,
        idUf: dadosCadastro.endereco.estado,
        complemento: dadosCadastro.endereco.complemento,
        pontoReferencia: dadosCadastro.endereco.referencia,
        logradouro: dadosCadastro.endereco.endereco,
        numero: dadosCadastro.endereco.numero,
        idCidade: dadosCadastro.endereco.cidade.id,
        idUsuario: dadosAuth.idUsuario,
      };

      if (novo) {
        await post("/usuario-sessao/client/usuario/endereco", dadosEndereco);
      } else {
        dadosEndereco.id = dadosCadastro.id;
        await put("/usuario-sessao/client/usuario/endereco", dadosEndereco);
      }
      sucesso = true;
      setDadosCadastro({});

      if (novaEntrada) {
        fechar();
      } else {
        setEtapa("mostrarEnderecos");
      }
    } catch (error) {
      console.log(error);

      if (novo) {
        toast.error("Erro ao cadastrar endereço");
      } else {
        toast.error("Erro ao editar endereço");
      }

      if (error.response?.data) {
        let erros = getErrosEndereco(error.data?.message);
        setDadosCadastro({
          ...dadosCadastro,
          errosPagamento: {
            ...erros,
          },
        });
      }
      sucesso = false;
    }

    return sucesso;
  }

  function MostrarEnderecos() {
    const theme = useTheme();

    const scrollRef = useRef(null);
    useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo(0, 0);
      }
    }, []);

    function getTipoIconeEndereco(tipo) {
      const styleIcon = {
        fontSize: 18,
        color: theme.coresCardapio.brand500,
        marginRight: 4,
        paddingTop: 2,
      };

      switch (tipo) {
        case "CASA":
          return <House style={styleIcon} />;
        case "TRABALHO":
          return <FreeBreakfastIcon style={styleIcon} />;
        case "OUTRO":
          return <LocationOnIcon style={styleIcon} />;
        default:
          return <LocationOnIcon style={styleIcon} />;
      }
    }

    function getTitulo(tipo) {
      const styleIcon = {
        fontSize: 18,
        color: theme.coresCardapio.brand500,
        marginRight: 4,
        paddingTop: 2,
      };

      switch (tipo) {
        case "CASA":
          return "Minha casa";
        case "TRABALHO":
          return "Meu Trabalho";
        case "OUTRO":
          return "Outro";
        default:
          return "Outro";
      }
    }

    function capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    function irParaEdicao(endereco) {
      setDadosCadastro({
        id: endereco.id,
        endereco: {
          cep: endereco.cep,
          endereco: endereco.logradouro,
          numero: endereco.numero,
          complemento: endereco.complemento,
          bairro: endereco.bairro,
          cidade: endereco.usuarioCidadeDTO,
          estado: endereco.usuarioUnidadeFederativaDTO.uf,
          estadoID: endereco.idUf,
          idUsuario: endereco.idUsuario,
          referencia: endereco.pontoReferencia,
          chipCasa: endereco.tipo === "CASA" ? true : false,
          chipTrabalho: endereco.tipo === "TRABALHO" ? true : false,
          chipOutro: endereco.tipo === "OUTRO" ? true : false,
        },
      });
      mudarTela("endereco");
    }

    function removerEndereco() {
      setAbertoAvisoRemover(false);
      setLoadingEnderecos(true);
      del("/usuario-sessao/client/usuario/endereco/" + enderecoSelecionado.id)
        .then((res) => {
          setEnderecoSelecionado({});
          getEnderecos();
          toast.success("Endereço removido com sucesso");
          setEtapa("mostrarEnderecos");
        })
        .catch((err) => {
          toast.error("Erro ao remover endereço.");
          setEnderecoSelecionado({});
          setEtapa("mostrarEnderecos");
          console.log(err);
        });
    }

    function ModalRemoverEndereco() {
      return (
        <GerarModalResponsivo
          width={"400px"}
          controle={abertoAvisoRemover && enderecoSelecionado.id !== undefined}
          onClose={() => setEnderecoSelecionado({})}
          blocking={false}
          overlayManual={true}
          children={() => {
            return (
              <Fragment>
                <Linha>
                  <h1>Excluir item</h1>
                </Linha>
                <Divider style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    padding: "16px 12px",
                    alignItems: "flex-start",
                    marginBottom: 16,
                  }}
                >
                  <p style={{ textAlign: "flex-start", marginBottom: 16 }}>
                    Deseja realmente remover o endereço selecionado?
                    {enderecoSelecionado && (
                      <p
                        style={{
                          width: "90%",
                          color: theme.coresCardapio.brand500,
                        }}
                      >
                        {enderecoSelecionado.logradouro},{" "}
                        {enderecoSelecionado.numero},{" "}
                        {enderecoSelecionado.bairro} -{" "}
                        {enderecoSelecionado.usuarioCidadeDTO?.nome}
                      </p>
                    )}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                      gap: 8,
                    }}
                  >
                    <BotaoVermelhoPreenchido
                      tamanho={"MD"}
                      texto={"Remover"}
                      onClick={() => {
                        removerEndereco();
                      }}
                    />
                    <BotaoVermelhoVazado
                      tamanho={"MD"}
                      texto={"Cancelar"}
                      onClick={() => {
                        setEnderecoSelecionado({});
                        setAbertoAvisoRemover(false);
                      }}
                    />
                  </div>
                </div>
              </Fragment>
            );
          }}
        />
      );
    }

    return (
      <div
        style={{ overflow: "scroll", maxHeight: largura < 990 ? "70vh" : null }}
        ref={scrollRef}
      >
        <Linha>
          <div />
          <h1>Meus endereços</h1>
          <FontAwesomeIcon
            onClick={() => fechar()}
            icon={faXmark}
            style={{ fontSize: 28, color: "red", cursor: "pointer" }}
          />
        </Linha>
        <Divider />
        <ModalRemoverEndereco />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            padding: "16px 12px",
            marginBottom: 16,
          }}
        >
          {loadingEnderecos ? (
            <div
              style={{
                width: "100%",
                height: 200,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress style={{ alignSelf: "center" }} />
            </div>
          ) : (
            enderecos.map((endereco, index) => {
              return (
                <CardEndereco key={index + "cardEndereco"} elevation={2}>
                  <LinhaCardEndereco>
                    <h3
                      style={{
                        width: "90%",
                      }}
                    >
                      {getTitulo(endereco.tipo)}
                    </h3>
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{
                        fontSize: 16,
                        color: theme.coresCardapio.brand500,
                        cursor: "pointer",
                        position: "absolute",
                        top: 16,
                        right: 16,
                      }}
                      onClick={() => irParaEdicao(endereco)}
                    />
                  </LinhaCardEndereco>
                  <LinhaCardEndereco
                    style={{
                      height: 48,
                    }}
                  >
                    <Fragment>
                      {getTipoIconeEndereco(endereco.tipo)}
                      <p
                        style={{
                          width: "90%",
                          color: theme.coresCardapio.brand500,
                        }}
                      >
                        {endereco.logradouro}, {endereco.numero},{" "}
                        {endereco.bairro} - {endereco.usuarioCidadeDTO.nome}
                      </p>
                    </Fragment>
                    <FontAwesomeIcon
                      onClick={() => {
                        setEnderecoSelecionado(endereco);
                        setAbertoAvisoRemover(true);
                      }}
                      icon={faTrash}
                      style={{
                        fontSize: 16,
                        marginLeft: "auto",
                        color: "red",
                        cursor: "pointer",
                        position: "absolute",
                        bottom: 16,
                        right: 16,
                      }}
                    />
                  </LinhaCardEndereco>
                </CardEndereco>
              );
            })
          )}
          <BotaoCardapioPreenchido
            texto={"NOVO ENDEREÇO"}
            onClick={() => {
              setDadosCadastro({});
              mudarTela("novoEndereco");
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <GerarModalResponsivo
      width={"500px"}
      controle={aberto}
      onClose={fechar}
      onOpen={onOpen}
      blocking={false}
      overlayManual={true}
      children={() => {
        switch (etapa) {
          case "loading":
            return (
              <div
                style={{
                  width: "100%",
                  height: 200,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{ alignSelf: "center" }} />
              </div>
            );

          case "mostrarEnderecos":
            return <MostrarEnderecos />;
          case "endereco":
            return (
              <Endereco
                modoEdicao={true}
                fechar={fechar}
                mudarTela={mudarTela}
                dadosCadastro={dadosCadastro}
                setDadosCadastro={setDadosCadastro}
                finalizar={finalizar}
              />
            );
          case "novoEndereco":
            return (
              <Endereco
                modoEdicao={true}
                fechar={fechar}
                mudarTela={mudarTela}
                dadosCadastro={dadosCadastro}
                setDadosCadastro={setDadosCadastro}
                finalizar={novoEndereco}
                apenasNovaEntrada={novaEntrada}
              />
            );
          default:
            break;
        }
      }}
    />
  );
};

export default ModalMeusEnderecos;
