import { useNavigate } from "react-router-dom";
import { CardPedido } from "../../../components/cardPedido";
import CardapioHeader from "../../../components/cardapio/header";
import InformacoesRestaurante from "../../../components/informacoesRestaurante";
import Rodape from "../../../components/rodape";
import {
  BotaoVoltarAoCardapio,
  Container,
  ContainerBotao,
  ContainerCabecalhoCorpo,
  ContainerCorpo,
  ContainerHeader,
  ContainerCarrinho,
  ContainerTituloCabecalho,
  TituloCabecalho,
  ContainerDados,
  TabCustomizada,
  TituloMensagemPagamento,
  TextoLaranja,
  TextoPreto,
  ContainerMensagem,
  TextoPretoLeve,
  TextoVermelho,
  Linha,
  TextoVerde,
  TextoBranco,
  ContainerProduto,
  CardInternoBranco,
  LinhaRegistro,
  TextoLaranjaPedido,
  TextoPretoPedido,
  TextoPretoLevePedido,
  ContainerDadosProdutos,
  CardContador,
  ContainerFinalizar,
  ContainerTabs,
  LinhaCupom,
  ContainerTextoLaranja,
  TituloModal,
  ContainerModal,
  SubTituloModal,
  Divider,
  ContainerColunaMesa,
  ContainerInputsMesa,
  BotaoBaseTipoConsumo,
} from "./styles";
import { ArrowLeft } from "phosphor-react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Fragment, useEffect, useState } from "react";
import { useUserAuth } from "../../../contexts/userAuth";
import {
  BotaoCardapioVazado,
  BotaoCardapioPreenchido,
} from "../../../components/botao";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTheme } from "styled-components";
import { useApi } from "../../../contexts/api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faMinus,
  faPlus,
  faTrashCan,
  faTrashCanCan,
} from "@fortawesome/free-solid-svg-icons";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "../../../components/chip";

import { CheckBox } from "@mui/icons-material";
import { CampoDeTextoOutlined } from "../../../components/campoInput";
import formatarReal from "../../../utils/formatarReal";
import ModalMeusEnderecos from "../../../components/modalMeusEnderecos";

import useTamanhoTela from "../../../utils/useTamanhoTela";
import ModalLogin from "../../../components/modalLogin";
import dayjs from "dayjs";
import { Pagamento } from "./components/pagamento";
import Endereco from "./components/endereco";
import { DadosPedido } from "./components/dadosPedido";
import { ItensCarrinho } from "./components/itensCarrinho";
import { useCarrinho } from "../../../contexts/carrinho";
import GerarModalResponsivo from "../../../components/gerarModalResponsivo";
import { ReactComponent as ImagemSucessoPedido } from "../../../assets/images/success_order.svg";
import { DadosMesa } from "./components/dadosMesa";

const tabsLegenda = ["Entrega", "Retirada"];
export function Carrinho() {
  const navigate = useNavigate();
  const { post, get, put, del } = useApi();
  const theme = useTheme();
  const { logado, dadosAuth, modoMesa, dadosEmpresa } = useUserAuth();
  const { largura } = useTamanhoTela();
  const { nota, produtos, resetarCarrinho } = useCarrinho();

  const [tabSelecionada, setTabSelecionada] = useState(0);

  const [dadosFinalizar, setDadosFinalizar] = useState({});
  const [loadingFinalizar, setLoadingFinalizar] = useState(false);
  const [modalSucesso, setModalSucesso] = useState(false);
  const [modalTipoConsumo, setModalTipoConsumo] = useState(false);

  useEffect(() => {
    setDadosFinalizar({
      modoEntrega: tabsLegenda[tabSelecionada],
    });
  }, [tabSelecionada]);

  useEffect(() => {
    console.log(dadosFinalizar);
  }, [dadosFinalizar]);

  function atualizarDadosFinalizar(dados) {
    setDadosFinalizar({ ...dadosFinalizar, ...dados });
  }

  function mostrarMensagemPagamento() {
    if (
      (dadosFinalizar.cartaoSelecionado &&
        Object.values(dadosFinalizar.cartaoSelecionado).length > 0) ||
      (dadosFinalizar.finalizadoraSelecionada &&
        Object.values(dadosFinalizar.finalizadoraSelecionada).length > 0)
    ) {
      return false;
    } else {
      return true;
    }
  }

  function mostrarMensagemEndereco() {
    if (
      (dadosFinalizar.enderecoSelecionado &&
        Object.values(dadosFinalizar.enderecoSelecionado).length > 0) ||
      tabSelecionada == 1
    ) {
      return false;
    } else {
      return true;
    }
  }

  function bloquearBotaoFinalizar() {
    if (modoMesa) {
      if (
        dadosFinalizar.nome?.length > 0 &&
        dadosFinalizar.telefone?.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        ((dadosFinalizar.cartaoSelecionado &&
          Object.values(dadosFinalizar.cartaoSelecionado).length > 0) ||
          (dadosFinalizar.finalizadoraSelecionada &&
            Object.values(dadosFinalizar.finalizadoraSelecionada).length >
              0)) &&
        (dadosFinalizar.modoEntrega === "Retirada" ||
          (dadosFinalizar.modoEntrega === "Entrega" &&
            dadosFinalizar.enderecoSelecionado &&
            Object.values(dadosFinalizar.enderecoSelecionado).length > 0)) &&
        logado
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  async function cancelarTransacao(idTransacaoCartao) {
    await post(
      "pagamento/pagamento-online-waymenu/cancelar-transacao/" +
        idTransacaoCartao
    )
      .then(() => {
        console.log("Transação cancelada");
        toast("Seu pagamento será estoranado");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Erro ao cancelar transação do pagamento");
      });
  }

  async function finalizar() {
    setLoadingFinalizar(true);

    if (produtos?.length === 0) {
      toast.info("O carrinho não pode estar vazio.");
      setLoadingFinalizar(false);
      return;
    }

    let enderecoIdCliente;
    if (dadosFinalizar.modoEntrega === "Entrega" && !modoMesa) {
      try {
        const response = await post(
          "pessoa/endereco/usuario/criar-endereco-cliente/" +
            dadosFinalizar.enderecoSelecionado.id
        );
        enderecoIdCliente = response.data;
      } catch (error) {
        toast.error("Erro ao vincular endereço");
        console.error(error);
        setLoadingFinalizar(false);
        return;
      }
    } else if (dadosFinalizar.modoEntrega === "Retirada" && !modoMesa) {
      enderecoIdCliente = dadosEmpresa.enderecoResumidoDTO.idEndereco;
    }

    let idFinalizadora;

    if (dadosFinalizar.tipoPagamento === "pagarOnline" && !modoMesa) {
      let finalizadoraCartao = {};

      if (dadosFinalizar.cartaoSelecionado.tipoPagamento === "CREDITO") {
        for (let finalizadora of dadosFinalizar.finalizadorasPagamentoOnline) {
          if (finalizadora.finalizadoraRFB === "Cartão de Crédito") {
            finalizadoraCartao = finalizadora;
            break;
          }
        }

        idFinalizadora = finalizadoraCartao.idFinalizadoraEmpresa;
      } else if (dadosFinalizar.cartaoSelecionado.tipoPagamento === "DEBITO") {
        for (let finalizadora of dadosFinalizar.finalizadorasPagamentoOnline) {
          if (finalizadora.finalizadoraRFB === "Cartão de Débito") {
            finalizadoraCartao = finalizadora;
            break;
          }
        }

        idFinalizadora = finalizadoraCartao.idFinalizadoraEmpresa;
      }
    } else if (dadosFinalizar.finalizadoraSelecionada && !modoMesa) {
      idFinalizadora =
        dadosFinalizar.finalizadoraSelecionada.formaCompleta
          .idFinalizadoraEmpresa;
    }

    let idTransacaoCartao;
    if (
      dadosFinalizar.tipoPagamento === "pagarOnline" &&
      dadosFinalizar.cartaoSelecionado
    ) {
      try {
        const response = await post(
          "pagamento/pagamento-online-waymenu/transacao",
          {
            idCartao: dadosFinalizar.cartaoSelecionado.id,
            idNota: nota.id,
            valor: nota.valorTotal,
          }
        );
        if (response.status !== 200) {
          throw new Error(
            response?.data?.message
              ? response?.data?.message
              : "Erro ao realizar transação"
          );
        } else {
          idTransacaoCartao = response.data.idTransacaoCartao;
        }
      } catch (error) {
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Erro ao realizar transação"
        );
        setLoadingFinalizar(false);
        return;
      }
    }

    if (!dadosFinalizar.cartaoSelecionado && !idTransacaoCartao) {
      try {
        await post("vendas/nota-finalizadora/inserir/" + nota.id, [
          {
            idNota: nota.id,
            idFinalizadora: idFinalizadora
              ? idFinalizadora
              : dadosFinalizar.idFinalizadoraEmpresa,
            valor: nota.valorTotal,
            cancelada: false,
            lancamentoManual: false,
            alteracaoManual: false,
            pagamentoOnline: dadosFinalizar.tipoPagamento === "pagarOnline",
            consumidorDocumento: dadosFinalizar.cpf,
          },
        ]);
      } catch (error) {
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Erro ao finalizar o pedido"
        );
        console.error(error);
        setLoadingFinalizar(false);
        return;
      }
    }

    try {
      let dadosNota = {
        "notaDTO": {
          ...nota,
          valorFrete: dadosFinalizar.frete,
          valorDesconto: dadosFinalizar.desconto,
          idEndereco: enderecoIdCliente,
        },
        "consumoDTO": {
          id: nota.id,
          tipoEntrega:
            dadosFinalizar.modoEntrega === "Entrega"
              ? "COMUM"
              : "RETIRADA_LOCAL",
          modulo: modoMesa ? "MESA" : "DELIVERY",
          slug: modoMesa ? null : null,
        },
      };
      const response = await put(
        "/vendas/nota/atualizar/" + nota.id,
        dadosNota
      );
      if (response.status !== 200) {
        throw new Error("Erro ao atualizar nota");
      }
      resetarCarrinho();
      setLoadingFinalizar(false);
      setModalSucesso(true);

      if (dadosFinalizar.idCupom) {
        await post(
          `/fidelidade/cupom/${dadosFinalizar.idCupom}/utilizado/${nota.id}`
        )
          .then(() => {
            console.log("Cupom utilizado");
          })
          .catch((error) => {
            console.error(error);
            toast.error("Erro com o cupom");
          });
      }
    } catch (error) {
      toast.error("Erro ao finalizar pedido.");
      if (idTransacaoCartao) {
        await cancelarTransacao(idTransacaoCartao);
      }
      setLoadingFinalizar(false);
      console.error(error);
    }
  }

  return (
    <>
      <Container>
        <ContainerCorpo>
          <ContainerTituloCabecalho>
            <TituloCabecalho>Finaliza Pedido</TituloCabecalho>
          </ContainerTituloCabecalho>
          <ContainerCarrinho>
            <ContainerDadosProdutos>
              <ItensCarrinho
                atualizarDadosFinalizar={atualizarDadosFinalizar}
                dadosFinalizar={dadosFinalizar}
              />
            </ContainerDadosProdutos>
            {modoMesa ? (
              <ContainerColunaMesa>
                <DadosMesa atualizarDadosFinalizar={atualizarDadosFinalizar} />
                {largura < 1100 && (
                  <DadosPedido
                    atualizarDadosFinalizar={atualizarDadosFinalizar}
                    dadosFinalizar={dadosFinalizar}
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 16,
                  }}
                >
                  <BotaoCardapioPreenchido
                    tamanho={"MD"}
                    disabled={bloquearBotaoFinalizar()}
                    style={{
                      height: 54,
                      pointerEvents: loadingFinalizar ? "none" : "auto",
                      letterSpacing: 1,
                    }}
                    texto={"FINALIZAR PEDIDO"}
                    icone={
                      loadingFinalizar && (
                        <CircularProgress
                          style={{
                            fill: "white",
                            color: "white",
                          }}
                          size={18}
                        />
                      )
                    }
                    onClick={() =>
                      modoMesa ? setModalTipoConsumo(true) : finalizar()
                    }
                  />
                </div>
                <ModalTipoConsumo
                  aberto={modalTipoConsumo}
                  setModalTipoConsumo={setModalTipoConsumo}
                  finalizar={finalizar}
                />
              </ContainerColunaMesa>
            ) : (
              <ContainerDados>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      borderRadius: "6px",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Tabs
                      value={tabSelecionada}
                      onChange={(event, newValue) => {
                        setTabSelecionada(newValue);
                      }}
                      sx={{
                        ".MuiTab-root": {
                          marginLeft: "auto",
                          marginRight: "auto",
                          fontWeight: 600,
                          color: "black",
                          opacity: 0.9,
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "19px",
                          textTransform: "none",
                        },
                        ".Mui-selected": {
                          color: "black",
                          opacity: 0.9,
                        },
                        ".MuiTabs-indicator": {
                          bottom: 10,
                          height: "1px",
                        },
                      }}
                    >
                      <TabCustomizada label="Entrega" variant="fullWidth" />
                      <TabCustomizada label="Retirada" variant="fullWidth" />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabSelecionada} index={0}>
                    {logado ? (
                      <>
                        <Pagamento
                          tabSelecionada={"entrega"}
                          atualizarDadosFinalizar={atualizarDadosFinalizar}
                        />
                        <Endereco
                          tabSelecionada={"entrega"}
                          atualizarDadosFinalizar={atualizarDadosFinalizar}
                        />
                      </>
                    ) : (
                      <MensagemDeslogado />
                    )}
                  </CustomTabPanel>
                  <CustomTabPanel value={tabSelecionada} index={1}>
                    {logado ? (
                      <>
                        <Pagamento
                          tabSelecionada={"retirada"}
                          atualizarDadosFinalizar={atualizarDadosFinalizar}
                        />
                        <Endereco
                          tabSelecionada={"retirada"}
                          atualizarDadosFinalizar={atualizarDadosFinalizar}
                        />
                      </>
                    ) : (
                      <MensagemDeslogado />
                    )}
                  </CustomTabPanel>
                </Box>
                {largura < 1100 && (
                  <DadosPedido
                    atualizarDadosFinalizar={atualizarDadosFinalizar}
                    dadosFinalizar={dadosFinalizar}
                  />
                )}
                {mostrarMensagemPagamento() &&
                  !mostrarMensagemEndereco() &&
                  logado && (
                    <TextoVermelho>
                      Selecione a forma de pagamento para continuar.
                    </TextoVermelho>
                  )}
                {mostrarMensagemEndereco() &&
                  !mostrarMensagemPagamento() &&
                  logado && (
                    <TextoVermelho>
                      Selecione o endereço para continuar.
                    </TextoVermelho>
                  )}
                {mostrarMensagemEndereco() &&
                  mostrarMensagemPagamento() &&
                  logado && (
                    <TextoVermelho>
                      Selecione a forma de pagamento e o endereço para
                      continuar.
                    </TextoVermelho>
                  )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 16,
                  }}
                >
                  <BotaoCardapioPreenchido
                    tamanho={"MD"}
                    disabled={bloquearBotaoFinalizar()}
                    style={{
                      height: 54,
                      pointerEvents: loadingFinalizar ? "none" : "auto",
                      letterSpacing: 1,
                    }}
                    texto={"FINALIZAR PEDIDO"}
                    icone={
                      loadingFinalizar && (
                        <CircularProgress
                          style={{
                            fill: "white",
                            color: "white",
                          }}
                          size={18}
                        />
                      )
                    }
                    onClick={() =>
                      modoMesa ? setModalTipoConsumo(true) : finalizar()
                    }
                  />
                </div>
              </ContainerDados>
            )}
          </ContainerCarrinho>
        </ContainerCorpo>
      </Container>
      <ModalSucesso aberto={modalSucesso} setModalSucesso={setModalSucesso} />
    </>
  );
}

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <ContainerTabs>
          <Typography>{children}</Typography>
        </ContainerTabs>
      )}
    </div>
  );
}

function MensagemDeslogado() {
  const { logado } = useUserAuth();

  const [abrirLogin, setAbrirLogin] = useState(false);
  const [etapaLogin, setEtapaLogin] = useState("login");

  return (
    <ContainerMensagem>
      <TituloMensagemPagamento>
        <TextoLaranja>$ Escolha uma forma de pagamento</TextoLaranja>
      </TituloMensagemPagamento>
      <TextoPreto style={{ marginTop: 16 }}>
        Entre na sua conta ou cadastre-se para adicionar seu cartão
      </TextoPreto>
      <BotaoCardapioPreenchido
        tamanho={"MD"}
        style={{ width: "100%", height: 54 }}
        texto={"FAZER LOGIN"}
        onClick={() => {
          setEtapaLogin("login");
          setAbrirLogin(true);
        }}
      />
      <TextoPreto>OU</TextoPreto>
      <BotaoCardapioVazado
        tamanho={"MD"}
        style={{ width: "100%", height: 54 }}
        texto={"CADASTRE-SE"}
        onClick={() => {
          setEtapaLogin("cadastro");
          setAbrirLogin(true);
        }}
      />
      {!logado && (
        <ModalLogin
          aberto={abrirLogin}
          setAberto={setAbrirLogin}
          loginGoogle={true}
          etapaLogin={etapaLogin}
        />
      )}
    </ContainerMensagem>
  );
}

function ModalSucesso({ aberto, setModalSucesso }) {
  const navigate = useNavigate();

  return (
    <GerarModalResponsivo
      onClose={() => {
        setModalSucesso(false);
        navigate("/");
      }}
      controle={aberto}
      width={600}
      children={() => (
        <ContainerModal>
          <ContainerTituloCabecalho>
            <TituloModal>Seu pedido foi enviado para a cozinha !!</TituloModal>
          </ContainerTituloCabecalho>
          <Divider />
          <ContainerTituloCabecalho>
            <SubTituloModal>
              Agora é só esperar o restaurante aceitar seu pedido
            </SubTituloModal>
          </ContainerTituloCabecalho>
          <ImagemSucessoPedido />
          <Linha style={{ gap: 16 }}>
            <BotaoCardapioPreenchido
              tamanho={"MD"}
              style={{ width: "100%", height: 64 }}
              texto={"VOLTAR AO CARDÁPIO"}
              onClick={() => {
                navigate("/");
              }}
            />
            <BotaoCardapioVazado
              tamanho={"MD"}
              style={{ width: "100%", height: 64 }}
              texto={"ACOMPANHAR PEDIDO"}
              onClick={() => {
                navigate("/pedidos");
              }}
            />
          </Linha>
        </ContainerModal>
      )}
    />
  );
}

function ModalTipoConsumo({ aberto, setModalTipoConsumo, finalizar }) {
  const [tipoConsumo, setTipoConsumo] = useState();

  useEffect(() => {
    if (tipoConsumo) {
      setModalTipoConsumo(false);
      finalizar();
    }
  }, [tipoConsumo]);

  function BotaoTipoConsumo({ tipo }) {
    return tipoConsumo == tipo ? (
      <BotaoCardapioPreenchido
        tamanho={"LG"}
        style={{ height: 80 }}
        texto={tipo}
        onClick={() => {
          tipoConsumo === tipo ? setTipoConsumo(null) : setTipoConsumo(tipo);
        }}
      />
    ) : (
      <BotaoCardapioVazado
        tamanho={"LG"}
        style={{ height: 80 }}
        texto={tipo}
        onClick={() => {
          tipoConsumo === tipo ? setTipoConsumo(null) : setTipoConsumo(tipo);
        }}
      />
    );
  }

  return (
    <GerarModalResponsivo
      onClose={() => {
        setModalTipoConsumo(false);
      }}
      controle={aberto}
      width={600}
      children={() => (
        <ContainerModal>
          <TituloModal style={{ textAlign: "center" }}>
            Tipo de consumo
          </TituloModal>
          <Divider style={{ margin: 0 }} />
          <Linha style={{ justifyContent: "space-around" }}>
            <BotaoTipoConsumo tipo={"Mesa"} />
            <BotaoTipoConsumo tipo={"Individual"} />
          </Linha>
        </ContainerModal>
      )}
    />
  );
}
