import React from "react";
import {
  BotaoBaseAzulPreenchido,
  BotaoBaseAzulVazado,
  BotaoBaseCardapioPreenchido,
  BotaoBaseCardapioPreenchidoVerde,
  BotaoBaseCardapioVazado,
  BotaoBaseVermelhoPreenchido,
  BotaoBaseVermelhoVazado,
  Icone,
  Linha,
} from "./styles";
import { CircularProgress } from "@mui/material";

const definirTamanho = (tamanho) => {
  switch (tamanho) {
    case "SM":
      return {
        minWidth: 120,
        height: 32,
        padding: " 8px 24px 8px 24px",
        fontSize: "15px",
      };
    case "MD":
      return {
        minWidth: 140,
        height: 40,
        padding: " 8px 16px 8px 16px",
        fontSize: "17px",
      };
    case "LG":
      return {
        minWidth: 204,
        height: 48,
        padding: " 8px 16px 8px 16px",
        fontSize: "19px",
      };
    default:
      return {
        minWidth: "fit-content",
        height: 40,
        padding: " 8px 16px 8px 16px",
      };
  }
};

///// Botao Azul

export const BotaoCardapioPreenchido = ({
  texto,
  icone,
  tamanho,
  style = {},
  onClick,
  loading,
  refs,
  disabled,
}) => {
  return (
    <BotaoBaseCardapioPreenchido
      ref={refs}
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
      disabled={disabled}
    >
      <Linha>
        {icone && <Icone style={{ marginRight: 10 }}>{icone}</Icone>}
        {texto}
        {loading && (
          <div style={{ position: "relative" }}>
            <CircularProgress
              style={{
                position: "absolute",
                right: -20,
                top: -5,
                fill: "white",
                color: "white",
              }}
              size={12}
            />
          </div>
        )}
      </Linha>
    </BotaoBaseCardapioPreenchido>
  );
};

export const BotaoCardapioPreenchidoVerde = ({
  texto,
  icone,
  tamanho,
  style = {},
  onClick,
  loading,
}) => {
  return (
    <BotaoBaseCardapioPreenchidoVerde
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && <Icone style={{ marginRight: 10 }}>{icone}</Icone>}
        {texto}
        {loading && (
          <div style={{ position: "relative" }}>
            <CircularProgress
              style={{
                position: "absolute",
                right: -20,
                top: -5,
                fill: "white",
                color: "white",
              }}
              size={12}
            />
          </div>
        )}
      </Linha>
    </BotaoBaseCardapioPreenchidoVerde>
  );
};

export const BotaoCardapioVazado = ({
  texto,
  icone,
  tamanho,
  style = {},
  onClick,
}) => {
  return (
    <BotaoBaseCardapioVazado
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && <Icone style={{ marginRight: 10 }}>{icone}</Icone>}
        {texto}
      </Linha>
    </BotaoBaseCardapioVazado>
  );
};

export const BotaoAzulPreenchido = ({
  texto,
  icone,
  tamanho,
  style = {},
  onClick,
}) => {
  return (
    <BotaoBaseAzulPreenchido
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && <Icone style={{ marginRight: 10 }}>{icone}</Icone>}
        {texto}
      </Linha>
    </BotaoBaseAzulPreenchido>
  );
};

export const BotaoAzulVazado = ({
  texto,
  icone,
  tamanho,
  style = {},
  onClick,
}) => {
  return (
    <BotaoBaseAzulVazado
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && <Icone style={{ marginRight: 10 }}>{icone}</Icone>}
        {texto}
      </Linha>
    </BotaoBaseAzulVazado>
  );
};

export const BotaoVermelhoVazado = ({
  texto,
  icone,
  tamanho,
  style = {},
  onClick,
}) => {
  return (
    <BotaoBaseVermelhoVazado
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && <Icone style={{ marginRight: 10 }}>{icone}</Icone>}
        {texto}
      </Linha>
    </BotaoBaseVermelhoVazado>
  );
};

export const BotaoVermelhoPreenchido = ({
  texto,
  icone,
  tamanho,
  style = {},
  onClick,
}) => {
  return (
    <BotaoBaseVermelhoPreenchido
      style={{ ...definirTamanho(tamanho), ...style }}
      onClick={onClick}
    >
      <Linha>
        {icone && (
          <Icone margin={texto != "vazio" ? true : false}>{icone}</Icone>
        )}
        {texto}
      </Linha>
    </BotaoBaseVermelhoPreenchido>
  );
};
