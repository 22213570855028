import React, { useEffect, useState } from "react";
import { ContainerSwitch, Switch } from "./styles";
import { ReactComponent as IconeCupom } from "../../assets/images/icones/cupom.svg";
import { ReactComponent as IconeFastFood } from "../../assets/images/icones/fastfood.svg";
import { Link, useParams } from "react-router-dom";
import { checarModoCardapio } from "../../utils/checarModoCardapio";
import { useApi } from "../../contexts/api";
import { useUserAuth } from "../../contexts/userAuth";

function SwitchCupons({ cardapio = false, cupons = false }) {
  const { tipoCardapio } = useParams();
  const [modoCardapio, setModoCardapio] = useState();
  const [contratoAtivo, setContratoAtivo] = useState(false)
  const {get} = useApi()
  const { dadosEmpresa, logado } = useUserAuth();

  useEffect(() => {
    async function buscarContratos() {
      try {
        const res = await get(`usuario-sessao/client/usuario/waymenu/contrato-itens/${dadosEmpresa.idEmpresa}`)
        console.log(res.data.listaItens);

        if (res.data.listaItens.includes("CUPOM_VANTAGEM")) {
          setContratoAtivo(true)
        } else {
          setContratoAtivo(false)
        }

      } catch (error) {
        console.log(error);
      }
    }

    buscarContratos()
    setModoCardapio(checarModoCardapio(tipoCardapio));
  }, [tipoCardapio]);

console.log(contratoAtivo);


  return modoCardapio || !contratoAtivo || !logado ? (
    <></>
  ) : (
    <ContainerSwitch>
      <Switch $ativo={cupons} to="./#/vantagens">
        <IconeCupom
          style={{ scale: "1.2", color: "inherit", fill: "inherit" }}
        />
      </Switch>
      <Switch $ativo={cardapio} to="./#/cardapio">
        <IconeFastFood
          style={{ scale: "0.8", color: "inherit", fill: "inherit" }}
        />
      </Switch>
    </ContainerSwitch>
  );
}

export default SwitchCupons;
