import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"


const firebaseConfig = {
    apiKey: "AIzaSyAzkI3AvpDYj-y9A9siQuyctr84COWBvPc",
    authDomain: "waymenu.firebaseapp.com",
    databaseURL: "https://waymenu.firebaseio.com",
    projectId: "waymenu",
    storageBucket: "waymenu.firebasestorage.app",
    messagingSenderId: "385231051669",
    appId: "1:385231051669:web:cb8e213b84f0c5ee8c349c"
  };

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export { app, auth }
