import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 85px;
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
