import {
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import useTamanhoTela from "../../utils/useTamanhoTela";
import scrollParaElemento from "../../utils/scrollParaElemento";
import {
  ItemCategoria,
  LinhaCategorias,
  ScrollHorizontal,
  SetaAvancar,
  SetaVoltar,
  LinhaVoltar,
  ContainerBarraDeCategorias,
} from "./styles";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

export const BarraDeCategorias = ({ categorias }) => {
  const { largura } = useTamanhoTela();
  const navigate = useNavigate();
  const { state } = useLocation();

  const scrollHorizontalRef = useRef(0);
  // Referência para o controle do scroll horizontal

  const [mostrarVoltar, setMostrarVoltar] = useState(state?.id ? true : false);
  const [grudadaNoTopo, setGrudadaNoTopo] = useState(false);
  const [noInicio, setNoInicio] = useState(true);
  const [noFim, setNoFim] = useState(false);
  // Estado para saber se a barra está grudada no topo

  function checkPosicaoBarra() {
    // Se a posição do topo da barra for menor ou igual a 0,
    // então ela está grudada no topo. o -42 é devido ao botão de voltar
    try {
      if (
        scrollHorizontalRef.current &&
        scrollHorizontalRef.current.getBoundingClientRect() &&
        scrollHorizontalRef.current.getBoundingClientRect().top - 42 <= 0
      ) {
        setGrudadaNoTopo(true);
      } else {
        setGrudadaNoTopo(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    //Adiciono como um Event Listener a janela, e removo ao desmontar
    window.addEventListener("scroll", checkPosicaoBarra);
    return () => {
      window.removeEventListener("scroll", checkPosicaoBarra);
    };
  }, []);

  ////////////////////////
  useEffect(() => {
    if (scrollHorizontalRef.current) {
      scrollHorizontalRef.current?.addEventListener(
        "scroll",
        checkPosicaoScrollCategorias
      );

      return () => {
        scrollHorizontalRef.current?.removeEventListener(
          "scroll",
          checkPosicaoScrollCategorias
        );
      };
    }
  }, []);

  function checkPosicaoScrollCategorias() {
    // Se a posição da barra for menor ou igual a 0, então ela está no início
    if (scrollHorizontalRef.current.scrollLeft <= 0) {
      setNoInicio(true);
    } else {
      setNoInicio(false);
    }

    // Se a posição da barra for maior ou igual ao tamanho total da barra menos
    // o tamanho da barra visível, então ela está no fim. O Valor 30 foi colocado
    // para dar uma margem de erro, pois o valor exato não estava sendo reconhecido as vezes

    if (
      scrollHorizontalRef.current.scrollLeft >=
      scrollHorizontalRef.current.scrollWidth -
        scrollHorizontalRef.current.clientWidth -
        30
    ) {
      setNoFim(true);
    } else {
      setNoFim(false);
    }
  }

  function avancarLista() {
    scrollHorizontalRef.current.scrollLeft += 250;
  }

  function voltarLista() {
    scrollHorizontalRef.current.scrollLeft -= 250;
  }

  function mostrarSetas() {
    if (largura > 850) {
      if (categorias.length > 4) {
        return true;
      } else {
        return false;
      }
    }

    if (largura > 640) {
      if (categorias.length > 3) {
        return true;
      } else {
        return false;
      }
    }

    // if (largura > 480) {
    if (categorias.length > 2) {
      return true;
    } else {
      return false;
    }
    // }
  }
  return (
    <>
      <ContainerBarraDeCategorias
        style={grudadaNoTopo ? { position: "sticky", top: 0 } : {}}
      >
        {mostrarVoltar && (
          <Link
            onClick={() => {
              if (window.location.hash) {
                window.history.replaceState(
                  "",
                  document.title,
                  window.location.pathname
                );
              } else {
                navigate("./delivery");
              }
            }}
          >
            <LinhaVoltar>
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  fontSize: "1.5rem",
                }}
              />
              Voltar para o menu
            </LinhaVoltar>
          </Link>
        )}
        <ScrollHorizontal ref={scrollHorizontalRef}>
          <LinhaCategorias>
            {categorias.map((categoria, index) => {
              return (
                <ItemCategoria
                  onClick={() =>
                    scrollParaElemento(`idCategoria_${categoria.id}`)
                  }
                  key={"categoriaKey" + categoria + index}
                >
                  {categoria.descricao}
                </ItemCategoria>
              );
            })}
            {
              //Usar a propriedade Sticky faz com que as Barras com position Fixed
              //parem de funcionar, por isso as removo, e adiciono novas fora desse
              //componente depois.
              !grudadaNoTopo && (
                <>
                  <SetaVoltar
                    icon={faChevronLeft}
                    //Se a barra estiver no início, a opacidade da seta diminui
                    style={{
                      opacity: noInicio ? 0.3 : 1,
                      display: mostrarSetas() ? "block" : "none",
                    }}
                    onClick={() => voltarLista()}
                  />
                  <SetaAvancar
                    icon={faChevronRight}
                    //Se a barra estiver no fim, a opacidade da seta diminui
                    style={{
                      opacity: noFim ? 0.3 : 1,
                      display: mostrarSetas() ? "block" : "none",
                    }}
                    onClick={() => avancarLista()}
                  />
                </>
              )
            }
          </LinhaCategorias>
        </ScrollHorizontal>
      </ContainerBarraDeCategorias>
      {
        //Adiciono as setas aqui, pois se elas estiverem dentro do ScrollHorizontal
        //elas não funcionam quando a barra está grudada no topo.
        //Elas tem posição fixa no topo, e só aparecem quando a barra está grudada
        grudadaNoTopo && (
          <>
            <SetaVoltar
              icon={faChevronLeft}
              onClick={() => voltarLista()}
              style={{
                position: "fixed",
                top: "42",
                //Se a barra estiver no início, a opacidade da seta diminui
                opacity: noInicio ? 0.3 : 1,
                display: mostrarSetas() ? "block" : "none",
              }}
            />
            <SetaAvancar
              icon={faChevronRight}
              onClick={() => avancarLista()}
              style={{
                position: "fixed",
                top: "42",
                //Se a barra estiver no fim, a opacidade da seta diminui
                opacity: noFim ? 0.3 : 1,
                display: mostrarSetas() ? "block" : "none",
              }}
            />
          </>
        )
      }
    </>
  );
};

export default BarraDeCategorias;
