import img_acai from "./imagens-placeholder/img_acai.webp";
import img_batata from "./imagens-placeholder/img_batata.jpg";
import img_coca from "./imagens-placeholder/img_coca.webp";
import img_combo from "./imagens-placeholder/img_combo.jpg";
import img_lanches from "./imagens-placeholder/img_lanches.jpg";
import img_pizza from "./imagens-placeholder/img_pizza.webp";
import img_porcao from "./imagens-placeholder/img_porcao.jpg";
import img_erro from "./imagens-placeholder/img_erro.png";

export const listaComponentesMock = [
  "Header",
  "Dados",
  "LinhaBotoes",
  "BarraDeCategorias",
  "PromocaoDoDia",
  "CategoriasCorpo",
  "Rodape",
];

export const listaPromocoesDoDiaMock = [
  {
    descricao: "Açaí",
    mockado: true,
    descricaoCategoria: "Açaí com banana e granola",
    preco: 10.0,
    precoAntigo: 12.0,
    urlImagem: img_acai,
    tipo: "produto",
    tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
  },
  {
    descricao: "PIZZA FRANGO COM CATUPIRY",
    mockado: true,
    descricaoCategoria: "Açaí com banana e granola",
    preco: 10.0,
    precoAntigo: 12.0,
    urlImagem: img_acai,
    tipo: "pizza",
    tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
  },
  {
    descricao: "MONSTRINHO NA LAGOA C/ DORITOS",
    mockado: true,
    descricaoCategoria: "Açaí com banana e granola",
    preco: 10.0,
    urlImagem: img_acai,
    tipo: "produto",
    tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
  },
  {
    descricao: "MONSTRINHO NA LAGOA C/ DORITOS MONSTRINHO NA LAGOA C/ DORITOS",
    mockado: true,
    descricaoCategoria: "Açaí com banana e granola",
    preco: 10.0,
    urlImagem: img_acai,
    tipo: "produto",
    tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
  },
];

export const listaCategoriasMock = [
  {
    descricao: "Fracionados",
    urlImagem: img_acai,
    produtos: [
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "PIZZA FRANGO COM CATUPIRY",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_acai,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "MONSTRINHO NA LAGOA C/ DORITOS",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao:
          "MONSTRINHO NA LAGOA C/ DORITOS MONSTRINHO NA LAGOA C/ DORITOS",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
    ],
  },
  {
    descricao: "Fritas",
    urlImagem: img_batata,
    produtos: [
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao:
          "Açaí com banana e granola, lorem ipsum dolor sit amet con, lorem ipsum dolor",
        descricaoCategoria:
          "Açaí com banana e granola, lorem ipsum dolor sit amet con, lorem ipsum dolor sit amet con, lorem ipsum dolor sit amet con, lorem ipsum dolor sit amet con",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
    ],
  },
  {
    descricao: "Lanches",
    urlImagem: img_lanches,
    tipo: "produto",
    tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
    produtos: [
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
    ],
  },
  {
    descricao: "Pizzas",
    urlImagem: img_pizza,
    produtos: [
      {
        descricao: "Pizza de Frango",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_pizza,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Pizza de Calabresa",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_pizza,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Pizza de Mussarela",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_pizza,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Pizza de Lombo",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_pizza,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Pizza de Frango",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_pizza,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Pizza de Calabresa",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_pizza,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Pizza de Mussarela",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_pizza,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Pizza de Lombo",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_pizza,
        tipo: "pizza",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
    ],
  },
  {
    descricao: "Pratos",
    urlImagem: img_porcao,
    produtos: [
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_coca,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_combo,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        precoAntigo: 12.0,
        urlImagem: img_porcao,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
      {
        descricao: "Açaí",
        descricaoCategoria: "Açaí com banana e granola",
        preco: 10.0,
        urlImagem: img_acai,
        tipo: "produto",
        tags: ["Contém Glutem", "Contem Lactose", "Proibido para Menores"],
      },
    ],
  },
  { descricao: "Waychef", urlImagem: img_combo },
];
