import React, { useEffect, useState } from "react";
import GerarModalResponsivo from "../gerarModalResponsivo";
import { X } from "phosphor-react";
import { TextField, CircularProgress } from "@mui/material";
import {
  CampoDeSenha,
  CampoDeSenhaOutlined,
  CampoDeTexto,
  CampoDeTextoOutlined,
} from "../campoInput";
import { BotaoCardapioPreenchido, BotaoCardapioVazado } from "../botao";
import { ContainerInputs, ContianerBotoes, Linha } from "./styles";
import { Close } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Login from "./login";
import Cadastro from "./cadastro";
import Endereco from "./endereço";
import useTamanhoTela from "../../utils/useTamanhoTela";
import FormasPagamento from "./formasPagamento";
import AdicionalFirebase from "./adicionalFirebase";
import { baseURL, useApi } from "../../contexts/api";
import { toast } from "react-toastify";
import axios from "axios";
import { useUserAuth } from "../../contexts/userAuth";
import { useNavigate } from "react-router-dom";

const ModalLogin = ({ aberto, setAberto, loginGoogle, onOpen, etapaLogin }) => {
  const { largura } = useTamanhoTela();
  const { loginUsuario, dadosAuth } = useUserAuth();
  const navigate = useNavigate();
  const { post } = useApi();

  const [etapa, setEtapa] = useState("login");
  const [dadosCadastro, setDadosCadastro] = useState({});
  const [token, setToken] = useState(null);
  const [cadastroFirebase, setCadastroFirebase] = useState(false);

  function fechar() {
    document.body.style.overflow = "unset";
    setAberto(false);
  }
  function mudarTela(tela) {
    setEtapa(tela);
  }

  useEffect(() => {
    if (largura > 768 && aberto) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [aberto]);

  useEffect(() => {
    if (etapaLogin) {
      setEtapa(etapaLogin);
    }
  }, [aberto]);

  function getTipoCartao(chipCredito, chipDebito) {
    if (chipCredito) {
      return "CREDITO";
    } else if (chipDebito) {
      return "DEBITO";
    }
  }

  function getTipoEndereco(chipCasa, chipTrabalho, chipOutro) {
    if (chipCasa) {
      return "CASA";
    } else if (chipTrabalho) {
      return "TRABALHO";
    } else if (chipOutro) {
      return "OUTRO";
    }
  }

  function getErrosEndereco(mensagemErro) {
    let errosRetorno = {};
    try {
      if (mensagemErro.toLowerCase().includes("cep")) {
        errosRetorno.erroCep = "CEP inválido";
      }
      if (mensagemErro.toLowerCase().includes("logradouro")) {
        errosRetorno.erroEndereco = "Endereço inválido";
      }
      if (mensagemErro.toLowerCase().includes("numero")) {
        errosRetorno.erroNumero = "Número inválido";
      }
      if (mensagemErro.toLowerCase().includes("complemento")) {
        errosRetorno.erroComplemento = "Complemento inválido";
      }
      if (mensagemErro.toLowerCase().includes("bairro")) {
        errosRetorno.erroBairro = "Bairro inválido";
      }
      if (mensagemErro.toLowerCase().includes("cidade")) {
        errosRetorno.erroCidade = "Cidade inválida";
      }
      if (mensagemErro.toLowerCase().includes("uf")) {
        errosRetorno.erroEstado = "Estado inválido";
      }
      if (
        mensagemErro.toLowerCase().includes("pontoReferencia") ||
        mensagemErro.toLowerCase().includes("referencia")
      ) {
        errosRetorno.erroReferencia = "Ponto de referência inválido";
      }
      if (mensagemErro.toLowerCase().includes("tipo")) {
        errosRetorno.erroChips = "Selecione um tipo de endereço";
      }
      return errosRetorno;
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  function getErrosPagamento(mensagemErro) {
    let errosRetorno = {};

    try {
      if (mensagemErro.toLowerCase().includes("nome")) {
        errosRetorno.erroNomeTitular = "Nome inválido";
      }
      if (
        mensagemErro.toLowerCase().includes("numeroCartao") ||
        mensagemErro.toLowerCase().includes("Bandeira")
      ) {
        errosRetorno.erroNumeroCartao = "Número inválido";
      }
      if (mensagemErro.toLowerCase().includes("validade")) {
        errosRetorno.erroValidade = "Validade inválida";
      }
      if (mensagemErro.toLowerCase().includes("cvv")) {
        errosRetorno.erroCvv = "CVV inválido";
      }
      if (mensagemErro.toLowerCase().includes("cpf")) {
        errosRetorno.errocpfCartao = "CPF inválido";
      }
      if (mensagemErro.toLowerCase().includes("tipo_cartao")) {
        errosRetorno.erroChips = "Selecione um tipo de cartão";
      }
    } catch (error) {
      return {};
    }

    return errosRetorno;
  }

  async function finalizarEndereco(dadosCadastro) {
    let sucesso = false;
    try {
      const promiseCadastro = await post(
        "/usuario-sessao/client/usuario/endereco",
        {
          padrao: true,
          tipo: getTipoEndereco(
            dadosCadastro.endereco.chipCasa,
            dadosCadastro.endereco.chipTrabalho,
            dadosCadastro.endereco.chipOutro
          ),
          bairro: dadosCadastro.endereco.bairro,
          cep: dadosCadastro.endereco.cep,
          idUf: dadosCadastro.endereco.estado,
          complemento: dadosCadastro.endereco.complemento,
          pontoReferencia: dadosCadastro.endereco.referencia,
          logradouro: dadosCadastro.endereco.endereco,
          numero: dadosCadastro.endereco.numero,
          idCidade: dadosCadastro.endereco.cidade.id,
          idUsuario: dadosAuth.idUsuario,
        }
      );

      let dadosCadastroAtualizado = { ...dadosCadastro };
      dadosCadastroAtualizado.endereco.id = promiseCadastro.data.id;
      setDadosCadastro(dadosCadastroAtualizado);
      sucesso = true;
    } catch (error) {
      console.log(error);
      toast.error(
        error.data?.message ? error.data?.message : "Erro ao cadastrar endereço"
      );
      if (error.response?.data) {
        let erros = getErrosEndereco(error.data?.message);
        setDadosCadastro({
          ...dadosCadastro,
          errosCadastro: {
            ...erros,
          },
        });
      }
      sucesso = false;
    }
    return sucesso;
  }

  async function finalizarPagamento(dadosCadastro) {
    let sucesso = false;
    try {
      // await apiCadastro
      // .post("/usuario-sessao/client/usuario/carteira", {
      const promisePagamento = await post(
        "/usuario-sessao/client/usuario/carteira",
        {
          tipoPagamento: getTipoCartao(
            dadosCadastro.pagamento.chipCredito,
            dadosCadastro.pagamento.chipDebito
          ),
          nome: dadosCadastro.pagamento.nomeTitular,
          numeroCartao: dadosCadastro.pagamento.numeroCartao.replace(/-/g, ""),
          validade: dadosCadastro.pagamento.validade.replace("/", "20"),
          cvv: dadosCadastro.pagamento.cvv,
        }
      );

      let dadosCadastroAtualizado = { ...dadosCadastro };
      dadosCadastroAtualizado.pagamento.id = promisePagamento.data.id;
      setDadosCadastro(dadosCadastroAtualizado);
      sucesso = true;
    } catch (error) {
      console.log(error);
      toast.error(
        error.data?.message
          ? error.data?.message
          : "Erro ao cadastrar pagamento"
      );
      if (error.response?.data) {
        let erros = getErrosPagamento(error.data?.message);
        setDadosCadastro({
          ...dadosCadastro,
          errosPagamento: {
            ...erros,
          },
        });
      }
      sucesso = false;
    }

    if (sucesso) {
      if (cadastroFirebase) {
        setEtapa("adicional");
        return;
      }
      toast.success("Cadastro da forma de pagamento realizado com sucesso");
      setEtapa("login");
      setDadosCadastro({});
      // setAberto(false);
      // navigate("./#/carrinho");
    }
  }

  async function finalizarSemPagamento(dadosCadastro) {
    if (cadastroFirebase) {
      setEtapa("adicional");
      return;
    }
    setEtapa("login");
    setDadosCadastro({});
    // setAberto(false);
    // navigate("./#/carrinho");
  }

  return (
    <GerarModalResponsivo
      width={"500px"}
      controle={aberto}
      onClose={fechar}
      onOpen={onOpen}
      children={() => {
        switch (etapa) {
          case "loading":
            return (
              <div
                style={{
                  width: "100%",
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{ alignSelf: "center" }} />
              </div>
            );
          case "login":
            return (
              <Login
                fechar={fechar}
                mudarTela={mudarTela}
                loginGoogle={loginGoogle}
              />
            );
          case "cadastro":
            return (
              <Cadastro
                fechar={fechar}
                mudarTela={mudarTela}
                dadosCadastro={dadosCadastro}
                setDadosCadastro={setDadosCadastro}
                setToken={setToken}
              />
            );
          case "endereco":
            return (
              <Endereco
                fechar={fechar}
                mudarTela={mudarTela}
                dadosCadastro={dadosCadastro}
                setDadosCadastro={setDadosCadastro}
                finalizar={finalizarEndereco}
                etapa={etapa}
              />
            );
          case "pagamento":
            return (
              <FormasPagamento
                fechar={fechar}
                mudarTela={mudarTela}
                dadosCadastro={dadosCadastro}
                setDadosCadastro={setDadosCadastro}
                finalizar={finalizarPagamento}
                finalizarSemPagamento={finalizarSemPagamento}
              />
            );
          case "adicional":
            return <AdicionalFirebase fechar={fechar} mudarTela={mudarTela} />;
          default:
            break;
        }
      }}
    />
  );
};

export default ModalLogin;
