import { BotaoCartaoPadrao, BotaoIcone, BotaoIrParaPagamento, Container, ContainerAdicionarCartao, ContainerBotaoCartaoPadrao, ContainerCartão, ContainerHeaderCartao, ContainerIcone, ContainerNumeroCartao, ContainerTitulo, FooterCartao, NumeroCartao, TextoBotaoIrParaPagameno, TextoCartaoPadrao, Titulo, TituloCartão } from "./styles";
import { ReactComponent as IconeMastercard } from "../../../assets/images/icones/logos_mastercard.svg";
import { ReactComponent as IconeMais } from "../../../assets/images/icones/icone_mais.svg";
import { ReactComponent as IconeLixo } from "../../../assets/images/icones/icone_lixo.svg";


export function MeusPagamentosWaypag() {
    return (
        <Container>
            <ContainerTitulo>
                <Titulo>
                    Meus cartões
                </Titulo>
            </ContainerTitulo>
            <ContainerCartão>
                <ContainerHeaderCartao>
                    <TituloCartão>
                        Marcio Luis
                    </TituloCartão>
                    <BotaoIcone>
                        <IconeLixo style={{ color: '#E74A3B' }} />
                    </BotaoIcone>
                </ContainerHeaderCartao>
                <ContainerNumeroCartao>
                    <NumeroCartao>
                        ***&nbsp;***&nbsp;***&nbsp;***&nbsp;123
                    </NumeroCartao>
                </ContainerNumeroCartao>
                <FooterCartao>
                    <ContainerIcone>
                        <IconeMastercard />
                    </ContainerIcone>
                    <ContainerBotaoCartaoPadrao>
                        <BotaoCartaoPadrao>
                            <TextoCartaoPadrao>Cartão Padrão</TextoCartaoPadrao>
                        </BotaoCartaoPadrao>
                    </ContainerBotaoCartaoPadrao>
                </FooterCartao>
            </ContainerCartão>
            <ContainerAdicionarCartao>
                <BotaoIcone>
                    <IconeMais />
                </BotaoIcone>
            </ContainerAdicionarCartao>
            <BotaoIrParaPagamento>
                <TextoBotaoIrParaPagameno>
                    Ir para o pagamento
                </TextoBotaoIrParaPagameno>
            </BotaoIrParaPagamento>
        </Container>
    )
}