import { styled } from "styled-components";

export const Botao = styled.div`
  border: 1px solid ${(props) => props.theme.cores.actionActive};
  border-radius: 16px;
  padding: 5px 12px;
  color: ${(props) =>
    props.$ativo
      ? props.theme.cores.neutral100
      : props.theme.cores.actionActive};
  background-color: ${(props) =>
    props.$ativo ? props.theme.cores.actionActive : "transparent"};
  margin-right: 15px;
  cursor: pointer;
  user-select: none;

  @media (max-width: 768px) {
    width: 38vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  @media (max-width: 400px) {
    width: 33vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 15px;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: block;
  background-color: #fff;

`;

export const ContainerCabecalhoCorpo = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerTituloCabecalho = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }
`;

export const TituloCabecalho = styled.h1`
  box-sizing: border-box;
  margin-top: 0;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 46px;
  letter-spacing: 0px;
  line-height: 69px;

  margin-left: auto;
  margin-bottom: 2.5rem;

  @media (max-width: 767px) {
    font-size: 26px;
  }
`;

export const ContainerCorpo = styled.div`
  background-color: ${({ theme }) => theme.coresCardapio.brand100};
  padding-top: 2rem;
  box-sizing: border-box;
  height: 100%;

  @media (min-width: 768px) {
    display: block;
  }

  @media (max-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
export const ContainerBotao = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

export const BotaoVoltarAoCardapio = styled(Botao)`
  border-radius: 8px;
  border-color: ${({ theme }) => theme.coresCardapio.brand500};
  color: ${({ theme }) => theme.coresCardapio.brand500};
  height: 55px;
  width: 270px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: 0.3s;
  margin: 0;

  &:hover {
    background-color: #f09400;
    color: white;
    box-shadow: 0px 0px 6px 1px #f09400;
  }
`;

export const ContainerPedidos = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 395px));
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 3rem;

  @media (max-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 500px)) 
  }
  @media (min-width: 1800px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 700px)) 
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(350px, 500px)) 
  }
 
`;

export const ContainerHeader = styled.div`
  position: relative;
`;

export const ContainerPaginacao =styled.div`
display: flex;
align-items: center;
justify-content: center;

margin: 20px 0;
`
export const ModalExcluir = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
position: relative;

> svg {
  position: absolute;
  cursor: pointer;
  color: red;
  top: 0;
  right: 0;
}
`