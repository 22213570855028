import React, { Fragment, useEffect, useState } from "react";
import {
  ContainerMensagem,
  ContainerTextoLaranja,
  Divider,
  Linha,
  TextoLaranja,
  TextoPreto,
  TextoPretoLeve,
} from "../styles";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ModalMeusEnderecos from "../../../../components/modalMeusEnderecos";
import ModalLogin from "../../../../components/modalLogin";
import { useTheme } from "styled-components";
import { useUserAuth } from "../../../../contexts/userAuth";
import { useApi } from "../../../../contexts/api";
import { toast } from "react-toastify";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GerarModalResponsivo from "../../../../components/gerarModalResponsivo";
import {
  BotaoVermelhoPreenchido,
  BotaoVermelhoVazado,
} from "../../../../components/botao";

function Endereco({ tabSelecionada, atualizarDadosFinalizar }) {
  const theme = useTheme();
  const { dadosEmpresa, dadosAuth, logado } = useUserAuth();
  const { get, post } = useApi();

  const [enderecos, setEnderecos] = useState([]);
  const [enderecoSelecionado, setEnderecoSelecionado] = useState({});
  const [loadingEnderecos, setLoadingEnderecos] = useState(true);
  const [abrirModalEnderecos, setAbrirModalEnderecos] = useState(false);
  const [valorFrete, setValorFrete] = useState(0);

  useEffect(() => {
    if (logado) {
      getEnderecos();
    }
  }, [logado]);

  useEffect(() => {
    let enderecoSelecionadoObjeto = enderecos.find(
      (endereco) => endereco.id == enderecoSelecionado
    );

    if (tabSelecionada == "entrega") {
      atualizarDadosFinalizar({
        enderecoSelecionado: enderecoSelecionadoObjeto,
        frete: valorFrete,
      });
      calcularFrete(enderecoSelecionadoObjeto);
    } else if (tabSelecionada == "retirada") {
      atualizarDadosFinalizar({ enderecoSelecionado: null, frete: valorFrete });
    }
  }, [enderecoSelecionado, valorFrete]);

  function getEnderecos() {
    setEnderecoSelecionado({});
    post("/usuario-sessao/client/usuario/endereco/buscar-todos/endereco", {
      usuarioEnderecoDTO: {
        idUsuario: dadosAuth?.idUsuario,
      },
    })
      .then((res) => {
        setEnderecos(res.data.content);
        setEnderecoSelecionado(
          res.data.content[0]?.id ? res.data.content[0]?.id : {}
        );
        setLoadingEnderecos(false);
      })
      .catch((err) => {
        setEnderecos([]);
        setLoadingEnderecos(false);
        toast.error("Erro ao buscar endereços");
        console.log(err);
      });
  }

  function formatarEndereco(enderecoResumidoDTO) {
    const {
      bairro,
      cep,
      complemento,
      logradouro,
      numero,
      pontoReferencia,
      cidade,
    } = enderecoResumidoDTO;

    let enderecoFormatado = ``;

    if (logradouro) {
      enderecoFormatado += `${logradouro}`;
    }

    if (numero) {
      enderecoFormatado += `, ${numero}`;
    }

    if (complemento) {
      enderecoFormatado += `, ${complemento}`;
    }

    if (bairro) {
      enderecoFormatado += `, ${bairro}`;
    }

    if (cidade) {
      enderecoFormatado += ` - ${cidade}`;
    }

    if (cep) {
      enderecoFormatado += `/ ${cep}`;
    }

    if (pontoReferencia) {
      enderecoFormatado += ` - ${pontoReferencia}`;
    }

    if (enderecoFormatado && !/^[A-Za-z]/.test(enderecoFormatado)) {
      enderecoFormatado = enderecoFormatado.substring(1);
    }

    return enderecoFormatado;
  }

  function calcularFrete(endereco) {
    if (!endereco) {
      return;
    }
    post("/pessoa/frete", {
      "cep": endereco.cep.replace("-", ""),
      "logradouro": endereco.logradouro,
      "numero": endereco.numero,
      "cidade": endereco.usuarioCidadeDTO.nome,
      "estado": endereco.usuarioCidadeDTO.uf,
    })
      .then((res) => {
        if (res.data.message) {
          toast.warn(res.data.message);
          setEnderecoSelecionado({});
          setValorFrete(0);
        } else {
          setValorFrete(res.data.frete);
        }
      })
      .catch((err) => {
        toast.error("Erro ao calcular frete");
        setEnderecoSelecionado({});
        setValorFrete(0);
        console.log(err);
      });
  }

  return (
    <ContainerMensagem style={{ alignItems: "flex-start" }}>
      {tabSelecionada == "entrega" ? (
        <TextoPreto>Endereço de entrega</TextoPreto>
      ) : tabSelecionada == "retirada" ? (
        <TextoPreto>Endereço de retirada</TextoPreto>
      ) : (
        <></>
      )}

      {tabSelecionada == "entrega" ? (
        <FormControl fullWidth>
          <RadioGroup
            value={enderecoSelecionado}
            onChange={(event) => {
              setEnderecoSelecionado(event.target.value);
            }}
          >
            {loadingEnderecos ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  sx={{ color: theme.coresCardapio.brand500 }}
                  style={{ alignSelf: "center" }}
                />
              </div>
            ) : (
              enderecos.map((endereco) => (
                <LinhaEndereco
                  key={endereco.id}
                  endereco={endereco}
                  setLoadingEnderecos={setLoadingEnderecos}
                  getEnderecos={getEnderecos}
                />
              ))
            )}
            <ContainerTextoLaranja>
              <TextoLaranja
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid " + theme.coresCardapio.brand500,
                  fontWeight: 400,
                }}
                onClick={() => setAbrirModalEnderecos(true)}
              >
                Adicionar Endereço
              </TextoLaranja>
            </ContainerTextoLaranja>
          </RadioGroup>
        </FormControl>
      ) : tabSelecionada == "retirada" ? (
        <ContainerMensagem style={{ alignItems: "flex-start" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 16,
            }}
          >
            <LocationOnIcon
              style={{
                fontSize: 24,
                color: theme.coresCardapio.brand500,
                marginRight: 4,
                paddingTop: 2,
              }}
            />
            <TextoPretoLeve style={{ fontSize: "1rem" }}>
              {dadosEmpresa.enderecoResumidoDTO &&
                formatarEndereco(dadosEmpresa.enderecoResumidoDTO)}
            </TextoPretoLeve>
          </div>
        </ContainerMensagem>
      ) : (
        <></>
      )}
      {logado && (
        <Fragment>
          <ModalMeusEnderecos
            aberto={abrirModalEnderecos}
            setAberto={setAbrirModalEnderecos}
            novaEntrada={true}
          />
        </Fragment>
      )}
    </ContainerMensagem>
  );
}

function LinhaEndereco({ endereco, setLoadingEnderecos, getEnderecos }) {
  const theme = useTheme();
  const { del } = useApi();

  const [modalConfirmacaoAberto, setModalConfirmacaoAberto] = useState(false);

  function removerEndereco() {
    setLoadingEnderecos(true);
    del("/usuario-sessao/client/usuario/endereco/" + endereco.id)
      .then((res) => {
        getEnderecos();
        toast.success("Endereço removido com sucesso");
      })
      .catch((err) => {
        toast.error("Erro ao remover endereço.");
        console.log(err);
      });
  }

  return (
    <>
      <FormControlLabel
        key={endereco.id}
        value={endereco.id}
        control={
          <Radio
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 28,
              },
            }}
          />
        }
        sx={{
          "& .MuiFormControlLabel-label": {
            width: "100%",
          },
        }}
        label={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>
              {endereco.logradouro}, {endereco.numero}, {endereco.bairro} -{" "}
              {endereco.usuarioCidadeDTO.nome}
            </p>
            <FontAwesomeIcon
              onClick={() => {
                setModalConfirmacaoAberto(true);
              }}
              icon={faTrashCan}
              style={{
                fontSize: 16,
                marginLeft: "auto",
                color: theme.coresCardapio.vermelhoDelete,
                cursor: "pointer",
              }}
            />
          </div>
        }
      />
      <GerarModalResponsivo
        controle={modalConfirmacaoAberto}
        width={"450px"}
        overlayManual={true}
        onClose={() => {
          setModalConfirmacaoAberto(false);
        }}
        children={() => {
          return (
            <Fragment>
              <Linha>
                <h1>Excluir endereco</h1>
              </Linha>
              <Divider style={{ margin: 0 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                  padding: "16px 12px",
                  alignItems: "flex-start",
                }}
              >
                <p style={{ textAlign: "flex-start", marginBottom: 16 }}>
                  Deseja realmente remover o endereço selecionado?
                  <p
                    style={{
                      width: "90%",
                      color: theme.coresCardapio.brand500,
                    }}
                  >
                    {endereco.logradouro}, {endereco.numero}, {endereco.bairro}{" "}
                    - {endereco.usuarioCidadeDTO?.nome}
                  </p>
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    gap: 8,
                  }}
                >
                  <BotaoVermelhoPreenchido
                    tamanho={"MD"}
                    texto={"Confirmar"}
                    onClick={() => {
                      removerEndereco();
                      setModalConfirmacaoAberto(false);
                    }}
                  />
                  <BotaoVermelhoVazado
                    tamanho={"MD"}
                    texto={"Cancelar"}
                    style={{ marginRight: 0 }}
                    onClick={() => {
                      setModalConfirmacaoAberto(false);
                    }}
                  />
                </div>
              </div>
            </Fragment>
          );
        }}
      />
    </>
  );
}

export default Endereco;
