import React, { useEffect, useState } from "react";
import {
  Container,
  ContainerTitulo,
  Titulo,
  ContainerAnimacao,
} from "./styles.js";
import Lottie from "lottie-react";
import circle_loader from "./animations/circle-loader.json";
import tomato_error from "./animations/97670-tomato-error.json";
import success from "./animations/97240-success.json";

export function ProcessandoPagamentoWaypag() {
  const [estado, setEstado] = useState("Processando");

  useEffect(() => {
    setTimeout(() => {
      setEstado("Sucesso");
    }, 5000);
    setTimeout(() => {
      setEstado("Falha");
    }, 10000);
  }, []);

  function getAnimacao() {
    if (estado === "Processando") {
      return <Lottie animationData={circle_loader} />;
    } else if (estado === "Sucesso") {
      return (
        <Lottie animationData={success} style={{ scale: "0.6" }} loop={false} />
      );
    } else if (estado === "Falha") {
      return (
        <Lottie
          animationData={tomato_error}
          style={{ scale: "0.6" }}
          loop={false}
        />
      );
    }
  }

  function getTexto() {
    if (estado === "Processando") {
      return (
        <Titulo key={"Processando"}>Estamos processando seu pagamento</Titulo>
      );
    } else if (estado === "Sucesso") {
      return <Titulo key={"Sucesso"}>Pagamento realizado com sucesso!</Titulo>;
    } else if (estado === "Falha") {
      return (
        <Titulo key={"Falha"}>Não foi possível efetuar seu pagamento</Titulo>
      );
    }
  }

  return (
    <Container>
      <ContainerTitulo>{getTexto()}</ContainerTitulo>
      <ContainerAnimacao>{getAnimacao()}</ContainerAnimacao>
    </Container>
  );
}
