import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BotaoCadastrar,
  BotaoIcone,
  Container,
  ContainerCadastrar,
  ContainerIcone,
  ContainerInputs,
  ContainerTitulo,
  Input,
  LinhaTitulo,
  TextoBotaoCadastrar,
  Titulo,
  ContainerOpcao,
  Opcao,
} from "./styles.js";
import {
  faArrowLeft,
  faCreditCard,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

export function AdicionarCartaoWaypag() {
  return (
    <Container>
      <ContainerIcone>
        <BotaoIcone>
          <FontAwesomeIcon icon={faArrowLeft} />
        </BotaoIcone>
      </ContainerIcone>
      <ContainerTitulo>
        <Titulo>Formas de Pagamento</Titulo>
        <LinhaTitulo />
      </ContainerTitulo>
      <ContainerOpcao>
        <Opcao>
          <FontAwesomeIcon icon={faCreditCard} />
          Crédito
        </Opcao>
        <Opcao>
          <FontAwesomeIcon icon={faDollarSign} />
          Débito
        </Opcao>
      </ContainerOpcao>
      <ContainerInputs>
        <Input placeholder="Nome do Titular" type="text" required />
        <Input placeholder="Numero do cartão" type="text" required />
        <Input placeholder="Validade" type="text" required />
        <Input placeholder="CVV" type="text" required />
        <Input placeholder="CPF" type="text" required />
      </ContainerInputs>
      <ContainerCadastrar>
        <BotaoCadastrar>
          <TextoBotaoCadastrar>Cadastrar</TextoBotaoCadastrar>
        </BotaoCadastrar>
      </ContainerCadastrar>
    </Container>
  );
}
