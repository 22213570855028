import dayjs from "dayjs";

export function assegurarValor(valor, padrao) {
  return valor ? valor : padrao;
}

export function gerarEntradaMenu({
  tipo,
  descricao,
  valor,
  valorTotal,
  quantidade,
  idGrade,
  idProdutoEmpresa,
}) {
  console.log(
    tipo,
    descricao,
    valor,
    valorTotal,
    quantidade,
    idGrade,
    idProdutoEmpresa
  );

  return {
    "id": "",
    "idItemPai": null,
    "index": 0,
    "indexMenu": 0,
    "itensSelecionadosObservacoes": {},
    "itensSelecionadosComponenteFixo": {},
    "itensSelecionadosComponenteExtra": {},
    "itensSelecionados": {},
    "expadirDetalhesProdutoComposto": null,
    "idNota": null,
    "nota": null,
    "tipo": tipo,
    "idEstacao": null,
    "idVendedor": null,
    "vendedor": null,
    "idUsuario": null,
    "usuario": null,
    "dataLancamento": null,
    "idTurno": null,
    "idProdutoEmpresa": idProdutoEmpresa,
    "produtoEmpresa": null,
    "idGrade": idGrade,
    "grade": null,
    "indice": null,
    "descricao": descricao,
    "quantidade": assegurarValor(quantidade, 1),
    "precoCusto": "0",
    "precoCMC": "0",
    "precoUnitario": assegurarValor(valor, 1),
    "precoTotal": assegurarValor(valorTotal, 1),
    "taxaServico": "0",
    "valorServico": "0",
    "valorDesconto": "0",
    "valorDescontoRateio": "0",
    "valorLiquido": "0",
    "valorAcrescimoRateio": "0",
    "valorFreteRateio": "0",
    "valorDespesaAcessoriaRateio": "0",
    "promocao": null,
    "quantidadePromocao": 0,
    "precoPromocao": "0",
    "valorDescontoPromocao": "0",
    "cancelado": false,
    "motivoCancelamento": null,
    "observacao": null,
    "consumoItem": {},
    "consumoItemDTO": {},
    "subitens": [],
    "idConcentrador": null,
    "idTabelaPreco": null,
    "tabelaPreco": null,
    "notaItemFiscal": null,
    "dadosIntegracao": [],
    "roundingMode": "HALF_UP",
    "somaTotal": "0",
    "dataEmissao": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    "dataLancamento": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  };
}

export function gerarEntradaItem(adicional) {
  console.log(adicional);

  let tipo;
  if (!adicional.pacote || adicional.pacote !== "NENHUM") {
    tipo = adicional.pacote;
  } else {
    if (adicional.tipo === "OBSERVACAO") {
      tipo = "OBSERVACAO";
    } else {
      tipo = "ITEM";
    }
  }

  return {
    "id": adicional.id,
    "idItemPai": null,
    "index": 0,
    "indexMenu": 0,
    "aplicacaoOrigem": "WAYMENU",
    "itensSelecionadosObservacoes": {},
    "itensSelecionadosComponenteFixo": {},
    "itensSelecionadosComponenteExtra": {},
    "itensSelecionados": {},
    "expadirDetalhesProdutoComposto": null,
    "idNota": null,
    "nota": null,
    "tipo": tipo,
    "idEstacao": null,
    "idVendedor": null,
    "vendedor": null,
    "idUsuario": null,
    "usuario": null,
    "dataLancamento": null,
    "idTurno": null,
    "idProdutoEmpresa": adicional.idProdutoEmpresa,
    "produtoEmpresa": null,
    "idGrade": adicional.idGrade,
    "grade": null,
    "indice": null,
    "descricao": adicional.descricaoComponente,
    "quantidade": assegurarValor(adicional.quantidade, 1),
    "precoCusto": "0",
    "precoCMC": "0",
    "precoUnitario": assegurarValor(adicional.valorComponente, 1),
    "precoTotal":
      assegurarValor(adicional.valorComponente, 0) *
      assegurarValor(adicional.quantidade, 1),
    "taxaServico": "0",
    "valorServico": "0",
    "valorDesconto": "0",
    "valorDescontoRateio": "0",
    "valorLiquido": "0",
    "valorAcrescimoRateio": "0",
    "valorFreteRateio": "0",
    "valorDespesaAcessoriaRateio": "0",
    "promocao": null,
    "quantidadePromocao": 0,
    "precoPromocao": "0",
    "valorDescontoPromocao": "0",
    "cancelado": false,
    "motivoCancelamento": null,
    "observacao": null,
    "consumoItem": {},
    "consumoItemDTO": {},
    "subitens": [],
    "idConcentrador": null,
    "idTabelaPreco": null,
    "tabelaPreco": null,
    "notaItemFiscal": null,
    "dadosIntegracao": [],
    "roundingMode": "HALF_UP",
    "somaTotal": "0",
    "dataEmissao": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    "dataLancamento": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  };
}

export function gerarDadosNota(idCliente) {
  return {
    "notaDTO": {
      "operacao": "VENDA",
      "idCaixa": 0,
      "aplicacaoOrigem": "WAYMENU",
      // "id": 0,
      // "serie": "string",
      // "subSerie": "string",
      "dataEmissao": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      "dataLancamento": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      // "dataEmissaoFim": "2024-08-20T13:53:14.199Z",
      // "dataEmissaoInicio": "2024-08-20T13:53:14.199Z",
      // "dataSaida": "2024-08-20T13:53:14.199Z",
      // "idVendedor": 0,
      // "idTurno": 0,
      // "idCop": 0,
      "idCliente": idCliente,
      // "idClienteAutorizado": 0,
      // "idFornecedor": 0,
      // "idRepresentante": 0,
      // "idEndereco": 0,
      "valorSubTotal": 0,
      "valorTotal": 0,
      // "valorAcrescimo": 0,
      // "valorDesconto": 0,
      // "valorDescontoItens": 0,
      // "valorTotalServico": 0,
      // "valorIsencaoServico": 0,
      // "valorFrete": 0,
      // "valorIsencaoFrete": 0,
      // "valorDespesaAcessoria": 0,
      // "descontoPromocao": true,
      "status": "ABERTA",
      "dataStatus": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      // "statusExpedicao": "NENHUMA",
      // "idEntregador": 0,
      // "dataCombinada": "2024-08-20T13:53:14.199Z",
      // "consumidorDocumento": "string",
      // "consumidorNome": "string",
      // "motivoCancelamento": "string",
      // "tokenSessao": "string",
      // "idSessao": 0,
      // "consideraFaturamento": true,
      // "consideraCMV": true,
      // "idCanalVenda": 0,
      // "usaConsumo": true,
      // "versao": "2024-08-20T13:53:14.199Z",
      // "idModuloMapeamentoPraca": 0,
      // "numero": 0,
      // "bilhetagem": 0,
      // "notaFiscalDTO": {
      //   "id": 0,
      //   "chave": "string",
      //   "chaveCancelamento": "string",
      //   "protocoloInutilizacao": "string",
      //   "protocolo": "string",
      //   "protocoloCancelamento": "string",
      //   "recibo": "string",
      //   "dataRecibo": "2024-08-20T13:53:14.199Z",
      //   "modeloFiscal": "NFSE",
      //   "numeroSerie": 0,
      //   "numeroCfe": 0,
      //   "numeroRps": 0,
      //   "codigoUf": 0,
      //   "dataEmissao": "2024-08-20T13:53:14.199Z",
      //   "dataEmissaoDe": "2024-08-20T13:53:14.199Z",
      //   "dataEmissaoAte": "2024-08-20T13:53:14.199Z",
      //   "idEmissorFiscal": 0,
      //   "qrCode": "string",
      //   "dataCompetencia": "2024-08-20T13:53:14.199Z",
      //   "idNotaXml": 0,
      //   "inutilizada": true,
      //   "informacoesAdicionais": "string",
      //   "status": "AUTORIZADO",
      //   "erroEmissao": "string",
      //   "idContingenciaFiscal": 0,
      //   "handle": "string",
      //   "linkNotaPDF": "string",
      //   "valorBcIcms": 0,
      //   "valorBcPis": 0,
      //   "valorBcCofins": 0,
      //   "valorIcms": 0,
      //   "valorPis": 0,
      //   "valorCofins": 0,
      //   "valorBcIcmsSt": 0,
      //   "valorIpi": 0,
      //   "valorIcmsSt": 0,
      //   "valorFcpSt": 0,
      //   "valorFcp": 0,
      //   "desconsiderarIcmsPisCofins": true
      // }
    },
    "consumoDTO": {
      // "id": 0,
      "modulo": "DELIVERY",
      "dataAbertura": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      "dataAtualizacao": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      "dataFechamento": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      "dataEntrega": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      // "comanda": null,
      // "senhaAtendimento": "string",
      // "pessoas": 0,
      "observacao": "",
      // "mesaVinculada": 0,
      // "tipoDesconto": "NENHUM",
      // "preDesconto": 0,
      // "idConsumoJuncao": 0,
      // "idConsumoOrigemRecebimento": 0,
      // "idEvento": 0,
      // "idPerfil": 0,
      // "idPromoter": 0,
      // "limite": 0,
      "tipoEntrega": "NENHUM",
      "entregue": false,
    },
  };
}

export function gerarDadosNotaMesa(slug) {
  return {
    "cadastroNotaWaymenuDTO": {
      "operacao": "VENDA",
      "idCaixa": 0,
      "origemAplicacao": "WAYMENU",
      "dataEmissao": dayjs().toISOString(),
      "dataLancamento": dayjs().toISOString(),
      "idCliente": null,
      "valorSubTotal": 0,
      "valorTotal": 0,
      "status": "ABERTA",
      "dataStatus": dayjs().toISOString(),
    },
    "cadastroConsumoWaymenuDTO": {
      // "modulo": "MESA",
      // "dataAbertura": dayjs().toISOString(),
      // "dataAtualizacao": dayjs().toISOString(),
      // "dataFechamento": dayjs().toISOString(),
      // "dataEntrega": dayjs().toISOString(),
      // "tipoEntrega": "NENHUM",
      "comanda": null,
      "observacao": "",
      "entregue": false,
      "tipoDesconto": "NENHUM",
    },
  };
}

export function gerarDadosItemNota(produto, tipo) {
  console.log(produto);

  return [
    {
      "idProdutoEmpresa": produto.idProdutoEmpresa,
      "quantidade": produto.dadosPedido.quantidadePedido,
      "precoUnitario": produto.gradeSelecionada.precoVenda,
      "precoTotal": produto.dadosPedido.valorTotal,
      "observacao": produto.dadosPedido.observacao,
      "idTabelaPreco": produto.gradeSelecionada.idTabelaPreco,
      "tipoItemSet": ["ITEM"],
      "id": produto.idProduto,
      "idCardapioMenu": produto.idCardapioMenu,
      "idItemPai": null,
      "aplicacaoOrigem": "WAYMENU",
      "index": null,
      "indexMenu": 0,
      "itensSelecionadosObservacoes": {},
      "itensSelecionadosComponenteFixo": {},
      "itensSelecionadosComponenteExtra": {},
      "itensSelecionados": {},
      "expadirDetalhesProdutoComposto": false,
      "idNota": null,
      "nota": null,
      "tipo": tipo ? tipo : "ITEM",
      "idEstacao": null,
      "idVendedor": null,
      "vendedor": null,
      "idUsuario": null,
      "usuario": null,
      "dataLancamento": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      "idTurno": null,
      "produtoEmpresa": null,
      "idGrade": produto.gradeSelecionada.idGrade,
      "idGradeEmpresa": produto.gradeSelecionada.idGradeEmpresa,
      "grade": null,
      "indice": null,
      "descricao": produto.descricao,
      "precoCusto": "0",
      "precoCMC": "0",
      "precoUnitario": assegurarValor(produto.gradeSelecionada.precoVenda, 0),
      "precoTotal": assegurarValor(produto.dadosPedido.valorTotal, 0),
      "taxaServico": "0",
      "valorServico": "0",
      "valorDesconto": "0",
      "valorDescontoRateio": "0",
      "valorLiquido": "0",
      "valorAcrescimoRateio": "0",
      "valorFreteRateio": "0",
      "valorDespesaAcessoriaRateio": "0",
      "promocao": null,
      "quantidadePromocao": 0,
      "precoPromocao": "0",
      "valorDescontoPromocao": "0",
      "cancelado": false,
      "motivoCancelamento": null,
      "consumoItem": {},
      "consumoItemDTO": {},
      subitens: [],
    },
  ];
}

export function gerarDadosItemNotaCombinado(produto, tipo) {
  console.log(produto);
  console.log(produto.idProdutoEmpresa);
  console.log(produto.dadosPedido.tamanho);
  return [
    {
      "idProdutoEmpresa": produto.idProdutoEmpresa,
      "quantidade": produto.dadosPedido.quantidadePedido,
      "precoUnitario": produto.dadosPedido.tamanho.valor.precoVenda,
      "precoTotal": produto.dadosPedido.valorTotal,
      "observacao": produto.dadosPedido.observacao,
      "idTabelaPreco": produto.dadosPedido.tamanho.idTabelaPreco,
      "tipoItemSet": ["ITEM"],
      "id": produto.idProduto,
      "idCardapioMenu": produto.idCardapioMenu,
      "idItemPai": null,
      "aplicacaoOrigem": "WAYMENU",
      "index": null,
      "indexMenu": 0,
      "itensSelecionadosObservacoes": {},
      "itensSelecionadosComponenteFixo": {},
      "itensSelecionadosComponenteExtra": {},
      "itensSelecionados": {},
      "expadirDetalhesProdutoComposto": false,
      "idNota": null,
      "nota": null,
      "tipo": tipo ? tipo : "ITEM",
      "idEstacao": null,
      "idVendedor": null,
      "vendedor": null,
      "idUsuario": null,
      "usuario": null,
      "dataLancamento": dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      "idTurno": null,
      "produtoEmpresa": null,
      "idGradeEmpresa": produto.dadosPedido.tamanho.idGradeEmpresa,
      "idGrade": produto.dadosPedido.tamanho.idGrade,
      "grade": null,
      "indice": null,
      "descricao": produto.descricao,
      "precoCusto": "0",
      "precoCMC": "0",
      "precoUnitario": produto.dadosPedido.valorTotal,
      "precoTotal": produto.dadosPedido.valorTotal,
      "taxaServico": "0",
      "valorServico": "0",
      "valorDesconto": "0",
      "valorDescontoRateio": "0",
      "valorLiquido": "0",
      "valorAcrescimoRateio": "0",
      "valorFreteRateio": "0",
      "valorDespesaAcessoriaRateio": "0",
      "promocao": null,
      "quantidadePromocao": 0,
      "precoPromocao": "0",
      "valorDescontoPromocao": "0",
      "cancelado": false,
      "motivoCancelamento": null,
      "consumoItem": {},
      "consumoItemDTO": {},
      subitens: [],
    },
  ];
}
