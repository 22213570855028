import {
  faAddressCard,
  faBars,
  faCartPlus,
  faCircleUser,
  faHamburger,
  faSearch,
  faShoppingCart,
  faSignIn,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { ReactComponent as WaymenuImagemGrande } from "../../../assets/images/wm_logo.svg";
import { ReactComponent as WaymenuImagemPequena } from "../../../assets/images/wm_logo_mobile.svg";
import {
  BotaoMenuUsuario,
  BotaoMeuCarrinhoContainer,
  Container,
  ContainerBotoes,
  ContainerMenuUsuario,
  ContainerOpcaoMenu,
  BotaoEntrar,
  BotaoEntrarMobile,
} from "./styles";
import BarraPesquisa from "../../barraPesquisa";
import { Link } from "react-router-dom";

import useTamanhoTela from "../../../utils/useTamanhoTela";
import { useNavigate } from "react-router-dom";
import ModalLogin from "../../modalLogin";
import { useUserAuth } from "../../../contexts/userAuth";
import ModalMeusEnderecos from "../../modalMeusEnderecos";
import { useParams } from "react-router-dom";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import ModalFormasPagamento from "../../modalFormasPagamento";
import { checarModoCardapio } from "../../../utils/checarModoCardapio";
import { getUrlCardapios } from "../../../utils/getUrlParaCadapios";

const CardapioHeader = ({ textoPesquisa, setTextoPesquisa }) => {
  const { largura } = useTamanhoTela();
  const navigate = useNavigate();
  let { urlAmigavel, tipoCardapio, slugMesa } = useParams();

  const {
    logado,
    deslogarUsuario,
    loginUsuario,
    dadosAuth,
    dadosUsuarioLogado,
    modoMesa,
  } = useUserAuth();

  const [abrirModalEnderecos, setAbrirModalEnderecos] = useState(false);
  const [abrirModalFormasPagamento, setAbrirModalFormasPagamento] =
    useState(false);
  const [modoCardapio, setModoCardapio] = useState(null);

  useEffect(() => {
    setModoCardapio(checarModoCardapio(tipoCardapio));
  }, [tipoCardapio]);
  /// Componentes

  const BotaoMeuCarrinho = () => {
    return (
      <BotaoMeuCarrinhoContainer
        $logado={logado}
        onClick={() => {
          navigate("./#/carrinho");
        }}
      >
        <FontAwesomeIcon
          icon={faCartPlus}
          color={logado ? "#fff" : "#f09400"}
          fontSize={18}
        />
        <p>MEU CARRINHO</p>
      </BotaoMeuCarrinhoContainer>
    );
  };

  const MenuUsuarioPopUp = ({ componente }) => {
    const { largura } = useTamanhoTela();
    const [abrirLogin, setAbrirLogin] = useState(false);
    const [abrirHeaderDeslogadoMobile, setAbrirHeaderDeslogadoMobile] =
      useState(false);

    return modoCardapio || modoMesa ? (
      <></>
    ) : logado ? (
      <Popup
        trigger={
          largura < 990 ? (
            <FontAwesomeIcon icon={faBars} color="#f09400" fontSize={28} />
          ) : (
            <BotaoMenuUsuario>
              <FontAwesomeIcon
                icon={faCircleUser}
                color="#f09400"
                fontSize={72}
              />
            </BotaoMenuUsuario>
          )
        }
        position="bottom right"
        arrowStyle={{ visibility: "hidden" }}
        contentStyle={{ width: 250 }}
      >
        <ContainerMenuUsuario>
          <FontAwesomeIcon icon={faCircleUser} color="#f09400" fontSize={72} />
          <h3>
            {dadosUsuarioLogado ? dadosUsuarioLogado.nome : "Nome Desconhecido"}
          </h3>
          <ul>
            <OpcaoMenu
              titulo="Meus pedidos"
              icone={faHamburger}
              onClick={() => {
                navigate("./#/pedidos");
              }}
            />
            <OpcaoMenu
              titulo="Meu carrinho"
              icone={faShoppingCart}
              onClick={() => {
                navigate("./#/carrinho");
              }}
            />
            <OpcaoMenu titulo="Meus dados" icone={faAddressCard} />
            <OpcaoMenu
              titulo="Meus endereços"
              icone={faMapLocationDot}
              onClick={() => setAbrirModalEnderecos(true)}
            />
            <OpcaoMenu
              titulo="Pagamento"
              icone={faCreditCard}
              onClick={() => setAbrirModalFormasPagamento(true)}
            />
            <OpcaoMenu
              titulo="Sair"
              icone={faSignIn}
              iconeCor="#E74A3B"
              onClick={() => {
                deslogarUsuario();
              }}
            />
          </ul>
        </ContainerMenuUsuario>
      </Popup>
    ) : largura < 990 ? (
      <>
        <ModalLogin
          aberto={abrirLogin}
          setAberto={setAbrirLogin}
          loginGoogle={true}
        />
        <Popup
          trigger={
            <FontAwesomeIcon
              icon={faBars}
              color="#f09400"
              fontSize={28}
              onClick={() => setAbrirHeaderDeslogadoMobile(true)}
            />
          }
          onClose={() => setAbrirHeaderDeslogadoMobile(false)}
          position="bottom right"
          arrowStyle={{ visibility: "hidden" }}
          contentStyle={{ width: 280, height: 280, paddingTop: 10 }}
        >
          {(close) => (
            <ContainerMenuUsuario>
              <FontAwesomeIcon
                icon={faCircleUser}
                color="#f09400"
                fontSize={72}
              />
              <h3>Bem vindo</h3>
              <ul>
                <>
                  <BotaoEntrarMobile
                    onClick={() => {
                      close();
                      setAbrirLogin(true);
                    }}
                  >
                    <p>ENTRAR</p>
                  </BotaoEntrarMobile>
                  <BotaoMeuCarrinho />
                </>
              </ul>
            </ContainerMenuUsuario>
          )}
        </Popup>
      </>
    ) : (
      <>
        <ModalLogin
          aberto={abrirLogin}
          setAberto={setAbrirLogin}
          loginGoogle={true}
        />
        <BotaoEntrar onClick={() => setAbrirLogin(true)}>
          <p>ENTRAR</p>
        </BotaoEntrar>
      </>
    );
  };

  const OpcaoMenu = ({
    titulo,
    icone,
    link = "#",
    iconeCor = "#f09400",
    onClick = () => {},
  }) => {
    return (
      <ContainerOpcaoMenu onClick={onClick}>
        <FontAwesomeIcon
          icon={icone}
          color={iconeCor}
          fontSize={18}
          style={{ padding: 10 }}
        />
        <li>{titulo}</li>
      </ContainerOpcaoMenu>
    );
  };

  const WaymenuLogo = () => {
    const { largura } = useTamanhoTela();
    return largura < 770 ? (
      <WaymenuImagemPequena style={{ width: 65 }} />
    ) : (
      <WaymenuImagemGrande style={{ width: 165 }} />
    );
  };

  //////// Retorno do componente

  return (
    <>
      <Container>
        <Link to={getUrlCardapios(urlAmigavel, tipoCardapio, slugMesa)}>
          <WaymenuLogo />
        </Link>
        <BarraPesquisa value={textoPesquisa} onChange={setTextoPesquisa} />
        {largura < 990 ? (
          <MenuUsuarioPopUp />
        ) : modoCardapio ? (
          <ContainerBotoes />
        ) : (
          <ContainerBotoes>
            <BotaoMeuCarrinho />
            {!modoMesa && <MenuUsuarioPopUp />}
          </ContainerBotoes>
        )}
      </Container>
      {logado && (
        <Fragment>
          <ModalMeusEnderecos
            aberto={abrirModalEnderecos}
            setAberto={setAbrirModalEnderecos}
          />
          <ModalFormasPagamento
            aberto={abrirModalFormasPagamento}
            setAberto={setAbrirModalFormasPagamento}
          />
        </Fragment>
      )}
    </>
  );
};

export default CardapioHeader;
