import { Chip } from "@mui/material/";
import styled from "styled-components";

export const ChipCustomizado = styled(Chip)`
  color: ${({ theme }) => theme.coresCardapio.brand500};
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  height: fit-content;
  border-width: 2px;
  border-radius: 8px;
  height: 28px;
  padding: 14px 6px;
  cursor: pointer;
  color: ${({ theme, $valor }) =>
    $valor ? "#fff" : theme.coresCardapio.brand500};
  fill: ${({ theme, $valor }) =>
    $valor ? "#fff" : theme.coresCardapio.brand500};

  .MuiChip-label {
    color: ${({ theme, $valor }) =>
      $valor ? "#fff" : theme.coresCardapio.brand500};
    fill: ${({ theme, $valor }) =>
      $valor ? "#fff" : theme.coresCardapio.brand500};
    padding: 0px 8px;
    margin-bottom: 0px;
  }
`;
