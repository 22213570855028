export const checarModoCardapio = (tipo) => {
  switch (String(tipo).toLocaleLowerCase()) {
    case "delivery":
      return false;
    case "mesa":
      return false;
    default:
      return true;
  }
};
