import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-spring-bottom-sheet/dist/style.css";
import "react-toastify/dist/ReactToastify.css";
import "dayjs/locale/pt-br";
import React from "react";
import { TemaProvider } from "./contexts/theme";
import RoutesApp from "./routes";
import GlobalStyle from "./styles/global";
import { ApiProvider } from "./contexts/api";
import { ToastContainer } from "react-toastify";
import { UserAuthProvider } from "./contexts/userAuth";
import * as updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import { CarrinhoProvider } from "./contexts/carrinho";
dayjs.extend(updateLocale);

const App = () => {
  dayjs.updateLocale("pt-br", {
    weekdaysMin: "D_S_T_Q_Q_S_S".split("_"),
  });

  return (
    <TemaProvider>
      <ApiProvider>
        <UserAuthProvider>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <RoutesApp />
            <GlobalStyle />
            <ToastContainer />
          </LocalizationProvider>
        </UserAuthProvider>
      </ApiProvider>
    </TemaProvider>
  );
};

export default App;
