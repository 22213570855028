import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import styled from "styled-components";

export const ContainerCategorias = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 9vw;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    padding: 0px 0px;
  }
`;

export const ImagemTitulo = styled.div`
  width: 100%;
  align-self: center;
  max-width: 1300px;
  height: 300px;
  border-radius: 0.5rem;
  margin: 20px 0px;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5)),
    url(${({ url }) => url});
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 30px;
  font-size: 36px;
  font-weight: 400;
  align-items: flex-start;
  font-family: "Poppins", sans-serif;

  @media (max-width: 768px) {
    margin: 15px 0px 0px 0px;
    border-radius: 0px;
    height: 130px;
  }
`;

export const CategoriaExpansivel = styled(Accordion)`
  width: 100%;
  max-width: 1300px;
  min-height: 80px;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  margin: 20px 0px;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  }
`;

export const CategoriaExpansivelTitulo = styled(AccordionSummary)`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  border-radius: 0.5rem;
  font-size: 24px;
  color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  font-family: "Poppins", sans-serif;
  font-weight: 400;

  .MuiSvgIcon-root {
    font-size: 48px;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};

    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;

export const CategoriaExpansivelTituloAlternativa = styled(
  CategoriaExpansivelTitulo
)`
  height: 185px;
  text-align: center;

  p {
    font-size: 24px;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    width: 90%;
    height: 100%;
    align-self: center;
  }

  @media (max-width: 768px) {
    height: 90px;

    p {
      text-align: left;
      padding-left: 10px;
    }
  }
`;

export const ImagemCategoriaAlternativa = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5)),
    url(${({ url }) => url});

  @media (max-width: 768px) {
    width: 72px;
    height: 72px;
  }
`;

export const CategoriaExpansivelConteudo = styled(AccordionDetails)`
  width: 100%;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
