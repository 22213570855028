import { Checkbox, FormControlLabel } from "@mui/material";
import {
  ContainerFinalizar,
  Linha,
  LinhaCupom,
  TextoPreto,
  TextoVerde,
} from "../styles";
import { CampoDeTextoOutlined } from "../../../../components/campoInput";
import { BotaoCardapioVazado } from "../../../../components/botao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatarReal from "../../../../utils/formatarReal";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { useCarrinho } from "../../../../contexts/carrinho";
import { useEffect, useState } from "react";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import FormatarCPFCNPJTempoReal from "../../../../utils/formatarCPFCNPJTempoReal";
import { toast } from "react-toastify";
import { useUserAuth } from "../../../../contexts/userAuth";
import { useApi } from "../../../../contexts/api";

export function DadosPedido({ atualizarDadosFinalizar, dadosFinalizar }) {
  const { produtos, nota, atualizarCarrinho, limparCarrinho, quantidadeItens } =
    useCarrinho();
  const { modoMesa, dadosAuth } = useUserAuth();
  const { largura } = useTamanhoTela();
  const { post } = useApi();

  const [cpf, setCpf] = useState("");
  const [cupom, setCupom] = useState("");
  const [cupomLiberado, setCupomLiberado] = useState(modoMesa ? true : false);
  const [cupomValido, setCupomValido] = useState(false);
  const [cupomInvalido, setCupomInvalido] = useState(false);

  useEffect(() => {
    setCupomValido(false);
    setCupomInvalido(false);
  }, [cupom]);

  useEffect(() => {
    if (dadosFinalizar?.enderecoSelecionado || modoMesa) {
      setCupomLiberado(true);
    } else {
      setCupomLiberado(false);
    }
  }, [dadosFinalizar]);

  function validarCupom() {
    if (!cupom) {
      toast.info("Nenhum cupom foi informado.");
      return;
    }

    let valido = false;

    post("/fidelidade/cupom/validar", {
      "codigoCupom": cupom,
      "valorPedido": nota.valorTotal,
      "valorEntrega": dadosFinalizar.frete ? dadosFinalizar.frete : 0,
      "idCliente": dadosAuth.idCliente,
    })
      .then((response) => {
        if (response.data.valido) {
          atualizarDadosFinalizar({
            cupom: cupom,
            idCupom: response.data.idCupom,
            valorDesconto: response.data.desconto,
          });
          setCupomValido(true);
          setCupomInvalido(false);
          toast.success("Cupom validado com sucesso!");
        } else {
          toast.error("Cupom inválido.");
        }
      })
      .catch((error) => {
        console.log(error);
        setCupomInvalido(true);
        atualizarDadosFinalizar({
          cupom: null,
          valorDesconto: null,
        });
        toast.error(
          error?.data?.message ? error.data.message : "Erro ao validar cupom."
        );
      });
  }

  return (
    <ContainerFinalizar>
      {[
        { label: "Subtotal:", value: formatarReal(nota.valorSubTotal) },
        {
          label: "Desconto:",
          value:
            quantidadeItens > 0
              ? formatarReal(dadosFinalizar.valorDesconto)
              : "R$ 0,00",
        },
        {
          label: "Taxa de entrega:",
          value:
            quantidadeItens > 0
              ? formatarReal(dadosFinalizar?.frete || 0)
              : "R$ 0,00",
        },
        {
          label: "Total:",
          value:
            quantidadeItens > 0
              ? formatarReal(
                  parseFloat(nota?.valorTotal) +
                    parseFloat(dadosFinalizar?.frete || 0) -
                    parseFloat(dadosFinalizar?.valorDesconto || 0)
                )
              : "R$ 0,00",
          isTotal: true,
        },
      ].map(({ label, value, isTotal }, index) => (
        <Linha key={index}>
          {isTotal ? (
            <>
              <TextoVerde>{label}</TextoVerde>
              <TextoVerde>{value}</TextoVerde>
            </>
          ) : (
            <>
              <TextoPreto>{label}</TextoPreto>
              <TextoPreto>{value}</TextoPreto>
            </>
          )}
        </Linha>
      ))}
      <Cpf
        cpf={cpf}
        setCpf={setCpf}
        atualizarDadosFinalizar={atualizarDadosFinalizar}
      />
      <LinhaCupom>
        <CampoDeTextoOutlined
          style={{ width: 200 }}
          titulo={"Adicionar Cupom"}
          disabled={!cupomLiberado}
          sucesso={cupomValido}
          error={cupomInvalido}
          value={cupom}
          onChange={(event) => setCupom(event.target.value)}
        />
        <BotaoCardapioVazado
          tamanho={"MD"}
          style={
            largura < 1200
              ? {
                  width: 54,
                  height: 54,
                  minWidth: 54,
                  justifyContent: "center",
                }
              : { width: 200, height: 54 }
          }
          icone={
            largura < 1200 ? (
              <FontAwesomeIcon icon={faAdd} style={{ marginRight: "-10px" }} />
            ) : null
          }
          texto={largura >= 1200 ? "ADICIONAR CUPOM" : null}
          onClick={validarCupom}
        />
      </LinhaCupom>
    </ContainerFinalizar>
  );
}

function Cpf({ cpf, setCpf, atualizarDadosFinalizar }) {
  const [cpfNaNota, setCpfNaNota] = useState(false);
  const [cpfFormatado, setCpfFormatado] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (sucesso) {
      atualizarDadosFinalizar({ cpfNaNota: true, cpf: cpf });
    } else {
      atualizarDadosFinalizar({ cpfNaNota: false, cpf: null });
    }
  }, [sucesso, error]);

  useEffect(() => {
    if (!cpfNaNota) {
      setCpf("");
      setCpfFormatado("");
      setError(false);
      setSucesso(false);
    }
  }, [cpfNaNota]);

  function validarCPF(cpf) {
    if (!cpf) {
      setError(false);
      setSucesso(false);
      return "";
    }

    if (cpf.length < 14) {
      setError(true);
      setSucesso(false);
    } else {
      setError(false);
      setSucesso(true);
    }
    return cpf;
  }

  return (
    <Linha
      style={{
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <FormControlLabel
        value={cpfNaNota}
        onChange={(event) => {
          setCpfNaNota(!cpfNaNota);
        }}
        control={<Checkbox />}
        label="CPF na nota"
      />

      {cpfNaNota && (
        <CampoDeTextoOutlined
          style={{
            width: 250,
          }}
          error={error}
          sucesso={sucesso}
          value={cpfFormatado}
          titulo={"CPF"}
          onChange={(event) => {
            if (event.target.value.length > 14) return;
            setCpfFormatado(
              validarCPF(FormatarCPFCNPJTempoReal(event.target.value))
            );
          }}
          onBlur={(event) => {
            setCpf(cpfFormatado);
          }}
        />
      )}
    </Linha>
  );
}
