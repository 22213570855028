import React, { Fragment, useEffect, useRef, useState } from "react";
import BarraDeCategorias from "../../../../components/barraCategorias";
import CardapioHeader from "../../../../components/cardapio/header";
import {
  Dados,
  LinhaBotoes,
} from "../../../../components/informacoesRestaurante";
import { Rodape } from "../../../../components/rodape";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { Container } from "./styles";
import {
  listaCategoriasMock,
  listaComponentesMock,
  listaPromocoesDoDiaMock,
} from "./dadosMockados";
import PromocaoDoDia from "../../../../components/cardapio/promocao";
import CategoriasCorpo from "../../../../components/cardapio/categorias";
import SwitchCupons from "../../../../components/switchCupons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../../contexts/api";
import { useUserAuth } from "../../../../contexts/userAuth";
import { toast } from "react-toastify";
import { checarModoCardapio } from "../../../../utils/checarModoCardapio";
import { getUrlCardapios } from "../../../../utils/getUrlParaCadapios";

function CardapioItens({ textoPesquisa }) {
  const { post, get } = useApi();
  const { urlAmigavel, tipoCardapio, slugMesa } = useParams();
  const navigate = useNavigate();

  let { state } = useLocation();
  const dadosCardapio = state;

  const [promocoesDoDia, setPromocoesDoDia] = useState(listaPromocoesDoDiaMock);

  const [dadosMontagem, setDadosMontagem] = useState({});
  const [dadosMontagemFiltrados, setDadosMontagemFiltrados] = useState({});
  const [listaCategorias, setListaCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [complementos, setComplementos] = useState([]);

  const [modoCardapio, setModoCardapio] = useState();

  useEffect(() => {
    setModoCardapio(checarModoCardapio(tipoCardapio));
  }, [tipoCardapio]);

  useEffect(() => {
    async function getDados() {
      let requestCardapio = await get(
        "/configuracoes-lojas/menu/buscar-cardapio-completo/" +
          dadosCardapio?.id
      )
        .then((response) => {
          response.data?.listaCardapioMenus?.sort((a, b) => {
            return (
              a.horariosCardapioMenuDTO?.infoHorarioCardapioMenuDTO
                ?.indiceCardapioMenu -
              b.horariosCardapioMenuDTO?.infoHorarioCardapioMenuDTO
                ?.indiceCardapioMenu
            );
          });
          setDadosMontagem(response.data);
          const listaCategorias = [];
          response.data?.listaCardapioMenus?.forEach((item) => {
            if (!item.horariosCardapioMenuDTO?.infoHorarioCardapioMenuDTO) {
              return;
            }
            listaCategorias.push({
              descricao:
                item.horariosCardapioMenuDTO?.infoHorarioCardapioMenuDTO
                  ?.descricaoCardapioMenu,
              id: item.horariosCardapioMenuDTO?.infoHorarioCardapioMenuDTO
                ?.idCardapioMenu,
            });
          });
          setListaCategorias(listaCategorias);
        })
        .catch((error) => {
          toast.error("Erro ao buscar cardápio");
          console.log(error);
        });
      let requestComplementos = await get(
        "/produtos/menu/buscar-todos-menus-waymenu"
      )
        .then((responseComplementos) => {
          setComplementos(responseComplementos.data.content);
        })
        .catch((error) => {
          toast.error("Erro ao buscar complementos do cardápio");
          console.log(error);
        });

      Promise.allSettled([requestCardapio, requestComplementos]).then(() => {
        setLoading(false);
      });
    }

    if (dadosCardapio?.id) {
      getDados();
    } else {
      let urlCardapios = getUrlCardapios(urlAmigavel, tipoCardapio, slugMesa);
      navigate(urlCardapios);
    }
  }, [dadosCardapio]);

  useEffect(() => {
    filtrarProdutos(textoPesquisa);
  }, [textoPesquisa, dadosMontagem]);

  function filtrarProdutos(texto) {
    if (texto && texto.length > 0) {
      const dadosFiltrados = {
        listaCardapioMenus: [],
      };

      dadosMontagem?.listaCardapioMenus?.map((categoria) => {
        let categoriaFiltrada = { ...categoria };
        categoriaFiltrada.listaProdutosCardapio = [];
        categoria.listaProdutosCardapio.forEach((produto) => {
          if (
            produto.descricao.toLowerCase().includes(texto.toLowerCase()) ||
            produto.descricaoAbreviada
              .toLowerCase()
              .includes(texto.toLowerCase())
          ) {
            categoriaFiltrada.listaProdutosCardapio.push(produto);
          }
        });
        if (categoriaFiltrada.listaProdutosCardapio.length > 0) {
          dadosFiltrados.listaCardapioMenus.push(categoriaFiltrada);
        }
      });
      setDadosMontagemFiltrados(dadosFiltrados);
    } else {
      setDadosMontagemFiltrados(dadosMontagem);
    }
  }

  //////////////////////////////////Retorno//////////////////////////////////

  console.log(dadosCardapio);
  return (
    <Container>
      <BarraDeCategorias categorias={listaCategorias} />
      {modoCardapio || true ? (
        <div style={{ height: 24 }} />
      ) : (
        <PromocaoDoDia promocoes={promocoesDoDia} />
      )}
      <CategoriasCorpo
        dadosMontagem={dadosMontagemFiltrados}
        loading={loading}
        complementos={complementos}
        tipoLayout={
          dadosCardapio?.tipoLayout ? dadosCardapio?.tipoLayout : "REDUZIDO"
        }
      />
      <Rodape />
      <SwitchCupons cardapio={true} />
    </Container>
  );
}

export default CardapioItens;
