import { assegurarValor } from "./gerarObjetosEscolhasCarrinho";

export const calcularAdicionais = (
  produto,
  { calcularQuantidade = false } = {}
) => {
  let total = 0;
  if (produto.tipo == "COMBINADO") {
    produto.itens?.forEach((item) => {
      item?.adicionais?.forEach((adicional) => {
        total += assegurarValor(adicional.precoTotal, 0);
      });
      item?.menus?.forEach((menu) => {
        menu?.adicionais?.forEach((adicional) => {
          total += assegurarValor(adicional.precoTotal, 0);
        });
      });
    });
    produto.menus?.forEach((menu) => {
      menu?.adicionais?.forEach((adicional) => {
        total += assegurarValor(adicional.precoTotal, 0);
      });
    });
  } else {
    produto.menus?.forEach((menu) => {
      menu?.adicionais?.forEach((adicional) => {
        total += assegurarValor(adicional.precoTotal, 0);
      });
    });
  }

  if (calcularQuantidade) {
    return assegurarValor(total, 0) * assegurarValor(produto.quantidade, 1);
  } else {
    return assegurarValor(total, 0);
  }
};
