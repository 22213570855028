import React, { Component, Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faMinus,
  faPlus,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import {
  PopupContainerTag,
  PopupTag,
  PopupContainer,
  PopupHeader,
  PopupConteudo,
  BotaoDeFechar,
  TextoSobre,
  CampoObservacao,
  ContainerQuantidadePedido,
  BotaoQuantidadePedido,
  InputQuantidadePedido,
  ContainerBotaoAdicionarAoCarrinho,
  Grades,
  ContainerGrades,
  ContainerBotaoSemEstoque,
  CampoObservacoes,
} from "./styles";
import ErroImagem from "../../../assets/images/img-erro.png";

import { Escolha, Adicionar } from "../escolhaExpansivel";
import { useApi } from "../../../contexts/api";
import { useUserAuth } from "../../../contexts/userAuth";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { useParams } from "react-router-dom";
import { checarModoCardapio } from "../../../utils/checarModoCardapio";
import { useCarrinho } from "../../../contexts/carrinho";
import ModalLogin from "../../modalLogin";
import formatarReal from "../../../utils/formatarReal";

export const ModalProduto = ({ produto, close }) => {
  const { get } = useApi();
  const { dadosEmpresa, urlBucket, logado, modoMesa } = useUserAuth();
  const { adicionarItem } = useCarrinho();
  const tema = useTheme();
  const { tipoCardapio } = useParams();

  const [modoCardapio, setModoCardapio] = useState();

  const [gradeSelecionada, setGradeSelecionada] = useState(null);
  const [complementos, setComplementos] = useState(null);
  const [valorTotal, setValorTotal] = useState(null);
  const [valorTotalSomado, setValorTotalSomado] = useState(null);
  const [valorComplementos, setValorComplementos] = useState({});
  const [quantidadePedido, setQuantidadePedido] = useState(
    produto.fracionamento ? null : 1
  );
  const [escolhasFeitas, setEscolhasFeitas] = useState({});
  const [dadosEscolhas, setDadosEscolhas] = useState({});
  const [botaoDesativado, setBotaoDesativado] = useState(true);
  const [observacoes, setObservacoes] = useState("");

  const [loading, setLoading] = useState(true);
  const [loadingFinalizar, setLoadingFinalizar] = useState(false);
  const [abrirLogin, setAbrirLogin] = useState(false);

  const [imagemSrc, setImagemSrc] = useState(
    produto.imagem ? produto.imagem : ErroImagem
  );
  function verificarEstoque() {
    if (produto.estocado) {
      if (produto.estoque > 0) {
        return "CONTEM_ESTOQUE";
      }
      return "SEM_ESTOQUE";
    }

    return "NAO_CONTROLA";
  }

  function atualizarObservacoes(event) {
    let texto = event.target.value;

    if (texto.length <= 150) {
      setObservacoes(event.target.value);
    }
  }

  useEffect(() => {
    let imgUrl = `${urlBucket}/imagens/PRODUTO_GRADE_IMAGEM/${produto.imagem}`;
    const img = new Image();
    img.onload = () => setImagemSrc(imgUrl); // Imagem carregada com sucesso
    img.onerror = () => setImagemSrc(ErroImagem); // Falha ao carregar, usar imagem de erro
    img.src = imgUrl;
  }, [produto]);

  useEffect(() => {
    setModoCardapio(checarModoCardapio(tipoCardapio));
  }, [tipoCardapio]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setLoading(true);
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    get(
      `produtos/menu-componente/buscar-vinculo-produto/${dadosEmpresa.idEmpresa}/${produto.idProdutoEmpresa}`
    )
      .then((response) => {
        let complementosOrdenados = response.data.sort((a, b) => {
          return a.produtoMenu.indice - b.produtoMenu.indice;
        });

        setComplementos(complementosOrdenados);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao buscar complementos do produto");
        close();
      });
  }, []);

  useEffect(() => {
    if (complementos !== null) {
      setLoading(false);
    }
  }, [complementos]);

  useEffect(() => {
    //Cálculo do Valor

    if (gradeSelecionada) {
      let valorProduto = gradeSelecionada.precoVenda;

      let valorTotalComplementosCalculado = Object.values(
        valorComplementos
      ).reduce((total, valor) => (valor ? total + valor : total), 0);

      setValorTotal(valorProduto * quantidadePedido);
      setValorTotalSomado(
        (valorTotalComplementosCalculado + valorProduto) * quantidadePedido
      );
    }
  }, [valorComplementos, quantidadePedido, gradeSelecionada]);

  function updateValorComplemento(valor, complemento) {
    let novosValores = { ...valorComplementos };

    if (valor) {
      novosValores[
        complemento.produtoMenu.id + complemento.produtoMenu.descricao
      ] = parseFloat(valor);
    } else {
      delete novosValores[
        complemento.produtoMenu.id + complemento.produtoMenu.descricao
      ];
    }

    setValorComplementos(novosValores);
  }
  function atribuirProximoId(totalItens, index, proximoItemFinal) {
    let indexMap = 0;

    if (index + 1 < totalItens) {
      indexMap = index + 1;
    } else {
      indexMap = proximoItemFinal;
    }

    return indexMap;
  }

  useEffect(() => {
    if (
      Object.values(escolhasFeitas).includes(false) ||
      quantidadePedido <= 0 ||
      !gradeSelecionada
    ) {
      setBotaoDesativado(true);
    } else {
      setBotaoDesativado(false);
    }
  }, [escolhasFeitas, quantidadePedido, gradeSelecionada]);

  useEffect(() => {
    if (produto.grades.length == 1) {
      setGradeSelecionada(produto.grades[0]);
    }
  }, [produto]);

  function atualizarObservacao(event) {
    let texto = event.target.value;

    if (texto.length <= 150) {
      setObservacoes(event.target.value);
    }
  }

  async function finalizar(dados) {
    if (botaoDesativado || loadingFinalizar) {
      return;
    }

    if (!logado && !modoMesa) {
      setAbrirLogin(true);
      return;
    }

    setLoadingFinalizar(true);
    let item = {
      ...produto,
      gradeSelecionada: gradeSelecionada,
      dadosPedido: dados,
    };

    try {
      await adicionarItem(item);
      setLoadingFinalizar(false);
      close();
    } catch (error) {
      console.log(error);
      setLoadingFinalizar(false);
      toast.error("Erro ao adicionar produto ao carrinho!");
    }
  }

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{ color: tema.coresCardapio.brand500 }}
        style={{ alignSelf: "center" }}
      />
    </div>
  ) : (
    <PopupContainer>
      <PopupHeader url={imagemSrc}>
        <h1>{produto.descricaoAbreviada}</h1>
        <BotaoDeFechar onClick={close}>
          <FontAwesomeIcon icon={faX} />
        </BotaoDeFechar>
      </PopupHeader>
      <PopupConteudo>
        {produto.detalhes && (
          <TextoSobre>
            <strong>Sobre: </strong>
            {produto.detalhes}
          </TextoSobre>
        )}
        <PopupContainerTag>
          {produto.glutem && (
            <PopupTag key={"tagGluten"}>Contém Gluten</PopupTag>
          )}
          {produto.lactose && (
            <PopupTag key={"tagLactose"}>Contém Lactose</PopupTag>
          )}
          {produto.proibidoMenor && (
            <PopupTag key={"tagProibido"}>Proibido para menores</PopupTag>
          )}
        </PopupContainerTag>
        {produto.grades.length > 1 && (
          <ContainerGrades>
            {produto.grades.map(
              (grade, index) =>
                index !== 0 && (
                  <ItemGrade
                    key={grade.idGradeEmpresaPreco + "gradeProduto" + index}
                    grade={grade}
                    gradeSelecionada={gradeSelecionada}
                    setGradeSelecionada={setGradeSelecionada}
                  />
                )
            )}
          </ContainerGrades>
        )}
        {complementos?.map((complemento, index) => {
          if (complemento.produtoMenu.quantidadeMaxima > 1) {
            return (
              <div id={"complemento" + index} key={"complemento" + index}>
                <Adicionar
                  idProximo={atribuirProximoId(
                    complementos.length,
                    index,
                    "itemFinal"
                  )}
                  key={
                    "adicionar" +
                    complemento.produtoMenu.id +
                    complemento.produtoMenu.descricao
                  }
                  aberto={true}
                  complemento={complemento}
                  updateValorComplemento={updateValorComplemento}
                  escolhasFeitas={escolhasFeitas}
                  setEscolhasFeitas={setEscolhasFeitas}
                  setDadosEscolhas={setDadosEscolhas}
                  index={index}
                  modoCardapio={modoCardapio}
                  produto={produto}
                />
              </div>
            );
          } else if (complemento.produtoMenu.quantidadeMaxima == 1) {
            return (
              <div id={"complemento" + index} key={"complemento" + index}>
                <Escolha
                  idProximo={atribuirProximoId(
                    complementos.length,
                    index,
                    "itemFinal"
                  )}
                  key={"escolha" + complemento.id + complemento.descricao}
                  aberto={true}
                  complemento={complemento}
                  updateValorComplemento={updateValorComplemento}
                  escolhasFeitas={escolhasFeitas}
                  setEscolhasFeitas={setEscolhasFeitas}
                  setDadosEscolhas={setDadosEscolhas}
                  modoCardapio={modoCardapio}
                  produto={produto}
                />
              </div>
            );
          }
        })}
        {!modoCardapio && (
          <Fragment>
            <div>
              {verificarEstoque() !== "SEM_ESTOQUE" && (
                <CampoObservacoes
                  onChange={atualizarObservacoes}
                  value={observacoes}
                  helperText={`${observacoes.length}/150`}
                  label="Observações"
                />
              )}
            </div>

            {produto.fracionamento ? (
              <QuantidadePedidoFracionado
                quantidadePedido={quantidadePedido}
                setQuantidadePedido={setQuantidadePedido}
                unidade={produto.unidadeMedida}
              />
            ) : (
              <QuantidadePedido
                quantidadePedido={quantidadePedido}
                setQuantidadePedido={setQuantidadePedido}
                produto={produto}
              />
            )}

            <BotaoAdicionarAoCarrinho
              id="complementoitemFinal"
              desativado={botaoDesativado}
              loading={loadingFinalizar}
              total={valorTotalSomado}
              dados={{
                valorTotal,
                valorTotalSomado,
                valorComplementos,
                quantidadePedido,
                escolhasFeitas,
                observacoes,
                dadosEscolhas,
                produto,
              }}
              produto={produto}
              quantidadePedido={quantidadePedido}
              finalizar={finalizar}
            />
          </Fragment>
        )}
      </PopupConteudo>
      {!logado && (
        <ModalLogin
          aberto={abrirLogin}
          setAberto={setAbrirLogin}
          loginGoogle={true}
          etapaLogin={"login"}
        />
      )}
    </PopupContainer>
  );
};

export const QuantidadePedido = ({
  quantidadePedido,
  setQuantidadePedido,
  produto,
}) => {
  const [quantidade, setQuantidade] = useState(quantidadePedido);

  function verificarEstoque() {
    if (produto.estocado) {
      if (produto.estoque > 0) {
        return "CONTEM_ESTOQUE";
      }
      return "SEM_ESTOQUE";
    }

    return "NAO_CONTROLA";
  }

  useEffect(() => {
    setQuantidadePedido(quantidade);
  }, [quantidade]);

  const salvarNumero = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    setQuantidade(result !== "" ? parseInt(result) : 0);
  };

  function adicionar() {
    setQuantidade(parseInt(quantidade + 1));
  }

  function subtrair() {
    if (quantidade > 1) {
      setQuantidade(parseInt(quantidade - 1));
    }
  }

  return (
    (verificarEstoque() === "CONTEM_ESTOQUE" ||
      verificarEstoque() === "NAO_CONTROLA") && (
      <div
        style={{ display: "flex", flexDirection: "column", placeItems: "end" }}
      >
        <ContainerQuantidadePedido>
          Quantidade do pedido
          <div
            style={{ display: "flex", flexDirection: "row", height: "100%" }}
          >
            <BotaoQuantidadePedido
              disabled={verificarEstoque() === "SEM_ESTOQUE"}
              onClick={() => {
                subtrair();
              }}
            >
              <FontAwesomeIcon icon={faMinus} style={{ fontSize: "22px" }} />
            </BotaoQuantidadePedido>
            <InputQuantidadePedido
              disabled={
                verificarEstoque() === "SEM_ESTOQUE" ||
                (verificarEstoque() === "CONTEM_ESTOQUE" &&
                  produto.estoque <= quantidade)
              }
              value={quantidade}
              onChange={(event) => {
                salvarNumero(event);
              }}
            />
            <BotaoQuantidadePedido
              disabled={
                verificarEstoque() === "SEM_ESTOQUE" ||
                (verificarEstoque() === "CONTEM_ESTOQUE" &&
                  produto.estoque <= quantidade)
              }
              onClick={() => {
                adicionar();
              }}
            >
              <FontAwesomeIcon icon={faPlus} style={{ fontSize: "22px" }} />
            </BotaoQuantidadePedido>
          </div>
        </ContainerQuantidadePedido>
        {/* <p>Você atingiu a quantidade máxima de produtos</p> */}
      </div>
    )
  );
};

export const QuantidadePedidoFracionado = ({
  quantidadePedido,
  setQuantidadePedido,
  unidade,
}) => {
  const [quantidade, setQuantidade] = useState(quantidadePedido);

  useEffect(() => {
    setQuantidadePedido(quantidade);
  }, [quantidade]);

  function salvarNumero(event) {
    const valorFormatado = formatarValor(event.target.value);
    setQuantidade(valorFormatado);
  }

  function formatarValor(valorInput) {
    // Remove qualquer caractere que não seja número
    let valor = valorInput.replace(/[^0-9]/g, "");

    // Se o valor for zero ou qualquer variação de zero, retorna null
    if (parseFloat(valor) === 0) {
      return "";
    }

    // Separa a parte inteira da parte decimal
    let inteira = valor.slice(0, -3) || "0";
    let decimal = valor.slice(-3).padStart(3, "0");

    // Remove zeros à esquerda da parte inteira
    inteira = inteira.replace(/^0+/, "") || "0";

    return `${inteira}.${decimal}`;
  }

  return (
    <ContainerQuantidadePedido>
      Quantidade do pedido
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <InputQuantidadePedido
          style={{ width: "100px" }}
          value={quantidade}
          placeholder="0.000"
          onChange={(event) => {
            salvarNumero(event);
          }}
        />
        <h3>{unidade}</h3>
      </div>
    </ContainerQuantidadePedido>
  );
};

export const BotaoAdicionarAoCarrinho = ({
  total,
  desativado,
  dados,
  id,
  finalizar,
  loading,
  produto,
  quantidadePedido,
}) => {
  console.log(desativado);

  function formatarReal(valor) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(valor);
  }

  function verificarEstoque() {
    if (produto.estocado) {
      if (produto.estoque > 0) {
        return "CONTEM_ESTOQUE";
      }
      return "SEM_ESTOQUE";
    }
    return "NAO_CONTROLA";
  }
  return verificarEstoque() !== "SEM_ESTOQUE" ? (
    <ContainerBotaoAdicionarAoCarrinho
      id={id}
      $desativado={
        verificarEstoque() === "SEM_ESTOQUE" ||
        (verificarEstoque() === "CONTEM_ESTOQUE" &&
          produto.estoque < quantidadePedido) ||
        desativado
      }
      onClick={() => {
        finalizar(dados);
      }}
      disabled={
        verificarEstoque() === "SEM_ESTOQUE" ||
        (verificarEstoque() === "CONTEM_ESTOQUE" &&
          produto.estoque < quantidadePedido) ||
        desativado
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {loading ? (
          <CircularProgress
            size={20}
            sx={{ color: "#fff" }}
            style={{ alignSelf: "center", marginRight: 8 }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCartPlus}
            style={{ fontSize: "18px", marginRight: 10 }}
          />
        )}
        ADICIONAR
      </div>
      {formatarReal(total)}
    </ContainerBotaoAdicionarAoCarrinho>
  ) : (
    <ContainerBotaoSemEstoque
      id={id}
      $desativado={desativado}
      disabled={
        verificarEstoque() === "SEM_ESTOQUE" ||
        (verificarEstoque() === "CONTEM_ESTOQUE" &&
          produto.estoque >= quantidadePedido)
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        PRODUTO EM FALTA
      </div>
    </ContainerBotaoSemEstoque>
  );
};

const ItemGrade = ({ grade, gradeSelecionada, setGradeSelecionada }) => {
  function selecionarTamanho() {
    setGradeSelecionada(grade);
  }

  return (
    grade.ativoGradeEmpresaPreco && (
      <Grades
        $ativo={
          gradeSelecionada &&
          gradeSelecionada.idGradeEmpresaPreco === grade.idGradeEmpresaPreco
        }
        onClick={() => selecionarTamanho()}
      >
        <p>{grade.descricao}</p>
        <p className="valor">{formatarReal(grade.precoVenda)}</p>
        {/* {item.valorPromocional && (
          <p className="riscado">{formatarReal(item.valorPromocional)}</p>
        )} */}
      </Grades>
    )
  );
};

export default ModalProduto;
