import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BotaoProximo,
  BotaoIcone,
  Container,
  ContainerBotaoProximo,
  ContainerIcone,
  ContainerInputs,
  ContainerTitulo,
  Input,
  LinhaTitulo,
  TextoBotaoProximo,
  Titulo,
} from "./styles.js";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export function CadastroWaypag() {
  return (
    <Container>
      <ContainerIcone>
        <BotaoIcone>
          <FontAwesomeIcon icon={faArrowLeft} />
        </BotaoIcone>
      </ContainerIcone>
      <ContainerTitulo>
        <Titulo>Cadastro</Titulo>
        <LinhaTitulo />
      </ContainerTitulo>

      <ContainerInputs>
        <Input placeholder="Nome" type="text" required />
        <Input placeholder="CPF (Opcional)" type="text" required />
        <Input placeholder="Celular" type="text" required />
        <Input placeholder="Email" type="text" required />
      </ContainerInputs>
      <ContainerBotaoProximo>
        <BotaoProximo>
          <TextoBotaoProximo>PRÓXIMO</TextoBotaoProximo>
        </BotaoProximo>
      </ContainerBotaoProximo>
    </Container>
  );
}
