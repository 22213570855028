import styled from "@emotion/styled";
import { FormControl, TextField } from "@mui/material/";

export const CampoDeTextoBase = styled(TextField)`
  width: 100%;
  height: 56px;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "12px;")};
    ${(props) => props.value != "" && `padding-top: 7px;`}
  }

  input {
    padding-left: 2px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  div {
    height: 56px;
  }
`;

export const CampoDeTextoBaseOutlined = styled(TextField)`
  width: 100%;
  height: 56px;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .MuiFormLabel-root {
    padding: 0px;
  }

  div {
    height: 56px;
  }
`;

export const FormularioCustomizado = styled(FormControl)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    padding-left: ${(props) => (props.value != "" ? `16px;` : "12px;")};
    ${(props) => props.value != "" && `padding-top: 7px;`}
  }

  input {
    padding-left: 12px;
    padding-top: 19px;
  }

  &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }
`;

export const FormularioCustomizadoOutlined = styled(FormControl)`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  label {
    /* padding-left: ${(props) => (props.value != "" ? `16px;` : "12px;")}; */
    /* ${(props) => props.value != "" && `padding-top: 7px;`} */
  }

  input {
    padding-left: 12px;
    /* padding-top: 19px; */
  }

  &:has(input:focus) label {
    /* padding-top: 7px; */
    /* padding-left: 16px; */
    background-color: white;
    /* width: 40px; */
    padding: 0px 6px;
  }

  .MuiFormLabel-root {
    /* padding-top: 0px;
    padding-bottom: 0px; */
    padding: 0px;
    color: ${({ $error }) =>
      $error ? "#d32f2f" : "rgba(0, 0, 0, 0.6)"}!important;
  }

  .MuiInputBase-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .MuiInputLabel-shrink {
    background-color: #fff;
    padding: 0px 5px;
    color: ${({ $error }) => ($error ? "red" : "rgba(0, 0, 0, 0.6)")};
  }

  .MuiInputLabel-shrink.Mui-focused {
    color: ${({ $error, $sucesso }) =>
      $error ? "red" : $sucesso ? "#2e7d32" : "rgba(240, 148, 0, 1)"};
  }
`;

export const FormularioCustomizadoSelecao = styled(FormControl)`
  width: 100%;

  background-color: ${({ theme }) =>
    theme.temaDarkAtivo
      ? String(theme.cores.neutral100).replace("1)", "0.2)")
      : "transparent"};
  /* border-top-left-radius: 4px;
  border-top-right-radius: 4px; */

  /* label {
    padding-left: ${(props) =>
    props.value != "" || props.value != null ? `12px;` : "16px;"};
    padding-top: 6px;
  } */

  input {
    padding-left: 12px;
    /* padding-top: 19px; */
  }

  /* &:has(input:focus) label {
    padding-top: 7px;
    padding-left: 16px;
  }

  .MuiSvgIcon-root {
    color: ${(props) => (props.cor != null ? `rgb(${props.cor})` : null)};
    margin-right: 12px;
  }

  height: 56px;

  div {
    height: 56px;
    padding-top: 12px;
  }

  .Mui-disabled {
    cursor: not-allowed;
    opacity: 0.7;
  } */

  &:has(input:focus) label {
    /* padding-top: 7px; */
    /* padding-left: 16px; */
    background-color: white;
    /* width: 40px; */
    padding: 0px 6px;
  }

  .MuiFormLabel-root {
    /* padding-top: 0px;
    padding-bottom: 0px; */
    padding: 0px;
  }

  .MuiInputBase-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .MuiInputLabel-shrink {
    background-color: #fff;
    padding: 0px 5px;
    color: ${({ $error }) => ($error ? "red" : "rgba(0, 0, 0, 0.6)")};
  }

  .MuiInputLabel-shrink.Mui-focused {
    color: ${({ $error, $sucesso }) =>
      $error ? "red" : $sucesso ? "#2e7d32" : "rgba(240, 148, 0, 1)"};
  }
`;
