import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 95px;
  width: 100vw;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;

  @media (max-width: 768px) {
    height: 70px;
    z-index: 1;
  }
`;

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 335px;
`;

export const BotaoMeuCarrinhoContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  height: 100%;
  margin-left: 1rem;
  background-color: ${(props) => (props.$logado ? "#f09400" : "transparent")};
  border: 1px solid ${(props) => (props.$logado ? "transparent" : "#f09400")};

  padding: 0 10px;
  height: 65px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in, border 0.3s ease-in,
    color 0.3s ease-in;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 6px 1px #f09400;
    background-color: ${(props) => (props.$logado ? "#f09400" : "#f09400")};

    p,
    svg {
      color: #fff;
    }
  }

  p {
    color: ${(props) => (props.$logado ? "#fff" : "#f09400")};
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.25px;
  }

  @media (max-width: 990px) {
    width: 85%;
    margin-top: 16px;
    height: 45px;
  }
`;

export const BotaoEntrar = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  margin-left: 1rem;
  background-color: #f09400;
  padding: 0 20px;
  border: none;
  height: 65px;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    box-shadow: 0px 0px 6px 1px #f09400;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.25px;
  }
`;

export const BotaoEntrarMobile = styled(BotaoEntrar)`
  width: 85%;
  height: 45px;
`;

export const BotaoMenuUsuario = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  overflow: hidden;
  cursor: pointer;
`;

export const ContainerMenuUsuario = styled.nav`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: lighter;
    text-align: center;
    font-size: 24px;
    text-overflow: ellipsis;
    margin: 10px 0;
  }

  ul {
    margin-top: 5px;
  }
`;

export const ContainerOpcaoMenu = styled.div`
  height: 55px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 20px 0 20px;
  border-top: 0.5px solid #cccc;
  justify-content: flex-start;

  li {
    list-style-type: none;
    font-size: 16;
  }
`;

export const ContainerBuscarProduto = styled.div`
  width: 250px;
  border: 1px solid #f09400;
  border-radius: 8px;
  padding: 0 10px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    border: none;
    width: 100%;
    font-size: 16px;
    color: #f09400;
    font-weight: 500;
    outline: none;
  }
`;
