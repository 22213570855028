import styled from "styled-components";

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Botao = styled.button`
  cursor: pointer;
  border: none;
  flex-direction: row;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  font-weight: 700;
`;

export const BotaoBaseCardapioVazado = styled(Botao)`
  color: ${(props) => props.theme.coresCardapio.brand500};
  fill: ${(props) => props.theme.coresCardapio.brand500};
  background-color: transparent;
  border: ${(props) => `1px solid ${props.theme.coresCardapio.brand500}`};

  padding-top: 5px;
  padding-bottom: 5px;

  &:hover {
    background-color: ${(props) => props.theme.coresCardapio.brand500};
    color: white;
    fill: white;
    border: ${(props) => `1px solid ${props.theme.coresCardapio.brand500}`};
    box-shadow: 0px 0px 4px 2px ${(props) => props.theme.coresCardapio.brand500};
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out,
      box-shadow 0.15s ease-in-out, color 0.3s ease-in-out;
  }
`;

export const BotaoBaseCardapioPreenchido = styled(Botao)`
  color: #fff;
  fill: #fff;
  background-color: ${(props) => props.theme.coresCardapio.brand500};
  border: ${(props) => `1px solid ${props.theme.coresCardapio.brand500}`};
  padding-top: 5px;
  padding-bottom: 5px;
  &:hover {
    background-color: ${(props) => props.theme.coresCardapio.brand500};
    border: ${(props) => `1px solid ${props.theme.coresCardapio.brand500}`};
    box-shadow: 0px 0px 4px 2px ${(props) => props.theme.coresCardapio.brand500};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: ${(props) => props.theme.cores.surfaceActionDisabled};
    color: ${(props) => props.theme.cores.textActionDisabled};
    fill: ${(props) => props.theme.cores.textActionDisabled};
    border: none;
    box-shadow: none;
    font-family: Source Sans Pro;
    font-weight: 400;
  }
`;

export const BotaoBaseCardapioPreenchidoVerde = styled(Botao)`
  color: #fff;
  fill: #fff;
  background-color: ${(props) => props.theme.coresCardapio.verdeBotao};
  border: ${(props) => `1px solid ${props.theme.coresCardapio.verdeBotao}`};
  padding-top: 5px;
  padding-bottom: 5px;
  &:hover {
    background-color: ${(props) => props.theme.coresCardapio.verdeBotao};
    border: ${(props) => `1px solid ${props.theme.coresCardapio.verdeBotao}`};
    box-shadow: 0px 0px 4px 2px
      ${(props) => props.theme.coresCardapio.verdeBotao};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;

export const ColunaCopiarCupom = styled.div`
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  svg {
    fill: ${({ theme }) => theme.coresCardapio.verdeBotao};
  }
  p {
    color: ${({ theme }) => theme.coresCardapio.brand500};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media (max-width: 768px) {
    gap: 16px;
    width: 80%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const LinhaCopiarCupom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.coresCardapio.verdeBotao};
  border-radius: 50px;
  margin-top: 10px;
  p {
    width: 100%;
    color: ${({ theme }) => theme.coresCardapio.verdeBotao};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
`;

export const BotaoBaseAzulPreenchido = styled(Botao)`
  color: ${(props) => props.theme.cores.neutral100brand80};
  fill: ${(props) => props.theme.cores.neutral100brand80};
  background-color: ${(props) => props.theme.cores.actionActive};
  border: ${(props) => `1px solid ${props.theme.cores.actionActive}`};
  &:hover {
    background-color: ${(props) =>
      props.theme.temaDarkAtivo
        ? props.theme.cores.actionHover
        : props.theme.cores.brand40Default};
    color: ${(props) => props.theme.cores.brand80};
    fill: ${(props) => props.theme.cores.brand80};
    border: ${(props) => `1px solid ${props.theme.cores.brand40Default}`};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out,
      color 0.3s ease-in-out;
  }
`;

export const BotaoBaseAzulVazado = styled(Botao)`
  color: ${(props) => props.theme.cores.actionActive};
  fill: ${(props) => props.theme.cores.semanticDanger2};
  background-color: ${(props) =>
    props.theme.temaDarkAtivo ? "transparent" : props.theme.cores.neutral100};
  border: ${(props) => `1px solid ${props.theme.cores.actionActive}`};

  &:hover {
    background-color: ${(props) =>
      props.theme.temaDarkAtivo
        ? props.theme.cores.actionHover
        : props.theme.cores.brand40Default};
    color: ${(props) => props.theme.cores.brand80};
    fill: ${(props) => props.theme.cores.brand80};
    border: ${(props) => `1px solid ${props.theme.cores.brand40Default}`};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out,
      color 0.3s ease-in-out;
  }
`;

export const BotaoBaseVermelhoVazado = styled(Botao)`
  color: ${(props) => props.theme.cores.semanticDanger2};
  fill: ${(props) => props.theme.cores.semanticDanger2};
  background-color: ${(props) =>
    props.theme.temaDarkAtivo ? "transparent" : props.theme.cores.neutral100};
  border: ${(props) => `1px solid ${props.theme.cores.semanticDanger2}`};
  margin-right: 20px;

  &:hover {
    background-color: ${(props) => props.theme.cores.semanticDanger3};
    color: ${(props) =>
      props.theme.temaDarkAtivo
        ? props.theme.cores.neutral100
        : props.theme.cores.semanticDanger2};
    fill: ${(props) =>
      props.theme.temaDarkAtivo
        ? props.theme.cores.neutral100
        : props.theme.cores.semanticDanger2};
    border: ${(props) => `1px solid ${props.theme.cores.semanticDanger3}`};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out;
  }
`;

export const Icone = styled.div`
  margin-right: 10px;
  fill: inherit;
  color: inherit;
`;

export const BotaoBaseVermelhoPreenchido = styled(Botao)`
  color: ${(props) => props.theme.adaptativo("neutral100", "semanticDanger3")};
  fill: ${(props) => props.theme.cores.neutral100};
  background-color: ${(props) =>
    props.theme.adaptativo("semanticDanger2", "semanticDanger2")};
  border: ${(props) =>
    `1px solid ${props.theme.adaptativo(
      "semanticDanger1",
      "semanticDanger3"
    )}`};
  &:hover {
    color: ${(props) =>
      props.theme.adaptativo("semanticDanger1", "neutral100")};
    fill: ${(props) => props.theme.adaptativo("semanticDanger1", "neutral100")};
    border: ${(props) =>
      `1px solid ${props.theme.adaptativo(
        "semanticDanger3",
        "semanticDanger3"
      )}`};
    background-color: ${(props) =>
      props.theme.adaptativo("semanticDanger3", "semanticDanger3")};
    transition: background-color 0.3s ease-in-out, border 0.2s ease-in-out,
      color 0.3s ease-in-out;
  }

  &:active {
    background-color: ${(props) => props.theme.cores.semanticDanger1};
    color: ${(props) => props.theme.cores.neutral100};
    fill: ${(props) => props.theme.cores.neutral100};
    border: ${(props) => `1px solid ${props.theme.cores.semanticDanger1}`};
  }
`;
