import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../../contexts/api";
import { useTheme } from "styled-components";
import useTamanhoTela from "../../../../utils/useTamanhoTela";
import { useCarrinho } from "../../../../contexts/carrinho";
import {
  BotaoCardapioVazado,
  BotaoVermelhoPreenchido,
  BotaoVermelhoVazado,
} from "../../../../components/botao";
import {
  CardContador,
  CardInternoBranco,
  ContainerProduto,
  Divider,
  InputQuantidadePedido,
  Linha,
  LinhaCupom,
  LinhaRegistro,
  TextoBranco,
  TextoLaranjaPedido,
  TextoPreto,
  TextoPretoLeve,
  TextoPretoLevePedido,
  TextoPretoPedido,
  TextoVerde,
  TextoVermelho,
} from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faMinus,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import formatarReal from "../../../../utils/formatarReal";
import { DadosPedido } from "./dadosPedido";
import GerarModalResponsivo from "../../../../components/gerarModalResponsivo";
import { assegurarValor } from "../../../../utils/gerarObjetosEscolhasCarrinho";
import { calcularAdicionais } from "../../../../utils/calcularAdicionaisItemCarrinho";
import { getUrlCardapios } from "../../../../utils/getUrlParaCadapios";

export function ItensCarrinho({ atualizarDadosFinalizar, dadosFinalizar }) {
  const navigate = useNavigate();
  const { post, get, put, del } = useApi();
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  const { produtos, nota, atualizarCarrinho, limparCarrinho, quantidadeItens } =
    useCarrinho();
  const { urlAmigavel, tipoCardapio, slugMesa } = useParams();
  function redirecionar() {
    let urlCardapio = getUrlCardapios(urlAmigavel, tipoCardapio, slugMesa);
    navigate(urlCardapio);
  }
  return (
    <Fragment>
      {produtos.map((produto, index) => (
        <CardProduto key={index} produto={produto} />
      ))}
      <Linha>
        {!(nota?.id || quantidadeItens > 0) ? <></> : <LimparCarrinho />}
      </Linha>
      <BotaoCardapioVazado
        style={{
          width: "100%",
          height: 54,
          marginTop: 16,
          marginBottom: 16,
        }}
        tamanho={"MD"}
        texto={"CONTINUAR COMPRANDO"}
        onClick={() => redirecionar()}
      />
      {largura > 1100 && (
        <DadosPedido
          atualizarDadosFinalizar={atualizarDadosFinalizar}
          dadosFinalizar={dadosFinalizar}
        />
      )}
    </Fragment>
  );
}

function CardProduto({ produto }) {
  const { post, get, put, del } = useApi();
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  const {
    nota,
    atualizarCarrinho,
    atualizarQuantidadeProduto,
    atualizandoQuantidade,
    removerProduto,
    limparCarrinho,
  } = useCarrinho();

  const [quantidade, setQuantidade] = useState();
  const [valorTotalProduto, setValorTotalProduto] = useState();

  useEffect(() => {
    setQuantidade(produto.quantidade);
    setValorTotalProduto(
      produto.precoTotal +
        calcularAdicionais(produto, { calcularQuantidade: true })
    );
  }, []);

  useEffect(() => {
    setQuantidade(produto.quantidade);
    setValorTotalProduto(
      produto.precoTotal +
        calcularAdicionais(produto, { calcularQuantidade: true })
    );
  }, [produto]);

  // useEffect(() => {
  //   // setValorTotalProduto(produto.preco * quantidade);
  //   setValorTotalProduto(produto.precoUnitario * quantidade);
  //   if (quantidade !== produto.quantidade) {
  //     if (quantidade === 0) {
  //       removerItem();
  //     } else {
  //       if (quantidade > produto.quantidade) {
  //         atualizarQuantidadeProduto(produto, quantidade, "adicionar");
  //       } else if (quantidade < produto.quantidade) {
  //         atualizarQuantidadeProduto(produto, quantidade, "remover");
  //       }
  //     }
  //   }
  // }, [quantidade]);

  function Contador() {
    return (
      <CardContador elevation={1}>
        <Linha style={{ gap: largura > 500 ? 8 : 0 }}>
          {quantidade > 1 ? (
            <FontAwesomeIcon
              style={{
                cursor: "pointer",
                opacity: atualizandoQuantidade ? 0.5 : 1,
              }}
              icon={faMinus}
              color={theme.coresCardapio.brand500}
              onClick={(e) => {
                e.stopPropagation();
                if (!atualizandoQuantidade) {
                  atualizarQuantidadeProduto(
                    produto,
                    quantidade - 1,
                    "remover"
                  );
                  setQuantidade(quantidade - 1);
                }
              }}
            />
          ) : (
            <RemoverItem produto={produto} setQuantidade={setQuantidade} />
          )}
          <TextoPretoLeve
            style={{
              userSelect: "none",
            }}
          >
            {quantidade}
          </TextoPretoLeve>
          <FontAwesomeIcon
            style={{
              cursor: "pointer",
              opacity: atualizandoQuantidade ? 0.5 : 1,
            }}
            icon={faPlus}
            color={theme.coresCardapio.brand500}
            onClick={(e) => {
              e.stopPropagation();
              if (!atualizandoQuantidade) {
                atualizarQuantidadeProduto(
                  produto,
                  quantidade + 1,
                  "adicionar"
                );
                setQuantidade(quantidade + 1);
              }
            }}
          />
        </Linha>
      </CardContador>
    );
  }

  function Fracionado() {
    function formatarValor(valor) {
      // Remove qualquer caractere que não seja número
      valor = valor.replace(/[^0-9]/g, "");

      // Separa a parte inteira da parte decimal
      let inteira = valor.slice(0, -3) || "0";
      let decimal = valor.slice(-3).padStart(3, "0");

      // Remove zeros à esquerda da parte inteira
      inteira = inteira.replace(/^0+/, "") || "0";

      return `${inteira}.${decimal}`;
    }

    return (
      <CardContador elevation={1}>
        <Linha style={{ gap: largura > 500 ? 8 : 0 }}>
          <RemoverItem produto={produto} setQuantidade={setQuantidade} />
          <TextoPretoLeve>{quantidade}</TextoPretoLeve>
          <h3>{produto.unidade}</h3>
        </Linha>
      </CardContador>
    );
  }

  return (
    !produto.cancelado && (
      <ContainerProduto>
        <Linha
          style={{
            marginBottom: 16,
          }}
        >
          {produto.fracionamento ? <Fracionado /> : <Contador />}
          <TextoBranco
            style={{
              marginRight: 8,
            }}
          >
            {produto.descricao}
          </TextoBranco>
          <TextoBranco>{formatarReal(produto.precoUnitario)}</TextoBranco>
        </Linha>
        <CardInternoBranco>
          {produto.tipo == "COMBINADO" ? (
            <>
              {produto.itens?.map((item, index) => (
                <EntradaCombinado
                  menu={item}
                  key={"entradaCombinado" + index}
                  observacao={produto.observacao}
                />
              ))}
              {produto.menus?.map((menu, index) => (
                <Entrada
                  menu={menu}
                  mostrarQuantidade={true}
                  key={"entrada" + index}
                  observacao={produto.observacao}
                />
              ))}
            </>
          ) : (
            produto.menus?.map((menu, index) => (
              <Entrada
                menu={menu}
                mostrarQuantidade={true}
                key={"entrada" + index}
                observacao={produto.observacao}
              />
            ))
          )}

          {produto.observacao && (
            <>
              <Linha>
                <LinhaRegistro>
                  <TextoPretoPedido>OBSERVAÇÃO</TextoPretoPedido>
                </LinhaRegistro>
              </Linha>
              <Linha>
                <TextoPretoLevePedido>
                  {produto.observacao}
                </TextoPretoLevePedido>
              </Linha>
            </>
          )}
        </CardInternoBranco>
        <Linha
          style={{
            justifyContent: "flex-end",
          }}
        >
          <TextoBranco>Total {formatarReal(valorTotalProduto)}</TextoBranco>
        </Linha>
      </ContainerProduto>
    )
  );
}

// function Entrada({ menu, mostrarQuantidade = false, formatoAdd = false }) {

//   if (!menu?.adicionais || menu?.adicionais?.length === 0) {
//     return <></>;
//   } else {
//     return (
//       <>
//         {mostrarQuantidade ? (
//           <TextoLaranjaPedido style={{ fontSize: 16 }}>
//             ({arrayItens.length}) {titulo}
//           </TextoLaranjaPedido>
//         ) : (
//           <TextoLaranjaPedido style={{ fontSize: 16 }}>
//             {titulo}
//           </TextoLaranjaPedido>
//         )}
//         {arrayItens.map((item, index) => {
//           if (item.adicionais && item.adicionais.length > 0) {
//             return (
//               <>
//                 <Linha key={index}>
//                   <LinhaRegistro>
//                     <TextoPretoLevePedido>{item.nome}</TextoPretoLevePedido>
//                   </LinhaRegistro>
//                 </Linha>
//                 {item.adicionais.map((itemAdicional, indexAdicional) => (
//                   <Linha key={indexAdicional}>
//                     <LinhaRegistro style={{ marginLeft: 16 }}>
//                       <TextoPretoPedido>ADD</TextoPretoPedido>
//                       <TextoPretoLevePedido>
//                         {itemAdicional.quantidade}x {itemAdicional.nome}
//                       </TextoPretoLevePedido>
//                     </LinhaRegistro>
//                     <TextoPretoLevePedido>
//                       {formatarReal(
//                         itemAdicional.preco * itemAdicional.quantidade
//                           ? itemAdicional.preco * itemAdicional.quantidade
//                           : 0
//                       )}
//                     </TextoPretoLevePedido>
//                   </Linha>
//                 ))}
//               </>
//             );
//           } else {
//             return (
//               <Linha key={index}>
//                 {formatoAdd ? (
//                   <LinhaRegistro>
//                     <TextoPretoPedido style={{ fontSize: 16 }}>
//                       ADD
//                     </TextoPretoPedido>
//                     <TextoPretoLevePedido style={{ fontSize: 16 }}>
//                       {item.quantidade}x {item.nome}
//                     </TextoPretoLevePedido>
//                   </LinhaRegistro>
//                 ) : (
//                   <LinhaRegistro>
//                     <TextoPretoPedido style={{ fontSize: 16 }}>
//                       {item.quantidade}x
//                     </TextoPretoPedido>
//                     <TextoPretoLevePedido style={{ fontSize: 16 }}>
//                       {item.nome}
//                     </TextoPretoLevePedido>
//                   </LinhaRegistro>
//                 )}
//                 <TextoPretoLevePedido style={{ fontSize: 16 }}>
//                   {formatarReal(
//                     item.preco * item.quantidade
//                       ? item.preco * item.quantidade
//                       : 0
//                   )}
//                 </TextoPretoLevePedido>
//               </Linha>
//             );
//           }
//         })}
//       </>
//     );
//   }
// }

function Entrada({ menu, mostrarQuantidade = false, formatoAdd = false }) {
  const adicionais = menu?.adicionais;

  return (
    <>
      <Linha>
        <LinhaRegistro>
          <TextoPretoPedido>{menu.descricao}</TextoPretoPedido>
        </LinhaRegistro>
      </Linha>
      {adicionais &&
        adicionais.map((adicional, index) => (
          <Linha key={"linhaEntrada" + index}>
            <LinhaRegistro style={{ marginLeft: 16 }}>
              <TextoPretoPedido>{adicional.quantidade}x</TextoPretoPedido>
              <TextoPretoLevePedido>{adicional.descricao}</TextoPretoLevePedido>
            </LinhaRegistro>
            <TextoPretoLevePedido>
              {formatarReal(adicional.precoTotal)}
            </TextoPretoLevePedido>
          </Linha>
        ))}
    </>
  );
  // }
}

function EntradaCombinado({ menu }) {
  const adicionais = menu?.adicionais;
  const menus = menu?.menus;
  return (
    <>
      <Linha>
        <LinhaRegistro>
          <TextoPretoLevePedido>{menu.descricao}</TextoPretoLevePedido>
        </LinhaRegistro>
      </Linha>
      {adicionais &&
        adicionais.map((adicional, index) => (
          <Linha key={"linhaEntrada" + index}>
            <LinhaRegistro style={{ marginLeft: 16 }}>
              <TextoPretoPedido>{adicional.quantidade}x</TextoPretoPedido>
              <TextoPretoLevePedido>{adicional.descricao}</TextoPretoLevePedido>
            </LinhaRegistro>
            <TextoPretoLevePedido>
              {formatarReal(adicional.precoTotal)}
            </TextoPretoLevePedido>
          </Linha>
        ))}
      {menus &&
        menus.map((menu, index) => {
          return (
            menu.adicionais &&
            menu.adicionais.map((adicional, index) => (
              <Linha key={"linhaEntrada" + index}>
                <LinhaRegistro style={{ marginLeft: 24 }}>
                  <TextoPretoPedido>
                    ADD {adicional.quantidade}x
                  </TextoPretoPedido>
                  <TextoPretoLevePedido>
                    {adicional.descricao}
                  </TextoPretoLevePedido>
                </LinhaRegistro>
                <TextoPretoLevePedido>
                  {formatarReal(adicional.precoTotal)}
                </TextoPretoLevePedido>
              </Linha>
            ))
          );
        })}
    </>
  );
  // }
}

function LimparCarrinho() {
  const { produtos, nota, atualizarCarrinho, limparCarrinho } = useCarrinho();
  const [aberto, setAberto] = useState(false);
  return (
    <>
      <TextoVermelho
        onClick={() => {
          setAberto(!aberto);
        }}
        style={{
          cursor: "pointer",
          fontWeight: 700,
          marginTop: 16,
          marginBottom: 16,
          fontSize: "1.2rem",
        }}
      >
        Limpar Carrinho
      </TextoVermelho>
      <GerarModalResponsivo
        controle={aberto}
        width={"450px"}
        overlayManual={true}
        onClose={() => {
          setAberto(false);
        }}
        children={() => {
          return (
            <Fragment>
              <Linha>
                <h1>Limpar carrinho</h1>
              </Linha>
              <Divider style={{ margin: 0 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                  padding: "16px 12px",
                  alignItems: "flex-start",
                }}
              >
                <p style={{ textAlign: "flex-start", marginBottom: 16 }}>
                  Tem certeza que deseja apagar todos os produtos do carrinho
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    gap: 8,
                  }}
                >
                  <BotaoVermelhoPreenchido
                    tamanho={"MD"}
                    texto={"Limpar"}
                    onClick={async () => {
                      await limparCarrinho();
                      setAberto(false);
                    }}
                  />
                  <BotaoVermelhoVazado
                    tamanho={"MD"}
                    texto={"Cancelar"}
                    style={{ marginRight: 0 }}
                    onClick={() => {
                      setAberto(false);
                    }}
                  />
                </div>
              </div>
            </Fragment>
          );
        }}
      />
    </>
  );
}

function RemoverItem({ produto, setQuantidade }) {
  const { removerProduto, atualizandoQuantidade } = useCarrinho();
  const theme = useTheme();
  const { largura } = useTamanhoTela();
  const [aberto, setAberto] = useState(false);
  return (
    <>
      <FontAwesomeIcon
        style={{ cursor: "pointer", opacity: atualizandoQuantidade ? 0.5 : 1 }}
        icon={faTrashCan}
        color={theme.coresCardapio.vermelhoDelete}
        onClick={() => {
          if (!atualizandoQuantidade) {
            setAberto(!aberto);
          }
        }}
      />

      <GerarModalResponsivo
        controle={aberto}
        width={"450px"}
        overlayManual={true}
        onClose={() => {
          setAberto(false);
        }}
        children={() => {
          return (
            <Fragment>
              <Linha>
                <h1>Excluir item</h1>
              </Linha>
              <Divider style={{ margin: 0 }} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                  padding: "16px 12px",
                  alignItems: "flex-start",
                }}
              >
                <p style={{ textAlign: "flex-start", marginBottom: 16 }}>
                  Tem certeza que deseja excluir esse item do carrinho?
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "100%",
                    gap: 8,
                  }}
                >
                  <BotaoVermelhoPreenchido
                    tamanho={"MD"}
                    texto={"Confirmar"}
                    onClick={async () => {
                      await removerProduto(produto);
                      setQuantidade(0);
                      setAberto(false);
                    }}
                  />
                  <BotaoVermelhoVazado
                    tamanho={"MD"}
                    texto={"Cancelar"}
                    style={{ marginRight: 0 }}
                    onClick={() => {
                      setAberto(false);
                    }}
                  />
                </div>
              </div>
            </Fragment>
          );
        }}
      />
    </>
  );
}
