import React, { useEffect, useState } from "react";
import GerarModalResponsivo from "../../gerarModalResponsivo";
import { X } from "phosphor-react";
import { StepLabel, TextField } from "@mui/material";
import {
  CampoDeSenha,
  CampoDeSenhaOutlined,
  CampoDeTexto,
  CampoDeTextoOutlined,
  DescricaoErro,
} from "../../campoInput";
import {
  BotaoCardapioPreenchido,
  BotaoCardapioVazado,
  BotaoVermelhoPreenchido,
  BotaoVermelhoVazado,
} from "../../botao";
import { ContainerInputs, ContianerBotoes, Divider, Linha } from "../styles";
import { Close } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useTheme } from "styled-components";
import { ReactComponent as IconeGoogle } from "../../../assets/images/icones/icon_google.svg";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

const AdicionalFirebase = ({ fechar, voltar }) => {
  const [cpf, setCpf] = useState("");
  const [erroCpf, setErroCpf] = useState(false);
  const [sucessoCpf, setSucessoCpf] = useState(false);
  const [celular, setCelular] = useState("");
  const [erroCelular, setErroCelular] = useState(false);
  const [sucessoCelular, setSucessoCelular] = useState(false);

  const theme = useTheme();

  function validarCpf(cpf) {
    if (cpf === "" || !cpf) {
      setErroCpf("Campo obrigatório");
      setSucessoCpf(false);
    } else if (cpf.length < 14) {
      setErroCpf("O campo deve conter ao menos 11 caracteres.");
      setSucessoCpf(false);
    } else {
      setErroCpf(false);
      setSucessoCpf(true);
    }
  }

  function formatarCpf(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length > 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }

    if (valorFormatado.length > 3) {
      valorFormatado =
        valorFormatado.substring(0, 3) + "." + valorFormatado.substring(3);
    }
    if (valorFormatado.length > 7) {
      valorFormatado =
        valorFormatado.substring(0, 7) + "." + valorFormatado.substring(7);
    }
    if (valorFormatado.length > 11) {
      valorFormatado =
        valorFormatado.substring(0, 11) + "-" + valorFormatado.substring(11);
    }

    return valorFormatado;
  }

  function formatarCelular(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length == 0) {
      setSucessoCelular(false);
      setErroCelular("Campo obrigatório");
    }

    if (valorFormatado.length <= 9) {
      setSucessoCelular(false);
      setErroCelular("Digite um número válido");
    } else {
      setSucessoCelular(true);
      setErroCelular(false);
    }

    if (valorFormatado.length >= 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }

    if (valorFormatado.length >= 2) {
      valorFormatado = valorFormatado.replace(/\D/g, "");

      let texto = ") ";

      if (valor.slice(-1) == " ") {
        texto = ") ";
      }
      if (valor.slice(-1) == ")") {
        texto = "";
      }

      valorFormatado =
        "(" +
        valorFormatado.substring(0, 2) +
        texto +
        valorFormatado.substring(2);
    } else {
      if (valorFormatado.length > 0) {
        valorFormatado = "(" + valorFormatado;
      }
    }

    if (valorFormatado.length > 9 && valorFormatado.length < 14) {
      valorFormatado =
        valorFormatado.substring(0, 9) + "-" + valorFormatado.substring(9);
    } else {
      if (valorFormatado.length > 9 && valorFormatado.length >= 14) {
        valorFormatado =
          valorFormatado.substring(0, 10) + "-" + valorFormatado.substring(10);
      }
    }

    return valorFormatado;
  }

  return (
    <div style={{ width: "100%" }}>
      <Linha>
        <h1>Informações Adicionais</h1>
      </Linha>
      <Divider />
      <p>Para finalizar o cadastro, essas informações são obrigatórias:</p>
      <ContainerInputs>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Celular"}
            error={erroCelular}
            value={celular}
            sucesso={sucessoCelular}
            onChange={(event) =>
              setCelular(formatarCelular(event.target.value.trim()))
            }
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroCelular} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"CPF"}
            error={erroCpf}
            sucesso={sucessoCpf}
            value={cpf}
            onChange={(event) => {
              validarCpf(event.target.value);
              setCpf(formatarCpf(event.target.value));
            }}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroCpf} />
        </div>
      </ContainerInputs>

      <ContianerBotoes style={{ flexDirection: "row" }}>
        <BotaoVermelhoPreenchido
          tamanho={"LG"}
          texto={"CANCELAR"}
          onClick={() => {
            voltar();
          }}
          style={{ minWidth: 0, width: "100%" }}
        />
        <BotaoCardapioPreenchido
          texto={"Salvar"}
          style={{
            width: "100%",
            fontSize: 16,
            fontWeight: 400,
            minWidth: 0,
          }}
          tamanho={"LG"}
          onClick={() => {}}
        />
      </ContianerBotoes>
    </div>
  );
};

export default AdicionalFirebase;
