import {
  Box,
  CircularProgress,
  OutlinedInput,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";

import {
  CampoDeTextoBase,
  CampoDeTextoBaseOutlined,
  FormularioCustomizado,
  FormularioCustomizadoOutlined,
  FormularioCustomizadoSelecao,
} from "./styles";
import { Padding, Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";

export const CampoDeTexto = ({
  titulo,
  style,
  value,
  variant = "standard",
  ...rest
}) => {
  return (
    <CampoDeTextoBase
      {...rest}
      label={titulo}
      variant={variant}
      style={style}
      value={value}
    />
  );
};

export const CampoDeSenha = ({ titulo, ...rest }) => {
  const [mostrarSenha, setMostrarSenha] = useState(false);

  function controlarMostrarSenha() {
    setMostrarSenha(!mostrarSenha);
  }

  return (
    <FormularioCustomizado variant="standard">
      <InputLabel htmlFor="standard-adornment-password">{titulo}</InputLabel>
      <Input
        {...rest}
        id="standard-adornment-password"
        type={mostrarSenha ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={controlarMostrarSenha}
              onMouseDown={controlarMostrarSenha}
            >
              {mostrarSenha ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormularioCustomizado>
  );
};

export const CampoDeTextoOutlined = ({
  titulo,
  style,
  value,
  sucesso,
  error,
  ...rest
}) => {
  function obterIconeStatus() {
    if (sucesso) {
      return (
        <InputAdornment position="end">
          <DoneIcon color="success" />
        </InputAdornment>
      );
    }

    if (error) {
      return (
        <InputAdornment position="end">
          <ErrorOutlineIcon color="error" />
        </InputAdornment>
      );
    }

    return null;
  }

  return (
    <CampoDeTextoBaseOutlined
      {...rest}
      label={titulo}
      error={error ? true : false}
      color={sucesso === true ? "success" : null}
      variant={"outlined"}
      InputProps={{
        endAdornment: obterIconeStatus(),
      }}
      style={style}
      value={value}
    />
  );
};

export const CampoDeSelecaoOutlined = ({
  titulo,
  referenciaNome,
  referenciaValor,
  defaultValue,
  valor,
  disabled,
  onChange,
  sucesso,
  items = [],
  campoVazio = true,
  iconeSearch = false,
  ...rest
}) => {
  const theme = useTheme();
  // Modelo de items:
  // Lista de Strings: ['item1', 'item2', 'item3']
  // Lista de Objetos: [
  //   {titulo: 'item1', valor: 'valor1'},
  //   {titulo: 'item2', valor: 'valor2'},
  //   {titulo: 'item3', valor: 'valor3'},
  // ]

  function GerenciarObjetosOuTextos() {
    return items.map((item, index) => {
      if (typeof item === "string") {
        return (
          <MenuItem key={titulo + item + index} value={item}>
            {item}
          </MenuItem>
        );
      } else if (typeof item === "object") {
        return (
          <MenuItem
            key={
              titulo + item[referenciaValor ? referenciaValor : "valor"] + index
            }
            value={item[referenciaValor ? referenciaValor : "valor"]}
          >
            {item[referenciaNome ? referenciaNome : "titulo"]}
          </MenuItem>
        );
      }
    });
  }

  return (
    <FormularioCustomizadoSelecao
      variant="standard"
      {...rest}
      value={valor}
      disabled={disabled}
    >
      <InputLabel
        style={{
          display: iconeSearch ? "flex" : "",
          alignItems: iconeSearch ? "center" : "",
          gap: iconeSearch ? 10 : "",
          paddingLeft: iconeSearch ? 16 : "",
        }}
        defaultValue="Atacado"
      >
        {iconeSearch && (
          <FontAwesomeIcon
            icon={faSearch}
            style={{
              color: theme.adaptativo("neutral50", "neutral100"),
              width: 15,
              height: 15,
              weight: "bold",
            }}
          />
        )}
        {titulo}
      </InputLabel>
      <Select
        value={items.length > 0 && valor ? valor : ""}
        label={titulo}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {campoVazio && <MenuItem value={"Selecione"}>Selecione</MenuItem>};
        {GerenciarObjetosOuTextos()}
      </Select>
    </FormularioCustomizadoSelecao>
  );
};

export const CampoDeSenhaOutlined = ({
  titulo,
  style,
  value,
  sucesso,
  error,
  ...rest
}) => {
  const [mostrarSenha, setMostrarSenha] = useState(false);

  function controlarMostrarSenha() {
    setMostrarSenha(!mostrarSenha);
  }

  return (
    <FormularioCustomizadoOutlined
      variant="outlined"
      $error={error}
      $sucesso={sucesso}
    >
      <InputLabel htmlFor="outlined-adornment-password">{titulo}</InputLabel>
      <OutlinedInput
        {...rest}
        value={value}
        error={error ? true : false}
        type={mostrarSenha ? "text" : "password"}
        color={sucesso === true ? "success" : null}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={controlarMostrarSenha}
              onMouseDown={controlarMostrarSenha}
            >
              {mostrarSenha ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormularioCustomizadoOutlined>
  );
};

export const DescricaoErro = ({ erro, style = {} }) => {
  return (
    <div
      style={{
        width: "100%",
        marginBottom: 16,
        position: "relative",
        display: erro ? "flex" : "none",
        ...style,
      }}
    >
      <p
        style={{
          color: "rgba(186, 26, 26, 1)",
          fontSize: 14,
          marginBottom: 8,
          marginTop: 8,
          position: "absolute",
          bottom: "-28px",
        }}
      >
        {erro?.message || erro}
      </p>
    </div>
  );
};

export function CampoDeBuscaAutoComplete({
  titulo,
  items,
  value,
  disabled,
  error,
  onChange = () => {},
  valorMostrado,
  ...rest
}) {
  return (
    <Autocomplete
      disablePortal
      filterOptions={(x) => x}
      disabled={disabled}
      error={error}
      options={items}
      autoHighlight
      noOptionsText="Não encontrado"
      includeInputInList
      autoComplete
      getOptionLabel={(option) => {
        return option.nome ? option.nome : null;
      }}
      value={value}
      onChange={(event, newInputValue) => onChange(event, newInputValue)}
      inputValue={valorMostrado}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={titulo}
          sx={{ ".MuiFormLabel-root": { paddingLeft: "0px" } }}
        />
      )}
      {...rest}
    />
  );
}
