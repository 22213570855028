import { useEffect, useState } from "react";
import { CampoDeTextoOutlined } from "../../../../components/campoInput";
import { ContainerInputsMesa } from "../styles";

export function DadosMesa({ atualizarDadosFinalizar }) {
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [sucessoNome, setSucessoNome] = useState(false);
  const [erroNome, setErroNome] = useState(false);
  const [sucessoCelular, setSucessoCelular] = useState(false);
  const [erroCelular, setErroCelular] = useState(false);

  useEffect(() => {
    if (sucessoNome) {
      atualizarDadosFinalizar({ nome: nome });
    } else {
      atualizarDadosFinalizar({ nome: null });
    }
  }, [nome]);

  useEffect(() => {
    if (sucessoCelular) {
      atualizarDadosFinalizar({ telefone: telefone });
    } else {
      atualizarDadosFinalizar({ telefone: null });
    }
  }, [telefone]);

  function validarNome(nome) {
    if (nome.length == 0) {
      setSucessoNome(false);
      setErroNome("Campo obrigatório");
    }

    if (nome.length > 0) {
      setSucessoNome(true);
      setErroNome(false);
    }
    return nome;
  }

  function formatarCelular(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length == 0) {
      setSucessoCelular(false);
      setErroCelular("Campo obrigatório");
    }

    if (valorFormatado.length <= 9) {
      setSucessoCelular(false);
      setErroCelular("Digite um número válido");
    } else {
      setSucessoCelular(true);
      setErroCelular(false);
    }

    if (valorFormatado.length >= 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }

    if (valorFormatado.length >= 2) {
      valorFormatado = valorFormatado.replace(/\D/g, "");

      let texto = ") ";

      if (valor.slice(-1) == " ") {
        texto = ") ";
      }
      if (valor.slice(-1) == ")") {
        texto = "";
      }

      valorFormatado =
        "(" +
        valorFormatado.substring(0, 2) +
        texto +
        valorFormatado.substring(2);
    } else {
      if (valorFormatado.length > 0) {
        valorFormatado = "(" + valorFormatado;
      }
    }

    if (valorFormatado.length > 9 && valorFormatado.length < 14) {
      valorFormatado =
        valorFormatado.substring(0, 9) + "-" + valorFormatado.substring(9);
    } else {
      if (valorFormatado.length > 9 && valorFormatado.length >= 14) {
        valorFormatado =
          valorFormatado.substring(0, 10) + "-" + valorFormatado.substring(10);
      }
    }

    return valorFormatado;
  }

  return (
    <ContainerInputsMesa>
      <CampoDeTextoOutlined
        titulo={"Nome*"}
        error={erroNome}
        successo={sucessoNome}
        value={nome}
        onChange={(e) => setNome(validarNome(e.target.value))}
      />
      <CampoDeTextoOutlined
        titulo={"Telefone*"}
        error={erroCelular}
        successo={sucessoCelular}
        value={telefone}
        onChange={(e) => setTelefone(formatarCelular(e.target.value))}
      />
    </ContainerInputsMesa>
  );
}
