import React, { useState, useEffect } from "react";
import {
  CardCustomizado,
  ConteudoProduto,
  ImagemProduto,
  CardPromocaoDoDia,
  PopupCustomizado,
  FaixaSemEstoque,
} from "./styles";
import ModalProduto from "../modalEscolherProduto";
import { ModalPizza } from "../modalEscolherPizza";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import ErroImagem from "../../../assets/images/img-erro.png";
import { useUserAuth } from "../../../contexts/userAuth";

export const CardProduto = ({ produto, precoCombinado, style, tipo }) => {
  const { urlBucket } = useUserAuth();
  const { largura } = useTamanhoTela();

  const [valorProduto, setValorProduto] = useState(0);

  useEffect(() => {
    definirValorProduto();
  }, [produto]);

  function definirValorProduto() {
    if (produto.tipoGrade == "NENHUMA") {
      let gradesReduzidas = produto.grades[0];
      produto.grades = [gradesReduzidas];
      setValorProduto(produto.grades[0].precoVenda);
    } else {
      if (produto.grades.length > 1) {
        let menorValor;
        produto.grades.map((grade) => {
          if (menorValor === undefined || grade.precoVenda < menorValor) {
            if (grade.precoVenda !== 0) {
              menorValor = grade.precoVenda;
            }
          }
        });
        setValorProduto(menorValor);
      } else {
        setValorProduto(produto.grades[0].precoVenda);
      }
    }
  }

  function formatarReal(valor) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(valor);
  }

  function verificarEstoque() {
    if (produto.estocado) {
      if (produto.estoque > 0) {
        return "CONTEM_ESTOQUE";
      }
      return "SEM_ESTOQUE";
    }

    return "NAO_CONTROLA";
  }

  return !valorProduto || valorProduto == 0 ? (
    <></>
  ) : (
    <PopupCustomizado
      contentStyle={{ width: largura > 500 ? 500 : largura, ...style }}
      trigger={
        <CardCustomizado>
          <ImagemProduto
            style={{ opacity: verificarEstoque() === "SEM_ESTOQUE" ? 0.5 : 1 }}
            url={
              produto?.imagem
                ? `${urlBucket}/imagens/PRODUTO_GRADE_IMAGEM/${produto.imagem}`
                : ErroImagem
            }
          />
          <ConteudoProduto
            style={{ opacity: verificarEstoque() === "SEM_ESTOQUE" ? 0.5 : 1 }}
          >
            <h3>{produto?.descricaoAbreviada}</h3>
            <p>{produto?.detalhes ? produto?.detalhes : ""}</p>
            {/* <span className="valorRiscado">
              {produto?.gradeEmpresaPrecoDTO?.precoPromocional
                ? `${produto.idTamanho ? "A partir de" : ""} ${formatarReal(
                    produto?.gradeEmpresaPrecoDTO?.precoVenda
                  )}`
                : ""}
            </span> */}
            {/* <span>
              {produto?.gradeEmpresaPrecoDTO?.precoPromocional
                ? `${produto.idTamanho ? "A partir de" : ""} ${formatarReal(
                    produto?.gradeEmpresaPrecoDTO?.precoPromocional
                  )}`
                : `${produto.idTamanho ? "A partir de" : ""} ${formatarReal(
                    produto?.gradeEmpresaPrecoDTO?.precoVenda
                  )}`}
            </span> */}

            <span>
              {produto.idTamanho ? "A partir de " : ""}
              {formatarReal(valorProduto)}
            </span>
          </ConteudoProduto>
          {verificarEstoque() === "SEM_ESTOQUE" && (
            <FaixaSemEstoque>
              <p>Em falta</p>
            </FaixaSemEstoque>
          )}
        </CardCustomizado>
      }
      modal
      nested
    >
      {(close) =>
        // produto?.tipo == "produto" ? (
        tipo == "COMBINADO" ? (
          <ModalPizza
            close={close}
            produto={produto}
            precoCombinado={precoCombinado}
          />
        ) : (
          <ModalProduto close={close} produto={produto} />
        )
      }
    </PopupCustomizado>
  );
};

export const CardPromocao = ({ produto }) => {
  const { largura } = useTamanhoTela();
  const { urlBucket } = useUserAuth();

  function formatarReal(valor) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(valor);
  }

  function verificarEstoque() {
    if (produto.estocado) {
      if (produto.estoque > 0) {
        return "CONTEM_ESTOQUE";
      }
      return "SEM_ESTOQUE";
    }

    return "NAO_CONTROLA";
  }

  return (
    <div
      style={{
        pointerEvents: produto.mockado ? "none" : "auto",
      }}
    >
      <PopupCustomizado
        contentStyle={{
          width: largura > 500 ? 500 : largura,
        }}
        trigger={
          <CardPromocaoDoDia
            possuiEstoque={verificarEstoque() !== "SEM_ESTOQUE"}
          >
            <ImagemProduto
              url={
                produto?.imagem
                  ? `${urlBucket}/imagens/PRODUTO_GRADE_IMAGEM/${produto.imagem}`
                  : ErroImagem
              }
            />
            <ConteudoProduto>
              <h3>{produto.titulo}</h3>
              <p>{produto.descricao ? produto.descricao : ""}</p>
              <span className="valorRiscado">
                {produto.precoAntigo
                  ? `${produto.idTamanho ? "A partir de" : ""} ${formatarReal(
                      produto.precoAntigo
                    )}`
                  : ""}
              </span>
              {/* <span>{produto.preco ? formatarReal(produto.preco) : ""}</span> */}
              <span>
                {produto.preco
                  ? `${produto.idTamanho ? "A partir de" : ""} ${formatarReal(
                      produto.preco
                    )}`
                  : ""}
              </span>
            </ConteudoProduto>
          </CardPromocaoDoDia>
        }
        modal
        nested
      >
        {(close) =>
          true ? (
            <ModalProduto close={close} produto={produto} />
          ) : (
            <ModalPizza close={close} produto={produto} />
          )
        }
      </PopupCustomizado>
    </div>
  );
};
