import React, { Fragment, useEffect, useState } from "react";
import { ImagemTitulo } from "./styles";
import {
  CategoriaExpansivel,
  CategoriaExpansivelConteudo,
  CategoriaExpansivelTitulo,
  CategoriaExpansivelTituloAlternativa,
  ContainerCategorias,
  ImagemCategoriaAlternativa,
} from "./styles";
import { CardProduto } from "../cardsProduto";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import ErroImagem from "../../../assets/images/img-erro.png";
import { useUserAuth } from "../../../contexts/userAuth";
import { CircularProgress } from "@mui/material";
import { useTheme } from "styled-components";

export const CategoriasCorpo = ({
  loading,
  dadosMontagem,
  tipoLayout = "EXPANDIDO",
}) => {
  const tema = useTheme();
  return loading ? (
    <div
      style={{
        width: "100%",
        height: "10vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{ color: tema.coresCardapio.brand500 }}
        style={{ alignSelf: "center" }}
      />
    </div>
  ) : (
    <ContainerCategorias>
      {dadosMontagem.listaCardapioMenus?.map((itemCardapio, index) => {
        let categoria =
          itemCardapio.horariosCardapioMenuDTO?.infoHorarioCardapioMenuDTO;
        let horarios = itemCardapio.horariosCardapioMenuDTO?.listaHorarios;
        let produtos = itemCardapio.listaProdutosCardapio;
        return (
          categoria && (
            <Categoria
              key={"Categoria" + categoria?.descricaoCardapioMenu + index}
              categoria={categoria}
              produtos={produtos}
              horarios={horarios}
              tipoLayout={tipoLayout}
              precoCombinado={categoria.precoCombinado}
            />
          )
        );
      })}
    </ContainerCategorias>
  );
};

export const Categoria = ({
  categoria,
  produtos,
  horarios,
  tipoLayout,
  precoCombinado,
}) => {
  const { urlBucket } = useUserAuth();

  const [imagemSrc, setImagemSrc] = useState();
  const [visivel, setVisivel] = useState(false);

  useEffect(() => {
    let imgUrl = `${urlBucket}/imagens/CARDAPIO_MENU_IMAGEM/${categoria.imagem}`;
    const img = new Image();
    img.onload = () => setImagemSrc(imgUrl); // Imagem carregada com sucesso
    img.onerror = () => setImagemSrc(ErroImagem); // Falha ao carregar, usar imagem de erro
    img.src = imgUrl;
  }, [categoria]);

  useEffect(() => {
    function checarVisibilidade(horarioDTO) {
      let {
        horarioInicial,
        horarioFinal,
        segunda,
        terca,
        quarta,
        quinta,
        sexta,
        sabado,
        domingo,
      } = horarioDTO;

      const diaAtual = dayjs().locale("pt-br").format("dddd").toLowerCase();
      const horarioAtual = dayjs().format("HH:mm");

      if (
        (diaAtual === "segunda-feira" && segunda) ||
        (diaAtual === "terça-feira" && terca) ||
        (diaAtual === "quarta-feira" && quarta) ||
        (diaAtual === "quinta-feira" && quinta) ||
        (diaAtual === "sexta-feira" && sexta) ||
        (diaAtual === "sábado" && sabado) ||
        (diaAtual === "domingo" && domingo)
      ) {
        if (
          dayjs(horarioAtual, "HH:mm").isAfter(
            dayjs(horarioInicial, "HH:mm")
          ) &&
          dayjs(horarioAtual, "HH:mm").isBefore(dayjs(horarioFinal, "HH:mm"))
        ) {
          // Cardápio está ativo e horário atual está dentro do intervalo
          setVisivel(true);
          return;
        }
      }
    }

    if (horarios) {
      horarios.forEach((horario) => {
        checarVisibilidade(horario);
      });
    }
  }, []);

  console.log("categoria", categoria);
  return (
    visivel && (
      <Fragment>
        {tipoLayout == "EXPANDIDO" && (
          <ImagemTitulo url={imagemSrc}>
            {categoria.descricaoCardapioMenu}
          </ImagemTitulo>
        )}
        <CategoriaExpansivel
          defaultExpanded={tipoLayout == "EXPANDIDO" ? true : false}
          id={`idCategoria_${categoria.idCardapioMenu}`}
        >
          {tipoLayout == "EXPANDIDO" && (
            <CategoriaExpansivelTitulo expandIcon={<ExpandMoreIcon />}>
              {categoria.descricaoCardapioMenu}
            </CategoriaExpansivelTitulo>
          )}
          {tipoLayout == "REDUZIDO" && (
            <CategoriaExpansivelTituloAlternativa
              expandIcon={<ExpandMoreIcon />}
            >
              <ImagemCategoriaAlternativa url={imagemSrc} />
              <p>{categoria.descricaoCardapioMenu}</p>
            </CategoriaExpansivelTituloAlternativa>
          )}
          <CategoriaExpansivelConteudo>
            {produtos?.map((produto, index) => {
              return (
                <CardProduto
                  key={"card" + produto.descricaoAbreviada + index}
                  produto={produto}
                  tipo={categoria.tipo}
                  precoCombinado={precoCombinado}
                />
              );
            })}
          </CategoriaExpansivelConteudo>
        </CategoriaExpansivel>
      </Fragment>
    )
  );
};

export default CategoriasCorpo;
