import styled from "styled-components";

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px 8px 8px;

  .MuiStepIcon-text {
    display: none;
  }

  @media (max-width: 900px) {
    h1 {
      font-size: 24px;
    }
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  margin-top: 24px;
  margin-bottom: 24px;
  p {
    color: ${({ theme }) => theme.coresCardapio.brand500};
    cursor: pointer;
  }
`;

export const Divider = styled.hr`
  width: 90%;
  margin: 15px 5%;
  align-self: center;
  height: 1px;
  opacity: 50%;
  color: gray;
`;

export const ContianerBotoes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
export const ContainerBotoesMaisInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
