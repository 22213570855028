import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.coresCardapio.background1};
  padding-bottom: 140px;
`;

export const StyledContainerCupons = styled.div`
  padding: 1.5rem 90px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  h2 {
    margin-top: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }

  @media (max-width: 900px) {
    margin: 0;
    padding: 0 14px;
  }
`;

export const ScrollHorizontal = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  margin-bottom: 10px;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    margin: 10px 0px;
    height: 4px;
    width: 8px;
    display: block;
    padding: 10px;
  }

  &::-webkit-scrollbar-track {
    padding: 10px 0px;
    width: 5%;
    border-radius: 5px;
    display: block;
    background-color: ${({ theme }) => theme.coresCardapio.background1};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      String(theme.coresCardapio.textoEscuro).replace("1)", "0.2)")};
    border-radius: 25px;
  }
`;

export const CuponsPopulares = styled.div`
  width: 100%;
  min-height: 220px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: nowrap;
  overflow: scroll;
  padding: 0px;
  min-height: 0px;
  width: max-content;
  gap: 48px;
`;

export const ContainerCategoria = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  grid-gap: 1rem;
  justify-content: space-around;

  list-style: none;
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  row-gap: 20px;
`;

export const BlocoCategoria = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
`;

export const ContainerMostrarMais = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  p {
    font-family: "Poppins", sans-serif;
    color: ${({ theme }) => theme.coresCardapio.brand500};
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
`;
