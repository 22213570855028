import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { FormControlLabel, TextField } from "@mui/material";

export const PopupCustomizado = styled(Popup)`
  &-overlay {
    overflow-y: scroll;
    padding: 20px 0px;

    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  &-content {
    border: none;
    padding: 0;
  }
`;

export const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.coresCardapio.background1};
`;

export const PopupHeader = styled.div`
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5)),
    url(${({ url }) => url});
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 30px;
  font-size: 24px;
  font-weight: 400;
  align-items: flex-start;
  font-family: "Poppins", sans-serif;
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
  h1 {
    color: ${({ theme }) => theme.coresCardapio.textoClaro};
    font-size: 24px;
  }
`;

export const BotaoDeFechar = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
`;

export const TextoSobre = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  line-height: 24px;

  strong {
    font-weight: bold;
  }
`;

export const PopupConteudo = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const CampoObservacao = styled(TextField)`
  width: 100%;

  .MuiInputLabel-root {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-top: 5px;
  }

  .MuiInputLabel-shrink {
    margin-left: -10px;
    margin-top: 0px;
    font-size: 17px;
  }

  .MuiFormHelperText-root {
    align-self: flex-end;
    padding: 0;
    margin: 0;
  }
`;

export const ContainerQuantidadePedido = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  align-items: center;
  padding: 0 10px;
`;

export const CampoObservacoes = styled(TextField)`
  width: 100%;

  .MuiInputLabel-root {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-top: 5px;
  }

  .MuiInputLabel-shrink {
    margin-left: -10px;
    margin-top: 0px;
    font-size: 17px;
  }

  .MuiFormHelperText-root {
    align-self: flex-end;
    padding: 0;
    margin: 0;
  }
`;

export const BotaoQuantidadePedido = styled.button`
  cursor: pointer;
  width: 50px;
  height: 100%;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.coresCardapio.brand500};
  border-radius: 0.5rem;
  border: none;

  &:not(:disabled):hover {
    border: 1px solid ${({ theme }) => theme.coresCardapio.brand500};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const InputQuantidadePedido = styled.input`
  cursor: pointer;
  width: 50px;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: none;
  text-align: center;

  font-family: "Poppins", sans-serif;
  font-size: 19px;
  color: ${({ theme }) => theme.coresCardapio.textoEscuro};

  &:not(:disabled):hover {
    border: 1px solid ${({ theme }) => theme.coresCardapio.brand500};
  }

  &:disabled {
    
  }
`;

export const ContainerBotaoAdicionarAoCarrinho = styled.button`
  cursor: pointer;
  width: 100%;
  height: 60px;
  background-color: ${({ theme, $desativado }) =>
    $desativado ? theme.coresCardapio.gray200 : theme.coresCardapio.brand500};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  color: ${({ theme, $desativado }) =>
    $desativado
      ? String(theme.coresCardapio.textoEscuro).replace("1)", "0.5)")
      : theme.coresCardapio.textoClaro};
  fill: ${({ theme, $desativado }) =>
    $desativado
      ? String(theme.coresCardapio.textoEscuro).replace("1)", "0.5)")
      : theme.coresCardapio.textoClaro};
  border: ${({ theme, $desativado }) =>
    `1px solid ${
      $desativado ? theme.coresCardapio.gray200 : theme.coresCardapio.brand500
    }`};
  padding: 5px 20px;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  margin-top: 20px;

  &:not(:disabled):hover {
    background-color: ${({ theme, $desativado }) =>
      $desativado ? theme.coresCardapio.gray200 : theme.coresCardapio.brand500};
    border: ${({ theme, $desativado }) =>
      `1px solid ${
        $desativado ? theme.coresCardapio.gray200 : theme.coresCardapio.brand500
      }`};
    box-shadow: 0px 0px 4px 2px
      ${({ theme, $desativado }) =>
        $desativado
          ? theme.coresCardapio.gray200
          : theme.coresCardapio.brand500};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &:disabled {
    cursor: not-allowed
  }
`;
export const ContainerBotaoSemEstoque = styled.button`
  cursor: pointer;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) =>
   theme.coresCardapio.gray200 };
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  color: ${({ theme, $desativado }) =>
    $desativado
      ? String(theme.coresCardapio.textoEscuro).replace("1)", "0.5)")
      : theme.coresCardapio.textoClaro};
  fill: ${({ theme, $desativado }) =>
    $desativado
      ? String(theme.coresCardapio.textoEscuro).replace("1)", "0.5)")
      : theme.coresCardapio.textoClaro};
  padding: 5px 20px;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  margin-top: 20px;
  justify-content: center;

  &:not(:disabled):hover {
    background-color: ${({ theme, $desativado }) =>
      $desativado ? theme.coresCardapio.gray200 : theme.coresCardapio.brand500};
    border: ${({ theme, $desativado }) =>
      `1px solid ${
        $desativado ? theme.coresCardapio.gray200 : theme.coresCardapio.brand500
      }`};
    box-shadow: 0px 0px 4px 2px
      ${({ theme, $desativado }) =>
        $desativado
          ? theme.coresCardapio.gray200
          : theme.coresCardapio.brand500};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &:disabled {
    cursor: not-allowed
  }
`;

export const PopupContainerTag = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

export const PopupTag = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 5px 10px;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const Grades = styled.button`
  width: 22%;
  height: 110px;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.coresCardapio.brand500};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  background-color: ${({ theme, $ativo }) =>
    $ativo ? theme.coresCardapio.brand600 : "white"};

  padding: 0.5rem;

  p {
    color: ${({ theme, $ativo }) =>
      $ativo ? theme.coresCardapio.textoClaro : theme.coresCardapio.brand500};
    font-size: 16px;
  }

  .valor {
    font-size: 19px;
  }

  .riscado {
    color: ${({ theme }) => theme.coresCardapio.gray200};
    text-decoration: line-through;
  }
`;

export const ContainerGrades = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4%;
  row-gap: 1rem;
  margin-bottom: 24px;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;
