import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LinhaCategorias = styled.nav`
  width: fit-content;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  transition: all;
  scroll-behavior: smooth;
`;

export const ItemCategoria = styled.section`
  scroll-snap-align: start;
  height: 100%;
  width: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
  cursor: pointer;
  font-family: "Open Sans", sans-serif;

  @media (max-width: 850px) {
    width: 33vw;
  }

  @media (max-width: 640px) {
    width: 50vw;
  }

  @media (max-width: 480px) {
    width: 100vw;
  }
`;

export const ScrollHorizontal = styled.div`
  overflow-x: scroll;
  height: 40px;
  overflow-y: hidden;
  width: 100%;
  scroll-snap-type: x mandatory;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24);

  scroll-behavior: smooth;
  color: ${({ theme }) => theme.coresCardapio.background2};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Seta = styled(FontAwesomeIcon)`
  width: 30px;
  padding: 10px;
  align-self: center;
  position: absolute;
  scale: 1.5;
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
  cursor: pointer;
  z-index: 1;
`;

export const SetaVoltar = styled(Seta)`
  left: 0px;
`;

export const SetaAvancar = styled(Seta)`
  right: 0px;
`;

export const LinhaVoltar = styled.div`
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  color: ${({ theme }) => theme.coresCardapio.brand500};
  width: 100%;
  height: 40px;
  z-index: 1;
  font-size: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const ContainerBarraDeCategorias = styled.div`
  z-index: 1;
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
`;
