import { styled } from "styled-components";

export const Container = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.coresWaypag.background1};
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow-y: scroll;
`;
export const ContainerIcone = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  svg {
    color: ${({ theme }) => theme.coresWaypag.primary};
    width: 20px;
    height: 20px;
  }
`;

export const BotaoIcone = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const ContainerTitulo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
`;

export const Titulo = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.coresWaypag.textoBranco};
`;

export const LinhaTitulo = styled.hr`
  border-color: ${({ theme }) => theme.coresWaypag.grayScale500};
  width: 100%;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 5rem;
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 4px;
  height: 56px;
  background: ${({ theme }) => theme.coresWaypag.background2};
  border: 1px solid ${({ theme }) => theme.coresWaypag.grayScale500};
  padding: 0px 12px 0px 16px;
  color: ${({ theme }) => theme.coresWaypag.textoBranco};
  font-family: "Poppins", sans-serif;
  outline: 0;

  &::placeholder {
    opacity: 0.7;
  }
`;

export const ContainerBotaoProximo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const BotaoProximo = styled.button`
  cursor: pointer;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 8px;
  background: ${({ theme }) => theme.coresWaypag.primary};
  border: none;
`;

export const TextoBotaoProximo = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #fff;
`;
