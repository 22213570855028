import React, { useEffect, useState } from "react";
import { ContainerCarrinhoPopUp } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Badge } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useCarrinho } from "../../contexts/carrinho";
import formatarReal from "../../utils/formatarReal";

function PopUpCarrinho() {
  const navigate = useNavigate();
  const location = useLocation();

  const { quantidadeItens, valorTotal } = useCarrinho();

  const [ativo, setAtivo] = useState(false);

  useEffect(() => {
    if (quantidadeItens > 0 && location.hash !== "#/carrinho") {
      setAtivo(true);
    } else {
      setAtivo(false);
    }
  }, [quantidadeItens, location]);

  return (
    ativo && (
      <ContainerCarrinhoPopUp to="#/carrinho">
        <Badge overlap="circular" badgeContent={quantidadeItens}>
          <FontAwesomeIcon icon={faCartShopping} />
        </Badge>
        <p>Ver meu carrinho</p>
        <p>
          <strong>{formatarReal(valorTotal)}</strong>
        </p>
      </ContainerCarrinhoPopUp>
    )
  );
}

export default PopUpCarrinho;
