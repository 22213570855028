import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContainerSwitch = styled.div`
  display: flex;
  position: fixed;
  top: 50%;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 136px;
  width: 78px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  gap: 16px;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.coresCardapio.brand100};
  z-index: 2;

  @media (max-width: 768px) {
    top: 80%;
    right: 40%;
    flex-direction: row;
    height: 78px;
    width: 170px;
  }
  @media (max-width: 480px) {
    right: 32%;
  }

  @media (max-width: 330px) {
    right: 25%;
  }
`;

export const Switch = styled(Link)`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme, $ativo }) =>
    $ativo ? theme.coresCardapio.brand500 : "white"};
  color: ${({ theme, $ativo }) =>
    !$ativo ? theme.coresCardapio.brand500 : "white"};
  fill: ${({ theme, $ativo }) =>
    !$ativo ? theme.coresCardapio.brand500 : "white"};
`;
