import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { Card, FormControlLabel, TextField } from "@mui/material";

export const PopupCustomizado = styled(Popup)`
  &-overlay {
    overflow-y: scroll;
    padding: 20px 0px;

    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  &-content {
    border: none;
    padding: 0;
  }
`;

export const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.coresCardapio.background1};
`;

export const PopupHeader = styled.div`
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center center;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5)),
    url(${({ url }) => url});
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 30px;
  font-size: 24px;
  font-weight: 400;
  align-items: flex-start;
  font-family: "Poppins", sans-serif;
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
  h1 {
    color: ${({ theme }) => theme.coresCardapio.textoClaro};
    font-size: 24px;
  }
`;

export const BotaoHeader = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
`;

export const TextoSobre = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  line-height: 24px;

  strong {
    font-weight: bold;
  }
`;

export const PopupConteudo = styled.div`
  padding: 12px;
`;

export const FormControlLabelCustomizado = styled(FormControlLabel)`
  height: 110px;
  margin-left: 0px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);

  span {
    color: inherit;
  }

  .MuiSvgIcon-root {
    font-size: 28px;
  }
  .Mui-checked {
    color: ${({ theme }) => theme.coresCardapio.brand500}!important;
  }
  .MuiFormControlLabel-label {
    width: 100%;
  }
`;

export const CardCustomizadoOpcao = styled.div`
  width: 100%;
  height: 100%;
  margin: 0.5% 1%;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  overflow: hidden;
`;

export const ConteudoOpcao = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;

  h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 0.1px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  }

  p {
    pointer-events: all;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};

    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
    }

    .tooltip .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: black;
      color: ${({ theme }) => theme.coresCardapio.textoClaro};
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }

  span {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: ${({ theme }) => theme.coresCardapio.semanticSucess};
  }

  .valorRiscado {
    text-decoration: line-through;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
    opacity: 0.5;
    font-size: 14px;
  }
`;

export const ContainerContador = styled.div`
  width: 80px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.coresCardapio.brand500};
  font-weight: 600;

  p {
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  }
`;

export const CampoObservacoes = styled(TextField)`
  width: 100%;

  .MuiInputLabel-root {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-top: 5px;
  }

  .MuiInputLabel-shrink {
    margin-left: -10px;
    margin-top: 0px;
    font-size: 17px;
  }

  .MuiFormHelperText-root {
    align-self: flex-end;
    padding: 0;
    margin: 0;
  }
`;

export const ContainerQuantidadePedido = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  align-items: center;
  padding: 0 10px;
`;

export const BotaoQuantidadePedido = styled.div`
  cursor: pointer;
  width: 50px;
  height: 100%;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.coresCardapio.brand500};
  border-radius: 0.5rem;

  &:hover {
    border: 1px solid ${({ theme }) => theme.coresCardapio.brand500};
  }
`;

export const InputQuantidadePedido = styled.input`
  cursor: pointer;
  width: 50px;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: none;
  text-align: center;

  font-family: "Poppins", sans-serif;
  font-size: 19px;
  color: ${({ theme }) => theme.coresCardapio.textoEscuro};

  &:hover {
    border: 1px solid ${({ theme }) => theme.coresCardapio.brand500};
  }
`;

export const ContainerBotaoAdicionarAoCarrinho = styled.div`
  cursor: pointer;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
  fill: ${({ theme }) => theme.coresCardapio.textoClaro};
  background-color: ${(props) => props.theme.coresCardapio.brand500};
  border: ${(props) => `1px solid ${props.theme.coresCardapio.brand500}`};
  padding: 5px 20px;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  margin-top: 20px;

  &:hover {
    background-color: ${(props) => props.theme.coresCardapio.brand500};
    border: ${(props) => `1px solid ${props.theme.coresCardapio.brand500}`};
    box-shadow: 0px 0px 4px 2px ${(props) => props.theme.coresCardapio.brand500};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;

export const PopupContainerTag = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0px 0px 0px;
`;

export const PopupTag = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 5px 10px;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

export const ItemSabor = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 2.5px 10px;
  border-radius: 0.5rem;
  border: ${({ theme }) => `1px solid ${theme.coresCardapio.brand500}`};
  background-color: ${({ theme, $selecionado }) =>
    $selecionado ? theme.coresCardapio.brand500 : "transparent"};
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;

  p {
    font-size: 16px;
    color: ${({ theme, $selecionado }) =>
      $selecionado
        ? theme.coresCardapio.textoClaro
        : theme.coresCardapio.brand500};
  }
`;

export const PaginaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Separador = styled.hr`
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const Linha = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 16px 0px;
`;

export const Tamanhos = styled.button`
  width: 22%;
  height: 110px;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.coresCardapio.brand500};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  background-color: ${({ theme, $ativo }) =>
    $ativo ? theme.coresCardapio.brand600 : "white"};

  padding: 0.5rem;

  p {
    color: ${({ theme, $ativo }) =>
      $ativo ? theme.coresCardapio.textoClaro : theme.coresCardapio.brand500};
    font-size: 16px;
  }

  .valor {
    font-size: 19px;
  }

  .riscado {
    color: ${({ theme }) => theme.coresCardapio.gray200};
    text-decoration: line-through;
  }
`;

export const BotaoProximo = styled.button`
  cursor: pointer;
  width: 100%;
  height: 60px;
  background-color: ${({ theme, $ativo }) =>
    $ativo ? theme.coresCardapio.brand500 : theme.coresCardapio.gray100};
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  color: ${({ theme, $ativo }) =>
    $ativo ? theme.coresCardapio.textoClaro : theme.coresCardapio.gray400};
  /* border: ${(props) => `1px solid ${props.theme.coresCardapio.brand500}`}; */
  padding: 5px 20px;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  margin: 15px 12px;
  justify-content: center;
  font-size: 18px;
border: none;

  &:not(:disabled):hover {
    box-shadow: 0px 0px 4px 2px
      ${({ theme, $ativo }) =>
        $ativo ? theme.coresCardapio.brand500 : theme.coresCardapio.gray100};
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &:disabled {
    cursor: not-allowed
  }
`;

export const CardNenhumResultado = styled(Card)`
  width: 100%;
  height: 330px;
  margin: 25px 0px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextoTituloNenhumResultado = styled.h5`
  font-family: "Open Sans", sans-serif;
  font-weight: 600 !important;
  font-size: 1.25rem;
  width: 100%;
  text-align: start;
  color: ${({ theme }) => theme.coresCardapio.brand500};
  margin-bottom: 5px;
`;

export const TextoSubtituloNenhumResultado = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 400 !important;
  font-size: 1rem;
  width: 100%;
  text-align: start;
  margin-bottom: 20px;
`;

export const EscolhaExpansivelPizza = styled(Accordion)`
  width: 100%;
  min-height: 90px;
  height: auto;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  margin: 20px 0px;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  @media (max-width: 768px) {
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
  }
`;

export const EscolhaExpansivelPizzaTitulo = styled(AccordionSummary)`
  width: 100%;
  min-height: 90px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  border-radius: 0.25rem;
  font-size: 24px;
  color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 0px 20px;
  letter-spacing: 0px;
  line-height: 34px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);

  p {
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    color: ${({ theme }) =>
      String(theme.coresCardapio.textoEscuro).replace("1)", "0.6)")};
    font-weight: 500;
    line-height: 1.2;
  }

  .MuiAccordionSummary-content {
    flex-direction: column;
  }

  .MuiSvgIcon-root {
    font-size: 42px;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};

    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;

export const EscolhaExpansivelPizzaConteudo = styled(AccordionDetails)`
  width: 100%;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
`;

export const ContainerTamanhos = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4%;
  row-gap: 1rem;
  margin-bottom: 8px;
  margin-top: 8px;
  @media (max-width: 500px) {
    justify-content: center;
  }
`;
