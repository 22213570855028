import { styled } from "styled-components";

export const Container = styled.div`
padding: 24px;
  background-color: ${({ theme }) => theme.coresWaypag.background1};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: scroll;
`

export const ContainerTitulo = styled.div`
    margin-top: 4.5rem;
    margin-bottom: 3rem;
`

export const Titulo = styled.p`
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};
`

export const ContainerCartão = styled.div`
    height: 169px;
    padding: 20px;
    border-radius: 16px;
    background: #0D0D0D;
    margin-bottom: 3rem;

`
export const ContainerHeaderCartao = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
`

export const TituloCartão = styled.p`
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};
`

export const BotaoIcone = styled.button`
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;

    &:hover {
        opacity: 0.8;
    }
`

export const BotaoIconeLixo = styled.button`
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;

    &:hover {
        opacity: 0.8;
    }
`


export const ConatainerIcone = styled.div`
    svg {
        color: ${({ theme }) => theme.coresWaypag.semanticDanger};
    }
`

export const ContainerNumeroCartao = styled.div`
    margin-bottom: 1rem;
`

export const NumeroCartao = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};
`

export const FooterCartao = styled.div`
    display: flex;
    justify-content: space-between;
`
export const ContainerIcone = styled.div`
    svg {
        width: 40px;
        height: 30px;
    }
`
export const ContainerBotaoCartaoPadrao = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const BotaoCartaoPadrao = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0px 8px;
    margin: 0;
    border: none;
    width: 118px;
    height: 24px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
        opacity: 0.8;
    }
`

export const TextoCartaoPadrao = styled.p`
    font-family: "Source Sans Pro", sans-serif;
    display: flex;
    align-items: center;
    color: #169C34;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    font-size: normal;
`

export const ContainerAdicionarCartao = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 169px;
    border-radius: 16px;
    background: transparent;
    border: 1px solid #8C9199;
    margin-bottom: 8rem;
`
export const BotaoIrParaPagamento = styled.button`
    cursor: pointer;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    border-radius: 16px;
    background: ${({ theme }) => theme.coresWaypag.primary};
    border: none;

    &:hover {
        opacity: 0.8;
    }
`

export const TextoBotaoIrParaPagameno = styled.div`
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21.28px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};
`