import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FormControlLabel, TextField } from "@mui/material";

export const EscolhaExpansivel = styled(Accordion)`
  width: 100%;
  min-height: 90px;
  height: auto;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  margin: 20px 0px;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ $modoEstatico }) =>
    $modoEstatico ? "none" : "0px 4px 16px rgba(0, 0, 0, 0.16)"};
  @media (max-width: 768px) {
    margin: 0px;
    box-shadow: ${({ $modoEstatico }) =>
      $modoEstatico ? "none" : "0px 4px 16px rgba(0, 0, 0, 0.16)"};
  }

  .modoEstatico {
    .MuiAccordionSummary-content {
      font-size: 20px;
    }
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  }
`;

export const EscolhaExpansivelTitulo = styled(AccordionSummary)`
  width: 100%;
  min-height: 90px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  border-radius: 0.25rem;
  font-size: 24px;
  color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding: 0px 20px;
  letter-spacing: 0px;
  line-height: 34px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);

  p {
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    color: ${({ theme }) =>
      String(theme.coresCardapio.textoEscuro)?.replace("1)", "0.6)")};
    font-weight: 500;
    line-height: 1.2;
  }

  .MuiAccordionSummary-content {
    flex-direction: column;
  }

  .MuiSvgIcon-root {
    font-size: 42px;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};

    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;

export const EscolhaExpansivelConteudo = styled(AccordionDetails)`
  width: 100%;
  background-color: ${({ theme }) => theme.coresCardapio.background2};
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FormControlLabelCustomizado = styled(FormControlLabel)`
  max-height: 150px;
  margin-left: 0px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);

  span {
    color: inherit;
  }

  .MuiSvgIcon-root {
    font-size: 28px;
  }
  .Mui-checked {
    color: ${({ theme }) => theme.coresCardapio.brand500}!important;
  }
  .MuiFormControlLabel-label {
    width: 100%;
  }
`;

export const CardCustomizadoOpcao = styled.div`
  width: 100%;
  height: 100%;
  max-width: 375px;
  margin: 0.5% 1%;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  overflow: hidden;
`;

export const ConteudoOpcao = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;

  h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 0.1px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  }

  p {
    pointer-events: all;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 16px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};

    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
    }

    .tooltip .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: black;
      color: ${({ theme }) => theme.coresCardapio.textoClaro};
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }

  span {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: ${({ theme }) => theme.coresCardapio.semanticSucess};
  }

  .valorRiscado {
    text-decoration: line-through;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
    opacity: 0.5;
    font-size: 14px;
  }
`;

export const ConteudoOpcaoPizza = styled.div`
  max-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;

  h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.1px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 12px;
    min-height: 32px;
    letter-spacing: 0.1px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};

    .tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;
    }

    .tooltip .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: black;
      color: ${({ theme }) => theme.coresCardapio.textoClaro};
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }

  span {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: ${({ theme }) => theme.coresCardapio.semanticSucess};
  }

  .valorRiscado {
    text-decoration: line-through;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
    opacity: 0.5;
    font-size: 14px;
  }

  @media (max-width: 350px) {
    h3 {
      font-size: 12px;
    }

    p {
      font-size: 10px;
      line-height: 10px;
    }
  }
`;

export const ContainerContador = styled.div`
  width: 80px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.coresCardapio.brand500};
  font-weight: 600;

  > p {
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: ${({ theme }) => theme.coresCardapio.textoEscuro};
    user-select: none;
  }
`;

export const ImagemProduto = styled.div`
  width: 40%;
  height: 100%;
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
`;


export const IconeBotao = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
> svg {
  color: ${({ theme }) => theme.coresCardapio.brand500};
}
 
`;