import React, { Fragment, useEffect, useState } from "react";
import GerarModalResponsivo from "../../gerarModalResponsivo";
import { X } from "phosphor-react";
import { StepLabel, TextField } from "@mui/material";
import {
  CampoDeSenha,
  CampoDeSenhaOutlined,
  CampoDeTexto,
  CampoDeTextoOutlined,
  DescricaoErro,
} from "../../campoInput";
import { BotaoCardapioPreenchido, BotaoCardapioVazado } from "../../botao";
import { ContainerInputs, ContianerBotoes, Divider, Linha } from "../styles";
import { Close } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useTheme } from "styled-components";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Chip from "../../chip";
import { useRef } from "react";
import useTamanhoTela from "../../../utils/useTamanhoTela";

const FormasPagamento = ({
  fechar,
  modoEdicao,
  loginGoogle,
  mudarTela,
  setDadosCadastro,
  dadosCadastro,
  finalizar,
  finalizarSemPagamento,
  apenasNovaEntrada = false,
}) => {
  const [nomeTitular, setNomeTitular] = useState("");
  const [erroNomeTitular, setErroNomeTitular] = useState(false);
  const [sucessoNomeTitular, setSucessoNomeTitular] = useState(false);
  const [numeroCartao, setNumeroCartao] = useState("");
  const [erroNumeroCartao, setErroNumeroCartao] = useState(false);
  const [sucessoNumeroCartao, setSucessoNumeroCartao] = useState(false);
  const [validade, setValidade] = useState("");
  const [erroValidade, setErroValidade] = useState(false);
  const [sucessoValidade, setSucessoValidade] = useState(false);
  const [cvv, setCvv] = useState("");
  const [erroCvv, setErroCvv] = useState(false);
  const [sucessoCvv, setSucessoCvv] = useState(false);
  const [cpfCartao, setCpfCartao] = useState("");
  const [errocpfCartao, setErrocpfCartao] = useState(false);
  const [sucessocpfCartao, setSucessocpfCartao] = useState(false);

  const [chipCredito, setChipCredito] = useState(false);
  const [chipDebito, setChipDebito] = useState(false);
  const [erroChips, setErroChips] = useState(false);

  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const { largura } = useTamanhoTela();

  useEffect(() => {
    if (dadosCadastro.pagamento) {
      setNomeTitular(dadosCadastro.pagamento?.nomeTitular);
      setNumeroCartao(dadosCadastro.pagamento?.numeroCartao);
      setValidade(dadosCadastro.pagamento?.validade);
      setCvv(dadosCadastro.pagamento?.cvv);
      setCpfCartao(dadosCadastro.pagamento?.cpfCartao);
      setChipCredito(dadosCadastro.pagamento?.chipCredito);
      setChipDebito(dadosCadastro.pagamento?.chipDebito);

      setErroNomeTitular(dadosCadastro.errosPagamento?.erroNomeTitular);
      setErroNumeroCartao(dadosCadastro.errosPagamento?.erroNumeroCartao);
      setErroValidade(dadosCadastro.errosPagamento?.erroValidade);
      setErroCvv(dadosCadastro.errosPagamento?.erroCvv);
      setErrocpfCartao(dadosCadastro.errosPagamento?.errocpfCartao);
      setErroChips(dadosCadastro.errosPagamento?.erroChips);
    }
  }, [dadosCadastro]);

  const proximaEtapaCheck = async () => {
    let erro = false;

    if (!chipCredito && !chipDebito) {
      setErroChips("Selecione um meio de pagamento");
      erro = true;
    }

    if (nomeTitular === "" || !nomeTitular || erroNomeTitular) {
      setErroNomeTitular("Campo obrigatório");
      erro = true;
    }

    if (numeroCartao === "" || !numeroCartao || erroNumeroCartao) {
      setErroNumeroCartao("Campo obrigatório");
      erro = true;
    }

    if (validade === "" || !validade || erroValidade) {
      setErroValidade("Campo obrigatório");
      erro = true;
    }

    if (cvv === "" || !cvv || erroCvv || cvv.length < 3) {
      setErroCvv("Campo obrigatório");
      erro = true;
    }

    if (cpfCartao === "" || !cpfCartao || errocpfCartao) {
      setErrocpfCartao("Campo obrigatório");
      erro = true;
    }

    if (erro) return;

    setLoading(true);
    let novoDadosCadastro = {
      ...dadosCadastro,
      pagamento: {
        nomeTitular,
        numeroCartao,
        validade,
        cvv,
        cpfCartao,
        chipCredito,
        chipDebito,
      },
      errosPagamento: {
        erroNomeTitular,
        erroNumeroCartao,
        erroValidade,
        erroCvv,
        errocpfCartao,
        erroChips,
      },
    };

    await finalizar(novoDadosCadastro);
    setLoading(false);
  };

  function formatarcpfCartao(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length > 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }

    if (valorFormatado.length > 3) {
      valorFormatado =
        valorFormatado.substring(0, 3) + "." + valorFormatado.substring(3);
    }
    if (valorFormatado.length > 7) {
      valorFormatado =
        valorFormatado.substring(0, 7) + "." + valorFormatado.substring(7);
    }
    if (valorFormatado.length > 11) {
      valorFormatado =
        valorFormatado.substring(0, 11) + "-" + valorFormatado.substring(11);
    }

    return valorFormatado;
  }

  function formatarValidade(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length > 4) {
      valorFormatado = valorFormatado.substring(0, 4);
    }

    if (valorFormatado.length >= 3 && valorFormatado[2] != "/") {
      valorFormatado =
        valorFormatado.substring(0, 2) + "/" + valorFormatado.substring(2);
      return valorFormatado;
    }

    if (valorFormatado.length == 3 && valorFormatado[2] == "/") {
      valorFormatado = valorFormatado.replace("/", "");
    }

    return valorFormatado;
  }

  function formatarCartao(valor) {
    if (valor) {
      let novoValor = valor.replace(/[^0-9-]/g, "");
      if (!novoValor) {
        return "";
      }
      novoValor = novoValor.match(/\d{1,4}/g).join("-");
      return novoValor;
    }
  }

  function validarcpfCartao(cpfCartao) {
    if (cpfCartao === "" || !cpfCartao) {
      setErrocpfCartao("Campo obrigatório");
      setSucessocpfCartao(false);
    } else if (cpfCartao.length < 14) {
      setErrocpfCartao("O campo deve conter ao menos 11 caracteres.");
      setSucessocpfCartao(false);
    } else {
      setErrocpfCartao(false);
      setSucessocpfCartao(true);
    }
  }

  function validarCVV(cvv, setErro, setSucesso) {
    if (cvv === "" || !cvv) {
      setErro("Campo obrigatório");
      setSucesso(false);
    } else {
      if (cvv.length < 3) {
        setErro("O CVV deve possuir 3 caracteres.");
        setSucesso(false);
      } else {
        setErro(false);
        setSucesso(true);
      }
    }
  }

  function validarCamposGenerico(estado, setErro, setSucesso) {
    if (estado === "") {
      setErro("Campo obrigatório");
      setSucesso(false);
    } else {
      setErro(false);
      setSucesso(true);
    }
  }

  function validarValidade(validade) {
    if (validade === "" || !validade) {
      setErroValidade("Campo obrigatório");
      setSucessoValidade(false);
    } else if (validade.length < 4) {
      setErroValidade("O campo deve conter ao menos 4 caracteres.");
      setSucessoValidade(false);
    } else {
      setErroValidade(false);
      setSucessoValidade(true);
    }
  }

  function validarCartao(numeroCartao, setErro, setSucesso) {
    if (numeroCartao === "" || !numeroCartao) {
      setErro("Campo obrigatório");
      setSucesso(false);
    } else if (numeroCartao.length < 19) {
      setErro("O campo deve conter ao menos 16 caracteres.");
      setSucesso(false);
    } else {
      setErro(false);
      setSucesso(true);
    }
  }

  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, []);

  return (
    <div
      style={{ overflow: "scroll", maxHeight: largura < 990 ? "70vh" : null }}
      ref={scrollRef}
    >
      <Linha>
        <FontAwesomeIcon
          onClick={() =>
            apenasNovaEntrada
              ? fechar()
              : modoEdicao
              ? mudarTela("mostrarFormasPagamento")
              : mudarTela("endereco")
          }
          icon={faArrowLeft}
          style={{
            fontSize: 28,
            color: theme.coresCardapio.brand500,
            cursor: "pointer",
          }}
        />
        <h1 style={{ fontSize: "26px" }}>Formas de pagamento</h1>
        <FontAwesomeIcon
          onClick={() => fechar()}
          icon={faXmark}
          style={{ fontSize: 28, color: "red", cursor: "pointer" }}
        />
      </Linha>
      <Divider />
      {modoEdicao ? (
        <Fragment />
      ) : (
        <Linha style={{ justifyContent: "center" }}>
          <Stepper activeStep={2} alternativeLabel style={{ width: "100%" }}>
            {["", "", ""].map((label, index) => (
              <Step key={label + index + "stepperPagamento"}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Linha>
      )}
      <p>Selecione um meio de pagamento:</p>
      <Linha style={{ justifyContent: "space-around" }}>
        <Chip
          label={"Crédito"}
          valor={chipCredito}
          icon={<CreditCardIcon />}
          onClick={() => {
            setChipCredito(true);
            setChipDebito(false);
            setErroChips(false);
          }}
        />
        <Chip
          label={"Débito"}
          valor={chipDebito}
          onClick={() => {
            setChipCredito(false);
            setChipDebito(true);
            setErroChips(false);
          }}
          icon={<AttachMoneyIcon />}
        />
      </Linha>
      <Linha style={{ padding: 0 }}>
        <DescricaoErro
          // style={{ justifyContent: "center", padding: 0 }}
          erro={erroChips}
        />
      </Linha>
      <ContainerInputs>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Nome do Titular"}
            error={erroNomeTitular}
            sucesso={sucessoNomeTitular}
            value={nomeTitular}
            onChange={(event) => {
              validarCamposGenerico(
                event.target.value.replace(/[^a-zA-Z ]/g, ""),
                setErroNomeTitular,
                setSucessoNomeTitular
              );
              setNomeTitular(event.target.value.replace(/[^a-zA-Z ]/g, ""));
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 250 }}
          />
          <DescricaoErro erro={erroNomeTitular} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Número do cartão"}
            error={erroNumeroCartao}
            sucesso={sucessoNumeroCartao}
            value={numeroCartao}
            onChange={(event) => {
              validarCartao(
                formatarCartao(event.target.value),
                setErroNumeroCartao,
                setSucessoNumeroCartao
              );
              setNumeroCartao(formatarCartao(event.target.value));
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 19 }}
          />
          <DescricaoErro erro={erroNumeroCartao} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Validade"}
            error={erroValidade}
            sucesso={sucessoValidade}
            value={validade}
            onChange={(event) => {
              validarValidade(event.target.value.replace(/\D/g, ""));
              setValidade(
                formatarValidade(event.target.value.replace(/\D/g, ""))
              );
            }}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroValidade} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"CVV"}
            error={erroCvv}
            sucesso={sucessoCvv}
            value={cvv}
            onChange={(event) => {
              validarCVV(
                event.target.value.replace(/\D/g, ""),
                setErroCvv,
                setSucessoCvv
              );
              setCvv(event.target.value.replace(/\D/g, ""));
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 3 }}
          />
          <DescricaoErro erro={erroCvv} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"CPF"}
            error={errocpfCartao}
            sucesso={sucessocpfCartao}
            value={cpfCartao}
            onChange={(event) => {
              validarcpfCartao(event.target.value);
              setCpfCartao(formatarcpfCartao(event.target.value));
            }}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={errocpfCartao} />
        </div>
      </ContainerInputs>

      {modoEdicao ? (
        <ContianerBotoes>
          <BotaoCardapioPreenchido
            texto={"Gravar"}
            style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
            tamanho={"LG"}
            onClick={() => {
              proximaEtapaCheck();
            }}
          />
        </ContianerBotoes>
      ) : (
        <ContianerBotoes>
          <span
            onClick={() => {
              finalizarSemPagamento(dadosCadastro);
            }}
            style={{
              color: theme.coresCardapio.brand500,
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Pular etapa
          </span>
          <BotaoCardapioPreenchido
            texto={"PRÓXIMO"}
            style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
            tamanho={"LG"}
            onClick={() => {
              proximaEtapaCheck();
            }}
          />
        </ContianerBotoes>
      )}
    </div>
  );
};

export default FormasPagamento;
