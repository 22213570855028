import { Card, Tab } from "@mui/material";
import { styled } from "styled-components";
import { BotaoCardapioVazado } from "../../../components/botao";

export const Botao = styled.div`
  border: 1px solid ${(props) => props.theme.cores.actionActive};
  border-radius: 16px;
  padding: 5px 12px;
  color: ${(props) =>
    props.$ativo
      ? props.theme.cores.neutral100
      : props.theme.cores.actionActive};
  background-color: ${(props) =>
    props.$ativo ? props.theme.cores.actionActive : "transparent"};
  margin-right: 15px;
  cursor: pointer;
  user-select: none;

  @media (max-width: 768px) {
    width: 38vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  @media (max-width: 400px) {
    width: 33vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 15px;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: block;
  background-color: #fff;
`;

export const ContainerCabecalhoCorpo = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerTituloCabecalho = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }
`;

export const TituloCabecalho = styled.h1`
  box-sizing: border-box;
  margin-top: 0;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: black;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 69px;

  margin-left: 5rem;
  margin-top: 0.5rem;
  width: 100%;

  @media (max-width: 767px) {
    font-size: 26px;
  }
`;

export const ContainerCorpo = styled.div`
  background-color: ${({ theme }) => theme.coresCardapio.brand050};
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 2.5rem;

  @media (min-width: 768px) {
    display: block;
  }

  @media (max-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 1100px) {
    background-color: ${({ theme }) => theme.coresCardapio.background1};
  }
`;
export const ContainerBotao = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

export const BotaoVoltarAoCardapio = styled(Botao)`
  border-radius: 8px;
  border-color: ${({ theme }) => theme.coresCardapio.brand500};
  color: ${({ theme }) => theme.coresCardapio.brand500};
  height: 55px;
  width: 270px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: 0.3s;
  margin: 0;

  &:hover {
    background-color: #f09400;
    color: white;
    box-shadow: 0px 0px 6px 1px #f09400;
  }
`;

export const ContainerCarrinho = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  box-sizing: border-box;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 3rem;

  @media (max-width: 576px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ContainerHeader = styled.div`
  position: relative;
`;

export const ContainerDados = styled.div`
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  padding-bottom: 32px;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #fff;
  display: block;
  box-sizing: border-box;

  @media (min-width: 1100px) {
    flex: 0 0 auto;
    width: 40%;
  }

  @media (max-width: 1100px) {
    width: 100%;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  @media (max-width: 576px) {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
`;

export const TabCustomizada = styled(Tab)`
  .MuiTabs-flexContainer {
    width: 50% !important;
  }

  .MuiTabs-indicator {
    bottom: 10px !important;
  }
`;

export const TextoLaranja = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.coresCardapio.brand500};
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  box-sizing: border-box;
  font-weight: 700;
`;

export const TextoPreto = styled.p`
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  box-sizing: border-box;
  font-weight: 700;
  text-overflow: ellipsis;
`;

export const TextoPretoLeve = styled.p`
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  box-sizing: border-box;
  font-weight: 400;
  text-overflow: ellipsis;
`;

export const TextoVermelho = styled.p`
  font-size: 16px;
  color: red;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24;
  box-sizing: border-box;
  font-weight: 400;
  text-overflow: ellipsis;
`;

export const TextoVerde = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.coresCardapio.semanticSucess};
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24;
  box-sizing: border-box;
  font-weight: 700;
  text-overflow: ellipsis;
`;

export const TextoBranco = styled.h1`
  font-size: 19px;
  color: #fff;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24;
  box-sizing: border-box;
  font-weight: 700;
  text-overflow: ellipsis;
`;

export const ContainerMensagem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const TituloMensagemPagamento = styled.div`
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid ${({ theme }) => theme.coresCardapio.brand500};

  @media (max-width: 350px) {
    p {
      font-size: 1rem;
    }
  }
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContainerProduto = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const CardInternoBranco = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 190px;
  overflow-y: scroll;

  &:empty {
    display: none;
  }
`;

export const LinhaRegistro = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  margin-bottom: 4px;
`;

export const TextoLaranjaPedido = styled(TextoLaranja)`
  font-size: 14px;
`;

export const TextoPretoPedido = styled(TextoPreto)`
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: bolder;

  letter-spacing: 0.1px;
  line-height: 24px;
`;

export const TextoPretoLevePedido = styled(TextoPretoLeve)`
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

export const ContainerDadosProdutos = styled(ContainerDados)`
  margin: 0 12px;
  padding: 0 24px;

  @media (max-width: 1100px) {
    background-color: ${({ theme }) => theme.coresCardapio.background1};
  }
`;

export const ContainerColunaMesa = styled(ContainerDadosProdutos)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 32px;
`;

export const ContainerInputsMesa = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 60%;

  @media (max-width: 576px) {
    width: 80%;
  }
`;

export const CardContador = styled(Card)`
  padding: 2px 8px;
  margin-right: 8px;
  min-width: fit-content;

  @media (max-width: 576px) {
    padding: 8px 6px;
    min-width: 65px;
    div {
      gap: 4px;
    }
  }
`;

export const ContainerFinalizar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  margin-top: 16px;

  @media (max-width: 1100px) {
    background-color: ${({ theme }) => theme.coresCardapio.background1};

    padding: 16px 8px;
    margin: 16px -8px;
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;

  .MuiTypography-root {
    width: 100%;
  }
`;

export const LinhaCupom = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

export const CardPagamento = styled(Card)`
  padding: 12px;
  width: 100%;
  align-self: center;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
`;

export const ContainerCartoes = styled.div`
  max-height: 230px;
  width: 100%;
  overflow-y: scroll;
  padding: 0px 4px;
`;

export const ContainerTextoLaranja = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 20px;
`;

export const TituloModal = styled.h1`
  box-sizing: border-box;
  margin-top: 0;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: black;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 40px;
  margin-left: 20%;
  margin-right: 20%;

  margin-top: 0.5rem;
  width: 100%;

  @media (max-width: 767px) {
    margin-left: 0%;
    margin-right: 0%;
  }
`;

export const SubTituloModal = styled.h3`
  box-sizing: border-box;
  margin-top: 0;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: black;
  font-size: 28px;
  letter-spacing: 0px;
  line-height: 40px;
  margin-left: 20%;
  margin-right: 20%;

  margin-top: 0.5rem;
  width: 100%;

  @media (max-width: 767px) {
    margin-left: 0%;
    margin-right: 0%;
  }
`;

export const Divider = styled.hr`
  width: 90%;
  margin: 15px 5%;
  align-self: center;
  height: 1px;
  opacity: 50%;
  color: gray;
`;

export const InputQuantidadePedido = styled.input`
  cursor: pointer;
  width: 50px;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: none;
  text-align: center;

  font-family: "Poppins", sans-serif;
  font-size: 19px;
  color: ${({ theme }) => theme.coresCardapio.textoEscuro};

  &:hover {
    border: 1px solid ${({ theme }) => theme.coresCardapio.brand500};
  }
`;
