import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.coresCardapio.background1};
`;

export const StyledContainerCardapios = styled.div`
  padding: 1.5rem 1rem;
  margin: 0 6rem;

  @media (max-width: 990px) {
    margin: 0;
    padding: 1rem 0 0 0;
  }

  @media (max-width: 400px) {
    margin: 0;
    padding: 1rem 0 0 0;
  }

  .h1Titulo {
    @media (min-width: 400px) {
      margin-left: 1rem;
    }
  }
`;

export const ContainerCardapios = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2.5rem 0;
  gap: 20px;

  @media (max-width: 1153px) {
    gap: 0px;
  }
`;

export const Cardapio = styled.div`
  position: relative;
  min-height: 300px;
  max-height: 300px;
  max-width: 400px;
  background-color: #aaa;
  margin-top: 8px;
  overflow: hidden;

  border-radius: 15px;
  cursor: pointer;
  img {
    width: 100%;
    height: 300px;
    max-height: 392px;
    background-size: cover;
    background-position: center center;
    /* background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5)), url(/media/menu_images/menu_image_qRl3lBX.jpeg); */
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.5)
    );
    pointer-events: none; // Permite cliques na imagem abaixo do pseudo-elemento
  }

  h1 {
    color: #fff;
    position: absolute;
    bottom: 10%;
    left: 10%;
  }

  @media (max-width: 768px) {
    max-width: none;
    min-width: 400px;
  }
`;

export const ContainerOrdenacao = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 30%);
  grid-gap: 1rem;
  justify-content: space-around;

  list-style: none;
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  row-gap: 20px;

  @media (max-width: 990px) {
    grid-template-columns: repeat(auto-fit, 48%);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, 100%);
  }
`;

export const LinkCustomizado = styled(Link)`
  text-decoration: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;
