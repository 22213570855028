import React, { useEffect, useRef, useState } from "react";
import GerarModalResponsivo from "../../gerarModalResponsivo";
import { X } from "phosphor-react";
import { StepLabel, TextField } from "@mui/material";
import {
  CampoDeSenha,
  CampoDeSenhaOutlined,
  CampoDeTexto,
  CampoDeTextoOutlined,
  DescricaoErro,
} from "../../campoInput";
import { BotaoCardapioPreenchido, BotaoCardapioVazado } from "../../botao";
import { ContainerInputs, ContianerBotoes, Divider, Linha } from "../styles";
import { Close } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useTheme } from "styled-components";
import { ReactComponent as IconeGoogle } from "../../../assets/images/icones/icon_google.svg";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import axios from "axios";
import { toast } from "react-toastify";
import { useUserAuth } from "../../../contexts/userAuth";
import { useApi } from "../../../contexts/api";
import { validarCPF } from "../../../utils/validarCPF";

const baseURL = process.env.REACT_APP_BASE_URL_API;
const clientKey = process.env.REACT_APP_CLIENT_KEY;

const Cadastro = ({
  fechar,
  loginGoogle,
  mudarTela,
  dadosCadastro,
  setDadosCadastro,
  setToken,
}) => {
  const { get, post } = useApi();
  const { dadosEmpresa } = useUserAuth();

  const [nome, setNome] = useState("");
  const [erroNome, setErroNome] = useState(false);
  const [sucessoNome, setSucessoNome] = useState(false);

  const [cpf, setCpf] = useState("");
  const [erroCpf, setErroCpf] = useState(false);
  const [sucessoCpf, setSucessoCpf] = useState(false);

  const [celular, setCelular] = useState("");
  const [erroCelular, setErroCelular] = useState(false);
  const [sucessoCelular, setSucessoCelular] = useState(false);

  const [email, setEmail] = useState("");
  const [erroEmail, setErroEmail] = useState(false);
  const [sucessoEmail, setSucessoEmail] = useState(false);

  const [senha, setSenha] = useState("");
  const [erroSenha, setErroSenha] = useState(false);
  const [sucessoSenha, setSucessoSenha] = useState(false);

  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [erroConfirmarSenha, setErroConfirmarSenha] = useState(false);
  const [sucessoConfirmarSenha, setSucessoConfirmarSenha] = useState(false);

  const [loadingCadastro, setLoadingCadastro] = useState(false);

  const theme = useTheme();

  const { largura } = useTamanhoTela();

  useEffect(() => {
    if (dadosCadastro) {
      setNome(dadosCadastro.nome ? dadosCadastro.nome : "");
      setCpf(dadosCadastro.cpf ? dadosCadastro.cpf : "");
      setCelular(dadosCadastro.celular ? dadosCadastro.celular : "");
      setEmail(dadosCadastro.email ? dadosCadastro.email : "");
      setSenha(dadosCadastro.senha ? dadosCadastro.senha : "");
      setConfirmarSenha(dadosCadastro.senha ? dadosCadastro.senha : "");
      checarSenhas();
    }
  }, []);

  function checkErrorAPI(error) {
    if (error.includes("CPF")) {
      setErroCpf(true);
      setSucessoCpf(false);
    }
    if (error.includes("Email") || error.includes("E-mail")) {
      setErroEmail(true);
      setSucessoEmail(false);
    }
    if (error.includes("Telefone") || error.includes("Celular")) {
      setErroCelular(true);
      setSucessoCelular(false);
    }

    if (error.includes("Senha")) {
      setErroSenha(true);
      setSucessoSenha(false);
      setErroConfirmarSenha(true);
      setSucessoConfirmarSenha(false);
    }
  }

  const proximaEtapaCheck = () => {
    let erro = false;
    if (nome === "" || !nome || erroNome) {
      setErroNome("Campo obrigatório");
      erro = true;
    }
    if (cpf === "" || !cpf || erroCpf) {
      setErroCpf("Campo obrigatório");
      erro = true;
    }
    if(!validarCPF(cpf)){
      setErroCpf("Insira um CPF válido")
    }
    if (celular === "" || !celular || erroCelular) {
      setErroCelular("Campo obrigatório");
      erro = true;
    }
    if (email === "" || !email || erroEmail) {
      setErroEmail("Campo obrigatório");
      erro = true;
    }
    if (senha === "" || !senha || erroSenha) {
      setErroSenha("Campo obrigatório");
      erro = true;
    }

    if (confirmarSenha === "" || !confirmarSenha || erroConfirmarSenha) {
      setErroConfirmarSenha("Campo obrigatório");
      erro = true;
    }

    if (erro) return;

    post(
      "/usuario-sessao/client/usuario/waymenu/cadastro?clientKey=" + clientKey,
      {
        nome: nome,
        cpf: cpf,
        email: email,
        password: senha,
        telefone: celular,
        idEmpresa: dadosEmpresa.idEmpresa,
      }
    )
      .then((response) => {
        setDadosCadastro({
          ...dadosCadastro,
          nome,
          cpf,
          celular,
          email,
          senha,
        });
        mudarTela("endereco");
      })
      .catch((error) => {
        console.log(error);
        setLoadingCadastro(false);
        if (error.data?.message) {
          toast.error(error.data?.message);
          checkErrorAPI(error.data?.message);
        } else {
          toast.error("Erro ao realizar cadastro");
        }
      });
  };

  function formatarCpf(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length > 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }
    if (valorFormatado.length > 3) {
      valorFormatado =
        valorFormatado.substring(0, 3) + "." + valorFormatado.substring(3);
    }
    if (valorFormatado.length > 7) {
      valorFormatado =
        valorFormatado.substring(0, 7) + "." + valorFormatado.substring(7);
    }
    if (valorFormatado.length > 11) {
      valorFormatado =
        valorFormatado.substring(0, 11) + "-" + valorFormatado.substring(11);
    }

    if (valorFormatado.length == 11) {
      setErroCpf("");
    }
    
    if (validarCPF(valor.replace(/\D/g, ""))) {
      setSucessoCpf(true);
    } else {
      setSucessoCpf(false);
    }

    setErroCpf(false);
    return valorFormatado;
  }

  function formatarCelular(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length == 0) {
      setSucessoCelular(false);
      setErroCelular("Campo obrigatório");
    }

    if (valorFormatado.length <= 9) {
      setSucessoCelular(false);
      setErroCelular("Digite um número válido");
    } else {
      setSucessoCelular(true);
      setErroCelular(false);
    }

    if (valorFormatado.length >= 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }

    if (valorFormatado.length >= 2) {
      valorFormatado = valorFormatado.replace(/\D/g, "");

      let texto = ") ";

      if (valor.slice(-1) == " ") {
        texto = ") ";
      }
      if (valor.slice(-1) == ")") {
        texto = "";
      }

      valorFormatado =
        "(" +
        valorFormatado.substring(0, 2) +
        texto +
        valorFormatado.substring(2);
    } else {
      if (valorFormatado.length > 0) {
        valorFormatado = "(" + valorFormatado;
      }
    }

    if (valorFormatado.length > 9 && valorFormatado.length < 14) {
      valorFormatado =
        valorFormatado.substring(0, 9) + "-" + valorFormatado.substring(9);
    } else {
      if (valorFormatado.length > 9 && valorFormatado.length >= 14) {
        valorFormatado =
          valorFormatado.substring(0, 10) + "-" + valorFormatado.substring(10);
      }
    }

    return valorFormatado;
  }

  function validarEmail(email) {
    if (email.length < 1) {
      setErroEmail("Campo obrigatório");
      setSucessoEmail(false);
      return;
    }
    var re = /\S+@\S+\.\S+/;
    if (re.test(email)) {
      setSucessoEmail(true);
      setErroEmail(false);
    } else {
      setErroEmail("Por favor, insira um email válido.");
      setSucessoEmail(false);
    }
  }

  function validarNome(nome) {
    if (nome.length < 1) {
      setErroNome("Campo obrigatório");
      setSucessoNome(false);
      return;
    }
    setSucessoNome(true);
    setErroNome(false);
  }

  function checarSenhas() {
    if (
      (!senha || senha.length < 1) &&
      (!confirmarSenha || confirmarSenha.length < 1)
    ) {
      return;
    }
    if (!/[A-Z]/.test(senha)) {
      setSucessoSenha(false);
      setSucessoConfirmarSenha(false);
      setErroSenha("A senha deve conter ao menos 1 letra maiuscula.");
      return;
    } else if (!/[a-z]/.test(senha)) {
      setSucessoSenha(false);
      setSucessoConfirmarSenha(false);
      setErroSenha("A senha deve conter ao menos 1 letra minuscula.");
      return;
    } else if (!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(senha)) {
      setSucessoSenha(false);
      setSucessoConfirmarSenha(false);
      setErroSenha("A senha deve conter ao menos 1 caracter especial.");
      return;
    }
    if (senha.length < 8) {
      setSucessoSenha(false);
      setSucessoConfirmarSenha(false);
      setErroSenha("A senha deve ter no mínimo 8 caracteres.");
      return;
    } else if (confirmarSenha && senha !== confirmarSenha) {
      if (senha.length > 0) {
        setErroSenha("Os campos de senha não coincidem.");
      }
      if (confirmarSenha.length > 0) {
        setErroConfirmarSenha("Os campos de senha não coincidem.");
      }
    } else {
      setErroSenha(false);
      setErroConfirmarSenha(false);
      setSucessoSenha(true);
      setSucessoConfirmarSenha(true);
      return;
    }
  }

  useEffect(() => {
    checarSenhas();
  }, [senha, confirmarSenha]);

  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{
        overflow: "scroll",
        width: "100%",
        maxHeight: largura < 990 ? "70vh" : null,
      }}
      ref={scrollRef}
    >
      <Linha>
        <FontAwesomeIcon
          onClick={() => mudarTela("login")}
          icon={faArrowLeft}
          style={{
            fontSize: 28,
            color: theme.coresCardapio.brand500,
            cursor: "pointer",
          }}
        />
        <h1>Cadastre-se</h1>
        <FontAwesomeIcon
          onClick={() => fechar()}
          icon={faXmark}
          style={{ fontSize: 28, color: "red", cursor: "pointer" }}
        />
      </Linha>
      {/* <Linha style={{ justifyContent: "center" }}>
        
      </Linha> */}
      <Divider />
      <Linha style={{ justifyContent: "center" }}>
        <Stepper activeStep={0} alternativeLabel style={{ width: "100%" }}>
          {["1", "2", "3"].map((label, index) => (
            <Step key={label + index + "cadastro"}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
      </Linha>
      <ContainerInputs>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Nome"}
            error={erroNome}
            sucesso={sucessoNome}
            value={nome}
            onChange={(event) => {
              validarNome(event.target.value);
              setNome(event.target.value);
            }}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroNome} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"CPF"}
            error={erroCpf}
            sucesso={sucessoCpf}
            value={cpf}
            onChange={(event) => setCpf(formatarCpf(event.target.value))}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroCpf} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Celular"}
            error={erroCelular}
            value={celular}
            sucesso={sucessoCelular}
            onChange={(event) =>
              setCelular(formatarCelular(event.target.value))
            }
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroCelular} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Email"}
            error={erroEmail}
            value={email}
            sucesso={sucessoEmail}
            onChange={(event) => {
              validarEmail(event.target.value);
              setEmail(event.target.value);
            }}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroEmail} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeSenhaOutlined
            titulo={"Senha"}
            error={erroSenha}
            value={senha}
            sucesso={sucessoSenha}
            onChange={(event) => setSenha(event.target.value)}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroSenha} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeSenhaOutlined
            titulo={"Confirmar senha"}
            error={erroConfirmarSenha}
            value={confirmarSenha}
            sucesso={sucessoConfirmarSenha}
            onChange={(event) => setConfirmarSenha(event.target.value)}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroConfirmarSenha} />
        </div>
      </ContainerInputs>

      <ContianerBotoes>
        <BotaoCardapioPreenchido
          texto={"PRÓXIMO"}
          style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
          tamanho={"LG"}
          loading={loadingCadastro}
          onClick={() => {
            proximaEtapaCheck();
          }}
        />
      </ContianerBotoes>
    </form>
  );
};

export default Cadastro;
