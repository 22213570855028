import { ChipCustomizado } from "./styles";

import { useTheme } from "styled-components";

export default function Chip({
  label,
  color = "primary",
  valor = false,
  style = {},
  ...rest
}) {
  const tema = useTheme();
  return (
    <ChipCustomizado
      {...rest}
      style={{ ...style }}
      $valor={valor}
      label={label}
      color={color}
      variant={valor == true ? "filled" : "outlined"}
    />
  );
}
