import { styled } from "styled-components";

export const Container = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.coresWaypag.background1};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: scroll;
`;

export const ContainerIcone = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  svg {
    color: ${({ theme }) => theme.coresWaypag.primary};
    width: 20px;
    height: 20px;
  }
`;

export const BotaoIcone = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
        opacity: 0.8;
    }
`;

export const ContainerTitulo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
`;

export const Titulo = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.coresWaypag.textoBranco};
`;

export const LinhaTitulo = styled.hr`
  border-color: ${({ theme }) => theme.coresWaypag.grayScale500};
  width: 100%;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 5rem;
`;

export const InputEmail = styled.input`
  width: 100%;
  border-radius: 4px;
  height: 56px;
  background: ${({ theme }) => theme.coresWaypag.background2};
  border: 1px solid ${({ theme }) => theme.coresWaypag.grayScale500};
  padding: 0px 12px 0px 16px;
  color: ${({ theme }) => theme.coresWaypag.textoBranco};
  font-family: "Poppins", sans-serif;
  outline: 0;

  &::placeholder {
    opacity: 0.7;
  }
`;
export const ContainerInputSenha = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.coresWaypag.grayScale500};
    margin-left: -70px;
  }
`;

export const InputSenha = styled.input`
  width: 100%;
  border-radius: 4px;
  height: 56px;
  background: ${({ theme }) => theme.coresWaypag.background2};
  border: 1px solid ${({ theme }) => theme.coresWaypag.grayScale500};
  padding: 0px 12px 0px 16px;
  color: ${({ theme }) => theme.coresWaypag.textoBranco};
  font-family: "Poppins", sans-serif;
  outline: 0;

  &::placeholder {
    opacity: 0.7;
  }
`;

export const BotaoMostrarSenha = styled.button`
  cursor: pointer;
  border: none;

  svg: {
    width: 20px;
    height: 20px;
  }

  &:hover {
        opacity: 0.8;
    }
`;

export const EsqueceuSenha = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.coresWaypag.primary};
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 21.79px;
  align-self: flex-end;
  cursor: pointer;

  &:hover {
        opacity: 0.8;
    }
`;

export const ContainerBotoesCadastro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const BotaoEntrar = styled.button`
  cursor: pointer;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 4px;
  background: ${({ theme }) => theme.coresWaypag.primary};
  border: none;

  &:hover {
        opacity: 0.8;
    }
`;

export const TextoBotaoEntrar = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.coresWaypag.textoBranco};
`;

export const BotaoEntrarGoogle = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 56px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.coresWaypag.primary};
  box-sizing: border-box;
  background: transparent;
  margin-bottom: 4rem;

  &:hover {
        opacity: 0.8;
    }
`;

export const TextoBotaoEntrarGoogle = styled.p`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.coresWaypag.primary};
`;

export const TextoNaoTemConta = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.79px;
  text-align: center;
  color: ${({ theme }) => theme.coresWaypag.textoBranco};

  a {
    color: ${({ theme }) => theme.coresWaypag.primary};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
  }
`;
