import { styled } from "styled-components";

export const Container = styled.div`
    border-radius: 6px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.32);
    padding-bottom: 2rem;
    padding-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    background-color: #fff;
    display: block;
    box-sizing: border-box;
    padding-left: calc(1.5rem * 0.5);
    padding-right: calc(1.5rem * 0.5);
    
    width: 100%;
    

    @media (max-width: 576px) {
        margin-left: 0;
        margin-right: 0;
    }

`
export const CabecalhoContainer = styled.div`
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
`
export const Titulo = styled.h1`
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0px;
    line-height: 34px;

    margin: 0;
    display: inline;
    box-sizing: border-box;
`

export const ContainerStatus = styled.div`
    display: inline;
    box-sizing: border-box;

    span {
        font-family: "Open Sans", sans-serif;
        padding-left: 0.65em;
        padding-right: 0.65em;
        padding-top: 0.35em;
        padding-bottom: 0.35em;
        font-size: 0.75em;
        font-weight: 700;
        border-radius: 0.375rem;
        line-height: 1.2;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        box-sizing: border-box;
        margin: 0;
        background-color: ${({ status }) => status};
        color: white;
    }
`

export const Subtitulo = styled.h1`
    margin: 0;
    margin-bottom: 1rem;
    margin-left: 1rem;
    font-size: 19px;
    font-weight: 500;
    font-family: sans-serif;
    letter-spacing: 0.15px;
    line-height: 28px;
    color: rgba(133, 133, 133);
    opacity: 0.9;
`

export const ContainerDataHora = styled.div`
    margin-bottom: 1.5rem;
    display: flex;
`

export const LinhaDataHora = styled.div`
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;

`

export const IconeData = styled.div`
    display: block;

    svg {
        color: ${({ theme }) => theme.coresCardapio.brand900};
        margin-top: 0.22rem;
    }
`

export const IconeHora = styled.div`
    margin-left: 1rem;
    display: block;

    svg {
        color: ${({ theme }) => theme.coresCardapio.brand900};
        margin-top: 0.22rem;
    }
`

export const TextoDataHora = styled.h5`
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 24px;  
    margin: 0;
    margin-left: 0.5rem;
    display: block;
`
export const ContainerDetalhesPedido = styled.div`
    margin-left: 1rem;
    display: block;
`

export const TextoDetalhesPedido = styled.p`
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 19px;
    letter-spacing: 0.15px;
    line-height: 28px;

    margin-bottom: 0.25rem;
    margin-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
`

export const TextoTotalPedido = styled.p`
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0px;
    line-height: 34px;

    margin-bottom: 0.25rem;
    margin-right: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    box-sizing: border-box;

    color: ${({ theme }) => theme.coresCardapio.semanticSucess};

    span {
        color: ${({ theme }) => theme.coresCardapio.semanticSucess}
    }
`

export const FooterCard = styled.div`
    padding-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
`

export const TextoVerMaisDetalhes = styled.button`
    cursor: pointer;
    font-size: 18px;
    color: ${({ theme }) => theme.coresCardapio.brand500};
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
`



export const BotaoCancelarPedido = styled.button`


    cursor: pointer;

    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 24px;

    padding: 1rem;
    border-width: 1px;
    border-radius: 8px;
    border-color: #e74a3b;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px rgba(0, 0, 0, 0.075);
    box-sizing: border-box;


    text-transform: uppercase;
    display: inline;
    background-color: transparent;
    color: #e74a3b;

    visibility: ${({ status }) => status === 'CANCELADO' ? 'hidden' : status === 'PEDIDO ACEITO' ? 'hidden' : 'visible'};

    &:hover {
        background-color: #e74a3b;
        color: white;
        box-shadow: 0px 0px 6px 1px #e74a3b;
        transition: 0.3s;
    }
`