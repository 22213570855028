import React from "react";
import { ReactComponent as Logo } from "../../assets/images/wm_logo_branco.svg";
import { Container } from "./styles";

export const Rodape = () => {
  return (
    <Container>
      <Logo />
    </Container>
  );
};

export default Rodape;
