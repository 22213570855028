export function getUrlCardapios(urlAmigavel, tipoCardapio, slugMesa) {
  let url = "/";
  if (urlAmigavel) {
    url = `/menu/${urlAmigavel}`;
    if (tipoCardapio) {
      url += `/${tipoCardapio}`;
      if (slugMesa) {
        url += `/${slugMesa}`;
      }
    }
  }
  return url + "/";
}
