const temaWaypag = {
  titulo: "light",
  temaDarkAtivo: false,
  coresWaypag: {
    background1: "rgba(30, 30, 30, 1)",
    background2: "rgba(49, 49, 49, 1)",

    primary: "rgba(24, 143, 252, 1)",

    grayScale900: "rgba(13, 13, 13, 1)",
    grayScale500: "rgba(133, 133, 133, 1)",

    neutral60: "rgba(138, 146, 151, 1)",

    semanticDanger: "rgba(231, 74, 59, 1)",
    semanticSuccess: "rgba(30, 185, 27, 1)",

    textoBranco: "rgba(255, 255, 255, 1)",
  },
};

export default temaWaypag;
