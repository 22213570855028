import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import {
  CampoObservacoes,
  ContainerBotaoAdicionarAoCarrinho,
  PaginaContainer,
  Separador,
} from "../styles";
import img_acai from "../imagens-placeholder/img_acai.webp";
import { ReactComponent as Borda1 } from "../imagens-placeholder/borda.svg";
import { ReactComponent as Borda2 } from "../imagens-placeholder/borda2.svg";
import { ReactComponent as Borda3 } from "../imagens-placeholder/borda3.svg";
import { ReactComponent as Borda4 } from "../imagens-placeholder/borda4.svg";
import Escolha, { Adicionar } from "../../escolhaExpansivel";
import { useTheme } from "styled-components";
import { useUserAuth } from "../../../../contexts/userAuth";
import { useApi } from "../../../../contexts/api";
import { CircularProgress } from "@mui/material";
import { checarModoCardapio } from "../../../../utils/checarModoCardapio";
import { useParams } from "react-router-dom";
import { Fragment } from "react";
import { toast } from "react-toastify";
import ModalLogin from "../../../modalLogin";
import { useCarrinho } from "../../../../contexts/carrinho";
import { tratarCasasDecimais } from "../../../../utils/tratarCasasDecimais";

export const PaginaBordas = ({
  produto,
  mudarPagina,
  dados,
  setDados,
  close,
  modoCardapio = false,
  precoCombinado,
}) => {
  const theme = useTheme();
  const { dadosEmpresa, modoMesa, logado } = useUserAuth();
  const { get } = useApi();
  const { adicionarItemCombinado } = useCarrinho();

  const graficosBordas = [<Borda1 />, <Borda2 />, <Borda3 />, <Borda4 />];

  const [loadingFinalizar, setLoadingFinalizar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dadosBorda, setDadosBorda] = useState([]);
  const [escolhasFeitas, setEscolhasFeitas] = useState({});
  const [dadosEscolhas, setDadosEscolhas] = useState({});
  const [observacoes, setObservacoes] = useState("");
  const [valorCalculado, setValorCalculado] = useState(0);

  const [abrirLogin, setAbrirLogin] = useState(false);

  useEffect(() => {
    getDadosBorda();
  }, []);

  useEffect(() => {
    if (dadosBorda) {
      setLoading(false);
    }
  }, [dadosBorda]);

  useEffect(() => {
    calcularValorTotal();
  }, [dadosEscolhas, escolhasFeitas]);

  function getGraficoBorda() {
    return graficosBordas[dados.quantidadeSabores - 1];
  }

  function getDadosBorda() {
    setLoading(true);
    get(
      `produtos/menu-componente/buscar-vinculo-produto/${dadosEmpresa.idEmpresa}/${produto.idProdutoEmpresa}`
    )
      .then((response) => {
        if (response.data.length > 0) {
          let complementosOrdenados = response.data.sort((a, b) => {
            return a.produtoMenu.indice - b.produtoMenu.indice;
          });

          complementosOrdenados = complementosOrdenados.filter(
            (complemento) => {
              return complemento.produtoMenu.categoriaProduto == "BORDA";
            }
          );

          setDadosBorda(complementosOrdenados);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao buscar complementos do produto");
        close();
      });
  }

  function atribuirProximoId(totalItens, index, proximoItemFinal) {
    let indexMap = 0;

    if (index + 1 < totalItens) {
      indexMap = index + 1;
    } else {
      indexMap = proximoItemFinal;
    }

    return indexMap;
  }

  function atualizarObservacoes(event) {
    let texto = event.target.value;

    if (texto.length <= 150) {
      setObservacoes(event.target.value);
    }
  }

  function achatarArvore(arvore, propriedade) {
    let resultado = [];

    function percorrer(node) {
      if (node) {
        if (node[propriedade] !== undefined) {
          resultado.push(node);
        }

        for (let chave in node) {
          if (node[chave] && typeof node[chave] === "object") {
            percorrer(node[chave]);
          }
        }
      }
    }

    percorrer(arvore);
    return resultado;
  }

  function garantirValor(valor, base = 0) {
    if (valor) {
      return valor;
    } else {
      return base;
    }
  }

  function calcularValorTotal() {
    let dadosNovos = { ...dados };
    dadosNovos.observacoes = observacoes;
    dadosNovos.borda = dadosEscolhas;
    setDados(dadosNovos);

    if (!logado && !modoMesa) {
      setAbrirLogin(true);
      return;
    }

    let item = {
      ...produto,
      dadosPedido: dadosNovos,
    };

    console.log(item);

    let arrayComplementos = achatarArvore(
      item.dadosPedido.complementos,
      "idProdutoMenu"
    );

    let valorComplementos = arrayComplementos.reduce((acc, item) => {
      return (
        acc +
        garantirValor(parseFloat(item.valorComponente)) *
          garantirValor(item.quantidade, 1)
      );
    }, 0);

    let arrayBordas = achatarArvore(item.dadosPedido.borda, "idProdutoMenu");
    let valorBordas = arrayBordas.reduce((acc, item) => {
      return (
        acc +
        garantirValor(parseFloat(item.valorComponente)) *
          garantirValor(item.quantidade, 1)
      );
    }, 0);

    let valorTotal;
    let valorMaior = 0;

    try {
      if (precoCombinado === "MEDIA_PRECO") {
        let quantidade = item.dadosPedido.quantidadeSabores;
        let valorSomado = 0;

        for (let i = 0; i < item.dadosPedido.sabores?.length; i++) {
          const sabor = item.dadosPedido.sabores[i];
          console.log(sabor);

          valorSomado +=
            parseFloat(sabor.tabelaDePreco.precoVenda) * sabor.quantidade;
        }
        console.log(valorSomado);
        valorSomado += parseFloat(item.dadosPedido.tamanho.valor.precoVenda);
        console.log(valorSomado);
        valorTotal = tratarCasasDecimais(valorSomado / quantidade, true, 2);
        console.log(valorTotal);

        item.dadosPedido.valorTotal = valorTotal;
        item.dadosPedido.quantidadePedido = 1;

        let valorTotalCalculado = valorComplementos + valorBordas + valorTotal;
        setValorCalculado(valorTotalCalculado);
        return { item, valorTotal };
      } else if (precoCombinado === "MAIOR_PRECO") {
        for (let i = 0; i < item.dadosPedido.sabores?.length; i++) {
          const sabor = item.dadosPedido.sabores[i];
          if (parseFloat(sabor.tabelaDePreco.precoVenda) > valorMaior) {
            valorMaior = parseFloat(sabor.tabelaDePreco.precoVenda);
          }
        }

        if (
          parseFloat(item.dadosPedido.tamanho.valor.precoVenda) > valorMaior
        ) {
          valorMaior = parseFloat(item.dadosPedido.tamanho.valor.precoVenda);
        }

        valorTotal = valorMaior;
        item.dadosPedido.valorTotal = valorTotal;
        item.dadosPedido.quantidadePedido = 1;

        let valorTotalCalculado = valorComplementos + valorBordas + valorTotal;
        setValorCalculado(valorTotalCalculado);
        return { item, valorMaior };
      }
    } catch (error) {
      console.log(error);
      toast.error("Erro ao adicionar produto ao carrinho!");
    }
  }

  async function finalizar(close) {
    if (loadingFinalizar) {
      return;
    }
    try {
      setLoadingFinalizar(true);
      if (precoCombinado === "MEDIA_PRECO") {
        let { item, valorTotal } = calcularValorTotal();
        console.log(item);

        await adicionarItemCombinado(item, precoCombinado);
      } else if (precoCombinado === "MAIOR_PRECO") {
        let { item, valorMaior } = calcularValorTotal();
        await adicionarItemCombinado(item, precoCombinado, valorMaior);
      }
      close();
      setLoadingFinalizar(false);
    } catch (error) {
      setLoadingFinalizar(false);
      console.log(error);
      toast.error("Erro ao adicionar produto ao carrinho!");
    }
  }

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "40vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{ color: theme.coresCardapio.brand500 }}
        style={{ alignSelf: "center" }}
      />
    </div>
  ) : (
    <PaginaContainer>
      <Separador />
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          gap: 20,
          margin: "20px 0",
        }}
      >
        {getGraficoBorda()}
        {dadosBorda?.length > 0 && (
          <p style={{ color: theme.coresCardapio.textoEscuro }}>
            Caso deseje, especifique a borda nas observações
          </p>
        )}
      </div>
      {dadosBorda?.length > 0 && <Separador />}
      {dadosBorda?.map((complemento, index) => {
        if (complemento.produtoMenu.quantidadeMaxima > 1) {
          return (
            <div
              key={`complemento${complemento.produtoMenu.id}`}
              style={{ maxWidth: `100%`, width: `100%` }}
            >
              <Adicionar
                key={
                  "adicionar" +
                  complemento.produtoMenu.id +
                  complemento.produtoMenu.descricao
                }
                idProximo={atribuirProximoId(
                  dadosBorda.length,
                  index,
                  "itemFinal"
                )}
                aberto={true}
                complemento={complemento}
                escolhasFeitas={escolhasFeitas}
                setEscolhasFeitas={setEscolhasFeitas}
                setDadosEscolhas={setDadosEscolhas}
                index={index}
                modoCardapio={modoCardapio}
              />
            </div>
          );
        } else if (complemento.produtoMenu.quantidadeMaxima == 1) {
          return (
            <div
              key={`complemento${complemento.produtoMenu.id}`}
              style={{ maxWidth: `100%`, width: `100%` }}
            >
              <Escolha
                key={"escolha" + complemento.id + complemento.descricao}
                aberto={true}
                idProximo={atribuirProximoId(
                  dadosBorda.length,
                  index,
                  "itemFinal"
                )}
                complemento={complemento}
                escolhasFeitas={escolhasFeitas}
                setEscolhasFeitas={setEscolhasFeitas}
                setDadosEscolhas={setDadosEscolhas}
                modoCardapio={modoCardapio}
              />
            </div>
          );
        }
      })}
      {!modoCardapio && (
        <Fragment>
          <CampoObservacoes
            style={{ color: theme.coresCardapio.gray400, marginTop: 24 }}
            value={observacoes}
            onChange={atualizarObservacoes}
            helperText="0/150"
            id="demo-helper-text-aligned"
            label="Observações"
          />
          <BotaoAdicionarAoCarrinho
            loading={loadingFinalizar}
            id="complementoitemFinal"
            onClick={() => {
              finalizar(close);
            }}
            total={valorCalculado}
          />
        </Fragment>
      )}
      {!logado && (
        <ModalLogin
          aberto={abrirLogin}
          setAberto={setAbrirLogin}
          loginGoogle={true}
          etapaLogin={"login"}
        />
      )}
    </PaginaContainer>
  );
};

export const BotaoAdicionarAoCarrinho = ({
  onClick,
  id,
  total,
  quantidade,
  loading,
}) => {
  function formatarReal(valor) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(valor);
  }

  return (
    <ContainerBotaoAdicionarAoCarrinho onClick={onClick} id={id}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {loading ? (
          <CircularProgress
            size={20}
            sx={{ color: "#fff" }}
            style={{ alignSelf: "center", marginRight: 8 }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCartPlus}
            style={{ fontSize: "18px", marginRight: 10 }}
          />
        )}
        ADICIONAR
      </div>
      {formatarReal(total)}
    </ContainerBotaoAdicionarAoCarrinho>
  );
};

export default PaginaBordas;
