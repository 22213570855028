import { styled } from "styled-components";

export const Container = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.coresWaypag.background1};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: scroll;
`
export const ContainerIcone = styled.div`
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;

    svg {
        color: ${({ theme }) => theme.coresWaypag.primary};
        width: 20px;
        height: 20px;
    }
`

export const BotaoIcone = styled.button`
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

export const ContainerTitulo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2.2rem;
`

export const Titulo = styled.p`
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};
`

export const LinhaTitulo = styled.hr`
    border-color: #858585;
    width: 100%;
`

export const CpfNotaContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
`
export const TextoCpfNota = styled.p`
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};
`
export const InputCpf = styled.input`
    width: 100%;
    border-radius: 4px;
    height: 56px;
    background: #2E2E2E;
    border: 1px solid #858585;
    padding: 0px 12px 0px 16px;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    outline: 0;
    margin-bottom: 2rem;

    &::placeholder {
        opacity: 0.7;
    }
`

export const BotaoIconeCpfDesmarcado = styled.button`
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

`

export const BotaoIconeCpfMarcado = styled.button`
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    
    svg {
        color: ${({ theme }) => theme.coresWaypag.primary};
        border: 1px solid ${({ theme }) => theme.coresWaypag.primary};
    }
`

export const ContainerSelecioneFormaPagamento = styled.div`
    margin-bottom: 1.7rem;
`

export const TituloSelecioneFormaPagamento = styled.p`
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};
`

export const ContainerBotoes = styled.div`
    display: flex;
    gap: 1.3rem;
    margin-bottom: 12rem;
`

export const BotaoDebito = styled.button`
    padding: 16px 24px;
    width: 50%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    background: transparent;
    color: ${({ theme }) => theme.coresWaypag.primary};
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.coresWaypag.primary};
    cursor: pointer;

    &:focus {
        background: ${({ theme }) => theme.coresWaypag.primary};
        
        p {
            color: ${({ theme }) => theme.coresWaypag.textoBranco}
        }

        svg {
            color: ${({ theme }) => theme.coresWaypag.textoBranco};
        }
    }

    &:hover {
        opacity: 0.8;
    }
`

export const BotaoCredito = styled.button`
    padding: 16px 24px;
    width: 50%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    background: transparent;
    color: ${({ theme }) => theme.coresWaypag.primary};
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.coresWaypag.primary};
    cursor: pointer;

    &:focus {
        background: ${({ theme }) => theme.coresWaypag.primary};

        p {
            color: #FFF;
        }

        svg {
            color: #FFF;
        }
    }

    &:hover {
        opacity: 0.8;
    }
`

export const TituloBotoes = styled.p`
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.coresWaypag.primary};
`

export const TotalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2rem;
`

export const TituloValorTotal = styled.p`
   font-family: "Poppins", sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 24px;
   line-height: 36px;
   color: #EEEEEE;
`

export const BotaoFazerPagamento = styled.button`
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 53px;
    border-radius: 16px;
    background: #1EB91B;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

export const TituloBotaoFazerPagamento = styled.p`
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.coresWaypag.textoBranco};

`

