import React, { useEffect, useState } from "react";
import {
  BotaoProximo,
  ItemSabor,
  Linha,
  Separador,
  Tamanhos,
  PaginaContainer,
  TextoSobre,
  ContainerTamanhos,
} from "../styles";
import formatarReal from "../../../../utils/formatarReal";
import Escolha from "../../escolhaExpansivel";
import img_acai from "../imagens-placeholder/img_acai.webp";

export const PaginaTamanhos = ({
  produto,
  tamanhos,
  dados,
  setDados,
  mudarPagina,
  modoCardapio = false,
}) => {
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState(null);
  const [quantidadeSabores, setQuantidadeSabores] = useState(null);
  const [quantidadeSaboresSelecionada, setQuantidadeSaboresSelecionada] =
    useState(null);
  const [proximoAtivo, setProximoAtivo] = useState(false);

  useEffect(() => {
    if (tamanhoSelecionado && quantidadeSaboresSelecionada !== null) {
      setProximoAtivo(true);
    } else {
      setProximoAtivo(false);
    }
  }, [tamanhoSelecionado, quantidadeSaboresSelecionada]);

  useEffect(() => {
    if (dados.tamanho) {
      setTamanhoSelecionado(dados.tamanho);
      if (dados.quantidadeSabores) {
        setQuantidadeSabores(getQuantidadeSabores(dados.tamanho));
        setQuantidadeSaboresSelecionada(dados.quantidadeSabores);
      }
    }
  }, []);

  // useEffect(() => {
  //   setQuantidadeSaboresSelecionada(null);
  // }, [tamanhoSelecionado]);

  function salvarDados() {
    let novosDados = { ...dados };
    novosDados.tamanho = tamanhoSelecionado;
    novosDados.quantidadeSabores = quantidadeSaboresSelecionada;
    setDados(novosDados);
  }

  function getQuantidadeSabores(tamanho) {
    if (tamanho.quatroSabores) {
      return 4;
    }
    if (tamanho.tresSabores) {
      return 3;
    }
    if (tamanho.doisSabores) {
      return 2;
    }
    return 1;
  }

  const ItemTamanho = ({
    tamanho,
    tamanhoSelecionado,
    setTamanhoSelecionado,
  }) => {
    function selecionarTamanho() {
      setTamanhoSelecionado(tamanho);
      let quantidadeSabores = getQuantidadeSabores(tamanho);
      setQuantidadeSabores(quantidadeSabores);
      setQuantidadeSaboresSelecionada(null);
    }

    return (
      <Tamanhos
        $ativo={
          tamanhoSelecionado &&
          tamanhoSelecionado.idTamanho === tamanho.idTamanho
        }
        onClick={() => selecionarTamanho()}
      >
        <p>{tamanho.descricao}</p>
        <p className="valor">{formatarReal(tamanho.valor.precoVenda)}</p>
        {/* {item.valorPromocional && (
          <p className="riscado">{formatarReal(item.valorPromocional)}</p>
        )} */}
      </Tamanhos>
    );
  };

  const LinhaSabores = ({
    quantidadeSabores,
    quantidadeSaboresSelecionada,
    tamanhoSelecionado,
  }) => {
    const [linhas, setLinhas] = useState([]);

    useEffect(() => {
      gerarLinhaSabores();
    }, [quantidadeSaboresSelecionada, tamanhoSelecionado, quantidadeSabores]);

    function gerarLinhaSabores() {
      let linhaTemp = [];
      for (let index = 1; index < quantidadeSabores + 1; index++) {
        linhaTemp.push(
          <ItemSabor
            key={index + "saborPizza"}
            $selecionado={quantidadeSaboresSelecionada === index}
            onClick={() => {
              setQuantidadeSaboresSelecionada(index);
            }}
          >
            <p>
              {index} {index === 1 ? "Sabor" : "Sabores"}
            </p>
          </ItemSabor>
        );
      }
      setLinhas(linhaTemp);
    }

    return <Linha style={{ minHeight: 40 }}>{linhas}</Linha>;
  };

  function verificarEstoque() {
    if (produto.estocado) {
      if (produto.estoque > 0) {
        return true;
      }
      return false;
    }

    return true;
  }
  return (
    <>
      <PaginaContainer>
        <Separador />
        {verificarEstoque() && (
          <ContainerTamanhos>
            {tamanhos.map((tamanho, index) => (
              <ItemTamanho
                key={tamanho.idTamanho + "tamanhoCombinado" + index}
                tamanho={tamanho}
                tamanhoSelecionado={tamanhoSelecionado}
                setTamanhoSelecionado={setTamanhoSelecionado}
              />
            ))}
          </ContainerTamanhos>
        )}
        {tamanhoSelecionado && (
          <LinhaSabores
            quantidadeSabores={quantidadeSabores}
            quantidadeSaboresSelecionada={quantidadeSaboresSelecionada}
            tamanhoSelecionado={tamanhoSelecionado}
          />
        )}
        <BotaoProximo
          disabled={!verificarEstoque()}
          $ativo={(proximoAtivo || modoCardapio) && verificarEstoque()}
          onClick={() => {
            if (proximoAtivo || modoCardapio) {
              salvarDados();
              if (quantidadeSaboresSelecionada == 1) {
                mudarPagina(2);
                return;
              }
              mudarPagina();
            }
          }}
        >
          {verificarEstoque() ? "PRÓXIMO" : "PRODUTO INDISPONÍVEL"}
        </BotaoProximo>
      </PaginaContainer>
    </>
  );
};
