import React, { Fragment, useEffect, useRef, useState } from "react";
import GerarModalResponsivo from "../../gerarModalResponsivo";
import { X } from "phosphor-react";
import { StepLabel, TextField } from "@mui/material";
import {
  CampoDeBuscaAutoComplete,
  CampoDeSelecaoOutlined,
  CampoDeSenha,
  CampoDeSenhaOutlined,
  CampoDeTexto,
  CampoDeTextoOutlined,
  DescricaoErro,
} from "../../campoInput";
import { BotaoCardapioPreenchido, BotaoCardapioVazado } from "../../botao";
import { ContainerInputs, ContianerBotoes, Divider, Linha } from "../styles";
import { Close, House } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useTheme } from "styled-components";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Chip from "../../chip";
import { useApi } from "../../../contexts/api";
import useTamanhoTela from "../../../utils/useTamanhoTela";
import { debounce } from "@mui/material/utils";
import { toast } from "react-toastify";

const Endereco = ({
  fechar,
  loginGoogle,
  mudarTela,
  dadosCadastro,
  modoEdicao = false,
  etapa,
  finalizar,
  apenasNovaEntrada = false,
}) => {
  const [cep, setCep] = useState("");
  const [erroCep, setErroCep] = useState(false);
  const [sucessoCep, setSucessoCep] = useState(false);
  const [endereco, setEndereco] = useState("");
  const [erroEndereco, setErroEndereco] = useState(false);
  const [sucessoEndereco, setSucessoEndereco] = useState(false);
  const [numero, setNumero] = useState("");
  const [erroNumero, setErroNumero] = useState(false);
  const [sucessoNumero, setSucessoNumero] = useState(false);
  const [complemento, setComplemento] = useState("");
  const [erroComplemento, setErroComplemento] = useState(false);
  const [sucessoComplemento, setSucessoComplemento] = useState(false);
  const [bairro, setBairro] = useState("");
  const [erroBairro, setErroBairro] = useState(false);
  const [sucessoBairro, setSucessoBairro] = useState(false);
  const [cidade, setCidade] = useState(null);
  const [erroCidade, setErroCidade] = useState(false);
  const [sucessoCidade, setSucessoCidade] = useState(false);
  const [estado, setEstado] = useState("");
  const [erroEstado, setErroEstado] = useState(false);
  const [estadoID, setEstadoID] = useState(null);
  const [sucessoEstado, setSucessoEstado] = useState(false);
  const [referencia, setReferencia] = useState("");
  const [erroReferencia, setErroReferencia] = useState(false);
  const [sucessoReferencia, setSucessoReferencia] = useState(false);

  const [chipCasa, setChipCasa] = useState(false);
  const [chipTrabalho, setChipTrabalho] = useState(false);
  const [chipOutro, setChipOutro] = useState(false);
  const [erroChips, setErroChips] = useState(false);

  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [cidadeLiberado, setCidadeLiberado] = useState(false);
  const [cidadePesquisa, setCidadePesquisa] = useState(null);

  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const { get, post } = useApi();
  const { largura } = useTamanhoTela();

  useEffect(() => {
    getDadosUFs();
  }, []);

  useEffect(() => {
    if (estado) {
      setCidadeLiberado(true);
    }
  }, [estado]);

  useEffect(() => {
    if (cidadePesquisa) {
      const timer = setTimeout(() => {
        pesquisarCidade(cidadePesquisa);
      }, 400);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [cidadePesquisa]);

  useEffect(() => {
    if (dadosCadastro.endereco) {
      setCep(dadosCadastro.endereco?.cep);
      setEndereco(dadosCadastro.endereco?.endereco);
      setNumero(dadosCadastro.endereco?.numero);
      setComplemento(dadosCadastro.endereco?.complemento);
      setBairro(dadosCadastro.endereco?.bairro);
      setCidade(dadosCadastro.endereco?.cidade);
      setEstado(dadosCadastro.endereco?.estado);
      setEstadoID(dadosCadastro.endereco?.estadoID);
      setReferencia(dadosCadastro.endereco?.referencia);
      setChipCasa(dadosCadastro.endereco?.chipCasa);
      setChipTrabalho(dadosCadastro.endereco?.chipTrabalho);
      setChipOutro(dadosCadastro.endereco?.chipOutro);

      //Erros

      setErroCep(dadosCadastro.errosEndereco?.erroCep);
      setErroEndereco(dadosCadastro.errosEndereco?.erroEndereco);
      setErroNumero(dadosCadastro.errosEndereco?.erroNumero);
      setErroComplemento(dadosCadastro.errosEndereco?.erroComplemento);
      setErroBairro(dadosCadastro.errosEndereco?.erroBairro);
      setErroCidade(dadosCadastro.errosEndereco?.erroCidade);
      setErroEstado(dadosCadastro.errosEndereco?.erroEstado);
      setErroReferencia(dadosCadastro.errosEndereco?.erroReferencia);
      setErroChips(dadosCadastro.errosEndereco?.erroChips);
    }
  }, [dadosCadastro, etapa]);

  function getDadosUFs() {
    function capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    setCidade(null);
    setCidadePesquisa(null);
    post("/waybe/cep/buscar-todas-unidades-federativas?size=30", {})
      .then((response) => {
        let ufs = response.data.content.map((uf) => {
          return {
            titulo: capitalize(uf.nome),
            valor: uf.uf,
          };
        });
        setUfs(ufs);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  function pesquisarCidade(valor) {
    if (valor.length <= 3 || !estado) {
      return;
    }
    post("/waybe/cep/buscar-todas-cidades?size=20", {
      nome: valor,
      unidadeFederativa: estado,
    })
      .then((response) => {
        setCidades([valor, ...response.data.content]);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  function validarCamposGenerico(estado, setErro, setSucesso) {
    if (estado === "") {
      setErro("Campo obrigatório");
      setSucesso(false);
    } else {
      setErro(false);
      setSucesso(true);
    }
  }

  function validarCep(cep, setErro, setSucesso) {
    if (cep === "") {
      setErro("Campo obrigatório");
      setSucesso(false);
    } else {
      if (cep.length < 9) {
        setErro("O CEP deve possuir 8 numeros");
        setSucesso(false);
      } else {
        setErro(false);
        setSucesso(true);
        getDadosCEP(cep);
      }
    }
  }

  function validarCamposEstado(estado, setErro, setSucesso) {
    if (estado === "" || !estado || estado.length !== 2) {
      setErro("Campo obrigatório");
      setSucesso(false);
    } else {
      setErro(false);
      setSucesso(true);
    }
  }

  const proximaEtapaCheck = async () => {
    let erro = false;
    if (cep === "" || !cep || erroCep) {
      setErroCep("Campo obrigatório");
      erro = true;
    }

    if (endereco === "" || !endereco || erroEndereco) {
      setErroEndereco("Campo obrigatório");
      erro = true;
    }

    if (numero === "" || !numero || erroNumero) {
      setErroNumero("Campo obrigatório");
      erro = true;
    }

    if (bairro === "" || !bairro || erroBairro) {
      setErroBairro("Campo obrigatório");
      erro = true;
    }

    if (cidade === "" || !cidade || erroCidade) {
      setErroCidade("Campo obrigatório");
      erro = true;
    }

    if (estado === "" || !estado || erroEstado || estado.length !== 2) {
      setErroEstado("Campo obrigatório");
      erro = true;
    }

    if (chipCasa === false && chipTrabalho === false && chipOutro === false) {
      setErroChips("Selecione uma opção");
      erro = true;
    }

    if (erro) return;

    setLoading(true);
    const novosDadosCadastro = {
      ...dadosCadastro,
      endereco: {
        cep,
        endereco,
        numero,
        complemento,
        bairro,
        cidade,
        estado,
        estadoID,
        referencia,
        chipCasa,
        chipTrabalho,
        chipOutro,
      },
      errosEndereco: {
        erroCep,
        erroEndereco,
        erroNumero,
        erroComplemento,
        erroBairro,
        erroCidade,
        erroEstado,
        erroReferencia,
        erroChips,
      },
    };

    if (modoEdicao) {
      await finalizar(novosDadosCadastro);
    } else {
      let sucesso = await finalizar(novosDadosCadastro);
      if (sucesso) {
        mudarTela("pagamento");
      }
    }
    setLoading(false);
  };

  function formatarCep(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length > 8) {
      valorFormatado = valorFormatado.substring(0, 8);
    }

    if (valorFormatado.length >= 6) {
      valorFormatado =
        valorFormatado.substring(0, 5) + "-" + valorFormatado.substring(5);
    }

    return valorFormatado;
  }

  async function getDadosCEP(cep) {
    if (!cep) {
      return;
    }
    let cepLimpo = cep.replace(/\D/g, "");

    if (cepLimpo.length === 8) {
      let dadosLogradouro = await get(
        `/waybe/cep/buscar-logradouro/${cepLimpo}`
      );

      if (
        dadosLogradouro.status == 200 &&
        dadosLogradouro?.data?.nomeAbreviado
      ) {
        setEndereco(dadosLogradouro.data.nomeAbreviado);
        setSucessoEndereco(true);
        setErroEndereco(false);
        let dadosBairro = await get(
          `/waybe/cep/buscar-bairro/${dadosLogradouro.data.idBairro}`
        );

        if (dadosBairro.status == 200 && dadosBairro?.data?.nomeAbreviado) {
          setBairro(dadosBairro.data.nomeAbreviado);
          setSucessoBairro(true);
          setErroBairro(false);
          let dadosCidade = await get(
            `/waybe/cep/buscar-cidade/${dadosBairro.data.idCidade}`
          );
          if (dadosCidade.status == 200 && dadosCidade?.data?.nomeAbreviado) {
            setCidade(dadosCidade.data);
            setSucessoCidade(true);
            setErroCidade(false);
            setEstado(dadosCidade.data.unidadeFederativa);
            setSucessoEstado(true);
            setErroEstado(false);
            definirEstadoID(dadosCidade.data.unidadeFederativa);
            // let dadosUf = await get(`/waybe/cep/por-sigla-uf`);
            // setEstado(dadosUf.data);
          }
        }
      }
    }
  }

  function definirEstadoID(estado) {
    get("/waybe/cep/por-sigla-uf?uf=" + estado)
      .then((response) => {
        setEstadoID(response.data);
      })
      .catch((erro) => {
        setEstadoID(null);
        setEstado("");
        setErroEstado("Erro ao selecionar estado");
        console.log(erro);
      });
  }

  // useEffect(() => {
  //   getDadosCEP();
  // }, [cep]);

  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, []);

  function voltar() {
    if (modoEdicao) {
      mudarTela("mostrarEnderecos");
    }
  }

  return (
    <div
      style={{ overflow: "scroll", maxHeight: largura < 990 ? "70vh" : null }}
      ref={scrollRef}
    >
      <Linha>
        <FontAwesomeIcon
          onClick={() => (apenasNovaEntrada ? fechar() : voltar())}
          icon={faArrowLeft}
          style={{
            fontSize: 28,
            color: theme.coresCardapio.brand500,
            cursor: modoEdicao ? "pointer" : "default",
            opacity: modoEdicao ? 1 : 0.2,
          }}
        />
        <h1>Endereço</h1>
        <FontAwesomeIcon
          onClick={() => fechar()}
          icon={faXmark}
          style={{ fontSize: 28, color: "red", cursor: "pointer" }}
        />
      </Linha>
      <Divider />
      {modoEdicao ? (
        <Fragment />
      ) : (
        <Linha style={{ justifyContent: "center" }}>
          <Stepper activeStep={1} alternativeLabel style={{ width: "100%" }}>
            {["", "", ""].map((label, index) => (
              <Step key={label + index + "stepper"}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Linha>
      )}
      <p>Marcar endereço como:</p>
      <Linha>
        <Chip
          label={"Casa"}
          valor={chipCasa}
          icon={<House />}
          onClick={() => {
            setChipCasa(true);
            setChipTrabalho(false);
            setChipOutro(false);
            setErroChips(false);
          }}
        />
        <Chip
          label={"Trabalho"}
          valor={chipTrabalho}
          onClick={() => {
            setChipTrabalho(true);
            setChipCasa(false);
            setChipOutro(false);
            setErroChips(false);
          }}
          icon={<FreeBreakfastIcon />}
        />
        <Chip
          label={"Outro"}
          valor={chipOutro}
          onClick={() => {
            setChipOutro(true);
            setChipCasa(false);
            setChipTrabalho(false);
            setErroChips(false);
          }}
          icon={<LocationOnIcon />}
        />
      </Linha>
      <Linha style={{ padding: 0 }}>
        <DescricaoErro
          // style={{ justifyContent: "center", padding: 0 }}
          erro={erroChips}
        />
      </Linha>
      <ContainerInputs>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"CEP"}
            error={erroCep}
            sucesso={sucessoCep}
            value={cep}
            onChange={(event) => {
              validarCep(event.target.value, setErroCep, setSucessoCep);
              setCep(formatarCep(event.target.value));
            }}
            variant={"outlined"}
            autoFocus={false}
          />
          <DescricaoErro erro={erroCep} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Endereço"}
            error={erroEndereco}
            sucesso={sucessoEndereco}
            value={endereco}
            onChange={(event) => {
              validarCamposGenerico(
                event.target.value,
                setErroEndereco,
                setSucessoEndereco
              );
              setEndereco(event.target.value);
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 100 }}
          />
          <DescricaoErro erro={erroEndereco} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Número"}
            error={erroNumero}
            sucesso={sucessoNumero}
            value={numero}
            onChange={(event) => {
              validarCamposGenerico(
                String(event.target.value).replace(/\D/g, ""),
                setErroNumero,
                setSucessoNumero
              );
              setNumero(String(event.target.value).replace(/\D/g, ""));
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 10 }}
          />
          <DescricaoErro erro={erroNumero} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Complemento"}
            error={erroComplemento}
            sucesso={sucessoComplemento}
            value={complemento}
            onChange={(event) => {
              // validarCamposGenerico(
              //   event.target.value,
              //   setErroComplemento,
              //   setSucessoComplemento
              // );
              setComplemento(event.target.value);
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 50 }}
          />
          <DescricaoErro erro={erroComplemento} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Bairro"}
            error={erroBairro}
            sucesso={sucessoBairro}
            value={bairro}
            onChange={(event) => {
              validarCamposGenerico(
                event.target.value,
                setErroBairro,
                setSucessoBairro
              );
              setBairro(event.target.value);
            }}
            variant={"outlined"}
            inputProps={{ maxLength: 50 }}
            autoFocus={false}
          />
          <DescricaoErro erro={erroBairro} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeSelecaoOutlined
            titulo={"Estado"}
            error={erroEstado}
            sucesso={sucessoEstado}
            valor={estado}
            items={ufs}
            onChange={(event) => {
              validarCamposEstado(
                event.target.value,
                setErroEstado,
                setSucessoEstado
              );
              setEstado(event.target.value);
              definirEstadoID(event.target.value);
              setCidade(null);
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 15 }}
          />
          <DescricaoErro erro={erroEstado} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeBuscaAutoComplete
            disabled={!cidadeLiberado}
            titulo={"Cidade"}
            items={cidades}
            error={erroCidade}
            sucesso={sucessoCidade}
            value={cidade ? cidade : null}
            onInputChange={(event, newInputValue) => {
              if (event) {
                setCidadePesquisa(newInputValue);
                if (newInputValue.length < 3) {
                  setCidades([]);
                }
              } else {
                setCidadePesquisa(null);
                setCidades([]);
              }
            }}
            onChange={(event, newValue) => {
              if (event) {
                validarCamposGenerico(
                  newValue,
                  setErroCidade,
                  setSucessoCidade
                );
                setCidade(newValue);
                // setCidades(newValue ? [newValue, ...cidades] : cidades);
              } else {
                setCidade(null);
              }
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 50 }}
          />
          <DescricaoErro erro={erroCidade} />
        </div>
        <div style={{ width: "100%" }}>
          <CampoDeTextoOutlined
            titulo={"Referência"}
            error={erroReferencia}
            sucesso={sucessoReferencia}
            value={referencia}
            onChange={(event) => {
              // validarCamposGenerico(
              //   event.target.value,
              //   setErroReferencia,
              //   setSucessoReferencia
              // );
              setReferencia(event.target.value);
            }}
            variant={"outlined"}
            autoFocus={false}
            inputProps={{ maxLength: 50 }}
          />
          <DescricaoErro erro={erroReferencia} />
        </div>
      </ContainerInputs>

      {modoEdicao ? (
        <BotaoCardapioPreenchido
          texto={"SALVAR"}
          style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
          tamanho={"LG"}
          onClick={() => {
            proximaEtapaCheck();
          }}
        />
      ) : (
        <Fragment>
          <ContianerBotoes>
            <span
              onClick={() => {
                mudarTela("pagamento");
              }}
              style={{
                color: theme.coresCardapio.brand500,
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Pular etapa
            </span>
            <BotaoCardapioPreenchido
              texto={"PRÓXIMO"}
              style={{ width: "100%", fontSize: 16, fontWeight: 400 }}
              tamanho={"LG"}
              onClick={() => {
                proximaEtapaCheck();
              }}
              loading={loading}
            />
          </ContianerBotoes>
        </Fragment>
      )}
    </div>
  );
};

export default Endereco;
