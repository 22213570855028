import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BotaoEntrar, BotaoEntrarGoogle, BotaoIcone, BotaoMostrarSenha, Container, ContainerBotoesCadastro, ContainerIcone, ContainerInputSenha, ContainerInputs, ContainerTitulo, EsqueceuSenha, InputEmail, InputSenha, LinhaTitulo, TextoBotaoEntrar, TextoBotaoEntrarGoogle, TextoNaoTemConta, Titulo } from './styles.js'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Eye, EyeSlash } from 'phosphor-react'
import { ReactComponent as IconeGoogle } from "../../../assets/images/icones/icon_google.svg";
import { useRef, useState } from 'react';

export function LoginWaypag() {
    const inputRef = useRef(null);
    const [mostarSenha, setMostrarSenha] = useState(false)

    function MostarSenha() {
        if (inputRef.current?.type === "password") {
          inputRef.current.type = "text";
        } else {
          if (inputRef.current !== null) inputRef.current.type = "password";
        }

        if (mostarSenha === false) {
            setMostrarSenha(true)
        } else {
            setMostrarSenha(false)
        }
      }


    return (
        <Container>
            <ContainerIcone>
                <BotaoIcone>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </BotaoIcone>
            </ContainerIcone>
            <ContainerTitulo>
                <Titulo>
                    Faça login
                </Titulo>
                <LinhaTitulo />
            </ContainerTitulo>

            <ContainerInputs>
                <InputEmail placeholder='Email' type='text' required />
                <ContainerInputSenha>
                    <InputSenha placeholder='Senha' type="password" required ref={inputRef} />
                        <BotaoMostrarSenha type='button' onClick={MostarSenha}>
                            {mostarSenha === false ? (
                                <EyeSlash size={20} />
                            ) : (
                                <Eye size={20} />
                            )}
                        </BotaoMostrarSenha>
                </ContainerInputSenha>
                <EsqueceuSenha href='#'>
                    Esqueceu a Senha?
                </EsqueceuSenha>
            </ContainerInputs>
            <ContainerBotoesCadastro>
                <BotaoEntrar>
                    <TextoBotaoEntrar>
                        Entrar
                    </TextoBotaoEntrar>
                </BotaoEntrar>
                <BotaoEntrarGoogle>
                    <IconeGoogle  />
                    <TextoBotaoEntrarGoogle>
                        Entrar com o Google
                    </TextoBotaoEntrarGoogle>
                </BotaoEntrarGoogle>
                <TextoNaoTemConta>
                    Não tem conta?
                    &nbsp;
                    <a href='/waypag/cadastro' target='blank'>Cadastre-se</a>
                </TextoNaoTemConta>
            </ContainerBotoesCadastro>
        </Container>
    )
}