import { Link } from "react-router-dom";
import { ButtonNavigation, Container } from "./styles";

export function Home() {
  return (
    <Container>
      <Link
        to="/menu/restaurante-waymenu/#/cardapio"
        style={{ textDecoration: "none" }}
      >
        <ButtonNavigation>Cardápio Geral</ButtonNavigation>
      </Link>

      <Link
        to="/menu/restaurante-waymenu/delivery/"
        style={{ textDecoration: "none" }}
      >
        <ButtonNavigation>Cardápio Delivery</ButtonNavigation>
      </Link>

      <Link
        to="/menu/restaurante-waymenu/mesa/"
        style={{ textDecoration: "none" }}
      >
        <ButtonNavigation>Cardápio Mesa</ButtonNavigation>
      </Link>

      <Link
        to="/menu/restaurante-waymenu/delivery/#/pedidos"
        style={{ textDecoration: "none" }}
      >
        <ButtonNavigation>Meus Pedidos</ButtonNavigation>
      </Link>

      <Link
        to="/menu/restaurante-waymenu/delivery/#/detalhesPedido"
        style={{ textDecoration: "none" }}
      >
        <ButtonNavigation>Detalhes do Pedido</ButtonNavigation>
      </Link>

      <Link to="/waypag/login" style={{ textDecoration: "none" }}>
        <ButtonNavigation>Waypag: Login (mobile)</ButtonNavigation>
      </Link>

      <Link to="/waypag/meusPagamentos" style={{ textDecoration: "none" }}>
        <ButtonNavigation>Waypag: Meus pagamentos (mobile)</ButtonNavigation>
      </Link>

      <Link to="/waypag/cpf" style={{ textDecoration: "none" }}>
        <ButtonNavigation>Waypag: CPF (mobile)</ButtonNavigation>
      </Link>

      <Link to="/waypag/cadastro" style={{ textDecoration: "none" }}>
        <ButtonNavigation>Waypag: Cadastro (mobile)</ButtonNavigation>
      </Link>

      <Link to="/waypag/formasPagamento" style={{ textDecoration: "none" }}>
        <ButtonNavigation>
          Waypag: Formas de pagamento (mobile)
        </ButtonNavigation>
      </Link>

      <Link to="/waypag/adicionarCartao" style={{ textDecoration: "none" }}>
        <ButtonNavigation>Waypag: Adicionar cartão (mobile)</ButtonNavigation>
      </Link>
      <Link
        to="/waypag/processandoPagamento"
        style={{ textDecoration: "none" }}
      >
        <ButtonNavigation>
          Waypag: Processando Pagamento (mobile)
        </ButtonNavigation>
      </Link>
    </Container>
  );
}
