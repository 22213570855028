import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faDollarSign,
  faGlobe,
  faMotorcycle,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { ReactComponent as IconeMais } from "../../assets/images/icones/add_circle_solid.svg";
import useTamanhoTela from "../../utils/useTamanhoTela";
import {
  AvatarLoja,
  BordaColorida,
  BotaoFecharPopup,
  Coluna,
  Container,
  ContainerCorpoModal,
  ContainerDados,
  ContainerPopup,
  ContainerRedesSociais,
  ContainerTelefone,
  CorpoModal,
  Indicador,
  Linha,
  LinhaInfos,
  LinhaTitulo,
  PopupHeader,
  TextoIcone,
  TextoLaranja,
  TextoTitulo,
  TextoVerde,
  TituloHeader,
  TituloTelefone,
  ConteudoTelefone,
  ContainerSobre,
  ConteudoSobre,
  TituloSobre,
  ContainerEndereco,
  TituloEndereco,
  ContainerConteudoEndereco,
  ConteudoEndereco,
  ContainerPagamento,
  TituloPagamento,
  TypographyTituloFormasDePagamento,
  StyledAccordion,
  ContainerModal,
  TypographyDescricaoFormasDePagamento,
  ImagemBandeira,
  ContainerBandeiras,
  ContainerBandeira,
} from "./styles";

import { BotaoCardapioPreenchido, BotaoCardapioVazado } from "../botao";
import { MapPin, X } from "phosphor-react";
import {
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";

import { useApi } from "../../contexts/api";
import { useNavigate, useParams } from "react-router-dom";
import formatarReal from "../../utils/formatarReal";
import { toast } from "react-toastify";
import ModalHorarioFuncionamento from "../modalHorarioFuncionamento";
import { useUserAuth } from "../../contexts/userAuth";
import ErroImagem from "../../assets/images/img-erro.png";
import { checarModoCardapio } from "../../utils/checarModoCardapio";

export const InformacoesRestaurante = () => {
  const { largura } = useTamanhoTela();
  const { get } = useApi();
  const tema = useTheme();
  const navigate = useNavigate();

  const { urlAmigavel, tipoCardapio } = useParams();
  const { dadosEmpresa } = useUserAuth();

  const [moduloSelecionado, setModuloSelecionado] = useState({});
  const [modoCardapio, setModoCardapio] = useState();

  useEffect(() => {
    setModoCardapio(checarModoCardapio(tipoCardapio));
  }, [tipoCardapio]);

  useEffect(() => {
    dadosEmpresa?.listaModulos?.forEach((modulo) => {
      if (
        String(modulo.tipo).toUpperCase() === String(tipoCardapio).toUpperCase()
      ) {
        setModuloSelecionado(modulo);
      }
    });
  }, []);

  return (
    <Container>
      {moduloSelecionado && (
        <Dados tipo={tipoCardapio} moduloSelecionado={moduloSelecionado} />
      )}
      {largura < 1100 ? <LinhaBotoes /> : <></>}
    </Container>
  );
};

function formatarTelefoneBR(numero) {
  // Passo 1: Remover caracteres não numéricos
  if (!numero) {
    return;
  }

  let numeros = numero.replace(/\D/g, "");

  // Passo 2 e 3: Verificar o comprimento e aplicar o formato
  if (numeros.length === 10) {
    // Formato (XX) XXXX-XXXX para telefones fixos
    return numeros.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  } else if (numeros.length === 11) {
    // Formato (XX) 9XXXX-XXXX para celulares
    return numeros.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  } else {
    // Retorna o número sem formatação se não corresponder aos casos acima
    return numero;
  }
}

export const Dados = ({ tipo, moduloSelecionado }) => {
  const { largura } = useTamanhoTela();
  const { dadosEmpresa } = useUserAuth();
  function getDadosEntregaDelivery() {
    if (moduloSelecionado?.modoEntrega) {
      switch (moduloSelecionado?.modoEntrega) {
        case "DELIVERY":
        case "AMBOS":
          if (
            !moduloSelecionado?.tempoEntregaMin &&
            !moduloSelecionado?.tempoEntregaMax
          ) {
            return <></>;
          } else if (
            (moduloSelecionado?.tempoEntregaMin &&
              !moduloSelecionado.tempoEntregaMax) ||
            (!moduloSelecionado?.tempoEntregaMin &&
              moduloSelecionado.tempoEntregaMax)
          ) {
            return (
              <TextoLaranja>
                <TextoIcone icon={faClock} />
                Entrega: {} min
              </TextoLaranja>
            );
          } else {
            return (
              <TextoLaranja>
                <TextoIcone icon={faClock} />
                Entrega: {moduloSelecionado?.tempoEntregaMin} -{" "}
                {moduloSelecionado.tempoEntregaMax} min
              </TextoLaranja>
            );
          }
        case "RETIRADA":
          if (
            !moduloSelecionado?.tempoRetiradaMin &&
            !moduloSelecionado?.tempoRetiradaMax
          ) {
            return <></>;
          } else if (
            (moduloSelecionado?.tempoRetiradaMin &&
              !moduloSelecionado.tempoRetiradaMax) ||
            (!moduloSelecionado?.tempoRetiradaMin &&
              moduloSelecionado.tempoRetiradaMax)
          ) {
            return (
              <TextoLaranja>
                <TextoIcone icon={faClock} />
                Retirada: {} min
              </TextoLaranja>
            );
          } else {
            return (
              <TextoLaranja>
                <TextoIcone icon={faClock} />
                Retirada: {moduloSelecionado?.tempoRetiradaMin} -{" "}
                {moduloSelecionado.tempoRetiradaMax} min
              </TextoLaranja>
            );
          }
        default:
          return <></>;
      }
    }
  }

  function getDadosEntregaMesa() {
    if (
      !moduloSelecionado?.tempoEntregaMin &&
      !moduloSelecionado?.tempoEntregaMax
    ) {
      return <></>;
    } else if (
      (moduloSelecionado?.tempoEntregaMin &&
        !moduloSelecionado?.tempoEntregaMax) ||
      (!moduloSelecionado?.tempoEntregaMin &&
        moduloSelecionado?.tempoEntregaMax)
    ) {
      return (
        <TextoLaranja>
          <TextoIcone icon={faClock} />
          Entrega:{" "}
          {moduloSelecionado?.tempoEntregaMin ||
            moduloSelecionado?.tempoEntregaMax}
          min
        </TextoLaranja>
      );
    } else {
      return (
        <TextoLaranja>
          <TextoIcone icon={faClock} />
          Entrega: {moduloSelecionado?.tempoEntregaMin} -{" "}
          {moduloSelecionado.tempoEntregaMax} min
        </TextoLaranja>
      );
    }
  }

  function irPara(url) {
    // Verifica se a URL já começa com http:// ou https://
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      // Adiciona https:// ao início da URL se não tiver
      url = "https://" + url;
    }
    window.open(url, "_blank");
  }

  return (
    <ContainerDados>
      <LogoLoja />
      <Coluna style={{ width: "75vw" }}>
        <LinhaTitulo>
          {largura > 1100 ? (
            <TextoTitulo>{dadosEmpresa?.apelido}</TextoTitulo>
          ) : (
            <></>
          )}
          <ContainerRedesSociais>
            {dadosEmpresa?.facebook && (
              <div
                style={{ cursor: `pointer` }}
                onClick={() => irPara(dadosEmpresa?.facebook)}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </div>
            )}
            {dadosEmpresa?.instagram && (
              <div
                style={{ cursor: `pointer` }}
                onClick={() => irPara(dadosEmpresa?.instagram)}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            )}
            {dadosEmpresa?.site && (
              <div
                style={{ cursor: `pointer` }}
                onClick={() => irPara(dadosEmpresa?.site)}
              >
                <FontAwesomeIcon icon={faGlobe} />
              </div>
            )}
          </ContainerRedesSociais>
        </LinhaTitulo>

        {String(tipo).toUpperCase() == `MESA` && (
          <LinhaInfos>
            <TextoLaranja>
              <TextoIcone icon={faPhone} />
              {formatarTelefoneBR(dadosEmpresa?.telefone)}
            </TextoLaranja>
            {getDadosEntregaMesa()}
          </LinhaInfos>
        )}
        {String(tipo).toUpperCase() == `DELIVERY` && (
          <LinhaInfos>
            {moduloSelecionado?.pedidoMinimo ? (
              <TextoVerde>
                <TextoIcone icon={faDollarSign} />
                Pedido mínimo {formatarReal(moduloSelecionado?.pedidoMinimo)}
              </TextoVerde>
            ) : (
              <></>
            )}

            <TextoLaranja>
              <TextoIcone icon={faPhone} />
              {formatarTelefoneBR(dadosEmpresa?.telefone)}
            </TextoLaranja>
            {moduloSelecionado?.entregaGratis &&
            parseFloat(moduloSelecionado?.entregaGratis) > 0 ? (
              <TextoLaranja>
                <TextoIcone icon={faMotorcycle} />
                Entrega grátis: {formatarReal(moduloSelecionado?.entregaGratis)}
              </TextoLaranja>
            ) : (
              <TextoVerde>
                <TextoIcone icon={faDollarSign} />
                Entrega Grátis
              </TextoVerde>
            )}

            {getDadosEntregaDelivery()}
          </LinhaInfos>
        )}
        {String(tipo).toUpperCase() != `MESA` &&
          String(tipo).toUpperCase() != `DELIVERY` && (
            <LinhaInfos>
              <TextoLaranja>
                <TextoIcone icon={faPhone} />
                {formatarTelefoneBR(dadosEmpresa?.telefone)}
              </TextoLaranja>
            </LinhaInfos>
          )}

        {largura > 1100 ? <LinhaBotoes /> : <></>}
      </Coluna>
    </ContainerDados>
  );
};

export const LogoLoja = () => {
  const tema = useTheme();
  const { largura } = useTamanhoTela();
  const { lojaAberta } = useUserAuth();
  const { dadosEmpresa, urlBucket } = useUserAuth();
  const [imagemSrc, setImagemSrc] = useState(
    dadosEmpresa?.logoEmpresa
      ? `${urlBucket}/imagens/LOGO_EMPRESA/${dadosEmpresa?.logoEmpresa}`
      : ErroImagem
  );


  useEffect(() => {
    let imgUrl = `${urlBucket}/imagens/LOGO_EMPRESA/${dadosEmpresa?.logoEmpresa}`;
    const img = new Image();
    img.onload = () => setImagemSrc(imgUrl); // Imagem carregada com sucesso
    img.onerror = () => setImagemSrc(ErroImagem); // Falha ao carregar, usar imagem de erro
    img.src = imgUrl;
  }, [dadosEmpresa]);

  return largura > 1100 ? (
    <Coluna style={{ marginRight: 40 }}>
      <BordaColorida
        cor={
          lojaAberta
            ? tema.coresCardapio.semanticSucess
            : tema.coresCardapio.vermelhoDelete
        }
      >
        <AvatarLoja src={imagemSrc} alt="Avatar da Loja" />
      </BordaColorida>
      <Indicador
        style={{
          backgroundColor: lojaAberta
            ? tema.coresCardapio.semanticSucess
            : tema.coresCardapio.vermelhoDelete,
        }}
      >
        {lojaAberta ? "Aberto" : "Fechado"}
      </Indicador>
    </Coluna>
  ) : (
    <Linha style={{ alignItems: "center" }}>
      <Coluna>
        <BordaColorida
          cor={
            lojaAberta
              ? tema.coresCardapio.semanticSucess
              : tema.coresCardapio.vermelhoDelete
          }
        >
          <AvatarLoja src={imagemSrc} alt="Avatar da Loja" />
        </BordaColorida>
        <Indicador
          style={{
            backgroundColor: lojaAberta
              ? tema.coresCardapio.semanticSucess
              : tema.coresCardapio.vermelhoDelete,
          }}
        >
          {lojaAberta ? "Aberto" : "Fechado"}
        </Indicador>
      </Coluna>
      <TextoTitulo>Restaurante Waymenu</TextoTitulo>
    </Linha>
  );
};

export const StyledPopup = () => {};

export const LinhaBotoes = ({ dadosPessoa, moduloSelecionado }) => {
  const { dadosEmpresa } = useUserAuth();
  const { get } = useApi();
  const { largura } = useTamanhoTela();
  const tema = useTheme();
  const [modalHorarioFuncionamento, setModalHorarioFuncionamento] =
    useState(false);
console.log(dadosEmpresa)
  const conteudoSobre = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque erat turpis, tempus in orci ac, lobortis ultrices elit.`;

  function formatarEndereco(enderecoResumidoDTO) {
    const {
      bairro = "",
      cep,
      complemento,
      logradouro,
      numero,
      pontoReferencia,
      cidade,
    } = enderecoResumidoDTO;
console.log(enderecoResumidoDTO)
    let enderecoFormatado = ``;

    if (logradouro) {
      enderecoFormatado += `${logradouro}`;
    }

    if (numero) {
      enderecoFormatado += `, ${numero}`;
    }

    if (complemento) {
      enderecoFormatado += `, ${complemento}`;
    }

    if (bairro) {
      enderecoFormatado += `, ${bairro}`;
    }

    if (cidade) {
      enderecoFormatado += ` - ${cidade}`;
    }

    if (cep) {
      enderecoFormatado += `/ ${cep}`;
    }

    if (pontoReferencia) {
      enderecoFormatado += ` - ${pontoReferencia}`;
    }

    if (enderecoFormatado && !/^[A-Za-z]/.test(enderecoFormatado)) {
      enderecoFormatado = enderecoFormatado.substring(1);
    }

    return enderecoFormatado;
  }

  return largura > 1100 ? (
    <Linha style={{ margin: "20px 0px" }}>
      <ContainerPopup
        trigger={
          <BotaoCardapioPreenchido
            tamanho={"LG"}
            style={{ fontSize: 16, marginRight: 20 }}
            icone={<IconeMais style={{ width: 32, fill: "#fff" }} />}
            texto={"MAIS INFORMAÇÕES"}
          />
        }
        modal
        nested
      >
        {(close) => (
          <ContainerModal>
            <PopupHeader>
              <TituloHeader>Mais Informações</TituloHeader>
              <BotaoFecharPopup onClick={close}>
                <X size={28} color="#E74A3B" weight="bold" />
              </BotaoFecharPopup>
            </PopupHeader>
            <ContainerCorpoModal>
              <CorpoModal>
                <ContainerTelefone>
                  <strong>
                    <TituloTelefone>Telefone</TituloTelefone>
                  </strong>
                  <ConteudoTelefone>
                    {formatarTelefoneBR(dadosEmpresa?.telefone)}
                  </ConteudoTelefone>
                </ContainerTelefone>
                <ContainerSobre>
                  <strong>
                    <TituloSobre>Sobre</TituloSobre>
                  </strong>
                  <ConteudoSobre>{conteudoSobre}</ConteudoSobre>
                </ContainerSobre>
                <ContainerEndereco>
                  <strong>
                    <TituloEndereco>Endereço</TituloEndereco>
                  </strong>
                  {dadosEmpresa?.enderecoResumidoDTO && (
                    <ContainerConteudoEndereco>
                      <MapPin weight="fill" />
                      <ConteudoEndereco>
                        {formatarEndereco(dadosEmpresa?.enderecoResumidoDTO)}
                      </ConteudoEndereco>
                    </ContainerConteudoEndereco>
                  )}
                </ContainerEndereco>
                <Finalizadoras />
              </CorpoModal>
            </ContainerCorpoModal>
          </ContainerModal>
        )}
      </ContainerPopup>
      <BotaoCardapioVazado
        tamanho={"LG"}
        style={{ fontSize: 16, fontFamily: "Open Sans" }}
        icone={<FontAwesomeIcon icon={faClock} />}
        texto={"HORÁRIOS DE FUNCIONAMENTO"}
        onClick={() => {
          setModalHorarioFuncionamento(true);
        }}
      />
      <ModalHorarioFuncionamento
        aberto={modalHorarioFuncionamento}
        setAberto={setModalHorarioFuncionamento}
      />
    </Linha>
  ) : (
    <Linha
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "0px 2vw",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <ContainerPopup
        trigger={
          <BotaoCardapioPreenchido
            style={{ fontSize: 16, marginRight: "1.5vw", width: "90%" }}
            icone={<IconeMais />}
            texto={"INFORMAÇÕES"}
          />
        }
        contentStyle={{ width: "85%", borderRadius: 8 }}
        modal
        nested
      >
        {(close) => (
          <ContainerModal>
            <PopupHeader>
              <TituloHeader>Mais Informações</TituloHeader>
              <BotaoFecharPopup onClick={close}>
                <X size={28} color="#E74A3B" weight="bold" />
              </BotaoFecharPopup>
            </PopupHeader>
            <ContainerCorpoModal>
              <CorpoModal>
                <ContainerTelefone>
                  <strong>
                    <TituloTelefone>Telefone</TituloTelefone>
                  </strong>
                  <ConteudoTelefone>
                    {formatarTelefoneBR(dadosEmpresa?.telefone)}
                  </ConteudoTelefone>
                </ContainerTelefone>
                <ContainerSobre>
                  <strong>
                    <TituloSobre>Sobre</TituloSobre>
                  </strong>
                  <ConteudoSobre>{conteudoSobre}</ConteudoSobre>
                </ContainerSobre>
                <ContainerEndereco>
                  <strong>
                    <TituloEndereco>Endereço</TituloEndereco>
                  </strong>
                  <ContainerConteudoEndereco>
                    <MapPin size={20} weight="fill" />
                    <ConteudoEndereco>
                      {formatarEndereco(dadosEmpresa.enderecoResumidoDTO)}
                    </ConteudoEndereco>
                  </ContainerConteudoEndereco>
                </ContainerEndereco>
                <Finalizadoras />
              </CorpoModal>
            </ContainerCorpoModal>
          </ContainerModal>
        )}
      </ContainerPopup>
      <BotaoCardapioVazado
        style={{ fontSize: 16, width: "90%" }}
        onClick={() => {
          setModalHorarioFuncionamento(true);
        }}
        icone={<FontAwesomeIcon icon={faClock} />}
        texto={"HORÁRIOS"}
      />
      <ModalHorarioFuncionamento
        aberto={modalHorarioFuncionamento}
        setAberto={setModalHorarioFuncionamento}
      />
    </Linha>
  );
};

function Finalizadoras() {
  const { largura } = useTamanhoTela();
  const { dadosEmpresa } = useUserAuth();
  const { get } = useApi();
  const tema = useTheme();

  const [loading, setLoading] = useState(true);
  const [finalizadoras, setFinalizadoras] = useState(null);
  const [pagamentoOnline, setPagamentoOnline] = useState([]);
  const [pagamentoNaEntrega, setPagamentoNaEntrega] = useState([]);
  const [pagamentoNaRetirada, setPagamentoNaRetirada] = useState([]);

  useEffect(() => {
    if (finalizadoras) {
      setLoading(false);
    }
  }, [finalizadoras]);

  useEffect(() => {
    getDadosFinalizadora();
  }, []);

  function getDadosFinalizadora() {
    get(
      `configuracoes-lojas/finalizadora-empresa-waymenu/buscar-todos?idEmpresa=${dadosEmpresa.idEmpresa}&size=100`
    ).then((response) => {
      setFinalizadoras(response.data);
      let pagamentoOnline = [];
      let pagamentoEntrega = [];
      let pagamentoRetirada = [];

      response.data.content.forEach((finalizadora) => {
        if (finalizadora.exibeCardapio && finalizadora.pagamentoEntrega) {
          pagamentoEntrega.push(finalizadora);
        }
        if (finalizadora.exibeCardapio && finalizadora.pagamentoRetirada) {
          pagamentoRetirada.push(finalizadora);
        }
        if (finalizadora.exibeCardapio && finalizadora.pagamentoOnline) {
          pagamentoOnline.push(finalizadora);
        }

        setPagamentoOnline(pagamentoOnline);
        setPagamentoNaEntrega(pagamentoEntrega);
        setPagamentoNaRetirada(pagamentoRetirada);
      });
    });
  }

  function Bandeira({ bandeira }) {
    const { urlBucket } = useUserAuth();
    const [imagemSrc, setImagemSrc] = useState(
      bandeira.imagem ? bandeira.imagem : null
    );

    useEffect(() => {
      if (bandeira.imagem) {
        let imgUrl = `${urlBucket}/imagens/IMAGEM_BANDEIRA/${bandeira.imagem}`;
        const img = new Image();
        img.onload = () => setImagemSrc(imgUrl); // Imagem carregada com sucesso
        img.onerror = () => setImagemSrc(null); // Falha ao carregar, usar imagem de erro
        img.src = imgUrl;
      }
    }, [bandeira]);

    return (
      <ContainerBandeira>
        <ImagemBandeira
          style={imagemSrc ? {} : { display: "none" }}
          src={imagemSrc}
        />
        <span>{bandeira.descricao}</span>
      </ContainerBandeira>
    );
  }

  function Pagamento({ titulo, finalizadoras }) {
    return (
      <>
        <ContainerPagamento>
          <TituloPagamento>{titulo}</TituloPagamento>
        </ContainerPagamento>

        {finalizadoras.map((finalizadora, index) => (
          <StyledAccordion
            key={"finalizadora" + titulo + index}
            style={{ marginTop: 16 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <TypographyTituloFormasDePagamento>
                {finalizadora.descricao}
              </TypographyTituloFormasDePagamento>
            </AccordionSummary>
            <AccordionDetails>
              <ContainerBandeiras>
                {finalizadora.listaIdentificacaoBandeiras.map(
                  (bandeira, index) => (
                    <Bandeira
                      bandeira={bandeira}
                      key={"bandeira" + titulo + index}
                    />
                  )
                )}
              </ContainerBandeiras>
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </>
    );
  }

  return (
    <>
      <ContainerPagamento>
        <strong>
          <TituloPagamento style={{ fontSize: 18 }}>
            Formas de Pagamento:
          </TituloPagamento>
        </strong>
      </ContainerPagamento>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            sx={{ color: tema.coresCardapio.brand500 }}
            style={{ alignSelf: "center" }}
          />
        </div>
      ) : (
        <>
          <Pagamento
            titulo="Pagamento na retirada"
            finalizadoras={pagamentoNaRetirada}
          />
          <Pagamento
            titulo="Pagamento na entrega"
            finalizadoras={pagamentoNaEntrega}
          />
          <Pagamento
            titulo="Pagamento online"
            finalizadoras={pagamentoOnline}
          />
        </>
      )}
    </>
  );
}

export default InformacoesRestaurante;
