import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    body{
        width: 100vw;
        overflow-x: hidden;
        height: 100vh;
        background-color: ${(props) => props.theme.cores.surfaceBackground1};
        font-family: Arial, Helvetica, sans-serif;
    }

    p, h1, h2, h3, h4, h5, h6, span {
        color: ${(props) => props.theme.cores.textoSimples};
    }

    h1, h2, h3, h4, h5 {
        font-family: 'Comfortaa', cursive;
    }

    p {
        font-family: 'Source Sans Pro', sans-serif;
    }

    a{
        text-decoration: none;
    }

    ::-webkit-scrollbar {
      height: 0px;
      width: 8px;
      opacity: 0;
      position: relative;
      display: none; 
      left: 8px; 
    }

    ::-webkit-scrollbar-thumb:hover {
      display: block; 
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px lightgrey; 
        border-radius: 5px;
        display: none;
    }
    ::-webkit-scrollbar-thumb {
        background: lightgrey; 
        border-radius: 5px;
        opacity: 0.1;
        left: 8px;
        height: 40px;
    }
    ::-webkit-scrollbar-thumb:hover {
        
        opacity: 0.1;
    }


    //MUI CLASSES de INPUT para adicionar o padding interno de forma geral



        
        .MuiFormLabel-root {
            padding: 0 10px;
        }

        .MuiInputBase-readOnly {
            padding: 5px 5px;
        }

        .MuiInputBase-root {
            padding: 5px 10px;
        }

    
`;

export default GlobalStyle;
