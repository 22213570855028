import styled from "styled-components";
import { ReactComponent as IconeFavorito } from "../../../assets/images/icones/favorite.svg";
import { ReactComponent as IconeFavoritoPreenchido } from "../../../assets/images/icones/favorite_preenchido.svg";
import GerarModalResponsivo from "../../gerarModalResponsivo";

export const ContainerCupom = styled.div`
  width: 328px;
  height: 128px;
  flex-shrink: 0;
  border-radius: 16px;
  background: ${({ theme }) => theme.coresCardapio.background2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 8px 24px;
`;

export const RecorteDireita = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${({ theme, $background }) =>
    $background ? $background : theme.coresCardapio.background1};
  position: absolute;
  right: -28px;
  top: 30%;
  pointer-events: none;
`;

export const RecorteEsquerda = styled(RecorteDireita)`
  left: -28px;
`;

export const LinhaMeio = styled.div`
  height: 100%;
  width: 6px;
  position: absolute;
  left: 50%;
  background: repeating-linear-gradient(
      to bottom,
      transparent 0 6px,
      ${({ theme }) => theme.coresCardapio.background1} 6px 16px
    )
    80%/4px 100% no-repeat;
`;

export const ImagemProdutoContainer = styled.div`
  display: flex;
  width: 50%;
  height: 120px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export const ImagemProduto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ContainerTexto = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    margin-left: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  span {
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
  }

  p {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;

    line-height: 1em;
    max-height: 2em;
    padding: 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  /* p:before {
    content: "...";
    float: right;
    margin-top: 1em;
  } */
`;

export const IconeFavoritoEstilizado = styled(IconeFavorito)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const IconeFavoritoEstilizadoPreenchido = styled(
  IconeFavoritoPreenchido
)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const ColunaPopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h4 {
    font-family: Poppins;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    h2 {
      display: none;
    }
  }
`;

export const LinhaPopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  h5 {
    font-family: Poppins;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LinhaBotoes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 48px;
`;

export const GerarModalResponsivoCustomizado = styled(GerarModalResponsivo)`
  .popup-content {
    background-color: ${({ theme }) => theme.coresCardapio.brand050};
  }
`;

export const ConteudoCupons = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      margin-bottom: 10px;
      font-weight: 500;
    }
    h5 {
      font-weight: 500;
    }
  }
`;
