import React, { useEffect, useState } from "react";
import img_acai from "../imagens-placeholder/img_acai.webp";
import { Adicionar, Escolha } from "../../escolhaExpansivel";
import {
  BotaoProximo,
  EscolhaExpansivelPizza,
  EscolhaExpansivelPizzaConteudo,
  EscolhaExpansivelPizzaTitulo,
  PaginaContainer,
} from "../styles";
import { useUserAuth } from "../../../../contexts/userAuth";
import { useApi } from "../../../../contexts/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress } from "@mui/material";
import { useTheme } from "styled-components";
import { toast } from "react-toastify";

export const PaginaAdicionais = ({
  produto,
  mudarPagina,
  dados,
  setDados,
  modoCardapio = false,
}) => {
  var idProximoNumero = 0;
  const { dadosEmpresa } = useUserAuth();
  const { get } = useApi();
  const tema = useTheme();

  const [proximoAtivo, setProximoAtivo] = useState(true);
  const [adicionais, setAdicionais] = useState(null);
  const [quantidadeAdicionais, setQuantidadeAdicionais] = useState(0);
  const [escolhasFeitas, setEscolhasFeitas] = useState({});
  const [dadosEscolhas, setDadosEscolhas] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getComplementos();
  }, []);

  useEffect(() => {
    if (adicionais && adicionais.length > 0) {
      setLoading(false);
    } else if (adicionais && adicionais.length == 0) {
      if (dados.complementos) {
        setDadosEscolhas(dados.complementos);
        setDados((dados) => {
          let novosDados = { ...dados };
          delete novosDados.complementos;
          return novosDados;
        });
      }

      mudarPagina();
    }
  }, [adicionais]);

  useEffect(() => {
    if (dados.complementos) {
      setDadosEscolhas(dados.complementos);
      setDados((dados) => {
        let novosDados = { ...dados };
        delete novosDados.complementos;
        return novosDados;
      });
    }
  }, []);

  function salvarDados() {
    let novosDados = { ...dados };
    novosDados.complementos = dadosEscolhas;
    setDados(novosDados);
  }

  function getComplementos() {
    setLoading(true);
    let idsComplementos = [];
    let relacaoNomeID = {};

    idsComplementos.push(produto.idProdutoEmpresa);

    dados.sabores?.forEach((sabor) => {
      if (!idsComplementos.includes(sabor.sabor.idProdutoEmpresa)) {
        idsComplementos.push(sabor.sabor.idProdutoEmpresa);
        relacaoNomeID[sabor.sabor.idProdutoEmpresa] =
          sabor.sabor.descricaoSabor;
      }
    });

    let todosComplementos = [];
    let quantidadeComplementos = 0;
    let promises = [];

    idsComplementos.forEach((id) => {
      const promise = get(
        `produtos/menu-componente/buscar-vinculo-produto/${dadosEmpresa.idEmpresa}/${id}`
      )
        .then((response) => {
          if (response.data.length > 0) {
            let complementosOrdenados = response.data.sort((a, b) => {
              return a.produtoMenu.indice - b.produtoMenu.indice;
            });

            complementosOrdenados = complementosOrdenados.filter(
              (complemento) => {
                return complemento.produtoMenu.categoriaProduto != "BORDA";
              }
            );

            if (complementosOrdenados.length > 0) {
              quantidadeComplementos += complementosOrdenados.length;
              todosComplementos.push({
                idProdutoEmpresa: id,
                listaComplementos: complementosOrdenados,
                descricao: relacaoNomeID[id],
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Erro ao buscar complementos do produto");
        });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      setQuantidadeAdicionais(quantidadeComplementos);
      setAdicionais(todosComplementos);
    });
  }

  function atribuirProximoId(totalItens, index, proximoItemFinal) {
    let indexMap = 0;

    if (index + 1 < totalItens) {
      indexMap = index + 1;
    } else {
      indexMap = proximoItemFinal;
    }

    return indexMap;
  }

  function verificarEstoque(complemento) {
    if (complemento?.estocado) {
      if (complemento.estoque > 0) {
        return "CONTEM_ESTOQUE"
      }
      return "SEM_ESTOQUE"
    }

    return "NAO_CONTROLA"
  }

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "40vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{ color: tema.coresCardapio.brand500 }}
        style={{ alignSelf: "center" }}
      />
    </div>
  ) : (
    <PaginaContainer>
      {adicionais.map((adicional, indexGeral) => {
        console.log(adicional);
        return (
          <EscolhaExpansivelPizza
            key={`${adicional.idProdutoEmpresa} ${indexGeral}`}
            defaultExpanded={true}
          >
            <EscolhaExpansivelPizzaTitulo expandIcon={<ExpandMoreIcon />}>
              Adicional
              <p>{adicional.descricao}</p>
            </EscolhaExpansivelPizzaTitulo>
            <EscolhaExpansivelPizzaConteudo>
              {adicional.listaComplementos?.map((complemento, index) => {
                console.log(complemento);
                let idAtual = idProximoNumero;
                idProximoNumero++;
                if (complemento.produtoMenu.quantidadeMaxima > 1) {
                  return (
                    <div
                      id={"complemento" + idAtual}
                      key={`complemento${idAtual}`}
                      style={{ maxWidth: `100%`, width: `100%` }}
                    >
                      <Adicionar
                        idProximo={atribuirProximoId(
                          quantidadeAdicionais,
                          idAtual,
                          "itemFinal"
                        )}
                        key={
                          "adicionar" +
                          complemento.produtoMenu.id +
                          complemento.produtoMenu.descricao
                        }
                        aberto={true}
                        idProdutoPai={adicional.idProdutoEmpresa}
                        complemento={complemento}
                        updateValorComplemento={() => {}}
                        escolhasFeitas={escolhasFeitas}
                        setEscolhasFeitas={setEscolhasFeitas}
                        setDadosEscolhas={setDadosEscolhas}
                        index={index}
                        iconeExpandir={false}
                        modoEstatico={true}
                        modoCardapio={modoCardapio}
                      />
                    </div>
                  );
                } else if (complemento.produtoMenu.quantidadeMaxima == 1) {
                  return (
                    <div
                      id={"complemento" + idAtual}
                      key={`complemento${idAtual}`}
                      style={{ maxWidth: `100%`, width: `100%` }}
                    >
                      <Escolha
                        idProximo={atribuirProximoId(
                          quantidadeAdicionais,
                          idAtual,
                          "itemFinal"
                        )}
                        key={"escolha" + complemento.id + complemento.descricao}
                        aberto={true}
                        complemento={complemento}
                        idProdutoPai={adicional.idProdutoEmpresa}
                        updateValorComplemento={() => {}}
                        escolhasFeitas={escolhasFeitas}
                        setEscolhasFeitas={setEscolhasFeitas}
                        setDadosEscolhas={setDadosEscolhas}
                        iconeExpandir={false}
                        modoEstatico={true}
                        modoCardapio={modoCardapio}
                        produto={produto}
                      />
                    </div>
                  );
                }
              })}
            </EscolhaExpansivelPizzaConteudo>
          </EscolhaExpansivelPizza>
        );
      })}
      <BotaoProximo
        style={{ marginTop: 24 }}
        id="complementoitemFinal"
        $ativo={proximoAtivo || modoCardapio}
        onClick={() => {
          if (proximoAtivo || modoCardapio) {
            salvarDados();
            mudarPagina();
          }
        }}
      >
        PRÓXIMO
      </BotaoProximo>
    </PaginaContainer>
  );
};
