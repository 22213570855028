import styled from "styled-components";
import {
  ContainerCategorias,
  CategoriaExpansivel,
  CategoriaExpansivelTitulo,
  CategoriaExpansivelConteudo,
} from "../categorias/styles";
import { CardCustomizado } from "../cardsProduto/styles";
export const PromocaoDoDiaContainer = styled(ContainerCategorias)`
  margin-top: 40px;
`;

export const PromocaoDoDiaExpansivel = styled(CategoriaExpansivel)``;

export const PromocaoDoDiaTitulo = styled(CategoriaExpansivelTitulo)`
  background-color: ${({ theme }) => theme.coresCardapio.brand500};
  color: ${({ theme }) => theme.coresCardapio.textoClaro};
  font-family: "Poppins", sans-serif;
  font-weight: 400;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.coresCardapio.textoClaro};
    font-size: 48px;
  }
`;

export const PromocaoDoDiaConteudo = styled(CategoriaExpansivelConteudo)`
  flex-wrap: nowrap;
  overflow: scroll;
  padding: 0px;
  min-height: 0px;
  width: max-content;
  gap: 10px;
`;

export const CardPromocaoDoDia = styled(CardCustomizado)`
  width: 545px;
  margin: 0px;
  margin-bottom: 7px;
  margin-top: 15px;

  @media (max-width: 1200px) {
    width: 545px;
  }

  @media (max-width: 768px) {
    width: 100vw;
    margin: 0.5% 0px;
  }
`;

export const ScrollHorizontal = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    margin: 10px 0px;
    height: 4px;
    width: 8px;
    display: block;
    padding: 10px;
  }

  &::-webkit-scrollbar-track {
    padding: 10px 0px;
    width: 5%;
    border-radius: 5px;
    display: block;
    background-color: ${({ theme }) => theme.coresCardapio.background1};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      String(theme.coresCardapio.textoEscuro).replace("1)", "0.5)")};
    border-radius: 25px;
  }
`;
