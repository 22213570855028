import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import {
  PopupContainerTag,
  PopupTag,
  PopupContainer,
  PopupHeader,
  PopupConteudo,
  BotaoHeader,
  TextoSobre,
} from "./styles";
import { PaginaTamanhos } from "./paginas/paginaTamanhos";
import { PaginaSabores } from "./paginas/paginaSabores";
import { PaginaAdicionais } from "./paginas/paginaAdicionais";
import { PaginaBordas } from "./paginas/paginaBorda";
import ErroImagem from "../../../assets/images/img-erro.png";
import { useApi } from "../../../contexts/api";
import { useUserAuth } from "../../../contexts/userAuth";
import { CircularProgress } from "@mui/material";
import { useTheme } from "styled-components";
import { toast } from "react-toastify";
import { checarModoCardapio } from "../../../utils/checarModoCardapio";
import { useParams } from "react-router-dom";

export const ModalPizza = ({ produto, precoCombinado, close }) => {
  const { get } = useApi();
  const { dadosEmpresa, urlBucket } = useUserAuth();
  const tema = useTheme();
  const { tipoCardapio } = useParams();
  const [modoCardapio, setModoCardapio] = useState();

  const [combinados, setCombinados] = useState(null);
  const [tamanhos, setTamanhos] = useState(null);
  const [complementos, setComplementos] = useState(null);
  const [dados, setDados] = useState({});

  const [loading, setLoading] = useState(true);

  const [imagemSrc, setImagemSrc] = useState(
    produto.imagem ? produto.imagem : ErroImagem
  );

  useEffect(() => {
    let imgUrl = `${urlBucket}/imagens/PRODUTO_GRADE_IMAGEM/${produto.imagem}`;
    const img = new Image();
    img.onload = () => setImagemSrc(imgUrl); // Imagem carregada com sucesso
    img.onerror = () => setImagemSrc(ErroImagem); // Falha ao carregar, usar imagem de erro
    img.src = imgUrl;
  }, [produto]);

  useEffect(() => {
    setModoCardapio(checarModoCardapio(tipoCardapio));
  }, [tipoCardapio]);

  useEffect(() => {
    get(
      `produtos/menu-componente/buscar-vinculo-produto/${dadosEmpresa.idEmpresa}/${produto.idProdutoEmpresa}`
    )
      .then((response) => {
        let complementosOrdenados = response.data.sort((a, b) => {
          return a.produtoMenu.indice - b.produtoMenu.indice;
        });

        setComplementos(complementosOrdenados);
      })
      .catch((error) => {
        toast.error("Erro ao buscar complementos do produto");
        close();
      });
    get(`configuracoes-lojas/combinado/${produto.idCardapioMenu}`).then(
      (response) => {
        setCombinados(response.data);
        let tamanhosDefinidos = response?.data?.infoMenuCombinado?.reduce(
          (acc, item) => {
            if (item.sabor.idProdutoEmpresa == produto.idProdutoEmpresa) {
              let itemTamanho = {
                ...item.tamanho,
                valor: item.tabelaDePreco,
                idGrade: item.idGrade,
                idGradeEmpresa: item.idGradeEmpresa,
                idTabelaPreco: item.tabelaDePreco.idTabelaPreco,
              };
              acc.push(itemTamanho);
            }
            return acc;
          },
          []
        );
        setTamanhos(tamanhosDefinidos);
      }
    );
  }, []);

  useEffect(() => {
    if (complementos !== null && tamanhos !== null) {
      setLoading(false);
    }
  }, [complementos, tamanhos]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  function resetarScroll() {
    let overlayComScroll = document.querySelector(".popup-overlay");
    overlayComScroll.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const paginas = [
    <PaginaTamanhos
      produto={produto}
      tamanhos={tamanhos}
      mudarPagina={mudarPagina}
      dados={dados}
      setDados={setDados}
      modoCardapio={modoCardapio}
    />,
    <PaginaSabores
      produto={produto}
      combinados={combinados}
      mudarPagina={mudarPagina}
      dados={dados}
      setDados={setDados}
      modoCardapio={modoCardapio}
    />,
    <PaginaAdicionais
      produto={produto}
      mudarPagina={mudarPagina}
      complementos={complementos}
      dados={dados}
      setDados={setDados}
      modoCardapio={modoCardapio}
    />,
    <PaginaBordas
      produto={produto}
      mudarPagina={mudarPagina}
      dados={dados}
      setDados={setDados}
      close={close}
      modoCardapio={modoCardapio}
      precoCombinado={precoCombinado}
    />,
  ];

  const [indexPagina, setIndexPagina] = useState(0);

  function mudarPagina(index) {
    if (index) {
      setIndexPagina(index);
    } else {
      setIndexPagina(indexPagina + 1);
    }
    resetarScroll();
  }

  function voltarPagina() {
    if (indexPagina == 3) {
      if (dados.complementos) {
        setDados((dados) => {
          let novosDados = { ...dados };
          delete novosDados.borda;
          delete novosDados.observacoes;
          delete novosDados.complementos;
          return novosDados;
        });
        setIndexPagina(2);
      } else {
        setDados((dados) => {
          let novosDados = { ...dados };
          delete novosDados.borda;
          delete novosDados.observacoes;
          delete novosDados.complementos;
          delete novosDados.sabores;
          return novosDados;
        });
        if (dados.quantidadeSabores > 1) {
          setIndexPagina(1);
        } else {
          setIndexPagina(0);
        }
      }
    } else if (indexPagina == 2) {
      setDados((dados) => {
        let novosDados = { ...dados };
        delete novosDados.complementos;
        delete novosDados.sabores;
        return novosDados;
      });
      if (dados.quantidadeSabores > 1) {
        setIndexPagina(1);
      } else {
        setIndexPagina(0);
      }
    } else if (indexPagina == 1) {
      setIndexPagina(0);
    }

    resetarScroll();
  }

  function mostrarPagina() {
    return paginas[indexPagina];
  }

  console.log(produto);

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{ color: tema.coresCardapio.brand500 }}
        style={{ alignSelf: "center" }}
      />
    </div>
  ) : (
    <PopupContainer>
      <PopupHeader url={imagemSrc}>
        <h1>{produto.descricaoAbreviada}</h1>
        {indexPagina == 0 ? (
          <BotaoHeader onClick={close}>
            <FontAwesomeIcon icon={faX} />
          </BotaoHeader>
        ) : (
          <BotaoHeader onClick={voltarPagina}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </BotaoHeader>
        )}
      </PopupHeader>
      <PopupConteudo>
        {produto.detalhes && (
          <TextoSobre>
            <strong>Sobre: </strong>
            {produto.detalhes}
          </TextoSobre>
        )}
        <PopupContainerTag>
          {produto.glutem && (
            <PopupTag key={"tagGluten"}>Contém Gluten</PopupTag>
          )}
          {produto.lactose && (
            <PopupTag key={"tagLactose"}>Contém Lactose</PopupTag>
          )}
          {produto.proibidoMenor && (
            <PopupTag key={"tagProibido"}>Proibido para menores</PopupTag>
          )}
        </PopupContainerTag>
        <TextoSobre style={{ fontSize: 14, marginTop: 16 }}>
          {precoCombinado === "MEDIA_PRECO"
            ? "Cálculo de preço: Média dos preços dos sabores escolhidos"
            : precoCombinado === "MAIOR_PRECO"
            ? "Cálculo de preço: Cobra pizza de maior valor"
            : ""}
        </TextoSobre>
        {mostrarPagina()}
      </PopupConteudo>
    </PopupContainer>
  );
};

export default ModalPizza;
