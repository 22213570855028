import { createContext, useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useUserAuth } from "./userAuth";

export const baseURL = process.env.REACT_APP_BASE_URL_API;
export const baseURLApp = process.env.REACT_APP_BASE_URL;
export const clientKey = process.env.REACT_APP_CLIENT_KEY;

export const versaoClienteDTO = {
  versaoPrincipal: 3,
  versaoRelease: 0,
  versaoCorrecao: 17,
};

export const ApiContext = createContext({});

export const ApiProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [triggerVerificacao, setTriggerVerificacao] = useState(false);

  const infoAPI = {
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    validateStatus: () => true,
  };

  function definirToken(token) {
    if (token) {
      setToken(token);
      // setToken(
      //   "eyJhbGciOiJIUzUxMiJ9.eyJpZFVzdWFyaW9FbXByZXNhIjoxMDY5OCwiaWRNYXRyaXoiOjIwMiwiaWRQZXJmaWwiOjcsInJvbGVzIjoiUk9MRV9TVVBPUlRFX05BT19TVVBFUlZJU0lPTkFETyIsImlkU2Vzc2FvIjo3NDYzMiwiaWRFbXByZXNhIjoyMDIsImlhdCI6MTcyNzY5ODEwNiwiZXhwIjoxNzI3NzQxMzA2fQ.3dWoclRqoIDxRjfnqBpB2fNAxseQjtcQeaJkFC5rfTw0cevvU3p3kfnkJUOp1X6AIyjYHncXeGbW0cAm0qo1Dg"
      // );
    } else {
      limparSessao();
    }
  }

  async function limparSessao() {
    localStorage.removeItem("sessao");
    try {
      await del("/usuario-sessao/client/usuario/waymenu/encerrar-sessao");
    } catch (error) {
      console.log(error);
    }
    setToken(null);
    setTriggerVerificacao((state) => !state);
  }

  function get(url) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((response) => {
          if (response?.status && response?.status === 401) {
            limparSessao();
          } else {
            if (response.status >= 200 && response.status < 300) {
              resolve(response);
            } else {
              throw response;
            }
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            limparSessao();
          }
          reject(error);
        });
    });
  }

  function post(url, data) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      api
        .post(url, data)
        .then((response) => {
          if (response?.status && response?.status === 401) {
            limparSessao();
            reject(response);
          } else {
            if (response.status >= 200 && response.status < 300) {
              resolve(response);
            } else {
              throw response;
            }
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            limparSessao();
          }
          reject(error);
        });
    });
  }

  function put(url, data) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      api
        .put(url, data)
        .then((response) => {
          if (response?.status && response?.status === 401) {
            limparSessao();
            reject(response);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            limparSessao();
          }
          reject(error);
        });
    });
  }

  function del(url, data) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      api
        .delete(url + objetoParaPathVariables(data))
        .then((response) => {
          if (response?.status && response?.status === 401) {
            //Verificar se está apontando para a rota de desconectar, para evitar que o usuario fique preso caso a API demore para responder
            if (url == "/usuario-sessao/client/usuario/encerrar-sessao") {
              // limparSessao();
            } else {
              limparSessao();
            }
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            limparSessao();
          }
          reject(error);
        });
    });
  }

  function objetoParaPathVariables(objeto) {
    const pathVariables = [];

    for (const chave in objeto) {
      if (objeto.hasOwnProperty(chave)) {
        pathVariables.push(`${chave}=${encodeURIComponent(objeto[chave])}`);
      }
    }

    return "?" + pathVariables.join("&");
  }

  // function getAsset(caminho) {
  //   return `${baseUrlAssets}/${caminho}`;
  // }

  return (
    <ApiContext.Provider
      value={{
        get,
        post,
        put,
        del,
        definirToken,
        token,
        triggerVerificacao,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
