export function formatarCpf(valor) {
    var valorFormatado = valor.replace(/\D/g, ""); //Remove tudo o que não é dígito

    if (valorFormatado.length > 11) {
      valorFormatado = valorFormatado.substring(0, 11);
    }
    if (valorFormatado.length > 3) {
      valorFormatado =
        valorFormatado.substring(0, 3) + "." + valorFormatado.substring(3);
    }
    if (valorFormatado.length > 7) {
      valorFormatado =
        valorFormatado.substring(0, 7) + "." + valorFormatado.substring(7);
    }
    if (valorFormatado.length > 11) {
      valorFormatado =
        valorFormatado.substring(0, 11) + "-" + valorFormatado.substring(11);
    }

    return valorFormatado;
  }