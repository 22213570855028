import { Fragment, useEffect, useState } from "react";
import {
  CardPagamento,
  ContainerCartoes,
  ContainerMensagem,
  ContainerTextoLaranja,
  TextoLaranja,
  TextoPretoLeve,
  TituloMensagemPagamento,
} from "../styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BotaoCardapioVazado } from "../../../../components/botao";
import ModalFormasPagamento from "../../../../components/modalFormasPagamento";
import Chip from "../../../../components/chip";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useTheme } from "styled-components";
import { useUserAuth } from "../../../../contexts/userAuth";
import { useApi } from "../../../../contexts/api";
import { useParams } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Pagamento({ tabSelecionada, atualizarDadosFinalizar }) {
  const theme = useTheme();
  const { dadosEmpresa } = useUserAuth();
  const { tipoCardapio } = useParams();
  const { get } = useApi();

  const [tipoPagamento, setTipoPagamento] = useState("pagarEntrega");

  const [pagamentoOnline, setPagamentoOnline] = useState([]);
  const [pagamentoNaEntrega, setPagamentoNaEntrega] = useState([]);
  const [pagamentoNaRetirada, setPagamentoNaRetirada] = useState([]);

  const [moduloSelecionado, setModuloSelecionado] = useState({});

  const [finalizadoraSelecionada, setFinalizadoraSelecionada] = useState({});
  const [cartaoSelecionado, setCartaoSelecionado] = useState({});

  const [pagarOnlineAtivo, setPagarOnlineAtivo] = useState(true);

  useEffect(() => {
    setFinalizadoraSelecionada({});
    setCartaoSelecionado({});
    atualizarDadosFinalizar({
      finalizadoraSelecionada: null,
      cartaoSelecionado: null,
      tipoPagamento,
      finalizadorasPagamentoOnline: pagamentoOnline,
    });
  }, []);

  useEffect(() => {
    setFinalizadoraSelecionada({});
    setCartaoSelecionado({});
    atualizarDadosFinalizar({
      finalizadoraSelecionada: null,
      cartaoSelecionado: null,
      tipoPagamento,
      finalizadorasPagamentoOnline: pagamentoOnline,
    });
  }, [tipoPagamento]);

  useEffect(() => {
    atualizarDadosFinalizar({
      finalizadoraSelecionada,
      cartaoSelecionado: null,
      tipoPagamento,
      finalizadorasPagamentoOnline: pagamentoOnline,
    });
  }, [finalizadoraSelecionada]);

  useEffect(() => {
    atualizarDadosFinalizar({
      finalizadoraSelecionada: null,
      cartaoSelecionado,
      tipoPagamento,
      finalizadorasPagamentoOnline: pagamentoOnline,
    });
  }, [cartaoSelecionado]);

  useEffect(() => {
    dadosEmpresa.listaModulos?.forEach((modulo) => {
      if (
        String(modulo.tipo).toUpperCase() === String(tipoCardapio).toUpperCase()
      ) {
        setModuloSelecionado(modulo);
        console.log(modulo);
      }
    });
  }, []);

  useEffect(() => {
    getDadosFinalizadora();
  }, []);

  function getDadosFinalizadora() {
    get(
      `configuracoes-lojas/finalizadora-empresa-waymenu/buscar-todos?idEmpresa=${dadosEmpresa.idEmpresa}&size=100`
    ).then((response) => {
      let pagamentoOnline = [];
      let pagamentoEntrega = [];
      let pagamentoRetirada = [];

      response.data.content.forEach((finalizadora) => {
        if (finalizadora.exibeCardapio && finalizadora.pagamentoEntrega) {
          pagamentoEntrega.push(finalizadora);
        }
        if (finalizadora.exibeCardapio && finalizadora.pagamentoRetirada) {
          pagamentoRetirada.push(finalizadora);
        }
        if (finalizadora.exibeCardapio && finalizadora.pagamentoOnline) {
          pagamentoOnline.push(finalizadora);
        }

        setPagamentoOnline(pagamentoOnline);
        setPagamentoNaEntrega(pagamentoEntrega);
        setPagamentoNaRetirada(pagamentoRetirada);
      });

      if (pagamentoOnline.length > 0) {
        setPagarOnlineAtivo(true);
      } else {
        setPagarOnlineAtivo(false);
      }
    });
  }

  return (
    <ContainerMensagem style={{ alignItems: "flex-start", marginBottom: 32 }}>
      <TituloMensagemPagamento>
        <TextoLaranja>$ Escolha uma forma de pagamento</TextoLaranja>
      </TituloMensagemPagamento>
      <FormControl fullWidth>
        <RadioGroup
          row
          value={tipoPagamento}
          onChange={(event) => {
            setTipoPagamento(event.target.value);
          }}
        >
          {tabSelecionada === "entrega" && (
            <FormControlLabel
              value="pagarOnline"
              control={
                <Radio
                  disabled={!pagarOnlineAtivo}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 28,
                    },
                  }}
                />
              }
              label="Pague Online"
            />
          )}
          <FormControlLabel
            value="pagarEntrega"
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 28,
                  },
                }}
              />
            }
            label="Pague na entrega"
          />
        </RadioGroup>
      </FormControl>
      {tipoPagamento === "pagarOnline" && (
        <FormaPagamentoOnline
          cartaoSelecionado={cartaoSelecionado}
          setCartaoSelecionado={setCartaoSelecionado}
        />
      )}
      {tipoPagamento === "pagarEntrega" ? (
        tabSelecionada == "entrega" ? (
          <FormaPagamento
            formasPagamento={pagamentoNaEntrega}
            finalizadoraSelecionada={finalizadoraSelecionada}
            setFinalizadoraSelecionada={setFinalizadoraSelecionada}
          />
        ) : tabSelecionada == "retirada" ? (
          <FormaPagamento
            formasPagamento={pagamentoNaRetirada}
            finalizadoraSelecionada={finalizadoraSelecionada}
            setFinalizadoraSelecionada={setFinalizadoraSelecionada}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </ContainerMensagem>
  );
}

function ChipSelecionarPagamento({ opcao, valor, setValor, formaCompleta }) {
  const [selecionado, setSelecionado] = useState(
    valor?.idFinalizadoraBandeiraWaymenu == opcao?.idFinalizadoraBandeiraWaymenu
  );
  const theme = useTheme();

  useEffect(() => {
    if (
      valor?.idFinalizadoraBandeiraWaymenu ==
      opcao?.idFinalizadoraBandeiraWaymenu
    ) {
      setSelecionado(true);
    } else {
      setSelecionado(false);
    }
  }, [valor]);

  function marcar() {
    if (selecionado) {
      setValor({});
    } else {
      setValor({ ...opcao, formaCompleta });
    }
  }

  return (
    <Chip
      onClick={() => marcar()}
      style={{
        marginRight: 8,
        borderRadius: 8,
        padding: "0px",
        color: selecionado
          ? theme.coresCardapio.brand500
          : theme.coresCardapio.gray400,
        border: "1px solid",
        borderColor: selecionado
          ? theme.coresCardapio.brand500
          : theme.coresCardapio.gray400,
        backgroundColor: selecionado
          ? theme.coresCardapio.brand500
          : "transparent",
      }}
      label={
        <div
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <CreditCardIcon
            style={{
              fontSize: 18,
              color: selecionado ? "white" : theme.coresCardapio.gray400,
              marginRight: 4,
            }}
          />
          <p
            style={{
              color: selecionado ? "white" : theme.coresCardapio.gray400,
              marginRight: 4,
            }}
          >
            {opcao.descricao}
          </p>
        </div>
      }
    />
  );
}

function FormaPagamento({
  formasPagamento,
  finalizadoraSelecionada,
  setFinalizadoraSelecionada,
}) {
  const theme = useTheme();

  return formasPagamento.map((forma, index) => (
    <Accordion
      key={forma.id + "FormaPagamento" + index}
      elevation={2}
      style={{
        width: "100%",
        minHeight: 50,
      }}
    >
      <AccordionSummary
        style={{
          minHeight: 50,
        }}
        expandIcon={
          <ExpandMoreIcon
            style={{
              fontSize: 32,
              color: theme.coresCardapio.brand500,
            }}
          />
        }
      >
        <Typography
          style={{
            color: theme.coresCardapio.brand500,
            fontSize: 19,
            fontWeight: 400,
          }}
        >
          {forma.descricao}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {forma.listaIdentificacaoBandeiras.map((opcao, index) => (
          <ChipSelecionarPagamento
            key={"opcao" + opcao.descricao}
            opcao={opcao}
            valor={finalizadoraSelecionada}
            formaCompleta={forma}
            setValor={setFinalizadoraSelecionada}
          />
        ))}
        {forma.finalizadoraRFB == "Dinheiro" && (
          <ChipSelecionarPagamento
            opcao={{
              descricao: "Dinheiro",
              idFinalizadoraBandeiraWaymenu: forma.idFinalizadoraEmpresa,
            }}
            valor={finalizadoraSelecionada}
            formaCompleta={forma}
            setValor={setFinalizadoraSelecionada}
          />
        )}
      </AccordionDetails>
    </Accordion>
  ));
}

function FormaPagamentoOnline({ cartaoSelecionado, setCartaoSelecionado }) {
  const { dadosAuth, logado } = useUserAuth();
  const { get } = useApi();
  const theme = useTheme();

  const [abrirModalFormasPagamento, setAbrirModalFormasPagamento] =
    useState(false);
  const [cartoes, setCartoes] = useState([]);
  const [marcado, setMarcado] = useState(false);
  const [loadingCartoes, setLoadingCartoes] = useState(true);

  useEffect(() => {
    if (logado) {
      getDadosPagamento();
    }
  }, []);

  useEffect(() => {
    getDadosPagamento();
  }, [abrirModalFormasPagamento]);

  function getDadosPagamento() {
    setLoadingCartoes(true);
    get(
      "/usuario-sessao/client/usuario/carteira/buscar-todos?idUsuario=" +
        dadosAuth.idUsuario
    )
      .then((response) => {
        let cartoes = [];
        response?.data?.content?.forEach((cartao) => {
          cartoes.push(cartao);
        });
        setCartoes(cartoes);
        setLoadingCartoes(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Erro ao buscar cartões");
        setLoadingCartoes(false);
      });
  }

  function Cartao({ cartao }) {
    const [marcado, setMarcado] = useState(false);

    useEffect(() => {
      if (cartaoSelecionado?.id == cartao.id) {
        setMarcado(true);
      } else {
        setMarcado(false);
      }
    }, [cartaoSelecionado]);

    return (
      <CardPagamento
        onClick={() => {
          marcado ? setCartaoSelecionado({}) : setCartaoSelecionado(cartao);
        }}
        sx={{
          boxShadow: 3,
          backgroundColor: marcado
            ? theme.coresCardapio.brand500
            : theme.coresCardapio.background2,
        }}
      >
        <p
          style={{
            color: marcado
              ? theme.coresCardapio.textoClaro
              : theme.coresCardapio.textoEscuro,
          }}
        >
          ({cartao.tipoPagamento}) **** **** **** {cartao.finalCartao}
        </p>
      </CardPagamento>
    );
  }

  return loadingCartoes ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        sx={{ color: theme.coresCardapio.brand500 }}
        style={{ alignSelf: "center" }}
      />
    </div>
  ) : cartoes.length > 0 && logado ? (
    <Fragment>
      <ContainerCartoes>
        {cartoes.map((cartao, index) => {
          return <Cartao key={cartao.id + "cartao"} cartao={cartao} />;
        })}
      </ContainerCartoes>
      <ContainerTextoLaranja>
        <TextoLaranja
          style={{
            cursor: "pointer",
            borderBottom: "1px solid " + theme.coresCardapio.brand500,
            fontWeight: 400,
          }}
          onClick={() => setAbrirModalFormasPagamento(true)}
        >
          Adicionar Cartão
        </TextoLaranja>
      </ContainerTextoLaranja>
      <ModalFormasPagamento
        aberto={abrirModalFormasPagamento}
        setAberto={setAbrirModalFormasPagamento}
        novaEntrada={true}
      />
    </Fragment>
  ) : (
    <Fragment>
      <TextoPretoLeve style={{ marginTop: 16 }}>
        Você ainda não tem um cardão cadastrado
      </TextoPretoLeve>
      <BotaoCardapioVazado
        style={{ height: 54 }}
        texto={"CADASTRAR CARTÃO"}
        onClick={() => setAbrirModalFormasPagamento(true)}
      />
      <ModalFormasPagamento
        aberto={abrirModalFormasPagamento}
        setAberto={setAbrirModalFormasPagamento}
        novaEntrada={true}
      />
    </Fragment>
  );
}
